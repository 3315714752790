import type { UseQueryOptions } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';
import type { UpdateUserActionRequest } from '@spotnana/types/openapi/models/update-user-action-request';
import type { UserActionUpdateResponse } from '@spotnana/types/openapi/models/user-action-update-response';
import type { GetMarriottBonvoyMemberDataResponse } from '@spotnana/types/openapi/models/get-marriott-bonvoy-member-data-response';
import type { ColorConfig } from '@spotnana/types/openapi/models/color-config';
import type { GetExpensePartnersResponse } from '@spotnana/types/openapi/models/get-expense-partners-response';
import type { OrganizationId } from '@spotnana/types/openapi/models';
import api from '../api';
import SpotnanaError from '../api/SpotnanaError';
import type { BusinessInfo } from '../types/api/v2/obt/model/business-info';
import type { RolesConfig } from '../types/api/v2/obt/model/roles-config';
import type { SpotnanaQueryResult } from '../types/common';
import type { UserProfile } from '../types/api/v2/obt/model/user-profile';
import type { UserProcessAuthResponseRequest } from '../types/api/v2/obt/model/user-process-auth-response-request';
import type { AuthProvider } from '../types/api/v2/obt/model/auth-provider';
import { defaultQueryClient } from './defaultQueryClient';
import type { UserId } from '../types';
import type { UserCreateRequest } from '../types/api/v2/obt/model/user-create-request';
import type { GetDelegateApproversResponse } from '../types/api/v2/obt/model/get-delegate-approvers-response';
import type { UpdateDelegateApproversRequest } from '../types/api/v2/obt/model/open-api/models/update-delegate-approvers-request';

const userBusinessInfoKey = (userId: string): unknown[] => ['user-business-infos', userId];

const invalidateUserBusinessInfoKey = (userId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(userBusinessInfoKey(userId));

const userRolesKey = (userId: string): unknown[] => ['user-roles-info', userId];

export const useUserBusinessInfo = (userId: string): SpotnanaQueryResult<BusinessInfo> =>
  useQuery<BusinessInfo, SpotnanaError>(
    userBusinessInfoKey(userId),
    () =>
      api('GET', 'userBaseUrl', {
        urlParam: `/${userId}/business-info`,
      }).then((response: unknown) => response as BusinessInfo),
    {
      enabled: !!userId,
      cacheTime: 0,
    },
  );

const updateUserBusinessInfo = async (userId: string, requestBody: BusinessInfo): Promise<void> => {
  await api('PUT', 'userBaseUrl', { urlParam: `/${userId}/business-info`, data: requestBody });
};

export const useUpdateBusinessInfo = (userId: string) =>
  useMutation((requestBody: BusinessInfo) => updateUserBusinessInfo(userId, requestBody), {
    onSuccess: async () => {
      await invalidateUserBusinessInfoKey(userId);
    },
  });

const delegateApproversKey = (userId: string): unknown[] => ['delegate-approvers', userId];

const invalidateDelegateApproversKey = (userId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(delegateApproversKey(userId));

export const useDelegateApprovers = (userId: string): SpotnanaQueryResult<GetDelegateApproversResponse> =>
  useQuery<GetDelegateApproversResponse, SpotnanaError>(
    delegateApproversKey(userId),
    () =>
      api('GET', 'userBaseUrl', { urlParam: `/${userId}/delegate-approvers` }).then(
        (response: unknown) => response as GetDelegateApproversResponse,
      ),
    {
      enabled: !!userId,
      cacheTime: 0,
    },
  );

const updateDelegateApprovers = async (userId: string, requestBody: UpdateDelegateApproversRequest): Promise<void> => {
  await api('PUT', 'userBaseUrl', { urlParam: `/${userId}/delegate-approvers`, data: requestBody });
};

export const useUpdateDelegateApprover = (userId: string) =>
  useMutation((requestBody: UpdateDelegateApproversRequest) => updateDelegateApprovers(userId, requestBody), {
    onSuccess: async () => {
      await invalidateDelegateApproversKey(userId);
    },
  });

export const useUserRoles = (userId: string, enabled: boolean): SpotnanaQueryResult<RolesConfig> =>
  useQuery<RolesConfig, SpotnanaError>(
    userRolesKey(userId),
    () =>
      api('GET', 'userBaseUrl', {
        urlParam: `/${userId}/roles `,
      }).then((response: unknown) => response as RolesConfig),
    {
      enabled,
    },
  );

export const fetchUserDetails = async (userId: string, includeInactive?: boolean): Promise<UserProfile> => {
  const urlParam = includeInactive ? `/${userId}?includeInactive=true` : `/${userId}`;
  const data = await api('GET', 'userBaseUrl', {
    urlParam,
  });
  return data as UserProfile;
};

const userDetailsKey = (userId: string): ['user-details', string] => ['user-details', userId];
type UseGetUserParams = {
  userId: string;
  includeInactive?: boolean;
};

const multipleUserDetailsKey = (userIds: string[]): ['user-details-multiple', string] => [
  'user-details-multiple',
  userIds.length ? [...userIds].sort().join(',') : 'empty-list',
];

export const invalidateGetUserQuery = (userId: string) => {
  return defaultQueryClient.invalidateQueries(userDetailsKey(userId));
};

export const removeGetUserQueryCache = (userId: string) => {
  defaultQueryClient.removeQueries(userDetailsKey(userId));
};

export const removeGetMultipleUserQueryCache = (userIds: string[]) => {
  defaultQueryClient.removeQueries(multipleUserDetailsKey(userIds));
  for (const userId of userIds) {
    removeGetUserQueryCache(userId);
  }
};

export const useGetUser = (
  { userId, includeInactive }: UseGetUserParams,
  options?: UseQueryOptions<UserProfile, SpotnanaError>,
): SpotnanaQueryResult<UserProfile> =>
  useQuery<UserProfile, SpotnanaError>(
    userDetailsKey(userId),
    () => fetchUserDetails(userId, includeInactive),
    options,
  );

type UseGetMultipleUsersParams = {
  userIds: string[];
  includeInactive?: boolean;
};
export const useGetMultipleUsers = (
  input: UseGetMultipleUsersParams,
  options?: UseQueryOptions<UserProfile, SpotnanaError>,
): SpotnanaQueryResult<UserProfile>[] =>
  useQueries(
    input.userIds.map((userId) => ({
      queryKey: userDetailsKey(userId),
      queryFn: () => fetchUserDetails(userId),
      options,
    })),
  ) as SpotnanaQueryResult<UserProfile>[];

export const fetchMultipleUserDetails = async (
  userIds: string[],
  includeInactive?: boolean,
): Promise<UserProfile[]> => {
  return Promise.all(
    userIds.map((userId) =>
      defaultQueryClient.fetchQuery({
        queryKey: userDetailsKey(userId),
        queryFn: () => fetchUserDetails(userId, includeInactive),
      }),
    ),
  );
};

export const useGetMultipleUsersWithSingleQuery = (
  { userIds, includeInactive }: UseGetMultipleUsersParams,
  options?: UseQueryOptions<UserProfile[], SpotnanaError>,
): SpotnanaQueryResult<UserProfile[]> => {
  return useQuery({
    ...options,
    queryKey: multipleUserDetailsKey(userIds),
    queryFn: () => fetchMultipleUserDetails(userIds, includeInactive),
  });
};

interface UserAuthConfigResponse {
  authProviders?: AuthProvider[];
  idleSessionTimeoutMin?: number;
  absoluteSessionTimeoutMin?: number;
}

export function fetchUserAuthConfig(userId: string): Promise<UserAuthConfigResponse> {
  return api(
    'GET',
    'userBaseUrl',
    { urlParam: `/${userId}/auth-config` },
    { shouldNotShowErrorForNotFound: true },
  ).then((res) => res as UserAuthConfigResponse);
}

function getUserAuthConfigKey(userId: string): string[] {
  return ['userAuthConfig', userId];
}

export function useUserAuthConfigQuery(
  userId: string | null | undefined,
  options?: UseQueryOptions<UserAuthConfigResponse>,
) {
  return useQuery<UserAuthConfigResponse>({
    ...options,
    queryKey: getUserAuthConfigKey(userId as string),
    queryFn: () => fetchUserAuthConfig(userId as string),
    enabled: !!userId && options?.enabled !== false,
  });
}

const createUser = async (requestBody: UserCreateRequest): Promise<UserId | undefined> => {
  const data = await api('POST', 'userBaseUrl', { data: requestBody });
  const createUserResponse = data as UserId;
  if (createUserResponse) {
    return createUserResponse;
  }
  throw new SpotnanaError('Could not create user');
};
export const useUserCreateMutation = () => useMutation((requestBody: UserCreateRequest) => createUser(requestBody), {});

const bulkCreateUsers = async (requestBody: {
  users: UserCreateRequest[];
  organizationRef: OrganizationId | undefined;
}): Promise<{ userIds: UserId[] } | undefined> => {
  const data = await api('POST', 'userBaseUrl', { urlParam: `/bulk-create`, data: requestBody });
  const bulkCreateUsersResponse = data as { userIds: UserId[] };
  if (bulkCreateUsersResponse) {
    return bulkCreateUsersResponse;
  }
  throw new SpotnanaError('Could not create user');
};

export const useBulkCreateUsersMutation = () => {
  return useMutation((requestBody: { users: UserCreateRequest[]; organizationRef: OrganizationId | undefined }) =>
    bulkCreateUsers(requestBody),
  );
};

/**
 * Updates a user action.
 * @param userId - The ID of the user.
 * @param request - The request body for updating the user action.
 * @returns The response containing the updated user action.
 */
const updateUserAction = (userId: string, request: UpdateUserActionRequest): Promise<UserActionUpdateResponse> => {
  return api('PUT', 'userBaseUrl', { urlParam: `/${userId}/action-update`, data: request }).then(
    (res) => res as UserActionUpdateResponse,
  );
};

/**
 * Returns a mutation function for updating a user action.
 * @param userId - The ID of the user.
 * @returns The mutation function for updating the user action.
 */
export const useUpdateUserAction = (userId: string) => {
  return useMutation((request: UpdateUserActionRequest) => updateUserAction(userId, request));
};

const getMarriottBonvoyAccountInfo = async (userId: string): Promise<GetMarriottBonvoyMemberDataResponse> => {
  return api('GET', 'userBaseUrl', { urlParam: `/${userId}/marriott-bonvoy-member-data` }).then(
    (res) => res as GetMarriottBonvoyMemberDataResponse,
  );
};

export const useGetMarriottBonvoyAccountInfo = ({ userId, enabled }: { userId: string; enabled: boolean }) => {
  return useQuery<GetMarriottBonvoyMemberDataResponse>({
    queryKey: ['marriott-bonvoy-account-info', userId],
    queryFn: () => getMarriottBonvoyAccountInfo(userId as string),
    enabled,
  });
};

const getUserColorConfig = async (userId: string): Promise<ColorConfig> => {
  return api('GET', 'userBaseUrl', { urlParam: `/${userId}/color-config` }).then((res) => res as ColorConfig);
};

export const useGetUserColorConfig = ({ userId, enabled }: { userId: string; enabled: boolean }) => {
  return useQuery<ColorConfig>({
    queryKey: ['user-color-config', userId],
    queryFn: () => getUserColorConfig(userId as string),
    enabled,
  });
};

const processAuthorization = (userId: string, request: UserProcessAuthResponseRequest) => {
  return api('POST', 'userBaseUrl', { urlParam: `/${userId}/process-authorization-response`, data: request });
};

export const useProcessAuthorization = (userId: string) => {
  return useMutation((request: UserProcessAuthResponseRequest) => processAuthorization(userId, request));
};

const unlinksMarriottBonvoyAccount = (userId: string) => {
  return api('DELETE', 'userBaseUrl', { urlParam: `/${userId}/marriott-bonvoy-account` });
};

export const useUnlinksMarriottBonvoyAccount = (userId: string) => {
  return useMutation(() => unlinksMarriottBonvoyAccount(userId));
};

const getUserExpensePartnersDetails = async (userId: string): Promise<GetExpensePartnersResponse> => {
  return api('GET', 'userBaseUrl', { urlParam: `/${userId}/expense-partners` }).then(
    (res) => res as GetExpensePartnersResponse,
  );
};

export const useGetUserExpensePartnersDetails = ({ userId }: { userId: string }) => {
  return useQuery<GetExpensePartnersResponse>({
    queryKey: ['expense-partners', userId],
    queryFn: () => getUserExpensePartnersDetails(userId as string),
    enabled: !!userId,
  });
};
