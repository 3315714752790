import { userRolesByFeature, ProcessedError, RBAC } from 'obt-common';
import Box from '@spotnana/pixel-react/dist/Box';
import Grid from '@spotnana/pixel-react/dist/Grid';
import * as Icons from '@spotnana/pixel-react/dist/Icons';
import { IconButton } from '@spotnana/pixel-react/dist/Button';
import CloseIcon from '@spotnana/pixel-react/dist/Icons/Close';
import Snackbar from '@spotnana/pixel-react/dist/Snackbar';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import Typography from '@spotnana/pixel-react/dist/Typography';

import { useTranslation } from 'react-i18next';
import { Button } from '@spotnana/blocks/src/Button';
import { convertRichTextToPlainText } from 'src/components/RichTextEditor/utils';
import { ROUTE_PATHS } from 'src/routes';
import { useCallback, useState } from 'react';
import useGetErrorMessageByCode from '../hooks/useGetErrorMessageByCode';
import ViewErrorDetails from '../app/shared/components/ViewErrorDetails';

interface IProps {
  errorCode: ProcessedError['errorCode'];
  errorParameters?: ProcessedError['errorParameters'];
  sourceErrorMessages?: ProcessedError['sourceErrorMessages'];
  onClose?: () => void;
  showAPIErrorCodes?: boolean;
  autoHideDuration?: number | null;
  debugId?: string;
}

const ErrorSnackbar: React.FC<IProps> = ({
  showAPIErrorCodes = true,
  errorCode,
  errorParameters,
  sourceErrorMessages,
  onClose,
  debugId,
  autoHideDuration,
}: IProps) => {
  const getErrorMessage = useGetErrorMessageByCode();
  const { t: tt } = useTranslation('WEB');

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const openModal = useCallback(() => setShowDetailsModal(true), [setShowDetailsModal]);
  const handleClose = useCallback(() => setShowDetailsModal(false), [setShowDetailsModal]);

  if (!errorCode) {
    return null;
  }

  const hasSourceErrorMessages = sourceErrorMessages !== undefined;
  const errorMessage = getErrorMessage({
    errorCode,
    errorParameters,
  });

  return (
    <Snackbar
      open
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
    >
      <Box
        bg="bg.regular"
        width="100vw"
        maxWidth={[600, 640, 768, 1024]}
        border="thinSolid"
        borderColor="border.disabled"
        px={20}
        py={16}
        mb="8px"
        borderRadius="lg"
      >
        <Grid.Row middle="xs">
          <Grid.Col>
            <Icons.Warning width={32} height={32} primaryColor={spotnanaTheme.colors.error} />
          </Grid.Col>

          <Grid.Col xs>
            <Grid.Row alignItems="center">
              <Grid.Col xs>
                <Typography
                  data-testid="handled-error-global"
                  variation="small"
                  color={spotnanaTheme.colors.error}
                  lineHeight={1.5}
                  as="div"
                >
                  {/**
                   * Some error reasons are stored in rich text in the backend. Convert these to plain text equivalent for rendering in ErrorSnackbar.
                   * Alternatively in the future, we can choose to use the RichTextDisplay component to render the message with full formatting.
                   */}
                  {convertRichTextToPlainText(errorMessage)}
                  <RBAC
                    allowedRoles={userRolesByFeature.adminPages}
                    secondaryPredicate={() => showAPIErrorCodes && !hasSourceErrorMessages}
                  >
                    {` (${errorCode})`}
                  </RBAC>
                </Typography>
              </Grid.Col>

              {showAPIErrorCodes && hasSourceErrorMessages && (
                <Grid.Col>
                  <Button variant="ghost" onClick={openModal}>
                    <Typography variant="body1">{tt('View error details')}</Typography>
                  </Button>
                  <ViewErrorDetails
                    open={showDetailsModal}
                    code={errorCode}
                    sourceErrorMessages={sourceErrorMessages}
                    handleClose={handleClose}
                  />
                </Grid.Col>
              )}
            </Grid.Row>
          </Grid.Col>
          {debugId && (
            <RBAC
              allowedRoles={userRolesByFeature.globalTmcAdminAndAgentRoles}
              condition="OR"
              secondaryPredicate={(userBasicInfo) => {
                return userBasicInfo.isImpersonationSession;
              }}
            >
              <Grid.Col justifyContent="center">
                <Grid.Row>
                  <Button
                    variant="ghost"
                    color="blue"
                    onClick={() => {
                      window.open(`${ROUTE_PATHS.SUPPORT_DEBUG_TOOL}?type=debugId&queryValue=${debugId}`);
                      onClose && onClose();
                    }}
                  >
                    {tt('Open Debug Tool')}
                  </Button>
                </Grid.Row>
              </Grid.Col>
            </RBAC>
          )}

          <Grid.Col>
            {onClose && (
              <IconButton data-testid="close-icon" onClick={onClose}>
                {(): JSX.Element => <CloseIcon />}
              </IconButton>
            )}
          </Grid.Col>
        </Grid.Row>
      </Box>
    </Snackbar>
  );
};

export default ErrorSnackbar;
