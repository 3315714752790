/* eslint-disable no-underscore-dangle */

import {
  Config,
  useFeatureFlag,
  useLoggedInUser,
  useLoggedInUserBasicInfo,
  useOrganizationReadBasicInfoQuery,
} from 'obt-common';
import { getOrgRelatedAnalyticsData, getUserRelatedAnalyticsData } from 'obt-common/utils/analyticsData';
import { useEffect } from 'react';

declare global {
  interface Window {
    pendo: any;
  }
}

type InitialisePendoInternalProps = {
  apiKey: string;
};

const pendoScript = (apiKey: string) => {
  (function mainFunction(p1: any, e: Document, n: string, d: string) {
    const p = p1;
    p[d] = p1[d] || {};
    const o = p[d];
    o._q = o._q || [];
    const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (let w = 0, x = v.length; w < x; w += 1) {
      (function helper1(m: string) {
        o[m] =
          o[m] ||
          function helper2(...args: any[]) {
            o._q[m === v[0] ? 'unshift' : 'push']([m, ...args]);
          };
      })(v[w]);
    }
    const y = e.createElement(n) as HTMLScriptElement;
    y.async = true;
    y.src = `https://content.spotnana.com/agent/static/${apiKey}/pendo.js`;
    const z = e.getElementsByTagName(n)[0];
    z.parentNode?.insertBefore(y, z);
  })(window, document, 'script', 'pendo');
};

const InitialisePendoInternal = ({ apiKey }: InitialisePendoInternalProps) => {
  pendoScript(apiKey);
  const { data: userData, isLoading: isLoggedInUserDataLoading } = useLoggedInUser();
  const userBasicInfo = useLoggedInUserBasicInfo();
  const { data: userOrgData, isLoading: isUserOrganizationInfoLoading } = useOrganizationReadBasicInfoQuery(
    userBasicInfo.existingUser?.userOrgId?.organizationId,
  );
  const { data: impersonatorOrgData, isLoading: isImpersonatorOrganizationInfoLoading } =
    useOrganizationReadBasicInfoQuery(userBasicInfo.impersonatingUserInfo?.userOrgId?.organizationId);

  useEffect(() => {
    if (isUserOrganizationInfoLoading || isImpersonatorOrganizationInfoLoading || isLoggedInUserDataLoading) {
      return;
    }

    const { isImpersonationSession, impersonatingUserInfo } = userBasicInfo;
    const impersonatedUserId = userData?.userOrgId?.userId?.id;
    const impersonatorUserId = impersonatingUserInfo?.userOrgId?.userId?.id;
    const impersonatorDomain = impersonatorOrgData?.name;
    const impersonationId = `impersonated-${impersonatedUserId}-by-${impersonatorUserId}@${impersonatorDomain}`;

    let visitorId;
    if (Config.VITE_ENVIRONMENT === 'production') {
      visitorId = isImpersonationSession ? impersonationId : userData?.userOrgId?.userId?.id;
    } else {
      visitorId = isImpersonationSession ? `staging-${impersonationId}` : `staging-${userData?.userOrgId?.userId?.id}`;
    }

    const accountId =
      Config.VITE_ENVIRONMENT === 'production'
        ? userBasicInfo.existingUser?.userOrgId?.organizationId?.id
        : `staging-${userBasicInfo.existingUser?.userOrgId?.organizationId?.id}`;

    const pendoPayload = {
      visitor: {
        id: visitorId,
        ...getUserRelatedAnalyticsData(userData, userBasicInfo, userOrgData),
      },
      account: {
        id: accountId,
        ...getOrgRelatedAnalyticsData(userData, userOrgData),
      },
    };

    if (window.pendo.isReady && window.pendo.isReady()) {
      window.pendo.identify(pendoPayload);
    }
    window.pendo.initialize(pendoPayload);
  }, [
    isLoggedInUserDataLoading,
    isUserOrganizationInfoLoading,
    userData,
    userBasicInfo,
    isImpersonatorOrganizationInfoLoading,
    impersonatorOrgData,
    userOrgData,
  ]);

  return null;
};

export const InitialisePendo = () => {
  const isPendoInstallationSnippetEnabled = useFeatureFlag('FE_INFRA_PENDO_INSTALLATION_SNIPPET');
  const apiKey = Config.VITE_PENDO_WEB_API_KEY;
  if (!isPendoInstallationSnippetEnabled || !apiKey) {
    return null;
  }
  return <InitialisePendoInternal apiKey={apiKey} />;
};
