import type { HotelSpecialRequestsAccessibleFeaturesEnum as HotelSpecialRequestsAccessibleFeaturesEnumType } from '../../types/api/v2/obt/model/hotel-special-requests';
import { HotelSpecialRequestsAccessibleFeaturesEnum } from '../../types/api/v2/obt/model/hotel-special-requests';
import { HotelRateCodeTypeEnum, SortOptionSortOrderEnum } from '../../types';
import type {
  HotelChainCode,
  HotelSpecialRequestConfig,
  HotelSpecialRequestFieldType,
  IHotelDetailFilter,
  IHotelDetailFilterV2,
  IHotelsFilterState,
  IHotelSuggestion,
  IKeyLabelPair,
  IKeyLabelPairWithMetaInfo,
  RateOptionFilters,
} from '../../types';
import {
  BedTypeEnum,
  CancellationPolicyRefundableEnum,
  PrepaidQualifier,
  RateInfoRateSource,
  RoomAmenityRoomAmenityTypeEnum,
  RoomViewTypeEnum,
} from '../../types/api/v1/obt/hotel/hotel_details_response';
import { SortOptionsSortBy } from '../../types/api/v1/obt/hotel/hotel_search_request';
import { LengthUnitEnum } from '../../types/api/v1/common/length';
import { defineMessage } from '../../translations/defineMessage';
import { HotelAmenitiesTypeEnum } from '../../types/api/v2/obt/model/hotel-amenities';
import type { HotelSpecialRequests } from '../../types/api/v1/obt/common/hotel_special_requests';
import {
  AccessibleFeature,
  HotelSpecialRequestsCheckIn,
  HotelSpecialRequestsRoomFeature,
  HotelSpecialRequestsRoomLocation,
  SupportedHotelSpecialFeatureGroupType,
} from '../../types/api/v1/obt/common/hotel_special_requests';

export const PER_ROOM_LIMIT = 5;

export const DEFAULT_NUMBER_OF_TRAVELERS_PER_ROOM = { adults: 1, children: 0 };

/**
 * @deprecated
 */
export const pricesMarks: { value: number; label: string }[] = [
  { value: 1, label: '$1' },
  { value: 500, label: '$500' },
  { value: 1000, label: '$1000' },
];

/**
 * @deprecated
 */
export const ratingMarks: { [k: string]: { value: number; label: string } } = {
  1: { value: 1, label: '★' },
  2: { value: 2, label: '★★' },
  3: { value: 3, label: '★★★' },
  4: { value: 4, label: '★★★★' },
  5: { value: 5, label: '★★★★★' },
};

export const hotelBrands: Record<string, string> = {
  CW: 'Carlson-Rezidor',
  EC: 'Choice',
  EH: 'Hilton',
  '6C': 'InterContinental',
  EM: 'Marriott',
  RR: 'Master-Red',
  NH: 'NH',
  TC: 'TravelCLICK',
  WR: 'Wyndham',
};

export const hotelChains: Record<HotelChainCode, string> = {
  RT: 'Accor Hotels',
  EC: 'Choice Hotels',
  GA: 'Global Hotel Alliances',
  EH: 'Hilton Hotels',
  HY: 'Hyatt Hotels',
  '6C': 'IHG Hotels',
  TC: 'Independent Hotels',
  EM: 'Marriott Hotels',
  NH: 'NH Hotels',
  PV: 'Preferred Hotels',
  RD: 'Radisson Hotels',
  RR: 'Red Roof Inns',
  WW: 'World Hotels',
  WR: 'Wyndham Hotels',
};

/**
 * This map is needed as there are sometimes two master code for a single chain
 * This happens when sometimes chains merge into one;
 * We use one code internally but use all corresponding codes when making API Calls
 */
export const sabreMasterCodesForHotelChains: Record<HotelChainCode, string[]> = {
  '6C': ['6C'],
  HY: ['HY', 'HU'],
  EC: ['EC'],
  EH: ['EH'],
  EM: ['EM'],
  NH: ['NH'],
  RR: ['RR'],
  TC: ['TC', 'YX'],
  WR: ['WR'],
  RD: ['RD', 'CW'],
  RT: ['RT'],
  WW: ['WW'],
  PV: ['PV'],
  GA: ['GA'],
};

/**
 * @deprecated
 */
export const hotelAmenitiesAssetMap = {
  // FREE_AIRPORT_SHUTTLE: 'airport_shuttle',  disabling it temporarily till sabre returns the expected response
  COMPLIMENTARY_BREAKFAST: 'free_breakfast',
  FREE_PARKING: 'local_parking',
  POOL: 'pool',
  NON_SMOKING_ROOMS_GENERIC: 'smoke_free',
};

export const hotelPropertyAssetMap = {
  APARTMENT: 'APARTMENT',
  HOTEL: 'local_parking',
  BED_AND_BREAKFAST: 'pool',
};

export const maxAmenitiesToShow = 10;

export const hotelCommonTranslationKeys = {
  SWITCH_TO_LIST_VIEW: 'SWITCH_TO_LIST_VIEW',
  SWITCH_TO_MAP_VIEW: 'SWITCH_TO_MAP_VIEW',
};

export const hotelMultipaxTranslationKeys = {
  ADD_ROOM: 'ADD_ROOM',
  DONE: 'DONE',
};

export const hotelSearchTranslationKeys = {
  SEARCH_LABEL: 'SEARCH_LABEL',
  LOADING_LOCATIONS: 'LOADING_LOCATIONS',
  FROM: 'FROM',
  TO: 'TO',
  SEARCH_HOTELS: 'SEARCH_HOTELS',
  CHECK_IN: 'CHECK_IN',
  CHECK_IN_DATE: 'CHECK_IN_DATE',
  CHECK_OUT: 'CHECK_OUT',
  CHECK_OUT_DATE: 'CHECK_OUT_DATE',
  SEARCHING_HOTELS: 'SEARCHING_HOTELS',
  SEARCHING_HOTELS_DESCRIPTION: 'SEARCHING_HOTELS_DESCRIPTION',
  NO_RESULTS_FOUND_DESCRIPTION: 'NO_RESULTS_FOUND_DESCRIPTION',
  SEARCH_THIS_AREA: 'SEARCH_THIS_AREA',
  RESET: 'RESET',
};

export const hotelFilterTranslationKeys = {
  SUMMARY: 'SUMMARY',
  RATING: 'RATING',
  BRANDS: 'BRANDS',
  AMENITIES: 'AMENITIES',
  RATES: 'RATES',
  FACILITIES: 'FACILITIES',
  PRICE: 'PRICE',
  DISTANCE: 'DISTANCE',
  NEAR_LOCATION: 'NEAR_LOCATION',
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  FILTER_BY_HOTEL_NAME: 'FILTER_BY_HOTEL_NAME',
  SOLD_OUT: 'SOLD_OUT',
  HOTEL_SEARCH_AREA: 'HOTEL_SEARCH_AREA',
  MORE_FILTERS: 'MORE_FILTERS',
};

export const hotelListTranslationKeys = {
  STAR_HOTEL: 'STAR_HOTEL',
  HOTEL_DETAILS: 'HOTEL_DETAILS',
  AVERAGE_NIGHTLY_RATE: 'AVERAGE_NIGHTLY_RATE',
  ROOM_DETAILS: 'ROOM_DETAILS',
  RELEVANCE: 'RELEVANCE',
  PRICE: 'PRICE',
  PRICE_LOWEST: 'PRICE_LOWEST',
  PRICE_HIGHEST: 'PRICE_HIGHEST',
  DISTANCE: 'DISTANCE',
  CLOSEST_DISTANCE: 'CLOSEST_DISTANCE',
  HIGHEST_RATING: 'HIGHEST_RATING',
  SORT_BY: 'SORT_BY',
  MORE_SORTING_OPTIONS: 'MORE_SORTING_OPTIONS',
  NO_AVAILABILITY: 'NO_AVAILABILITY',
  AWAY: 'AWAY',
  FOR_COUNT_NIGHTS: 'FOR_COUNT_NIGHTS',
};

export const hotelDetailTranslationKeys = {
  HOTEL_INFORMATION: 'HOTEL_INFORMATION',
  WEBSITE: 'WEBSITE',
  AMENITIES: 'AMENITIES',
  AVAILABLE_ROOMS: 'AVAILABLE_ROOMS',
  NIGHT: 'NIGHT',
  MORE: 'MORE',
  LESS: 'LESS',
  FOR: 'FOR',
  DAYS: 'DAYS',
  SELECT: 'SELECT',
  ROOMS: 'ROOMS',
  BACK_TO_RESULTS: 'BACK_TO_RESULTS',
  HOTEL_DETAILS: 'HOTEL_DETAILS',
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  STARTING_FROM: 'STARTING_FROM',
  FILTER_BY: 'FILTER_BY',
  EARN_LOYALTY_POINTS: 'EARN_LOYALTY_POINTS',
  SHOW_CONDITIONAL_RATES: 'SHOW_CONDITIONAL_RATES',
  FREE_CANCELLATION: 'FREE_CANCELLATION',
  NON_REFUNDABLE: 'NON_REFUNDABLE',
  BREAKFAST_INCLUDED: 'BREAKFAST_INCLUDED',
  BREAKFAST_NOT_INCLUDED: 'BREAKFAST_NOT_INCLUDED',
  ELIGIBLE_FOR_LOYALTY_POINTS: 'ELIGIBLE_FOR_LOYALTY_POINTS',
  NOT_ELIGIBLE_FOR_LOYALTY_POINTS: 'NOT_ELIGIBLE_FOR_LOYALTY_POINTS',
  PRICE_DETAILS: 'PRICE_DETAILS',
  LOCATION: 'LOCATION',
  NO_FILTERS_APPLIED: 'NO_FILTERS_APPLIED',
  FILTER: 'FILTER',
  APPLIED: 'APPLIED',
  SHOW_MORE: 'SHOW_MORE',
  SHOW_LESS: 'SHOW_LESS',
  SHOW_ROOMS: 'SHOW_ROOMS',
  NO_ROOMS_AVAILABLE: 'NO_ROOMS_AVAILABLE',
  NO_ROOMS_AVAILABLE_WITH_FILTER: 'NO_ROOMS_AVAILABLE_WITH_FILTER',
  OPEN_WITH: 'OPEN_WITH',
  UNTIL: 'UNTIL',
  ON_CANCELLATION: 'ON_CANCELLATION',
  NOT_ELIGIBLE_REFUND: 'NOT_ELIGIBLE_REFUND',
  NEGOTIATED_RATE: 'NEGOTIATED_RATE',
  SPOTNANA_RATE: 'SPOTNANA_RATE',
};

export const hotelCheckoutTranslationKeys = {
  HOTEL_SUMMARY: 'HOTEL_SUMMARY',
  CHECK_IN: 'CHECK_IN',
  CHECK_OUT: 'CHECK_OUT',
  TELEPHONE: 'TELEPHONE',
  VIEW_ALL: 'VIEW_ALL',
  VIEW_LESS: 'VIEW_LESS',
  TRAVELER_INFO: 'TRAVELER_INFO',
  CONFIRMATION: 'CONFIRMATION',
  REVALIDATING_HOTEL_PRICE: 'REVALIDATING_HOTEL_PRICE',
  REVALIDATING_PRICE: 'REVALIDATING_PRICE',
  BOOKING: 'BOOKING',
  BOOKING_HOTEL: 'BOOKING_HOTEL',
  BOOK_HOTEL: 'BOOK_HOTEL',
  BOOKING_SUCCESS: 'BOOKING_SUCCESS',
  PNR_NUMBER: 'PNR_NUMBER',
  GO_HOMEPAGE: 'GO_HOMEPAGE',
  BASE_AMOUNT: 'BASE_AMOUNT',
  SUMMARY_OF_CHARGES: 'SUMMARY_OF_CHARGES',
  TAX: 'TAX',
  FEES: 'FEES',
  TOTAL: 'TOTAL',
  CONFIRM_AND_PAY: 'CONFIRM_AND_PAY',
  BACK_TO_DETAILS: 'BACK_TO_DETAILS',
};

export const facilitiesFilterConfig = [
  { value: HotelAmenitiesTypeEnum.Parking, label: defineMessage('Parking') },
  { value: HotelAmenitiesTypeEnum.Pool, label: defineMessage('Pool') },
  { value: HotelAmenitiesTypeEnum.Spa, label: defineMessage('Spa') },
  { value: HotelAmenitiesTypeEnum.Restaurant, label: defineMessage('Restaurant') },
  { value: HotelAmenitiesTypeEnum.CommunalBarArea, label: defineMessage('Bar') },
  { value: HotelAmenitiesTypeEnum.FamilyPlan, label: defineMessage('Family friendly') },
  { value: HotelAmenitiesTypeEnum.FitnessCenter, label: defineMessage('Fitness center') },
  { value: HotelAmenitiesTypeEnum.OnsiteLaundry, label: defineMessage('Laundry services') },
  { value: HotelAmenitiesTypeEnum.AccessibleRooms, label: defineMessage('Accessible') },
  { value: HotelAmenitiesTypeEnum.Transportation, label: defineMessage('Transportation') },
  { value: HotelAmenitiesTypeEnum.BusinessCenter, label: defineMessage('Business center') },
  { value: HotelAmenitiesTypeEnum.PetsAllowed, label: defineMessage('Pet friendly') },
  { value: HotelAmenitiesTypeEnum.Beachfront, label: defineMessage('Beach access') },
];

export type AmenitiesInSearchPageFilter = (typeof facilitiesFilterConfig)[number]['value'];

export const hotelPropertyTypeConfig: { value: string; label: string; icon: string }[] = [
  { value: 'HOTEL', label: defineMessage('Hotel'), icon: 'hotel' },
  { value: 'APARTMENT', label: defineMessage('Home or apartment'), icon: 'apartment' },
  { value: 'BED_AND_BREAKFAST', label: defineMessage('Bed and breakfast'), icon: 'bad_and_breakfast' },
];

export const distanceMarksMiles: { distance: number; label: string }[] = [
  { distance: 1, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 3, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 5, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 10, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 20, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 50, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
];

export const distanceMarksKms: { distance: number; label: string }[] = [
  { distance: 2, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 5, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 8, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 15, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 30, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
  { distance: 80, label: hotelFilterTranslationKeys.HOTEL_SEARCH_AREA },
];

export const distanceMarksMilesSlider: { value: number; label: string }[] = [
  { value: 1, label: '' },
  { value: 3, label: '' },
  { value: 5, label: '' },
  { value: 10, label: '' },
  { value: 20, label: '' },
  { value: 50, label: '' },
];

export const distanceMarksKmsSlider: { value: number; label: string }[] = [
  { value: 2, label: '' },
  { value: 5, label: '' },
  { value: 8, label: '' },
  { value: 15, label: '' },
  { value: 30, label: '' },
  { value: 80, label: '' },
];

export const emptyIHotelSuggestion: IHotelSuggestion = {
  type: '',
  name: '',
  location: '',
  state: '',
  country: '',
  data: '',
  sessionToken: '',
};

export const defaultRadiusInMiles = { length: 20, unit: LengthUnitEnum.MILE };

export const defaultRadiusInKilometers = { length: 15, unit: LengthUnitEnum.KM };

export const defaultHotelFilters: Required<Omit<IHotelsFilterState, 'distance' | 'modifiableOnly'>> = {
  radius: defaultRadiusInMiles,
  rates: [0, 1000],
  brands: [],
  amenities: [],
  rateTypes: [],
  rateType: HotelRateCodeTypeEnum.REGULAR,
  rating: [],
  nameQuery: '',
  showUnavailable: false,
  prepaidQualifier: PrepaidQualifier.INCLUDE_PREPAID,
  payByPoints: false,
  refundableOnly: false,
  propertyTypes: [],
  preferredOnly: false,
};

/**
 * @deprecated
 */
export const defaultRateOptionsFilters: RateOptionFilters = {
  eligibleForLoyalty: false,
  freeCancellation: false,
  qualifiedRate: false,
  prepaidRoomsOnly: false,
  payByPointsOnly: false,
  isModifiable: false,
};

/** @deprecated */
export const defaultHotelDetailsFilters: IHotelDetailFilter = {
  eligibleForLoyalty: false,
  showOutOfPolicy: true,
  rates: [0, 1000],
  inclusionsTypes: [],
  bedTypes: [],
  refundableOnly: false,
  rateTypes: [],
  payByPoints: false,
  prepaidQualifier: PrepaidQualifier.INCLUDE_PREPAID,
};

export const defaultHotelDetailsFiltersV2: IHotelDetailFilterV2 = {
  payByPoints: false,
  showOutOfPolicy: true,
  refundable: false,
  eligibleForLoyalty: false,
  showConditional: false,
  prepaid: false,
  payAtProperty: false,
  complimentaryBreakfast: false,
  accessible: false,
};

export const ICancellationPolicyRefundableEnum = CancellationPolicyRefundableEnum;

export const defaultHotelsSortType = SortOptionsSortBy.UNRECOGNIZED;

/** @deprecated */
export const hotelSortTypeOptionsOld: IKeyLabelPair<SortOptionsSortBy>[] = [
  { backendKey: SortOptionsSortBy.UNRECOGNIZED, labelKey: hotelListTranslationKeys.RELEVANCE },
  { backendKey: SortOptionsSortBy.PRICE, labelKey: hotelListTranslationKeys.PRICE },
  { backendKey: SortOptionsSortBy.DISTANCE, labelKey: hotelListTranslationKeys.DISTANCE },
];

export const hotelSortTypeOptions: IKeyLabelPairWithMetaInfo[] = [
  {
    backendKey: SortOptionsSortBy.UNRECOGNIZED,
    labelKey: hotelListTranslationKeys.RELEVANCE,
    sortOrder: SortOptionSortOrderEnum.ASCENDING,
  },
  {
    backendKey: SortOptionsSortBy.PRICE,
    labelKey: hotelListTranslationKeys.PRICE_LOWEST,
    sortOrder: SortOptionSortOrderEnum.ASCENDING,
  },
  {
    backendKey: SortOptionsSortBy.PRICE,
    labelKey: hotelListTranslationKeys.PRICE_HIGHEST,
    sortOrder: SortOptionSortOrderEnum.DESCENDING,
  },
  {
    backendKey: SortOptionsSortBy.DISTANCE,
    labelKey: hotelListTranslationKeys.CLOSEST_DISTANCE,
    sortOrder: SortOptionSortOrderEnum.ASCENDING,
  },
  {
    backendKey: SortOptionsSortBy.SABRE_RATING,
    labelKey: hotelListTranslationKeys.HIGHEST_RATING,
    sortOrder: SortOptionSortOrderEnum.DESCENDING,
  },
];

export const breakfastRoomAmenities = [
  RoomAmenityRoomAmenityTypeEnum.BUFFET_BREAKFAST,
  RoomAmenityRoomAmenityTypeEnum.MEAL_INCLUDED_BREAKFAST,
  RoomAmenityRoomAmenityTypeEnum.MEAL_INCLUDED_CONTINENTAL_BREAKFAST,
  RoomAmenityRoomAmenityTypeEnum.MEAL_INCLUDED_FULL_AMERICAN_BREAKFAST,
];

// These are in miles
export const HOTELS_SEARCH_RADIUS_MILES_MIN = 0;
export const HOTELS_SEARCH_RADIUS_MILES_MAX = 200;

export const expediaTermsAndConditionsUrl = 'https://developer.expediapartnersolutions.com/terms/en';

export const rateSourceNameMapper: { [key in RateInfoRateSource]?: string } = {
  [RateInfoRateSource.SABRE]: 'Sabre',
  [RateInfoRateSource.BOOKING_COM]: 'Booking.com',
  [RateInfoRateSource.EXPEDIA]: 'Expedia',
  [RateInfoRateSource.OFFLINE]: 'Offline',
  [RateInfoRateSource.HRS]: 'HRS',
  [RateInfoRateSource.BCD]: 'BCD',
  [RateInfoRateSource.QANTAS_HOTELS]: 'Qantas Hotels',
  [RateInfoRateSource.HOTEL_HUB]: 'Hotel Hub',
  [RateInfoRateSource.MARRIOTT]: 'Marriott',
};

export const roomViewLabelsMap: Record<RoomViewTypeEnum, string> = {
  [RoomViewTypeEnum.UNKNOWN]: defineMessage('Unknown View'),
  [RoomViewTypeEnum.AIRPORT]: defineMessage('Airport View'),
  [RoomViewTypeEnum.BAY]: defineMessage('Bay View'),
  [RoomViewTypeEnum.CITY]: defineMessage('City View'),
  [RoomViewTypeEnum.COURTYARD]: defineMessage('Courtyard View'),
  [RoomViewTypeEnum.GOLF]: defineMessage('Golf View'),
  [RoomViewTypeEnum.HARBOR]: defineMessage('Harbor View'),
  [RoomViewTypeEnum.INTERCOASTAL]: defineMessage('Intercoastal View'),
  [RoomViewTypeEnum.LAKE]: defineMessage('Lake View'),
  [RoomViewTypeEnum.MARINA]: defineMessage('Marina View'),
  [RoomViewTypeEnum.MOUNTAIN]: defineMessage('Mountain View'),
  [RoomViewTypeEnum.OCEAN]: defineMessage('Ocean View'),
  [RoomViewTypeEnum.POOL]: defineMessage('Pool View'),
  [RoomViewTypeEnum.RIVER]: defineMessage('River View'),
  [RoomViewTypeEnum.WATER]: defineMessage('Water View'),
  [RoomViewTypeEnum.BEACH]: defineMessage('Beach View'),
  [RoomViewTypeEnum.GARDEN]: defineMessage('Garden View'),
  [RoomViewTypeEnum.PARK]: defineMessage('Park View'),
  [RoomViewTypeEnum.FOREST]: defineMessage('Forest View'),
  [RoomViewTypeEnum.RAIN_FOREST]: defineMessage('Rain forest View'),
  [RoomViewTypeEnum.VARIOUS]: defineMessage('Various Views'),
  [RoomViewTypeEnum.LIMITED]: defineMessage('Limited Views'),
  [RoomViewTypeEnum.SLOPE]: defineMessage('Slope View'),
  [RoomViewTypeEnum.STRIP]: defineMessage('Strip View'),
  [RoomViewTypeEnum.COUNTRYSIDE]: defineMessage('Countryside View'),
  [RoomViewTypeEnum.SEA]: defineMessage('Sea View'),
  [RoomViewTypeEnum.GULF]: defineMessage('Gulf View'),
  [RoomViewTypeEnum.UNRECOGNIZED]: defineMessage('Unknown View'),
};

export const bedTypeLabelsMap: Record<BedTypeEnum, string> = {
  [BedTypeEnum.UNRECOGNIZED]: defineMessage('Unknown'),
  [BedTypeEnum.UNKNOWN_BED_TYPE]: defineMessage('Assigned at hotel'),
  [BedTypeEnum.TATAMI_MATS]: defineMessage('Tatami Mats'),
  [BedTypeEnum.RUN_OF_THE_HOUSE]: defineMessage('Run of the house'),
  [BedTypeEnum.DOUBLE]: defineMessage('Double bed'),
  [BedTypeEnum.FUTON]: defineMessage('Futon'),
  [BedTypeEnum.KING]: defineMessage('King size bed'),
  [BedTypeEnum.MURPHY]: defineMessage('Murphy bed'),
  [BedTypeEnum.QUEEN]: defineMessage('Queen size bed'),
  [BedTypeEnum.SOFA]: defineMessage('Sofa bed'),
  [BedTypeEnum.TWIN]: defineMessage('Twin bed'),
  [BedTypeEnum.SINGLE]: defineMessage('Single bed'),
  [BedTypeEnum.FULL]: defineMessage('Full bed'),
  [BedTypeEnum.DORM]: defineMessage('Dorm bed'),
  [BedTypeEnum.WATER]: defineMessage('Water bed'),
  [BedTypeEnum.PULL_OUT]: defineMessage('Pull out bed'),
};

export const supportedHotelSpecialRequestGroupLabelMap: Record<SupportedHotelSpecialFeatureGroupType, string> = {
  [SupportedHotelSpecialFeatureGroupType.UNRECOGNIZED]: defineMessage('Unrecognized features'),
  [SupportedHotelSpecialFeatureGroupType.ACCESSIBLE_FEATURE]: defineMessage('Accessible features'),
  [SupportedHotelSpecialFeatureGroupType.SUPPORTED_HOTEL_SPECIAL_REQUEST_GROUP_TYPE_UNKNOWN]:
    defineMessage('Unrecognized features'),
};

export const supportedHotelSpecialRequestGroupFieldTypeMap: Partial<
  Record<SupportedHotelSpecialFeatureGroupType, HotelSpecialRequestFieldType>
> = {
  [SupportedHotelSpecialFeatureGroupType.ACCESSIBLE_FEATURE]: 'radio',
};

export const supportedHotelSpecialRequestGroupFieldTypeToKeyMap: Partial<
  Record<SupportedHotelSpecialFeatureGroupType, keyof HotelSpecialRequests>
> = {
  [SupportedHotelSpecialFeatureGroupType.ACCESSIBLE_FEATURE]: 'accessibleFeatures',
};

export const accessibleFeatureOptionsLabelMap: Record<AccessibleFeature, string> = {
  [AccessibleFeature.ACCESSIBLE_FEATURE_UNKNOWN]: defineMessage('Unknown'),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TUB]: defineMessage('Mobility accessible room with tub'),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER]: defineMessage(
    'Mobility accessible room with roll-in shower',
  ),
  [AccessibleFeature.HEARING_ACCESSIBLE_ROOM]: defineMessage('Hearing accessible room'),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_ROLL_IN_SHOWER]: defineMessage(
    'Mobility accessible room with tub and roll-in shower',
  ),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_HEARING_ACCESSIBLE_ROOM]: defineMessage(
    'Mobility accessible room with tub and hearing accessible room',
  ),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_ROLL_IN_SHOWER_AND_HEARING_ACCESSIBLE_ROOM]: defineMessage(
    'Mobility accessible room with roll-in shower and hearing accessible room',
  ),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER]: defineMessage(
    'Mobility accessible room with shower transfer',
  ),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TUB_AND_TRANSFER_SHOWER]: defineMessage(
    'Mobility accessible room with tub and shower transfer',
  ),
  [AccessibleFeature.MOBILITY_ACCESSIBLE_ROOM_WITH_TRANSFER_SHOWER_AND_HEARING_ACCESSIBLE_ROOM]: defineMessage(
    'Mobility accessible room with shower transfer and hearing accessible room',
  ),
  [AccessibleFeature.UNRECOGNIZED]: defineMessage('Unrecognized'),
};

// V2 type map for email
export const accessibleFeaturesMap: Record<HotelSpecialRequestsAccessibleFeaturesEnumType, string> = {
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTub]: defineMessage(
    'Mobility accessible room with tub',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithRollInShower]: defineMessage(
    'Mobility accessible room with roll-in shower',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.HearingAccessibleRoom]: defineMessage('Hearing accessible room'),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTubAndRollInShower]: defineMessage(
    'Mobility accessible room with tub and roll-in shower',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTubAndHearingAccessibleRoom]: defineMessage(
    'Mobility accessible room with tub and hearing accessible room',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithRollInShowerAndHearingAccessibleRoom]:
    defineMessage('Mobility accessible room with roll-in shower and hearing accessible room'),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTransferShower]: defineMessage(
    'Mobility accessible room with shower transfer',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTubAndTransferShower]: defineMessage(
    'Mobility accessible room with tub and shower transfer',
  ),
  [HotelSpecialRequestsAccessibleFeaturesEnum.MobilityAccessibleRoomWithTransferShowerAndHearingAccessibleRoom]:
    defineMessage('Mobility accessible room with shower transfer and hearing accessible room'),
};

export const hotelSpecialRequestsConfig: {
  roomLocation: HotelSpecialRequestConfig;
  roomFeatures: HotelSpecialRequestConfig;
  checkIn: HotelSpecialRequestConfig;
  other: HotelSpecialRequestConfig;
} = {
  roomLocation: {
    title: defineMessage('Location'),
    field: {
      type: 'radio',
      options: [
        {
          label: defineMessage('High floor'),
          value: HotelSpecialRequestsRoomLocation.HIGH_FLOOR,
        },
        {
          label: defineMessage('Low floor'),
          value: HotelSpecialRequestsRoomLocation.LOW_FLOOR,
        },
        {
          label: 'No preference',
          value: HotelSpecialRequestsRoomLocation.ROOM_LOCATION_UNKNOWN,
        },
      ],
    },
  },
  roomFeatures: {
    title: defineMessage('Room features'),
    field: {
      type: 'checkbox',
      options: [
        {
          label: defineMessage('Crib'),
          value: HotelSpecialRequestsRoomFeature.CRIB,
        },
        {
          label: defineMessage('Rollaway bed'),
          value: HotelSpecialRequestsRoomFeature.ROLLAWAY_BED,
        },
        {
          label: defineMessage('Feather free room'),
          value: HotelSpecialRequestsRoomFeature.FEATHER_FREE_ROOM,
        },
        {
          label: defineMessage('Accessible room'),
          value: HotelSpecialRequestsRoomFeature.ACCESSIBLE_ROOM,
        },
        {
          label: defineMessage('Near elevator'),
          value: HotelSpecialRequestsRoomFeature.NEAR_ELEVATOR,
        },
      ],
    },
  },
  checkIn: {
    title: defineMessage('Check-in'),
    field: {
      type: 'radio',
      options: [
        {
          label: defineMessage('Early check-in'),
          value: HotelSpecialRequestsCheckIn.EARLY_CHECK_IN,
        },
        {
          label: defineMessage('Late check-in'),
          value: HotelSpecialRequestsCheckIn.LATE_CHECK_IN,
        },
        {
          label: 'No preference',
          value: HotelSpecialRequestsCheckIn.CHECK_IN_UNKNOWN,
        },
      ],
    },
  },
  other: {
    title: defineMessage('Other special requests'),
    field: {
      type: 'textarea',
      label: defineMessage('Add your request below'),
      placeholder: defineMessage('Write your request here...'),
      characterLimit: 50,
    },
  },
};

export const hotelSpecialRequestsDefaultValues: HotelSpecialRequests = {
  additionalNote: '',
  flightNumber: '',
  roomLocations: [HotelSpecialRequestsRoomLocation.ROOM_LOCATION_UNKNOWN],
  roomFeatures: [],
  checkIn: HotelSpecialRequestsCheckIn.CHECK_IN_UNKNOWN,
  accessibleFeatures: [],
};
