import first from 'lodash/first';

import { FeeType } from '../../../types/api/v1/obt/hotel/hotel_common';
import { RebookReferenceRebookTypeEnum } from '../../../types/api/v2/obt/model/rebook-reference';
import { defineCommonMessage } from '../../../translations/defineMessage';
import { accessibleFeaturesMap } from '../../../constants/hotels';
import type { HotelSpecialRequestsAccessibleFeaturesEnum } from '../../../types/api/v2/obt/model/hotel-special-requests';
import {
  HotelSpecialRequestsCheckInEnum,
  HotelSpecialRequestsRoomFeaturesEnum,
  HotelSpecialRequestsRoomLocationsEnum,
} from '../../../types/api/v2/obt/model/hotel-special-requests';

import { getDateDiff } from '../../../date-utils';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import {
  MoneyUtil,
  feeTypeTitleMapper,
  getHotelCancellationPolicyText,
  getLocationFullAddressV2,
  getNameStringFromName,
  pluralize,
} from '../../../utils';
import type { DateTimeLocal, MandatoryFees, MandatoryFeesBreakdown } from '../../../types';
import { FormOfPaymentTypeEnum, HotelPaymentPaymentTypeEnum } from '../../../types';
import type { Hotel } from '../../../types/api/v2/obt/model/hotel';

export class HotelPnrV3Manager extends PnrV3Manager {
  hotelPnr?: Hotel;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.hotelPnr = this.pnrData.hotelPnr;
  }

  public hotelName(): string {
    return this.hotelPnr?.hotelInfo.name ?? '';
  }

  public totalNights(): number {
    const checkInDate = this.hotelPnr?.checkInDateTime.iso8601 ?? '';
    const checkOutDate = this.hotelPnr?.checkOutDateTime.iso8601 ?? '';
    return Math.max(getDateDiff(checkInDate, checkOutDate), 1);
  }

  public hotelDetails() {
    const { hotelPnr } = this;
    const startDate = hotelPnr?.checkInDateTime;
    const endDate = hotelPnr?.checkOutDateTime;
    const cancellationPolicy = hotelPnr?.room.cancellationPolicy
      ? getHotelCancellationPolicyText(hotelPnr?.room.cancellationPolicy)
      : '';
    const phoneNumber = {
      rawInput: hotelPnr?.hotelInfo.phone?.rawInput,
      countryCode: hotelPnr?.hotelInfo.phone?.countryCode,
    };
    const fax = {
      rawInput: first(hotelPnr?.hotelInfo.fax)?.rawInput,
      countryCode: first(hotelPnr?.hotelInfo.fax)?.countryCode,
    };

    const hotelEmail = hotelPnr?.hotelInfo.email;

    const hotelDetails = {
      name: hotelPnr?.hotelInfo.name ?? '',
      chainCode: hotelPnr?.hotelInfo.masterChainCode ?? hotelPnr?.hotelInfo.chainCode ?? '',
      confirmationNumber: hotelPnr?.vendorConfirmationNumber ?? '',
      bookingId: this.pnrId,
      vendorCancellationId: hotelPnr?.vendorCancellationId ?? '',
      numberOfRooms: hotelPnr?.numberOfRooms.toString() ?? '',
      roomType: hotelPnr?.room.roomName ?? '',
      checkIn: hotelPnr?.checkInDateTime,
      checkOut: hotelPnr?.checkOutDateTime,
      duration: `${this.totalNights()}d`,
      contactDetails: {
        address: getLocationFullAddressV2(hotelPnr?.hotelInfo.address),
        phoneNumber,
        fax,
        latLng: hotelPnr?.hotelInfo.coordinates,
        locality: hotelPnr?.hotelInfo.address?.locality,
      },
      cancellationPolicy,
      hotelEmail,
      numPax: this.totalTravelers(),
      additionalDetails: hotelPnr?.room?.additionalDetails ?? [],
      co2EmissionsValue: hotelPnr?.room.co2EmissionDetail?.co2EmissionValue ?? 0,
      starRating: hotelPnr?.hotelInfo.starRating,
    };

    const passengers = this.pnrData?.pnrTravelers?.map((pnrTraveler) => ({
      name: pnrTraveler.personalInfo?.name,
      id: pnrTraveler.userId.id,
    }));

    const passengerDetails =
      hotelPnr?.travelerInfos?.map((travelerInfo) => {
        const requiredPassenger = passengers?.find((passenger) => passenger.id === travelerInfo.userId?.id);
        return {
          name: getNameStringFromName(requiredPassenger?.name),
          preferredName: getNameStringFromName(requiredPassenger?.name, { usePreferredName: true }),
          loyaltyNumber: travelerInfo.loyaltyInfos?.map((l) => l.id).join(', ') ?? '',
        };
      }) ?? [];

    return {
      startDate,
      endDate,
      hotelDetails,
      passengerDetails,
    };
  }

  public hotelSavings() {
    const { hotelPnr } = this;
    const savingsAmount = hotelPnr?.rebookReference?.hotelRateAssuranceMetadata?.actualSavings?.amount;
    const savingsCurrency = hotelPnr?.rebookReference?.hotelRateAssuranceMetadata?.actualSavings?.currencyCode;

    const savings = MoneyUtil.parse({
      currencyCode: savingsCurrency ?? '',
      amount: savingsAmount ?? 0,
    });

    return savings;
  }

  public isRateAssuranceRebooking() {
    return (
      !!this.hotelPnr?.rebookReference?.cancelledPnrIds?.length &&
      this.hotelPnr?.rebookReference.rebookType === RebookReferenceRebookTypeEnum.RateAssurance
    );
  }

  public paymentInfo() {
    const isPayLater = this.hotelPnr?.payment.paymentType === HotelPaymentPaymentTypeEnum.PayAtHotel;

    const rateInfo = this.hotelPnr?.room.rateInfo;
    const transactionDate = rateInfo?.totalRate.transactionDate;

    const prepaidBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.prepaidRate?.base);
    const prepaidTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.prepaidRate?.tax);

    const prepaidExtras = rateInfo?.prepaidRate?.extras ?? [];
    const prepaidTotalExtrasAmount = prepaidExtras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoneyWithOriginal(prepaidBaseAmount.getCurrency(), prepaidBaseAmount?.getOriginalCurrency()));

    const prepaidAmount = prepaidBaseAmount.add(prepaidTaxAmount.add(prepaidTotalExtrasAmount));

    const postpaidBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.postpaidRate?.base);
    const postpaidTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.postpaidRate?.tax);

    const postpaidExtras = rateInfo?.postpaidRate?.extras ?? [];

    let mandatoryFeesTotal = MoneyUtil.zeroMoneyWithOriginal(
      prepaidBaseAmount.getCurrency(),
      prepaidBaseAmount?.getOriginalCurrency(),
    );

    const mandatoryFeesBreakdown: MandatoryFeesBreakdown[] = [];
    postpaidExtras.forEach((extra) => {
      if (extra.type) {
        mandatoryFeesTotal = mandatoryFeesTotal.add(MoneyUtil.convertV2MoneyToMoneyUtil(extra.amount));
        mandatoryFeesBreakdown.push({
          title: feeTypeTitleMapper[FeeType[extra.type as keyof typeof FeeType]],
          amount: MoneyUtil.convertV2MoneyToMoneyUtil(extra.amount),
          feeInclusions: [],
        });
      }
    });

    const postpaidTotalExtrasAmount = postpaidExtras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoneyWithOriginal(postpaidBaseAmount.getCurrency(), postpaidBaseAmount?.getOriginalCurrency()));

    const amountDueLater = postpaidBaseAmount.add(postpaidTaxAmount.add(postpaidTotalExtrasAmount));
    const changeFee = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.overallPenaltyCharged);

    const brexPointsFop = this.pnrData.paymentInfo?.find(
      (paymentInfo) => paymentInfo.fop.paymentMethod === FormOfPaymentTypeEnum.BrexPoints,
    );

    const paidByBrexPointsInstruction = brexPointsFop
      ? `${MoneyUtil.convertV2MoneyToMoneyUtil(brexPointsFop.totalCharge).getBrexPoints()?.amount} Brex Points`
      : undefined;

    const totalNightlyRate = MoneyUtil.convertV2MoneyToMoneyUtil(rateInfo?.totalRate?.base);

    const merchantCancellationPenaltyBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.penalty?.base,
    );

    const merchantCancellationPenaltyTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.penalty?.tax,
    );

    const merchantCancellationFee = merchantCancellationPenaltyBaseAmount.add(merchantCancellationPenaltyTaxAmount);

    const refundAmountBaseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.refundAmount?.base,
    );

    const refundAmountTaxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(
      rateInfo?.totalRate.refundInfo?.refundAmount?.tax,
    );

    const refundAmount = refundAmountBaseAmount.add(refundAmountTaxAmount);

    const reservationAmount = prepaidAmount.add(amountDueLater);

    const mandatoryFees: MandatoryFees = {
      total: mandatoryFeesTotal,
      breakdown: mandatoryFeesBreakdown,
    };

    const feesWithOutExtras = postpaidTotalExtrasAmount.subtract(mandatoryFeesTotal);

    return {
      isPayLater,
      ...super.paymentInfo(),
      transactionDate,
      reservationAmount,
      prepaidAmount,
      amountDueLater,
      fees: feesWithOutExtras,
      mandatoryFees,
      changeFee,
      paidByBrexPointsInstruction,
      refundAmount,
      merchantCancellationFee,
      totalNightlyRate,
    };
  }

  public totalTravelers() {
    let totalTravelers = 0;
    const { hotelPnr } = this;

    if (!hotelPnr) {
      return totalTravelers;
    }

    hotelPnr.occupancy?.forEach(({ numAdults = 0, numChildren = 0, numInfants = 0 }) => {
      totalTravelers += numAdults + numChildren + numInfants;
    });

    return totalTravelers;
  }

  public getRoomLocationSpecialRequest(roomLocationRequest?: HotelSpecialRequestsRoomLocationsEnum): string {
    if (roomLocationRequest === HotelSpecialRequestsRoomLocationsEnum.HighFloor) {
      return 'High floor';
    }

    if (roomLocationRequest === HotelSpecialRequestsRoomLocationsEnum.LowFloor) {
      return 'Low floor';
    }

    return '';
  }

  public getSupportedSpecialFeatures(
    accessibleFeatures?: Array<HotelSpecialRequestsAccessibleFeaturesEnum>,
  ): Array<string> {
    return (
      accessibleFeatures?.map((feature) => {
        return accessibleFeaturesMap[feature as HotelSpecialRequestsAccessibleFeaturesEnum] ?? '';
      }) ?? []
    );
  }

  public getRoomCheckInSpecialRequest({
    checkIn,
    checkInTime,
  }: {
    checkIn?: HotelSpecialRequestsCheckInEnum;
    checkInTime?: DateTimeLocal;
  }) {
    if (checkIn === HotelSpecialRequestsCheckInEnum.EarlyCheckIn) {
      if (!checkInTime) {
        return 'Early check-in';
      }

      return `Early check-in (Anticipated ${checkInTime.iso8601} Hrs)`;
    }

    if (checkIn === HotelSpecialRequestsCheckInEnum.LateCheckIn) {
      if (!checkInTime) {
        return `Late check-in`;
      }

      return `Late check-in (Anticipated ${checkInTime.iso8601} Hrs)`;
    }

    return '';
  }

  public getRoomFeaturesSpecialRequest(roomFeatures?: HotelSpecialRequestsRoomFeaturesEnum): string {
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.AccessibleRoom) {
      return 'Accessible room';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.Crib) {
      return 'Crib';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.FeatherFreeRoom) {
      return 'Feather free room';
    }
    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.NearElevator) {
      return 'Near elevator';
    }

    if (roomFeatures === HotelSpecialRequestsRoomFeaturesEnum.RollawayBed) {
      return 'Rollaway bed';
    }

    return '';
  }

  public specialRequests() {
    const hotelSpecialRequests = this.hotelPnr?.hotelSpecialRequests;

    const locationRequest = this.getRoomLocationSpecialRequest(first(hotelSpecialRequests?.roomLocations));

    const otherRequest = hotelSpecialRequests?.additionalNote;
    const arrivalFlight = hotelSpecialRequests?.flightNumber;
    const checkInRequest = this.getRoomCheckInSpecialRequest({
      checkIn: hotelSpecialRequests?.checkIn,
      checkInTime: hotelSpecialRequests?.checkInTime,
    });

    const roomFeaturesRequest = this.getRoomFeaturesSpecialRequest(first(hotelSpecialRequests?.roomFeatures));
    const supportedSpecialFeatures = this.getSupportedSpecialFeatures(hotelSpecialRequests?.accessibleFeatures);
    const showSpecialRequests =
      !!locationRequest ||
      !!otherRequest ||
      !!arrivalFlight ||
      !!checkInRequest ||
      !!roomFeaturesRequest ||
      (supportedSpecialFeatures?.length ?? -1) > 0;

    return {
      locationRequest,
      roomFeaturesRequest,
      checkInRequest,
      otherRequest,
      arrivalFlight,
      supportedSpecialFeatures,
      showSpecialRequests,
    };
  }

  public pnrTitle() {
    const hotelName = this.hotelName();
    const totalNights = this.totalNights();

    const text = pluralize('night', totalNights);

    const pnrTitle = defineCommonMessage('{{noOfDays}} {{text}} in {{hotelName}}');
    pnrTitle.values = {
      noOfDays: totalNights,
      text,
      hotelName,
    };
    return pnrTitle;
  }
}
