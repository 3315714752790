import {
  RBAC,
  userRolesByFeature,
  checkForSeat1aRestrictions,
  TMCAgentAndAboveRoles,
  AgentAvailabilityStatus,
  getFullAssetPath,
} from 'obt-common';
import { useTranslation } from 'react-i18next';
import { AvatarButton, TAvatarButtonProps } from '@spotnana/blocks/src/AvatarButton';
import { forwardRef, useId } from 'react';
import { useGetUserRingColor } from 'src/hooks/useGetUserRingColor';
import { availabilityStatusColorsMap } from '../../app/agent/QueueDashboardPage/constants';
import { outline_ring } from './AppHeader.styles';

export const AvatarRing = ({ ringBorderColor, children }: { ringBorderColor?: string; children: React.ReactNode }) => {
  if (!ringBorderColor) {
    return <>{children}</>;
  }

  return (
    <div data-testid="avatar-ring" css={outline_ring} style={{ borderColor: ringBorderColor }}>
      {children}
    </div>
  );
};

interface AvatarDescriptionProps {
  id: string;
  isSeat1a?: boolean;
  currentUser: string;
  impersonationText?: string;
  descriptionText?: string;
  agentAvailabilityStatus?: AgentAvailabilityStatus;
}

export const AvatarDescription: React.FC<AvatarDescriptionProps> = ({
  id,
  isSeat1a,
  currentUser,
  impersonationText,
  descriptionText,
  agentAvailabilityStatus,
}: AvatarDescriptionProps) => {
  const { t } = useTranslation('WEB');

  const generalDescriptionText = t('Current user - {{currentUser}},', { currentUser });
  const seat1AText = isSeat1a ? t('Tier - Seat 1A,') : undefined;
  const availabilityText = agentAvailabilityStatus
    ? t('Availability status - {{status}},', { status: agentAvailabilityStatus })
    : undefined;
  const badgeText = agentAvailabilityStatus ? availabilityText : seat1AText;
  const finalDescriptionText = [generalDescriptionText, badgeText, descriptionText, impersonationText]
    .filter(Boolean)
    .join(' ');

  return (
    <p id={id} style={{ display: 'none' }}>
      {finalDescriptionText}
    </p>
  );
};

interface UserAvatarProps extends TAvatarButtonProps {
  isSeat1a?: boolean;
  agentAvailabilityStatus?: AgentAvailabilityStatus;
  currentUser: string;
  impersonationText?: string;
  descriptionText?: string;
}

export const HeaderAvatarButton = forwardRef<HTMLButtonElement, UserAvatarProps>(
  (
    {
      isSeat1a = false,
      currentUser,
      impersonationText,
      agentAvailabilityStatus,
      descriptionText,
      avatarProps,
      ...restProps
    }: UserAvatarProps,
    ref,
  ): JSX.Element => {
    const { t } = useTranslation('COMMON');
    const id = useId();

    const ringBorderColor = useGetUserRingColor();

    if (agentAvailabilityStatus) {
      return (
        <RBAC allowedRoles={TMCAgentAndAboveRoles}>
          <AvatarDescription
            agentAvailabilityStatus={agentAvailabilityStatus}
            currentUser={currentUser}
            impersonationText={impersonationText}
            descriptionText={descriptionText}
            isSeat1a={isSeat1a}
            id={id}
          />
          <AvatarRing ringBorderColor={ringBorderColor}>
            <AvatarButton
              ref={ref}
              avatarProps={{
                ...avatarProps,
                badge: (
                  <div
                    style={{
                      height: '8px',
                      width: '8px',
                      borderRadius: '100%',
                      backgroundColor: availabilityStatusColorsMap[agentAvailabilityStatus],
                    }}
                    data-testid="agent-availability-status"
                  />
                ),
              }}
              {...restProps}
              aria-describedby={id}
            />
          </AvatarRing>
        </RBAC>
      );
    }

    if (!isSeat1a) {
      return (
        <>
          <AvatarDescription
            agentAvailabilityStatus={agentAvailabilityStatus}
            currentUser={currentUser}
            impersonationText={impersonationText}
            descriptionText={descriptionText}
            isSeat1a={isSeat1a}
            id={id}
          />
          <AvatarRing ringBorderColor={ringBorderColor}>
            <AvatarButton avatarProps={avatarProps} ref={ref} {...restProps} aria-describedby={id} />
          </AvatarRing>
        </>
      );
    }

    return (
      <RBAC
        allowedRoles={userRolesByFeature.seat1aView}
        secondaryPredicate={checkForSeat1aRestrictions()}
        fallback={<AvatarButton avatarProps={avatarProps} {...restProps} />}
      >
        <AvatarDescription
          agentAvailabilityStatus={agentAvailabilityStatus}
          currentUser={currentUser}
          impersonationText={impersonationText}
          descriptionText={descriptionText}
          isSeat1a={isSeat1a}
          id={id}
        />
        <AvatarRing ringBorderColor={ringBorderColor}>
          <AvatarButton
            ref={ref}
            avatarProps={{
              ...avatarProps,
              badge: (
                <img
                  src={getFullAssetPath('/v1-assets/images/seat1a-logo', 'svg')}
                  alt={`Seat 1A ${t('USER')}`}
                  title={`Seat 1A ${t('USER')}`}
                  style={{
                    height: '8px',
                    width: '8px',
                    borderRadius: '100%',
                  }}
                />
              ),
            }}
            {...restProps}
            aria-describedby={id}
          />
        </AvatarRing>
      </RBAC>
    );
  },
);

export default HeaderAvatarButton;
