import noop from 'lodash/noop';
import { Config, IAccessRefreshTokens, tokenExchange } from 'obt-common';
import {
  TokenExchangeRequestEvent,
  TokenExchangeResponseEvent,
  TokenExchangeTypes,
} from '../components/EmbedContext/types';

function isTokenExchangeResponseEvent(event: any): event is TokenExchangeResponseEvent {
  return (
    event?.type === TokenExchangeTypes.TOKEN_EXCHANGE_RESPONSE &&
    event?.payload?.accessToken &&
    event?.payload?.refreshToken
  );
}

const messageCallback =
  (
    idp: string,
    resolve: (value: { accessToken: string; refreshToken: string }) => void,
    reject: (error: unknown) => void,
  ) =>
  (event: MessageEvent): void => {
    const { data, origin } = event;

    if (!Config.VITE_HOST_APP_DASHBOARD_URL) {
      reject(new Error('VITE_HOST_APP_DASHBOARD_URL is not defined'));
      return;
    }

    const isTargetOrigin = [...Config.VITE_HOST_APP_DASHBOARD_URL?.split(','), Config.VITE_WEB_URL].some((url) =>
      url?.startsWith(origin),
    );

    if (isTokenExchangeResponseEvent(data)) {
      if (tokenExchange.includes(idp)) {
        if (isTargetOrigin) {
          const { accessToken, refreshToken } = data.payload;
          resolve({ accessToken, refreshToken });
        } else {
          reject(new Error(`Origin: ${origin} is not configured for Token Exchange. Kindly contact Spotnana team...`));
        }
      } else {
        reject(new Error(`Unexpected idp: ${idp}`));
      }
    }
  };

interface TokensViaPostMessage {
  accessToken: string;
  refreshToken: string;
}

const getTokensViaOnMessage = (idp: string): Promise<TokensViaPostMessage> => {
  let listener = noop;

  return new Promise<TokensViaPostMessage>((resolve, reject) => {
    listener = messageCallback(idp, resolve, reject);
    window.addEventListener('message', listener);

    const message: TokenExchangeRequestEvent = {
      type: TokenExchangeTypes.TOKEN_EXCHANGE_REQUEST,
      from: 'spotnana-embed',
    };
    window.parent.postMessage(message, '*');
  }).finally(() => {
    window.removeEventListener('message', listener);
  });
};

export const getTokensViaPostMessage = async (idp: string): Promise<IAccessRefreshTokens> => {
  const { accessToken, refreshToken } = await getTokensViaOnMessage(idp);
  return {
    access_token: accessToken,
    refresh_token: refreshToken,
  };
};
