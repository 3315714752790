/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type { IApiRequestSubType, IResponseData } from '../api/apiTypes';

/**
 * @deprecated
 * This method is deprecated and should not be used.
 */
const reportEvent = async (
  // @ts-ignore
  eventType: string,
  // @ts-ignore
  eventData: object,
  // @ts-ignore
  subType?: IApiRequestSubType,
): Promise<IResponseData | undefined> => Promise.resolve(undefined);

export default reportEvent;
