import { useHistory } from 'react-router-dom';

import { useAuth, useFeatureFlag } from 'obt-common';
import Box from '@spotnana/pixel-react/dist/Box';
import { Modal } from '@spotnana/pixel-react/dist/Modal';
import Typography from '@spotnana/pixel-react/dist/Typography';

import { GlobalErrorToast } from 'src/app/shared/components/GlobalErrorToast';
import { useSnackbarErrorContext } from '../components/SnackbarErrorContext';
import ErrorSnackbar from './ErrorSnackbar';

interface IProps {
  showAPIErrorCodes?: boolean;
}

const Errors = ({ showAPIErrorCodes = true }: IProps): JSX.Element => {
  const isFeInfraErrorToastEnabled = useFeatureFlag('FE_INFRA_ERROR_TOAST') ?? false;

  const history = useHistory();
  const { logout } = useAuth();

  const { snackbarError, setSnackbarError } = useSnackbarErrorContext();

  const clearAppError = (): void => {
    setSnackbarError(null);
  };

  const reloadPage = (): void => {
    history.go(0);
  };

  const hardLogout = async (): Promise<void> => {
    await logout();
    reloadPage();
  };
  const debugIdLink = snackbarError?.debugToolLink;
  // Format is https://debug-stage.services.spotnana.com/file-search-results/483a8506cd644775_483a8506cd644775?q=debugid
  const debugId = debugIdLink
    ? new URL(snackbarError.debugToolLink || '').pathname.split('?q=debugid')[0].split('/file-search-results/')[1]
    : '';

  // TODO: (Himesh) Decide between this approach and the alert approach for logging out the user if not found
  const userNotFoundError = snackbarError?.errorMessage?.includes('User not found with email');
  const userNotFoundErrorMessage = "You haven't been onboarded yet, Please contact Support";
  if (userNotFoundError) {
    return (
      <Modal closeButtonOutside visible={Boolean(snackbarError)} onClose={hardLogout}>
        <Box p="40px">
          <Typography variation="h4" fontWeight="strong">
            {userNotFoundErrorMessage}
          </Typography>
        </Box>
      </Modal>
    );
  }

  if (!snackbarError) {
    return <></>;
  }

  if (isFeInfraErrorToastEnabled) {
    return <GlobalErrorToast snackbarError={snackbarError} debugId={debugId} onClose={clearAppError} />;
  }

  return (
    <ErrorSnackbar
      errorCode={snackbarError.errorCode}
      errorParameters={snackbarError.errorParameters}
      sourceErrorMessages={snackbarError.sourceErrorMessages}
      debugId={debugId || ''}
      showAPIErrorCodes={showAPIErrorCodes}
      onClose={clearAppError}
      autoHideDuration={null}
    />
  );
};

export default Errors;
