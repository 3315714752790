/* istanbul ignore file */
/* eslint-disable global-require,import/no-extraneous-dependencies */
import * as Sentry from '@sentry/react';
import { Primitive, Extras, SeverityLevel } from '@sentry/types';
import { Config } from 'obt-common';
import LogRocket from 'logrocket';
import { useEffect, useRef } from 'react';

export const sentryErrorMessageIgnores = [
  // This happens due to marker sometimes, but otherwise on Windows 10 Chrome due to Lastpass etc
  // ( https://github.com/getsentry/sentry-javascript/issues/3440 )
  'Non-Error promise rejection captured',
  /** No need to get request failure redux errors as tickets */
  /request failed with status code/i,
  /** Chrome on Safari non-app issue */
  '<unknown>',
  /** Chrome on Safari non-app issue */
  '__gCrWeb',
  'ChunkLoadError',
  'Signout timeout fail',
  'moat_px is not defined',
  'IMUID is not defined',
  '__cmp is not defined',
  'apstagLOADED is not defined',
  "Cannot read properties of undefined (reading 'outputCurrentConfiguration')",
  "Cannot read properties of undefined (reading 'cmp')",
  "Cannot read properties of undefined (reading 'ns')",
  // We now store all old JS files on S3, so any of these errors are device/network related and not code related
  /failed to fetch dynamically imported module/i,
  /**
   * https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
   */
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: cancelled',
  // Ignore Network error raised by Cognito https://spotnana.atlassian.net/browse/ST-68063
  'Error: Network Error',
  // This is a known error raised for Brex Rewards Login
  // This flow is going to be removed soon so suppressing this error
  'Error: REDIRECTION_NEEDED_FOR_BE',
  'Error: timeout of 0ms exceeded',
  "TypeError: Cannot read properties of undefined (reading 'audioVolumeChange')",
  "TypeError: Cannot read properties of undefined (reading 'fireChangeEvent')",
  "TypeError: Cannot read properties of undefined (reading 'fireEvent')",
  "TypeError: Cannot read properties of undefined (reading 'fireReadyEvent')",
  "TypeError: Cannot read properties of undefined (reading 'setCurrentPosition')",
  "TypeError: Cannot read properties of undefined (reading 'setDefaultPosition')",
  "TypeError: Cannot read properties of undefined (reading 'setIsViewable')",
  "TypeError: Cannot read properties of undefined (reading 'setMaxSize')",
  "TypeError: Cannot read properties of undefined (reading 'setScreenSize')",
  'Error: WKWebView API client did not respond to this postMessage',
];

export const sentryUrlPatternIgnores = [
  // notoriously high bugs from edge.marker.io script
  /edge\.marker\.io/i,
  /marker\.io/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  /^pptr:\/\//i,
  /__puppeteer_evaluation_script__/i,
];

const sentryAllowUrls = [/https:\/\/.*\.spotnana\.com/gi];

const HAS_SENTRY = Boolean(Config.VITE_SENTRY_DSN?.length);
const HAS_LOGROCKET = Boolean(Config.VITE_LOGROCKET_ID?.length);

export function initLogRocket() {
  if (process.env.NODE_ENV !== 'development' && Config.VITE_ENVIRONMENT !== 'testing' && HAS_LOGROCKET) {
    LogRocket.init(Config.VITE_LOGROCKET_ID as string, {
      dom: {
        inputSanitizer: true,
      },
    });

    if (HAS_SENTRY) {
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  }
}

// 30s Timeout
const TIMEOUT = 30 * 1000;

const isSentryEnabled = () =>
  process.env.NODE_ENV !== 'development' && Config.VITE_ENVIRONMENT !== 'testing' && HAS_SENTRY;

export function initSentry(): void {
  if (isSentryEnabled()) {
    const eventLimiter: Record<string, boolean> = {};
    Sentry.init({
      dsn: Config.VITE_SENTRY_DSN,
      environment: Config.VITE_ENVIRONMENT,
      normalizeDepth: 6,
      denyUrls: sentryUrlPatternIgnores,
      allowUrls: sentryAllowUrls,
      ignoreErrors: sentryErrorMessageIgnores,
      attachStacktrace: true,
      beforeSend: (event, hint) => {
        const logRocketSession = LogRocket?.sessionURL;
        if (logRocketSession) {
          // eslint-disable-next-line no-param-reassign
          if (event.extra) event.extra.LogRocket = logRocketSession;
          // eslint-disable-next-line no-param-reassign
          else event.extra = { LogRocket: logRocketSession };
        }

        const error = hint?.originalException;
        const message = typeof error !== 'string' ? error?.message : undefined;

        // do not send event if already sent in last 1 minute
        if (message) {
          if (message in eventLimiter) {
            // eslint-disable-next-line no-console
            console.log('Rate limiting activated for:', message);
            return null;
          }

          eventLimiter[message] = true;

          setTimeout(() => {
            delete eventLimiter[message];
          }, TIMEOUT);
        }

        /** Don't send error if from third party origins, adding this as denyUrls is seemingly flaky  */
        const isIgnored = isFileIgnored(event);

        if (isIgnored) {
          return null;
        }

        return event;
      },
    });
  }
}

function isFileIgnored(event: Sentry.Event): boolean {
  return sentryUrlPatternIgnores.some((blockedFileSubstring) =>
    // has excessive optional chaining to avoid any errors due to Sentry throwing unreliable data
    event?.exception?.values?.some((value) =>
      value?.stacktrace?.frames?.some(
        (frame) => frame?.abs_path?.match(blockedFileSubstring) || frame?.filename?.match(blockedFileSubstring),
      ),
    ),
  );
}

export const SENTRYTS_TESTABLE = {
  isFileIgnored,
};

export const addBreadcrumb = (breadcrumb: Sentry.Breadcrumb): void => {
  try {
    if (!isSentryEnabled()) {
      // skip
    }

    Sentry.addBreadcrumb(breadcrumb);
  } catch (error) {
    // ignore any errors
    if (Config.VITE_ENVIRONMENT !== 'production') {
      console.warn('[addBreadcrumb] Error:', error);
    }
  }
};

interface ICaptureExceptionOptions {
  /**
   * A uniq tag to group errors from one source
   *
   * Examples:
   *  source: 'myFnName'
   *  source: 'myClass.myFnName'
   */
  source: string;
  /**
   * Extra data to be attached to the error
   */
  extra?: Extras;
  /**
   * Easy way to filter out errors from Sentry
   * more: https://docs.sentry.io/platforms/node/guides/azure-functions/enriching-events/tags/
   */
  tags?: Record<string, Primitive>;
  /**
   * String literal type:
   *  'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug'
   * more: https://docs.sentry.io/platforms/node/usage/set-level/
   */
  level?: SeverityLevel;
  /**
   * Used to group errors together based on fingerprint array keys
   * Example networks errors:
   * ```
   *   fingerprint: [method, endpoint, String(response.statusCode)];
   *                ^^^ ['GET','v1/traveler/read', '400']
   * ```
   * more: https://docs.sentry.io/platforms/node/usage/sdk-fingerprinting/
   */
  fingerprint?: string[];
}

export function captureException(error: unknown, options?: ICaptureExceptionOptions): void {
  try {
    if (!isSentryEnabled()) {
      console.warn('[captureException]:', error);
    }

    const { level = 'error', fingerprint, extra, tags, source } = options || {};
    Sentry.withScope((scope) => {
      if (extra) {
        scope.setExtras(extra);
      }

      scope.setLevel(level);

      if (fingerprint) {
        scope.setFingerprint(fingerprint);
      }

      scope.setTags({ source, ...tags });

      Sentry.captureException(error);
    });
  } catch {
    // ignore any errors
    if (Config.VITE_ENVIRONMENT !== 'production') {
      console.warn('[captureException] Error:', error);
    }
  }
}

/**
 * Inits logrocket if feature flag enabled
 */
export function useInitialiseLogRocket(): null {
  const hasInitialisedLogRocketRef = useRef(false);
  useEffect(() => {
    if (!hasInitialisedLogRocketRef.current) {
      // adding tryCatch to ensure we don't break root app tree due to accidental logrocket changes
      try {
        initLogRocket();
      } catch (e) {
        console.warn('could not initialise LogRocket');
        console.error(e);
      }
      hasInitialisedLogRocketRef.current = true;
    }
  }, []);

  return null;
}

export function InitaliseLogRocket(): null {
  useInitialiseLogRocket();
  return null;
}
