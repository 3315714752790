import MUITooltip, { TooltipProps } from '@mui/material/Tooltip';
import { RemoveMuiProps } from '../types';
import { tooltip } from './Tooltip.styles';
import { Typography } from '../Typography';

export type TTooltipProps = Omit<RemoveMuiProps<TooltipProps>, 'arrow'> & {
  variant?: 'info' | 'label';
};

/**
 * Tooltip component.
 * Please ensure your title prop is just a string for it to be properly aria labelled.
 * Use label variant for body2 typography and info variant for body3 typography on title.
 * @param props
 * @returns
 */
export const Tooltip = ({ title, children, variant = 'label', placement = 'top', ...restProps }: TTooltipProps) => {
  return (
    <MUITooltip
      classes={{ popper: 'Blocks-tooltip' }}
      data-testid="blocks-Tooltip"
      css={tooltip}
      title={
        title ? (
          <Typography variant={variant === 'label' ? 'body2' : 'body3'} color="buttonLabel">
            {title}
          </Typography>
        ) : undefined
      }
      placement={placement}
      // mobile ux to show tooltip on touch
      leaveTouchDelay={3000}
      enterTouchDelay={50}
      arrow
      aria-label={typeof title === 'string' ? title : undefined}
      {...restProps}
    >
      {children}
    </MUITooltip>
  );
};
