import { useState } from 'react';
import * as React from 'react';
import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import { Typography } from '@spotnana/blocks/src/Typography';
import { useTranslation } from 'react-i18next';
import styled, { CSSObject } from 'styled-components';
import css from '@styled-system/css';
import { NavLink, useLocation } from 'react-router-dom';
import { useBlocksTheme } from '@spotnana/blocks/src/utils/styling/useBlocksTheme';
import Beta from '../shared/Beta';

const StyledIconContainer = styled(Box)(
  css({
    height: 40,
    width: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: 'bg.blueGray',
    mr: 16,
    borderRadius: 'sm',
  }) as unknown as CSSObject,
);

const StyledLink = styled(NavLink)(
  css({
    display: 'flex',
    alignItems: 'center',
    my: 12,
    cursor: 'pointer',
    [`:hover ${StyledIconContainer}, &.active ${StyledIconContainer}`]: {
      bg: 'primary',
      transition: 'regular',
    },
    [`:hover ${Typography}, &.active ${Typography}`]: {
      color: 'text.semiBold',
      fontWeight: 600,
      transition: 'regular',
    },
  }) as unknown as CSSObject,
);

interface IProgramItem {
  getIcon: (color: string) => React.ReactNode;
  content: string;
  path: string;
  enableFeature?: boolean;
  onClick?: VoidFunction;
}

const TabItem = ({ getIcon, content, path, onClick }: IProgramItem): JSX.Element => {
  const { t } = useTranslation();
  const { palette } = useBlocksTheme();

  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (): void => setIsHovered(true);
  const handleMouseLeave = (): void => setIsHovered(false);

  const { pathname } = useLocation();

  const modeColor = palette.mode === 'light' ? palette.text.inverse : palette.text.primary;
  const iconColor = pathname.startsWith(path) || isHovered ? modeColor : spotnanaTheme.colors.text.label;

  return (
    <StyledLink
      to={path}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-testid={`nav-${t(content).toLowerCase()}-link`}
      onClick={onClick}
    >
      <StyledIconContainer>{getIcon(iconColor)}</StyledIconContainer>
      <Typography
        kind={isHovered ? 'semibold' : 'regular'}
        variant="body2"
        data-testid={`nav-${t(content).toLowerCase()}-text`}
      >
        {t(content)}
      </Typography>
    </StyledLink>
  );
};

interface ITabSection {
  title: string;
  isActive: boolean;
  options: IProgramItem[];
  enableFeature?: boolean;
  isInBeta?: boolean;
  onClick?: VoidFunction;
}

const TabSection = ({ title, isActive, options, isInBeta, onClick }: ITabSection): JSX.Element => {
  if (!options.length) return <></>;

  return (
    <Box p={24} pb={0} data-testid={title}>
      <Flex alignItems="center" justifyContent="space-between">
        <Typography as="label" variant="body3" kind="semibold" color={isActive ? 'brand' : 'primary'}>
          {title.toUpperCase()}
        </Typography>
        {isInBeta && <Beta variation="small" />}
      </Flex>
      {options.map((option) => (
        <TabItem onClick={onClick} key={option.path} {...option} />
      ))}
    </Box>
  );
};

export default TabSection;
