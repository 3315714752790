import type { MoneyUtil } from '../utils/Money';
import type { Name } from './api/v1/common/name';
import type { PostalAddress } from './api/v1/common/postal_address';
import type { FlightNumber as FlightNumberV1, PassengerType } from './api/v1/obt/air/air_common';
import type { FlightNumber as FlightNumberV2 } from './api/v2/obt/model/flight-number';
import type { Preference } from './api/v1/obt/common/common';
import type { Traveler } from './api/v1/obt/common/traveler';
import type { LoyaltyInfo } from './api/v1/obt/common/traveler_personal_info';
import type { KnownTravelerNumber, RedressNumber } from './api/v1/obt/common/user';
import type { Persona, TierEnumNumerals } from './api/v1/obt/common/user_org_id';
import type { HotelAmenity } from './api/v1/obt/hotel/hotel_common';
import type { AdditionalDetail, Room } from './api/v1/obt/hotel/hotel_details_response';
import type {
  AirPnr,
  AirPnrOtherServiceInformation,
  AirPnrTravelerInfoSeatInfo,
  AirPnrTravelerInfoSpecialServiceRequestInfo,
  CarPnr,
  CarPnrTravelerInfo,
  HotelPnr,
  LimoPnr,
  LimoPnrTravelerInfo,
  MiscPnr,
  MiscPnrTravelerInfo,
  Pnr,
  PnrBookingHistory,
  PnrBookingHistoryBookerInfo,
  PnrBookingHistoryBookingInfo,
  PnrBookingHistoryBookingStatus,
  RailPnr,
  RailPnrBookerDetail,
  Ticket,
  TicketCancellationPolicyExchangePolicy,
  UserFacingStatus as UserFacingStatusV1,
  PnrFreshnessInfo,
} from './api/v1/obt/pnr/pnr';
import {
  AirPnrAlertType,
  AirPnrLegAlertType,
  AirPnrTravelerInfoSeatInfoSeatStatus,
  AirPnrTravelerInfoSpecialServiceRequestInfoSpecialServiceRequestStatus,
  PnrStatusV1,
} from './api/v1/obt/pnr/pnr';
import { ThirdPartySourceEnum } from './api/v1/obt/supplier/third_party_info';
import type { ListTripSummariesResponseTripSummary, ListTripsResponseTripInfo } from './api/v1/obt/trip/list_trips';
import { TripCategoryRequest } from './api/v1/obt/trip/list_trips';
import type { ApplicationContext, GetTripDetailsResponse, TripInfo } from './api/v1/obt/trip/trip_details';
import type { ListTasksSortOrder } from './api/v2/obt/model/list-tasks-sort-order';
import type { ILayoverInfo, ILegAirports, ILegUTA, SeatUpgradeStatus } from './flight';
import type { ICancellationTerm, IHotelSummary } from './hotel';
import type { ITraveler, IUserOrgId } from './traveler';
import type { DateTime } from './api/v1/common/date_time';
import type { SabreRemarkPriority } from './api/v2/obt/model/sabre-remark-priority';
import type { PnrDetailsResponseWithId } from './api/v2/obt/model/pnr-details-response-with-id';
import type { UserFacingStatus as UserFacingStatusV2 } from './api/v2/obt/model/user-facing-status';
import type {
  Card,
  CardCompany,
  OBTFeatureConfig,
  OBTFeatureConfigObtFeatureTypeEnum,
  OBTFeaturePreferencesPreferenceVisibilityEnum,
} from './api/v2/obt/model';
import type { DeviceType } from './common';
import type { OtherAncillary } from './api/v1/obt/pnr/pnr_common';
import type { PaymentMethod } from './api/v2/obt/model/payment-method';
import type { IRailTicket } from './rail';
import type { ItineraryDetails } from './api/v1/obt/rail/rail_common';
import type RailPnrCancellationResponseManager from '../services/RailPnrCancellationResponseManager';

export { AirCancelPnrRequestCancelType } from './api/v1/obt/pnr/air_cancel_pnr';
export type { CTC as CTCv1 } from './api/v1/obt/pnr/payment';
export { PnrBookingHistoryBookerRole, PnrBookingHistoryBookingStatus } from './api/v1/obt/pnr/pnr';
export type { AirTravelerInfoUpdateRequest as IAirTravelerInfoUpdateRequest } from './api/v1/obt/supplier/air_traveler_info_update_request';
export { AirTravelerInfoUpdateField as IAirTravelerInfoUpdateField } from './api/v1/obt/supplier/air_traveler_info_update_request';
export type { AirTravelerInfoUpdateResponse as IAirTravelerInfoUpdateResponse } from './api/v1/obt/supplier/air_traveler_info_update_response';
export type { AgentNote as IAgentNote } from './api/v2/obt/model/agent-note';
export { AirTicketPnrResponseTicketStatusEnum as IAirTicketPnrResponseTicketStatusEnum } from './api/v2/obt/model/air-ticket-pnr-response';
export type { TravelerNote } from './api/v2/obt/model/traveler-note';
export type { TripActivity as ITripActivity } from './api/v2/obt/model/trip-activity';
export type { TripActivityType as ITripActivityType } from './api/v2/obt/model/trip-activity-type';
export type { TripRemark as ITripRemark } from './api/v2/obt/model/trip-remark';
export { SabreRemarkPriority } from './api/v2/obt/model/sabre-remark-priority';

export { AirPnrAlertType, AirPnrLegAlertType };
export { AirPnrTravelerInfoSeatInfoSeatStatus };
export { ThirdPartySourceEnum, TripCategoryRequest };
export type IAirPnrTravelerInfoSpecialServiceRequestInfo = AirPnrTravelerInfoSpecialServiceRequestInfo;
export type IAirPnrOtherServiceInformation = AirPnrOtherServiceInformation;
export type SSRStatus = AirPnrTravelerInfoSpecialServiceRequestInfoSpecialServiceRequestStatus;
export const SSRStatus = AirPnrTravelerInfoSpecialServiceRequestInfoSpecialServiceRequestStatus;
export const SSRStatusMapping: Record<SSRStatus, string> = {
  [SSRStatus.SPECIAL_SERVICE_REQUEST_STATUS_UNKNOWN]: 'Unknown',
  [SSRStatus.CONFIRMED]: 'Confirmed',
  [SSRStatus.REQUESTED]: 'Requested',
  [SSRStatus.UNABLE_TO_CONFIRM]: 'Unable to confirm',
  [SSRStatus.DENIED]: 'Denied',
  [SSRStatus.UNRECOGNIZED]: 'Unrecognized',
};
type SSRStatusMappingType = typeof SSRStatusMapping;
export type SSRStringStatus = SSRStatusMappingType[keyof SSRStatusMappingType];

export interface INewTripInfo {
  name: string;
  description: string;
}

export interface INewTripErrorState {
  name: string;
}

export interface INewTripValidationState {
  errors: INewTripErrorState;
  isValid: boolean | null;
}

export type ITripDetails = GetTripDetailsResponse;

// legInfoArrayIndex is populated via SplitAirPNR function, only use this property for splitted PNRs, else it would be undefined
// In a split PNR, we always have a single leg, thus we can use index 0 to find the leg details inside the array structure, however for additional information like utas, fareComponents, legStatus, etc we may still need the original legIndex as well as the legInfoIndex, which is why we have this extra field.
// Reason why legInfoIndex is undefined: Air split pnr will always have legInfoIndex, but split pnr for rail doesn't need this information, thus it has `Pnr` type without the legInfoIndex as its return type. To allow both these data types to be returned when all pnrs are sorted (returntype of GetSortedPnrDetails) we have to allow legInfoIndex to be undefined, thus null coalescing to zero is required.
// TODO (abhishek) : Use union types for Pnr result of rail and air and make legInfoIndex a mandatory field
export type ITripPnr = Pnr & {
  legInfoArrayIndex?: number;
  /**
   * Is only available for hotel manual bookings at the moment
   */
  incomplete?: boolean;
};
export type ITripAirPnr = AirPnr;
export type ITripHotelPnr = HotelPnr;
export type ITripCarPnr = CarPnr;
export type ITripRailPnr = RailPnr;

export type ITripLimoPnr = LimoPnr;
export type ITripMiscPnr = MiscPnr;
export type IAnyPnr = AirPnr | HotelPnr | CarPnr | RailPnr | LimoPnr | MiscPnr;

export type { PnrFreshnessInfo };

export type TripV3SplittedPnr = PnrDetailsResponseWithId & {
  legInfoArrayIndex?: number;
  /**
   * Is only available for hotel manual bookings at the moment
   */
  incomplete?: boolean;
};

export interface ITripTravelerFlightDetails {
  ticketNumber: string;
  couponStatus: string;
  loyalty: LoyaltyInfo | undefined;
  /** @deprecated Use seatInfo instead */
  seat: string;
  seatInfo: AirPnrTravelerInfoSeatInfo | undefined;
  earlyBirdInfo: OtherAncillary | undefined;
  origin: string;
  destination: string;
  upgradeStatus: SeatUpgradeStatus;
}

export interface IPnrFlightBrandNameBookingCodeCabin {
  brandName: string;
  bookingCode: string;
  cabin: string;
}

export interface ITripTravellerDetail {
  name?: Name;
  paxType: PassengerType;
  email?: string;
  persona?: Persona;
  profilePhoto: string;
  tier?: TierEnumNumerals;
  /** @deprecated use ktn instead */
  tsaNumber: string;
  /** @deprecated use redress instead */
  redressNumber: string;
  ktn?: KnownTravelerNumber;
  redress?: RedressNumber;
  flightTravellerDetails: ITripTravelerFlightDetails[];
  userOrgId: IUserOrgId | undefined;
  itinSource: ThirdPartySourceEnum;
  specialServiceRequests?: AirPnrTravelerInfoSpecialServiceRequestInfo[];
  otherServiceInfos?: AirPnrOtherServiceInformation[];
  originalTraveler?: Traveler;
}

export interface ITripFlightCabinInfo {
  arrivalAirport: string;
  departureAirport: string;
  cabinType: string;
  bookingCode: string;
}

export interface ITripFlightDetail {
  arrivalDateTime: string;
  originalArrivalDateTime?: string;
  arrivalDateTimePrevious?: string;
  isArrivalDateTimeChanged?: boolean;
  isArrivalDateTimeDelayed?: boolean;
  arrivalDateTimeDelayMinutes?: number;
  departureDateTime: string;
  originalDepartureDateTime?: string;
  departureDateTimePrevious?: string;
  isDepartureDateTimeChanged?: boolean;
  isDepartureDateTimeDelayed?: boolean;
  departureDateTimeDelayMinutes?: number;
  duration: string;
  departureCountryCode: string;
  departureAirport: string;
  originalDepartureAirport?: string;
  departureAirportName: string;
  departureAirportCityName: string;
  departureAirportTerminal: string;
  departureAirportTerminalPrevious?: string;
  isDepartureAirportTerminalChanged?: boolean;
  departureAirportGate: string;
  departureAirportGatePrevious?: string;
  isDepartureAirportGateChanged?: boolean;
  arrivalCountryCode: string;
  arrivalAirport: string;
  originalArrivalAirport?: string;
  arrivalAirportName: string;
  arrivalAirportCityName: string;
  arrivalAirportTerminal: string;
  arrivalAirportTerminalPrevious?: string;
  isArrivalAirportTerminalChanged?: boolean;
  cabinType: string;
  marketingAirLine: string;
  marketing: FlightNumberV1 | undefined;
  originalMarketing?: FlightNumberV1;
  operating: FlightNumberV1 | undefined;
  originalOperating?: FlightNumberV1;
  marketingAirlineLabel: string;
  operatingAirlineLabel: string;
  brandName: string;
  bookingCode: string;
  isHavingConnectionRisk?: boolean;
  minConnectionDurationIso8601?: string;
  previousConnectionDurationIso8601?: string;
  updatedConnectionDurationIso8601?: string;
  isFlightCancelled?: boolean;
  isFlightReinstated?: boolean;
  flightIndex: number;
  flightSourceStatus: string;
  hiddenLayovers: ILayoverInfo[];
}

export interface ITripV3FlightDetail {
  arrivalDateTime: string;
  arrivalDateTimePrevious?: string;
  isArrivalDateTimeChanged?: boolean;
  isArrivalDateTimeDelayed?: boolean;
  arrivalDateTimeDelayMinutes?: number;
  departureDateTime: string;
  departureDateTimePrevious?: string;
  isDepartureDateTimeChanged?: boolean;
  isDepartureDateTimeDelayed?: boolean;
  departureDateTimeDelayMinutes?: number;
  duration: string;
  departureAirport: string;
  departureAirportName: string;
  departureAirportCityName: string;
  departureAirportTerminal: string;
  departureAirportTerminalPrevious?: string;
  isDepartureAirportTerminalChanged?: boolean;
  departureAirportGate: string;
  departureAirportGatePrevious?: string;
  isDepartureAirportGateChanged?: boolean;
  arrivalAirport: string;
  arrivalAirportName: string;
  arrivalAirportCityName: string;
  arrivalAirportTerminal: string;
  arrivalAirportTerminalPrevious?: string;
  isArrivalAirportTerminalChanged?: boolean;
  cabinType: string;
  marketingAirLine: string;
  marketing: FlightNumberV2 | undefined;
  operating: FlightNumberV2 | undefined;
  marketingAirlineLabel: string;
  operatingAirlineLabel: string;
  brandName: string;
  bookingCode: string;
  isHavingConnectionRisk?: boolean;
  minConnectionDurationIso8601?: string;
  previousConnectionDurationIso8601?: string;
  updatedConnectionDurationIso8601?: string;
  isFlightCancelled?: boolean;
  isFlightReinstated?: boolean;
  flightIndex: number;
  flightSourceStatus: string;
}

export type ICarPnrTravelerInfo = CarPnrTravelerInfo;
export type IRailPnrBookerDetail = RailPnrBookerDetail;
export type ILimoPnrTravelerInfo = LimoPnrTravelerInfo;
export type IMiscPnrTravelerInfo = MiscPnrTravelerInfo;

export type IPnrBookingHistory = PnrBookingHistory;
export type IPnrBookingHistoryBookerInfo = PnrBookingHistoryBookerInfo;
export type IPnrBookingHistoryBookingInfo = PnrBookingHistoryBookingInfo;
export type BookingHistoryStatus =
  | PnrBookingHistoryBookingStatus.BOOKED
  | PnrBookingHistoryBookingStatus.EXCHANGED
  | PnrBookingHistoryBookingStatus.CANCELLED
  | PnrBookingHistoryBookingStatus.UPDATED;

export interface ITripInfo {
  info: TripInfo | undefined;
  start: string;
  end: string;
  itineraryNo: string;
  status: UserFacingStatusV1;
  isPersonalTrip?: boolean;
  /** Not implemented in Mobile. Hence, keeping it optional */
  isAdhocTrip?: boolean;
  applicationContext?: ApplicationContext;
  /** Not implemented in Mobile. Hence, keeping it optional */
  isAdhocUserSaved?: boolean;
}

export type TripV3Info = {
  id: string;
  name: string;
  description: string;
  start: string;
  end: string;
  status: UserFacingStatusV2;
  isPersonalTrip?: boolean;
  /** Not implmented in Mobile. Hence, keeping it optional */
  isAdhocTrip?: boolean;
  applicationContext?: ApplicationContext;
  /** Not implemented in Mobile. Hence, keeping it optional */
  isAdhocUserSaved?: boolean;
};

export interface IAirPnrFlightInfo {
  legIndex: number;
  flightIndex: number;
  vendorConfirmationId: string;
  vendorConfirmationStatus?: PnrStatusV1;
  seatNumber: string;
  seatStatus?: AirPnrTravelerInfoSeatInfoSeatStatus;
}

export interface IAirPnrFlightItineraryLevelInfo {
  loyaltyInfo: LoyaltyInfo[];
  ticketNumbers: string[] | undefined;
}

export interface IHotelCarPnrInfo {
  vendorConfirmationId: string;
  status?: PnrStatusV1;
  pnrNumber: string;
}

export const ITripPnrStatusEnum = PnrStatusV1;
export type ITripPnrStatusType = PnrStatusV1;

export type IListTripSummariesResponseTripSummary = ListTripSummariesResponseTripSummary;

export type IUserFacingStatusType = UserFacingStatusV1;
export type IUserFacingStatusV2Type = UserFacingStatusV2;

export enum IsRefundableEnum {
  FALSE = 0,
  TRUE = 1,
  UNRECOGNIZED = 2,
}

export const IThirdPartySourceEnum = ThirdPartySourceEnum;

export interface IAirPnrCard {
  destinationCityName: string;
  originCityName: string;
  legStatus: UserFacingStatusV1;
  legStatusWarning: string;
  legDuration: string;
  totalFare: MoneyUtil;
  baseFare: MoneyUtil;
  tax: MoneyUtil;
  totalPaid: MoneyUtil;
  stops: number;
  pnrId: string;
  destinationAirport: string;
  destinationAirportCode: string;
  originAirport: string;
  originAirportCode: string;
  originDepartureTime: string;
  destinationArrivalTime: string;
  itinSource: ThirdPartySourceEnum;
  itinSourcePcc: string;
  status: PnrStatusV1;
  airLine: string;
  flightDetails: ITripFlightDetail[];
  travellers: ITripTravellerDetail[];
  utas: ILegUTA[];
  showRawFareRules: boolean;
  airlineReference: string;
  currentLegDetailIndex: number;
  ticketForGivenLegIndex?: Ticket;
  contactSupport: boolean;
  preferences: Preference[];
  pnrFreshnessInfo?: PnrFreshnessInfo;
  isAnyFlightCheckedIn: boolean;
  sourcePnrId: string;
  dateOfBooking: string;
  rateOptionAirline: string;
}

export interface ITripHotelDetails {
  address: PostalAddress;
  amenities: HotelAmenity[];
  roomDetails: Room;
  cancellationTerms: ICancellationTerm[];
  additionalCancellationInfo: AdditionalDetail | undefined;
  summary: IHotelSummary;
}

export enum ITripTypes {
  Flight,
  Hotel,
  Rail,
  Car,
  Limo,
  Miscellaneous,
}

export interface IPaymentCardNumberAndCompany {
  number: string;
  company: CardCompany;
}

export enum TripCategoryPath {
  Upcoming = 'upcoming',
  Past = 'past',
  Cancelled = 'cancelled',
}

/**
 * Group Trips do not have the same status
 *
 * TODO: Homogenize TripCategory and GroupTrip Category
 */
export enum GroupTripCategoryPath {
  Upcoming = 'upcoming',
  Completed = 'completed',
  Cancelled = 'cancelled',
}

export interface TicketBaseAndTaxFare {
  base: MoneyUtil;
  tax: MoneyUtil;
}

export interface ITripPassengersFareBreakup {
  baseFare: MoneyUtil;
  tax: MoneyUtil;
  seatSelectionPrice: MoneyUtil;
  totalFare: MoneyUtil;
  name: Name | undefined;
  penalty: MoneyUtil;
  tickets: TicketBaseAndTaxFare[];
  flights: Record<number, ILegAirports[]>;
}

export interface IAirCancellationParameters {
  isAgent: boolean;
  pnr: ITripPnr | undefined;
  device: DeviceType;
}

export interface IHotelCancellationParameters {
  isAgent?: boolean;
  isOutsideBooking?: boolean;
}

export type ICarCancellationParameters = IHotelCancellationParameters;

export type ListTripActivityType = 'task' | 'history' | 'qc';
export type TripActivityTabType = 'task' | 'comment' | 'history' | 'ssrOsi' | 'qc';

export interface ITripActivityUrlParams {
  pnrId?: string[];
  remarkPriority?: SabreRemarkPriority[];
  activityType?: TripActivityTabType;
  sortOrder: ListTasksSortOrder;
  userId: IUserOrgId;
}

export enum PnrCreationOrigin {
  SHELL = 'SHELL',
  OBT = 'OBT',
}

export interface GroupTripDetailsQueryParams {
  redirectFrom: string;
  type: TripCategoryPath;
}

export enum TripCancelMethodReasonEnum {
  NON_CANCELABLE_ITIN_SOURCE = 'NON_CANCELABLE_ITIN_SOURCE',
  TRIP_NOT_CONFIRMED_OR_TICKETED = 'TRIP_NOT_CONFIRMED_OR_TICKETED',
  FLIGHT_IS_EXCHANGED = 'FLIGHT_IS_EXCHANGED',
  TRIP_HAS_STARTED = 'TRIP_HAS_STARTED',
  NON_VOIDABLE_NOR_REFUNDABLE = 'NON_VOIDABLE_NOR_REFUNDABLE',
  NON_CANCELLABLE_LEG_STATUS = 'NON_CANCELLABLE_LEG_STATUS',
  OUTSIDE_BOOKING_AND_IS_MOBILE_DEVICE = 'OUTSIDE_BOOKING_AND_IS_MOBILE_DEVICE',
  OUTSIDE_BOOKING_AND_NOT_AGENT = 'OUTSIDE_BOOKING_AND_NOT_AGENT',
  UNKNOWN = 'UNKNOWN',
  REFUND = 'REFUND',
  VOID = 'VOID',
}

export type ICancelReason =
  | {
      type: TripCancelMethodReasonEnum.NON_CANCELABLE_ITIN_SOURCE;
      metaData: { itinSource: ThirdPartySourceEnum };
    }
  | { type: TripCancelMethodReasonEnum.TRIP_NOT_CONFIRMED_OR_TICKETED; metaData: { pnrStatus: PnrStatusV1 } }
  | { type: TripCancelMethodReasonEnum.FLIGHT_IS_EXCHANGED; metaData: boolean }
  | { type: TripCancelMethodReasonEnum.TRIP_HAS_STARTED; metaData: boolean }
  | { type: TripCancelMethodReasonEnum.NON_VOIDABLE_NOR_REFUNDABLE; metaData: boolean }
  | { type: TripCancelMethodReasonEnum.NON_CANCELLABLE_LEG_STATUS; metaData: { legStatus: UserFacingStatusV1 } }
  | {
      type: TripCancelMethodReasonEnum.OUTSIDE_BOOKING_AND_IS_MOBILE_DEVICE;
      metaData: boolean;
    }
  | { type: TripCancelMethodReasonEnum.OUTSIDE_BOOKING_AND_NOT_AGENT; metaData: boolean }
  | { type: TripCancelMethodReasonEnum.UNKNOWN };

export interface ICancellationWorkflow {
  cancellationWorkflow: CancellationWorkflow;
  reason: ICancelReason;
}

export type TripOption = {
  label: string;
  value: string;
  startDate: DateTime | undefined;
  tripMetadata: ListTripsResponseTripInfo['tripMetadata'];
};

export enum ChangeBySupportReasonEnum {
  NO_TICKET_NUMBER_FOUND = 'NO_TICKET_NUMBER_FOUND',
  PNR_SOURCE_OFFLINE = 'PNR_SOURCE_OFFLINE',
  NON_CHANGEABLE_ITINERARY_SOURCE = 'NON_CHANGEABLE_ITINERARY_SOURCE',
  LEG_STATUS_NOT_CONFIRMED = 'LEG_STATUS_NOT_CONFIRMED',
  NON_EXCHANGEABLE_EXCHANGE_POLICY = 'NON_EXCHANGEABLE_EXCHANGE_POLICY',
  LEG_TICKET_ALREADY_CHANGED = 'LEG_TICKET_ALREADY_CHANGED',
  EXCHANGE_POLICY_INFO_NOT_AVAILABLE = 'EXCHANGE_POLICY_INFO_NOT_AVAILABLE',
  MANUAL_CHANGE_ALLOWED = 'MANUAL_CHANGE_ALLOWED',
  UNKNOWN = 'UNKNOWN',
}

export type IChangeBySupportReason =
  | {
      type: ChangeBySupportReasonEnum.NO_TICKET_NUMBER_FOUND;
    }
  | {
      type: ChangeBySupportReasonEnum.PNR_SOURCE_OFFLINE;
      metaData: { pnrSource: ThirdPartySourceEnum };
    }
  | {
      type: ChangeBySupportReasonEnum.NON_CHANGEABLE_ITINERARY_SOURCE;
      metaData: { itinSource: ThirdPartySourceEnum };
    }
  | {
      type: ChangeBySupportReasonEnum.LEG_STATUS_NOT_CONFIRMED;
      metaData: { legStatus: UserFacingStatusV1 };
    }
  | {
      type: ChangeBySupportReasonEnum.NON_EXCHANGEABLE_EXCHANGE_POLICY;
      metaData: { exchangePolicy: TicketCancellationPolicyExchangePolicy | undefined };
    }
  | { type: ChangeBySupportReasonEnum.LEG_TICKET_ALREADY_CHANGED }
  | {
      type: ChangeBySupportReasonEnum.EXCHANGE_POLICY_INFO_NOT_AVAILABLE;
      metaData: { exchangePolicy: TicketCancellationPolicyExchangePolicy | undefined };
    }
  | { type: ChangeBySupportReasonEnum.MANUAL_CHANGE_ALLOWED }
  | { type: ChangeBySupportReasonEnum.UNKNOWN };

export interface IPnrChangeUsingSupport {
  changeBySupport: boolean;
  reason: IChangeBySupportReason;
  legId?: string;
}

export interface IPnrCancellationDetailsRequest {
  tripId: string;
  pnrId: string;
  isEnabled: boolean;
}

export interface IVirtualCardMetaData {
  pnrId: string;
}

export enum CancellationWorkflow {
  REFUND = 'REFUND',
  VOID = 'VOID',
  SUPPORT = 'SUPPORT',
}

export interface getFeatureVisibilityStateProps {
  features: OBTFeatureConfig[] | undefined;
  obtFeatureTypeEnum: OBTFeatureConfigObtFeatureTypeEnum;
  defaultVisibility?: OBTFeaturePreferencesPreferenceVisibilityEnum;
}

export interface ITripsFilterState {
  tripStatus?: TripCategoryRequest;
}

export interface BookingPaymentSourceTraveler {
  traveler: ITraveler | undefined;
  paxType: PassengerType;
}

export interface PaymentSourceBreakdownLine {
  card?: Card;
  paymentMethod: PaymentMethod | undefined;
  amountValue: MoneyUtil;
}

export interface RailCancelRailSelectionProps {
  itineraryDetails: ItineraryDetails; // Itinerary details for the rail journey.
  cancellationDetails: RailPnrCancellationResponseManager; // Manager for cancellation details associated with the rail PNR.
  selectedLegs: number[]; // Array of selected leg indices within the rail journey.
  userSelectedLegs: number[]; // Array of user selected leg indices within the rail journey.
  onLegSelection: (legIds: number[]) => void; // Callback function for updating the selected leg indices.
  onUserLegSelection: (legIds: number[]) => void; // Callback function for user-initiated leg selection changes.
  setCheckoutPageVisibility: (isVisible: boolean) => void; // Callback function for controlling the visibility of the checkout page.
  showAutoSelectCancellationWarning: boolean; // Flag to indicate whether to show an auto-select cancellation warning.
  tickets: IRailTicket[]; // Array of tickets within the rail journey.
}
