import { CardCompany, CardTypeEnum, ManualFormPaymentType } from '../types';

export enum bookingImporterTranslationKeys {
  CAR_DETAILS = 'CAR_DETAILS',
  CONTACT = 'CONTACT',
  CAR_RENTAL_COMPANY = 'CAR_RENTAL_COMPANY',
  MILEAGE = 'MILEAGE',
  CONF_NO = 'CONF_NO',
  EMAIL_ADDRESS = 'EMAIL_ADDRESS',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PICK_UP = 'PICK_UP',
  DROP_OFF = 'DROP_OFF',
  AIRPORT = 'AIRPORT',
  ADDRESS_LINE = 'ADDRESS_LINE',
  ZIPCODE = 'ZIPCODE',
  CITY = 'CITY',
  DEPARTURE_DATE_AND_TIME = 'DEPARTURE_DATE_AND_TIME',
  VEHICLE_INFORMATION = 'VEHICLE_INFORMATION',
  FUEL_POLICY = 'FUEL_POLICY',
  NUMBER_OF_SEATS = 'NUMBER_OF_SEATS',
  NUMBER_OF_DOORS = 'NUMBER_OF_DOORS',
  ENGINE_TYPE = 'ENGINE_TYPE',
  AIR_CONDITIONING = 'AIR_CONDITIONING',
  TRANSMISSION = 'TRANSMISSION',
  CAR_TYPE = 'CAR_TYPE',
  ELECTRIC_VEHICLE = 'ELECTRIC_VEHICLE',
  LARGE_SUITCASE_LIMIT = 'LARGE_SUITCASE_LIMIT',
  SMALL_SUITCASE_LIMIT = 'SMALL_SUITCASE_LIMIT',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  CHARGING_METHOD = 'CHARGING_METHOD',
  OUT_OF_POLICY = 'OUT_OF_POLICY',
  COST = 'COST',
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
  BASE_FARE = 'BASE_FARE',
  BASE_FARE_AMOUNT = 'BASE_FARE_AMOUNT',
  TAXES = 'TAXES',
  ADD_EXTRA = 'ADD_EXTRA',
  FULL_AMOUNT = 'FULL_AMOUNT',
  AIRPORT_CODE = 'AIRPORT_CODE',
  PICKUP_TYPE = 'PICKUP_TYPE',
  LIMO_DETAILS = 'LIMO_DETAILS',
  ADD_SEGMENT = 'ADD_SEGMENT',
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
  STANDARD_CAR = 'STANDARD_CAR',
  EXECUTIVE_CAR = 'EXECUTIVE_CAR',
  BUSINESS_VAN = 'BUSINESS_VAN',
  FIRST_CLASS = 'FIRST_CLASS',
  PAY_TO_LIMO_VENDOR = 'PAY_TO_LIMO_VENDOR',
  PREPAID = 'PREPAID',
  SELECT_METHOD = 'SELECT_METHOD',
  EXTRA = 'EXTRA',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
  CANCEL = 'CANCEL',
  RETRY = 'RETRY',
  OUTSIDE_BOOKING_ADDED = 'OUTSIDE_BOOKING_ADDED',
  OUTSIDE_BOOKING_SUCCESS_MSG = 'OUTSIDE_BOOKING_SUCCESS_MSG',
  VIEW_TRIP = 'VIEW_TRIP',
  HOTEL_DETAILS = 'HOTEL_DETAILS',
  HOTEL_NAME = 'HOTEL_NAME',
  CONFIRMATION_NUMBER = 'CONFIRMATION_NUMBER',
  CANCELLATION_NUMBER = 'CANCELLATION_NUMBER',
  CHECK_IN_DATE_AND_TIME = 'CHECK_IN_DATE_AND_TIME',
  CHECK_OUT_DATE_AND_TIME = 'CHECK_OUT_DATE_AND_TIME',
  STAR_RATING = 'STAR_RATING',
  COUNTRY = 'COUNTRY',
  SELECT_TIME = 'SELECT_TIME',
  PICKUP_LOCATION = 'PICKUP_LOCATION',
  DROPOFF_LOCATION = 'DROPOFF_LOCATION',
  PICKUP_DATE_TIME = 'PICKUP_DATE_TIME',
  DROPOFF_DATE_TIME = 'DROPOFF_DATE_TIME',
  PICKUP_NOTE = 'PICKUP_NOTE',
  DROPOFF_NOTE = 'DROPOFF_NOTE',
  LOCATION = 'LOCATION',
  ROOM_DETAILS = 'ROOM_DETAILS',
  ARRIVAL_DATE_AND_TIME = 'ARRIVAL_DATE_AND_TIME',
  RESET = 'RESET',
  DELETE = 'DELETE',
  ADD_BOOKING = 'ADD_BOOKING',
  DELETE_BOOKING = 'DELETE_BOOKING',
  DRIVER_NAME = 'DRIVER_NAME',
  LIMO_COMPANY = 'LIMO_COMPANY',
  IS_REQUIRED = 'IS_REQUIRED',
  PICKUP_DATE_TIME_ERROR = 'PICKUP_DATE_TIME_ERROR',
  DROPOFF_DATE_TIME_ERROR = 'DROPOFF_DATE_TIME_ERROR',
  ROOM = 'ROOM',
  BED_TYPE = 'BED_TYPE',
  NUM_OF_BEDS = 'NUM_OF_BEDS',
  NUM_OF_ROOMS = 'NUM_OF_ROOMS',
  CANCELLATION_POLICY = 'CANCELLATION_POLICY',
  COST_PER_NIGHT = 'COST_PER_NIGHT',
  COST_PER_PERSON = 'COST_PER_PERSON',
  ROOM_CHARGES = 'ROOM_CHARGES',
  NUM_OF_NIGHTS = 'NUM_OF_NIGHTS',
  TOTAL_AMOUNT = 'TOTAL_AMOUNT',
  ALPHANUM_ONLY = 'ALPHANUM_ONLY',
  SHOULD_BE_NON_NEGATIVE = 'SHOULD_BE_NON_NEGATIVE',
  SHOULD_BE_POSITIVE = 'SHOULD_BE_POSITIVE',
  EXCHANGE_RATES_UNAVAILABLE = 'EXCHANGE_RATES_UNAVAILABLE',
  UNKNOWN_CAR_TYPE = 'UNKNOWN_CAR_TYPE',
  CANNOT_BE_AFTER = 'CANNOT_BE_AFTER',
  CANNOT_BE_BEFORE = 'CANNOT_BE_BEFORE',
  IS_INVALID = 'IS_INVALID',
  SELECT_CARD = 'SELECT_CARD',
  ENTER_DATE_AND_TIME = 'ENTER_DATE_AND_TIME',
  POLICY = 'POLICY',
  OTHER_TYPE = 'OTHER_TYPE',
  METHOD = 'METHOD',
  DEADLINE = 'DEADLINE',
  LOADING_LOCATIONS = 'LOADING_LOCATIONS',
  FREE_CANCELLATION_UNTIL = 'FREE_CANCELLATION_UNTIL',
  NON_REFUNDABLE = 'NON_REFUNDABLE',
  OTHER = 'OTHER',
  PAY_AT_HOTEL = 'PAY_AT_HOTEL',
  DOUBLE = 'DOUBLE',
  FUTON = 'FUTON',
  KING = 'KING',
  MURPHY = 'MURPHY',
  QUEEN = 'QUEEN',
  SOFA = 'SOFA',
  TATAMI_MATS = 'TATAMI_MATS',
  TWIN = 'TWIN',
  SINGLE = 'SINGLE',
  FULL = 'FULL',
  RUN_OF_THE_HOUSE = 'RUN_OF_THE_HOUSE',
  DORM = 'DORM',
  WATER = 'WATER',
  PULL_OUT = 'PULL_OUT',
  UNLIMITED = 'UNLIMITED',
  LIMIT = 'LIMIT',
  KM = 'KM',
  MILES = 'MILES',
  UNIT = 'UNIT',
  CALL_FOR_SHUTTLE = 'CALL_FOR_SHUTTLE',
  IN_TERMINAL = 'IN_TERMINAL',
  IN_TERMINAL_RENTAL_SHUTTLE = 'IN_TERMINAL_RENTAL_SHUTTLE',
  OFF_AIRPORT_RENTAL_SHUTTLE = 'OFF_AIRPORT_RENTAL_SHUTTLE',
  ON_AIRPORT_AIRPORT_SHUTTLE = 'ON_AIRPORT_AIRPORT_SHUTTLE',
  TWO_SHUTTLES_AIRPORT_AND_RENTAL = 'TWO_SHUTTLES_AIRPORT_AND_RENTAL',
  UNKNOWN_COUNTER = 'UNKNOWN_COUNTER',
  MINI = 'MINI',
  ECONOMY = 'ECONOMY',
  COMPACT = 'COMPACT',
  MID_SIZE = 'MID_SIZE',
  STANDARD = 'STANDARD',
  FULL_SIZE = 'FULL_SIZE',
  PREMIUM = 'PREMIUM',
  LUXURY = 'LUXURY',
  CONVERTIBLE = 'CONVERTIBLE',
  MINIVAN = 'MINIVAN',
  SUV = 'SUV',
  VAN = 'VAN',
  PICKUP = 'PICKUP',
  SPORTS = 'SPORTS',
  SPECIAL = 'SPECIAL',
  RECREATIONAL_VEHICLE = 'RECREATIONAL_VEHICLE',
  PAY_AT_CAR_VENDOR = 'PAY_AT_CAR_VENDOR',
  PETROL = 'PETROL',
  DIESEL = 'DIESEL',
  ELECTRIC = 'ELECTRIC',
  CNG = 'CNG',
  AC_AVAILABLE = 'AC_AVAILABLE',
  AC_NOT_AVAILABLE = 'AC_NOT_AVAILABLE',
  MANUAL_UNSPECIFIED_DRIVE = 'MANUAL_UNSPECIFIED_DRIVE',
  AUTO_UNSPECIFIED_DRIVE = 'AUTO_UNSPECIFIED_DRIVE',
  SEAT = 'SEAT',
  CABIN = 'CABIN',
  COACH = 'COACH',
  NO_CREDIT_CARD_AVAILABLE = 'NO_CREDIT_CARD_AVAILABLE',
  TO = 'TO',
  FROM = 'FROM',
  RAIL_CARRIER = 'RAIL_CARRIER',
  TRAIN_NUMBER = 'TRAIN_NUMBER',
  CLASS = 'CLASS',
  FARE_TYPE = 'FARE_TYPE',
  FIRST = 'FIRST',
  RAIL_DETAILS = 'RAIL_DETAILS',
  TICKET_INFORMATION = 'TICKET_INFORMATION',
  COACH_AND_SEAT = 'COACH_AND_SEAT',
  ONE_WAY = 'ONE_WAY',
  ROUND_TRIP = 'ROUND_TRIP',
  TRIP_TYPE = 'TRIP_TYPE',
  THIRD_PARTY_SUPPLIER_NAME = 'THIRD_PARTY_SUPPLIER_NAME',
  THIRD_PARTY_CONFIRMATION_NUMBER = 'THIRD_PARTY_CONFIRMATION_NUMBER',
  TICKET_DELIVERY_METHOD = 'TICKET_DELIVERY_METHOD',
  SELECT_DELIVERY_METHOD = 'SELECT_DELIVERY_METHOD',
  ELECTRONIC_TICKET = 'ELECTRONIC_TICKET',
  KIOSK = 'KIOSK',
  TICKET_NUMBERS_AND_COST = 'TICKET_NUMBERS_AND_COST',
  SPLIT_COST = 'SPLIT_COST',
  COMBINE_COST = 'COMBINE_COST',
  TICKET_COLLECTION = 'TICKET_COLLECTION',
  UPLOAD_PDF = 'UPLOAD_PDF',
  RETURN = 'RETURN',
  CURRENCY = 'CURRENCY',
  MISCELLANEOUS_DETAILS = 'MISCELLANEOUS_DETAILS',
  START_DATE_AND_TIME = 'START_DATE_AND_TIME',
  END_DATE_AND_TIME = 'END_DATE_AND_TIME',
  DESCRIPTION = 'DESCRIPTION',
  START_TIME = 'START_TIME',
  END_TIME = 'END_TIME',
  START_DATE_TIME_ERROR = 'START_DATE_TIME_ERROR',
  END_DATE_TIME_ERROR = 'END_DATE_TIME_ERROR',
  CANCELLATION_POLICY_DEADLINE_ERROR = 'CANCELLATION_POLICY_DEADLINE_ERROR',
  ARRIVAL_DATE_TIME_ERROR = 'ARRIVAL_DATE_TIME_ERROR',
  OPEN_RETURN = 'OPEN_RETURN',
  SHOULD_BE_AFTER = 'SHOULD_BE_AFTER',
  PREVIOUS = 'PREVIOUS',
  THIRD_PARTY = 'THIRD_PARTY',
  SPOTNANA_CONFIRMATION_NUMBER = 'SPOTNANA_CONFIRMATION_NUMBER',
  AIRLINE_CONFIRMATION_NUMBER = 'AIRLINE_CONFIRMATION_NUMBER',
  SHOULD_BE_LESS_THAN_TOTAL_AMOUNT = 'SHOULD_BE_LESS_THAN_TOTAL_AMOUNT',
  SHOULD_BE_LESS_THAN_OR_EQUAL_TO_TOTAL_AMOUNT = 'SHOULD_BE_LESS_THAN_OR_EQUAL_TO_TOTAL_AMOUNT',
  NEAREST_AIRPORT = 'NEAREST_AIRPORT',
  PRICE_SUMMARY = 'PRICE_SUMMARY',
  HOTEL = 'HOTEL',
  CREATE = 'CREATE',
  UPDATE_OR_EDIT = 'UPDATE_OR_EDIT',
  REFUND = 'REFUND',
  EXCHANGE = 'EXCHANGE',
  CHANGE_TYPE = 'CHANGE_TYPE',
  HOTEL_INFORMATION = 'HOTEL_INFORMATION',
  TRANSACTION_DATE = 'TRANSACTION_DATE',
  REFUND_AMOUNT = 'REFUND_AMOUNT',
  PENALTY = 'PENALTY',
  TAX_REFUND = 'TAX_REFUND',
  FULL_REFUND_AMOUNT = 'FULL_REFUND_AMOUNT',
  BOOKING = 'BOOKING',
  EDIT_OR_UPDATE = 'EDIT_OR_UPDATE',
  REFUND_BASE_FARE = 'REFUND_BASE_FARE',
  REFUND_TAXES_AMOUNT = 'REFUND_TAXES_AMOUNT',
  SEGMENT = 'SEGMENT',
  VEHICLE_TYPE = 'VEHICLE_TYPE',
  SAVE = 'SAVE',
  ADD_AIR_SEGMENT_TO_LEG = 'ADD_AIR_SEGMENT_TO_LEG',
  ADD_BAGGAGE_TO_LEG = 'ADD_BAGGAGE_TO_LEG',
  ADD_SEAT_TO_AIR_LEG_SEGMENT = 'ADD_SEAT_TO_AIR_LEG_SEGMENT',
  DELETE_SEAT = 'DELETE_SEAT',
  DELETE_SEGMENT = 'DELETE_SEGMENT',
  TICKET_DETAILS_WITH_NUMBER = 'TICKET_DETAILS_WITH_NUMBER',
  TICKET_TYPE = 'TICKET_TYPE',
  TICKET_NUMBER = 'TICKET_NUMBER',
  NEW_TICKET_NUMBER = 'NEW_TICKET_NUMBER',
  ADD_LEG = 'ADD_LEG',
  DELETE_TICKET = 'DELETE_TICKET',
  ADD_NEW_FLIGHT_TICKET = 'ADD_NEW_FLIGHT_TICKET',
  COMMISSION = 'COMMISSION',
  LEG_WITH_NUMBER = 'LEG_WITH_NUMBER',
  DELETE_LEG = 'DELETE_LEG',
  LEG_SEGMENT_AIR = 'LEG_SEGMENT_AIR',
  LEG_SEGMENT_SEAT = 'LEG_SEGMENT_SEAT',
  MARKETING_AIRLINES = 'MARKETING_AIRLINES',
  MARKETING_AIRLINES_FLIGHT_NO = 'MARKETING_AIRLINES_FLIGHT_NO',
  OPERATING_AIRLINES = 'OPERATING_AIRLINES',
  OPERATING_AIRLINES_FLIGHT_NO = 'OPERATING_AIRLINES_FLIGHT_NO',
  AIRLINE_CONFIRMATION_NO = 'AIRLINE_CONFIRMATION_NO',
  BRANDED_FARE = 'BRANDED_FARE',
  THIRD_PARTY_CONFIRMATION_NO = 'THIRD_PARTY_CONFIRMATION_NO',
  BAGGAGE = 'BAGGAGE',
  PURCHASE = 'PURCHASE',
  ARC_BSP = 'ARC_BSP',
  LEG_BAGGAGE = 'LEG_BAGGAGE',
  LOWER_FARE_DECLINED = 'LOWER_FARE_DECLINED',
  LOWER_FARE_DECLINED_CURRENCY = 'LOWER_FARE_DECLINED_CURRENCY',
  LOWER_FARE_DECLINED_AMOUNT = 'LOWER_FARE_DECLINED_AMOUNT',
  ADD_TAX_CODE = 'ADD_TAX_CODE',
  TAX_CODE = 'TAX_CODE',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  LOYALTY_NUMBER = 'LOYALTY_NUMBER',
  LOYALTY_PROGRAM = 'LOYALTY_PROGRAM',
  PROGRAM_NUMBER = 'PROGRAM_NUMBER',
  NEW_BASE_FARE = 'NEW_BASE_FARE',
  NEW_TAX = 'NEW_TAX',
  SEAT_NUMBER = 'SEAT_NUMBER',
  OTHER_TRAVELERS_FORM_ISSUES = 'OTHER_TRAVELERS_FORM_ISSUES',
  BOOKING_DETAILS = 'BOOKING_DETAILS',
  REASON = 'REASON',
  TAX_CODES_SUM_SHOULD_NOT_EXCEED_TAX = 'TAX_CODES_SUM_SHOULD_NOT_EXCEED_TAX',
  PAYMENT_METHOD_TO_USE = 'PAYMENT_METHOD_TO_USE',
  CHARGING_METHOD_TO_USE = 'CHARGING_METHOD_TO_USE',
  DELAYED_INVOICE_PAYMENT_HELPER = 'DELAYED_INVOICE_PAYMENT_HELPER',
  DELAYED_INVOICE_TMC_PAYMENT_HELPER = 'DELAYED_INVOICE_TMC_PAYMENT_HELPER',
  NON_TMC_PAYMENT_HELPER = 'NON_TMC_PAYMENT_HELPER',
  SHOULD_NOT_EXCEED = 'SHOULD_NOT_EXCEED',
  SEAT_IS_ALREADY_TAKEN = 'SEAT_IS_ALREADY_TAKEN',
  SHOULD_BE_UNIQUE = 'SHOULD_BE_UNIQUE',
  EITHER_ALL_OR_NONE_TICKETS_SHOULD_HAVE_LLF = 'EITHER_ALL_OR_NONE_TICKETS_SHOULD_HAVE_LLF',
  OVERALL_PENALTY_CHARGED = 'OVERALL_PENALTY_CHARGED',
}

export const MAX_HOTEL_RATING = 5;

export const MAX_PDF_FILE_SIZE = 5000000; // 5MB

/**
 * To be used as a `confirmationNumber` in incomplete outside bookings.
 *
 * Is only used in Hotel Manual Form at the moment
 */
export const DUMMY_CONFIRMATION_NUMBER_FOR_INCOMPLETE_OUTSIDE_BOOKINGS = '__DUMMY_VENDOR_CONFIRMATION_NUMBER';

export const DELAYED_INVOICE_FAKE_CARD_LABEL = 'Delayed invoice';

export const DELAYED_INVOICE_FAKE_CARD = {
  isDisabled: false,
  label: DELAYED_INVOICE_FAKE_CARD_LABEL,
  value: {
    paymentCard: {
      company: CardCompany.None,
      cvv: '',
      expiryMonth: 0,
      expiryYear: 9999,
      id: DELAYED_INVOICE_FAKE_CARD_LABEL,
      label: DELAYED_INVOICE_FAKE_CARD_LABEL,
      name: DELAYED_INVOICE_FAKE_CARD_LABEL,
      number: '',
      type: CardTypeEnum.Unknown,
    },
    // type set as TMC otherwise charging method will be paid if type is CENTRAL or PERSONAL - refer above explaination
    type: ManualFormPaymentType.TMC,
  },
};
