import type { UseQueryOptions, UseQueryResult, UseMutationResult } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';

import get from 'lodash/get';
import api from '../api';
import type SpotnanaError from '../api/SpotnanaError';
import type {
  ListBookingPaymentSourcesRequest,
  ListBookingPaymentSourcesResponse,
  PaymentSetupRequest,
  PaymentSetupResponse,
  SpotnanaQueryOptions,
  SpotnanaQueryResult,
} from '../types';
import type { AirItinerary } from '../types/api/v2/obt/model/air-itinerary';
import type { CarItinerary } from '../types/api/v2/obt/model/car-itinerary';
import type { HotelItinerary } from '../types/api/v2/obt/model/hotel-itinerary';
import type { RailItinerary } from '../types/api/v2/obt/model/rail-itinerary';
import type { VaultConfigResponse } from '../types/api/v2/obt/model/vault-config-response';
import { defaultQueryClient } from './defaultQueryClient';
import type { BookingWorkflowType } from '../types/api/v2/obt/model/booking-workflow-type';
import type { CheckoutTravelerInfo } from '../types/api/v2/obt/model/checkout-traveler-info';

const paymentSetupKey = (paymentSetupRequest: PaymentSetupRequest | undefined): readonly unknown[] =>
  ['payment-setup', paymentSetupRequest] as const;

type IPaymentSetupKey = ReturnType<typeof paymentSetupKey>;

const fetchPaymentSetup = async (data: PaymentSetupRequest | undefined): Promise<PaymentSetupResponse> => {
  const paymentSetupResponse = await api('POST', 'paymentSetup', { data });
  return paymentSetupResponse as PaymentSetupResponse;
};

export const usePaymentSetupQuery = (
  paymentSetupRequest: PaymentSetupRequest | undefined,
  enabled: boolean,
): UseQueryResult<PaymentSetupResponse, SpotnanaError> =>
  useQuery<PaymentSetupResponse, SpotnanaError>(
    paymentSetupKey(paymentSetupRequest),
    () => fetchPaymentSetup(paymentSetupRequest),
    {
      enabled,
    },
  );

type IUseMultiplePaymentSetupQueryOptions = SpotnanaQueryOptions<IPaymentSetupRequestResponse, IPaymentSetupKey>;

export interface IPaymentSetupRequestInfo {
  request: PaymentSetupRequest;
  paymentSourceId: string;
}
export interface IPaymentSetupRequestResponse {
  requestInfo: IPaymentSetupRequestInfo;
  response: PaymentSetupResponse;
}

interface IUseAirReadMultipleLoyaltyProgramsProps {
  requestInfo: IPaymentSetupRequestInfo;
  queryOptions?: IUseMultiplePaymentSetupQueryOptions;
}

export const useMultiplePaymentSetupQuery = (
  props: IUseAirReadMultipleLoyaltyProgramsProps[],
): SpotnanaQueryResult<IPaymentSetupRequestResponse>[] => {
  const queries: IUseMultiplePaymentSetupQueryOptions[] = props.map(({ requestInfo, queryOptions }) => {
    const isEnabled = !!requestInfo.request && !!requestInfo.paymentSourceId && get(queryOptions, 'enabled', true);

    return {
      ...queryOptions,
      queryKey: paymentSetupKey(requestInfo.request),
      queryFn: async () => {
        const response = await fetchPaymentSetup(requestInfo.request);
        return { requestInfo, response };
      },
      enabled: isEnabled,
    };
  });

  return useQueries(queries as UseQueryOptions[]) as SpotnanaQueryResult<IPaymentSetupRequestResponse>[];
};

export const useMutatePaymentSetup = (
  options?: UseQueryOptions<PaymentSetupResponse, SpotnanaError>,
): UseMutationResult<PaymentSetupResponse, SpotnanaError, PaymentSetupRequest> =>
  useMutation<PaymentSetupResponse, SpotnanaError, PaymentSetupRequest>(
    (paymentSetupRequest) => fetchPaymentSetup(paymentSetupRequest),
    options,
  );

export const invalidatePaymentSetup = (): void => {
  defaultQueryClient.invalidateQueries('payment-setup');
};

export enum ListBookingPaymentSourcesRequestType {
  AIR = 'AIR',
  CAR = 'CAR',
  HOTEL = 'HOTEL',
  RAIL = 'RAIL',
}

type PaymentSourcesQueryParameters = {
  userId: string | undefined;
  eventId?: string | undefined;
  type: ListBookingPaymentSourcesRequestType;
  itineraryId: string | undefined;
  seatMapResponseId?: string;
  searchId?: string | undefined;
  includeTmcPaymentSources?: boolean;
  initiateBookingResponseId?: string;
  pnrId?: string;
  bookingWorkflowType: BookingWorkflowType;
  checkoutTravelerInfo?: CheckoutTravelerInfo;
  tripId?: string;
};

const LIST_PAYMENT_SOURCES_QUERY_IDENTIFIER = 'list-payment-sources';

const listBookingPaymentSourcesKey = ({
  userId,
  type,
  eventId,
  itineraryId,
  seatMapResponseId,
  searchId,
  includeTmcPaymentSources,
  initiateBookingResponseId,
  pnrId,
  bookingWorkflowType,
  checkoutTravelerInfo,
  tripId,
}: PaymentSourcesQueryParameters) =>
  [
    LIST_PAYMENT_SOURCES_QUERY_IDENTIFIER,
    userId,
    type,
    itineraryId,
    seatMapResponseId,
    searchId,
    includeTmcPaymentSources,
    initiateBookingResponseId,
    pnrId,
    bookingWorkflowType,
    checkoutTravelerInfo,
    eventId,
    // avoid empty string for tripId
    tripId || undefined,
  ] as const;

type ListBookingPaymentSourcesKeyType = ReturnType<typeof listBookingPaymentSourcesKey>;

const fetchListBookingPaymentSources = async (
  data: ListBookingPaymentSourcesKeyType,
): Promise<ListBookingPaymentSourcesResponse> => {
  const [
    ,
    userId = '',
    type,
    itineraryId,
    seatMapResponseId,
    searchId,
    includeTmcPaymentSources,
    initiateBookingResponseId,
    pnrId,
    bookingWorkflowType,
    checkoutTravelerInfo,
    eventId,
    tripId,
  ] = data;
  const itinerary: ListBookingPaymentSourcesRequest['itinerary'] = {};

  switch (type) {
    case ListBookingPaymentSourcesRequestType.AIR:
      (itinerary as AirItinerary).airItineraryId = {
        ancillaryResponseId: itineraryId,
        initiateBookingResponseId,
        seatMapResponseId,
      };
      break;
    case ListBookingPaymentSourcesRequestType.CAR:
      (itinerary as CarItinerary).carItineraryId = {
        searchId: searchId ?? '',
        carId: itineraryId ?? '',
      };
      break;
    case ListBookingPaymentSourcesRequestType.HOTEL:
      (itinerary as HotelItinerary).hotelItineraryId = {
        priceValidateKey: itineraryId ?? '',
      };
      break;
    case ListBookingPaymentSourcesRequestType.RAIL:
      (itinerary as RailItinerary).railItineraryId = {
        searchKey: itineraryId ?? '',
      };
      break;
    default:
      break;
  }

  const listBookingPaymentSourcesRequest: ListBookingPaymentSourcesRequest = {
    userId: { id: userId },
    itinerary,
    includeTmcPaymentSources,
    pnrId,
    eventId,
    bookingWorkflowType,
    checkoutTravelerInfo,
    // avoid empty string for tripId
    tripId: tripId || undefined,
  };
  const listBookingPaymentSourcesResponse = await api('POST', 'listBookingPaymentSources', {
    data: listBookingPaymentSourcesRequest,
  });
  return listBookingPaymentSourcesResponse as ListBookingPaymentSourcesResponse;
};

export const useListBookingPaymentSourcesQuery = (
  params: PaymentSourcesQueryParameters,
  config: UseQueryOptions<
    ListBookingPaymentSourcesResponse,
    SpotnanaError,
    ListBookingPaymentSourcesResponse,
    ListBookingPaymentSourcesKeyType
  > = {},
): UseQueryResult<ListBookingPaymentSourcesResponse, SpotnanaError> =>
  useQuery(listBookingPaymentSourcesKey(params), ({ queryKey }) => fetchListBookingPaymentSources(queryKey), {
    cacheTime: 0,
    ...config,
  });

export const invalidateListBookingPaymentSourcesQueryWithParams = (params: PaymentSourcesQueryParameters): void => {
  const queryKey = listBookingPaymentSourcesKey(params);

  // Make sure we do cascade invalidate
  defaultQueryClient.invalidateQueries(params.itineraryId ? queryKey : queryKey.slice(0, -1));
};

export const invalidateListBookingPaymentSourcesQuery = (): void => {
  defaultQueryClient.invalidateQueries(LIST_PAYMENT_SOURCES_QUERY_IDENTIFIER);
};

/**
 * =================================================
 * Vault Config Query related code [Start]
 * =================================================
 */

function getVaultConfigQueryKey(userId: string): string[] {
  return ['vault-config', userId];
}

export async function fetchVaultConfig(userId: string): Promise<VaultConfigResponse> {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/applicable-vault-config` });
  return data as VaultConfigResponse;
}

type UseVaultConfigQueryOptions = {
  enabled?: boolean;
};

export function useVaultConfigQuery(
  userId: string,
  { enabled = true }: UseVaultConfigQueryOptions = {},
): SpotnanaQueryResult<VaultConfigResponse> {
  return useQuery(
    getVaultConfigQueryKey(userId),
    ({ queryKey: [_queryKeyIdentifier, userIdFromKey] }) => fetchVaultConfig(userIdFromKey),
    {
      enabled,
    },
  );
}

export function invalidateVaultConfigQueries(): Promise<void> {
  return defaultQueryClient.invalidateQueries('vault-config');
}

/**
 * =================================================
 * Vault Config Query related code [End]
 * =================================================
 */
