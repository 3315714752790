import './v1-styles/styles.scss';

import * as React from 'react';
import { Suspense, useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { I18nProvider } from '@react-aria/i18n';
import { Flex } from '@spotnana/pixel-react/dist/Box';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import { StylesProvider } from '@spotnana/pixel-react/dist/StylesProvider';
import {
  CUSTOM_FIELD_TYPES,
  ComposeProviders,
  Config,
  LoginState,
  MissingErrorDetailsTracker,
  RBAC,
  ScreenDimensionsTelemetry,
  StorageKeys,
  TMCAgentAndAboveRoles,
  TravelerPreferredCurrencyProvider,
  TravelerProvider,
  UrlEncodingKeyProvider,
  UserIdentityTelemetry,
  initializeSessionId,
  parseParams,
  tokenExchange,
  useAuth,
  useFeatureFlag,
  useHasUserAccess,
  useIsLoggedIn,
  useLoggedInUserId,
  useMountEffect,
  useWhiteLabelConfig,
  webSessionStorage,
} from 'obt-common';

import PixelProvider from '@spotnana/pixel-react/dist/PixelProvider';
import { useDateFnsLocale } from '@spotnana/blocks/src/utils/dates';
import { useCustomI18nResources } from 'obt-common/hooks/i18n/useCustomI18nResources';
import { useGeoCustomization } from 'obt-common/hooks/useGeoCustomization';
import { useCompanionViewConfig } from 'obt-common/hooks/useCompanionViewConfig';
import { InitiateFullStoryCapture } from 'src/app/tracking/FullStory/InitiateFullStoryCapture';
import { themed } from '@spotnana/blocks/src/utils';
import { css } from '@emotion/react';
import { ToastRenderer } from '@spotnana/blocks/src/Toast/ToastRenderer';
import { useDebarUserContext } from './components/DebarUserContext';
import {
  EmbedEventTypes,
  EmbedProvider,
  LoginStatus,
  useEmbedHistory,
  useEmbedStore,
  useEmitEmbedErrors,
} from './components/EmbedContext';

import { AppHeader } from './v1-components/AppHeader/AppHeader';
import ErrorBoundary from './v1-components/ErrorBoundary';
import Errors from './v1-components/Errors';
import Footer from './v1-components/Footer';
import SupportMenu from './app/agent/SupportMenu/SupportMenu';

import { LoaderContainer } from './v2-components/flights/checkout/styles';
import ChatInitializer from './v2-components/shared/ChatInitializer';
import GlobalError from './v2-components/shared/GlobalError';
import { SessionTimeoutModal } from './app/auth/SessionTimeoutModal/SessionTimeoutModal';
import {
  BlocksThemeProviderWhiteLabel,
  DefaultThemeStyle,
  ThemeStylesWithWhiteLabelConfig,
} from './app/theming/ThemeStyles';

import appRouteList, { ROUTE_PATHS } from './routes';
import { SurfaceTelemetryProvider } from './utils/telemetry/surfaceTelemetryProvider';
import { WebTelemetryProvider } from './utils/telemetry/webTelemetryProvider';

import useAPIErrorListener from './hooks/useAPIErrorListener';
import { useBrexBudgetInfoWithoutCard } from './hooks/useBrexBudgetInfo';
import useDebarUserListener from './hooks/useDebarUserListener';
import useReportStorageInaccesibleEvent from './hooks/useReportStorageInaccesibleEvent';
import useScrollReset from './hooks/useScrollReset';

import NotFound from './NotFound';
import { PageConfigProvider } from './PageConfigProvider';
import { AppBrandingProvider } from './components/AppBrandingProvider/AppBrandingProvider';
import { GlobalStyles } from './components/GlobalStyles';
import { RouteLoadingBar } from './components/RouteLoadingBar/RouteLoadingBar';
import { useAddBreadcrumbsOnUrlChange } from './hooks/useAddBreadcrumbsOnUrlChange';
import { useApplyAndFetchUserPreferredLanguageToApp } from './hooks/useApplyAndFetchUserPreferredLanguageToApp';
import { usePreserveURLForRedirectOnLogin } from './hooks/usePreserveURLForRedirectOnLogin';
import { useThirdPartyAuthFlow } from './hooks/useThirdPartyAuthFlow';
import { useDisallowedRoutes } from './v1-components/search/hooks/useAllowedTravelTypes';
import { HideOnRouteMatch } from './v2-components/HideOnRouteMatch';
import InitializePartnerEmbedScript from './v2-components/shared/InitializePartnerEmbedScript';
import { InitialisePendo } from './v2-components/shared/Pendo/pendo';
import { SiteMessagingBanners } from './v2-components/sitemessaging/SiteMessagingBanners';
import { SiteMessagingContextProvider } from './v2-components/sitemessaging/SiteMessagingContextProvider';
import { useEnhancedMonitoring } from './hooks/useEnhancedMonitoring';
import { RouteChangeTelemetry } from './utils/telemetry/RouteChangeTelemetry';
import { useSecureCode } from './hooks/security/useSecureCode';
import RightPanel from './app/agent/RightPanel';
import { RedirectToUserActionPage } from './app/user/actions/RedirectToUserActionPage';
import { RedirectToOnboardingPage } from './app/user/actions/RedirectToOnboardingPage';
import { BonvoyLogin } from './app/partners/marriott/BonvoyLogin';
import { useEmbedLanguageEvents } from './hooks/embed/useEmbedLanguageEvents';
import { useI18nDocumentLangSync } from './hooks/useI18nDocumentLangSync';
import { PrimaryTravelerUpdateReporter } from './v2-components/shared/PrimaryTravelerUpdateReporter';
import { useEmbedNavigationListener } from './hooks/embed/useEmbedNavigationListener';
import { useDisableBooking } from './app/shared/hooks/useDisableBooking';

const Companion = React.lazy(async () => ({
  default: (await import('./app/agent/CompanionView')).Companion,
}));

const publicRoutes = appRouteList.filter((route) => route.isPublicRoute === true).map((route) => route.path);

const main_app_container = themed(
  ({ palette }) => css`
    background-color: ${palette.surface.base};
    position: relative;
    flex-grow: 1;
  `,
);

const App: React.FC = () => {
  const { loginState, logout, ssoLogin } = useAuth();
  const { pathname, search } = useLocation();
  const isUserLoggedIn = useIsLoggedIn();
  const loggedInUser = useLoggedInUserId();

  initializeSessionId();
  useCustomI18nResources();
  useScrollReset();
  useEmbedHistory();
  useReportStorageInaccesibleEvent();
  useEnhancedMonitoring();
  useSecureCode();
  useEmbedLanguageEvents();
  useI18nDocumentLangSync();
  useEmbedNavigationListener();

  const headlessAuthRedirectUrl = webSessionStorage.getItem(StorageKeys.HEADLESS_AUTH_REDIRECT_URL_KEY);
  const thirdPartyAuthIDP = webSessionStorage.getItem(StorageKeys.THIRD_PARTY_AUTH_IDP);
  // Check that we don't have IDP for third party auth stored in session storage i.e we are in post forced login phase
  if (isUserLoggedIn && !thirdPartyAuthIDP && headlessAuthRedirectUrl) {
    webSessionStorage.removeItem(StorageKeys.HEADLESS_AUTH_REDIRECT_URL_KEY);
    window.location.replace(headlessAuthRedirectUrl);
  }

  // Track debar user
  useDebarUserListener();
  const { geoCustomization } = useGeoCustomization();

  const { debarUser } = useDebarUserContext();
  if (debarUser) {
    logout();
  }

  const { reportEvent } = useEmbedStore();
  useEffect(() => {
    if (loginState === LoginState.LOGGED_IN || loginState === LoginState.LOGGED_OUT) {
      reportEvent({
        type: EmbedEventTypes.LOGIN_STATUS,
        payload: {
          state: loginState === LoginState.LOGGED_IN ? LoginStatus.LOGGED_IN : LoginStatus.LOGGED_OUT,
          userId: loggedInUser?.userId?.id,
        },
      });
    }
  }, [reportEvent, loginState, loggedInUser]);

  useMountEffect(() => {
    reportEvent({
      type: EmbedEventTypes.MOUNTED,
    });
  });

  useMountEffect(() => {
    const storageEventHandler = (event: StorageEvent): void => {
      if (
        event.key === StorageKeys.LOGGED_IN_USER_ID &&
        String(event.newValue) !== String(event.oldValue) &&
        event.newValue === 'null'
      ) {
        window.location.reload();
      }
    };

    window.addEventListener('storage', storageEventHandler);

    return (): void => window.removeEventListener('storage', storageEventHandler);
  });

  const params = parseParams(search);

  useLayoutEffect(() => {
    if (pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH) && params.redirect_uri) {
      webSessionStorage.setItem(StorageKeys.HEADLESS_AUTH_REDIRECT_URL_KEY, params.redirect_uri);
    }
  }, [params.idp, params.redirect_uri, pathname, ssoLogin]);

  useThirdPartyAuthFlow();

  usePreserveURLForRedirectOnLogin();

  const locale = useDateFnsLocale();

  const isHeadlessAuth = pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH) || !!headlessAuthRedirectUrl;
  if (isHeadlessAuth) {
    return null;
  }

  const isThirdPartyRedirection = params.idp && !publicRoutes.includes(pathname);

  if (loginState === LoginState.DEFAULT || isThirdPartyRedirection || tokenExchange.includes(params.idp)) {
    return (
      <LoaderContainer data-testid="logging-loader-wrapper">
        <CircularLoader size={50} data-testid="logging-loader" />
      </LoaderContainer>
    );
  }

  const config = { showFlags: geoCustomization.showFlags };

  return (
    <AppBrandingProvider>
      <BlocksThemeProviderWhiteLabel config={config}>
        <I18nProvider locale={locale.code}>
          <StylesProvider injectFirst>
            <PixelProvider config={config}>
              <ErrorBoundary>
                <PageConfigProvider>
                  <RoutesContainerMemo />
                </PageConfigProvider>
              </ErrorBoundary>
            </PixelProvider>
          </StylesProvider>
        </I18nProvider>
      </BlocksThemeProviderWhiteLabel>
    </AppBrandingProvider>
  );
};

function PageLayoutInternal(): JSX.Element {
  return (
    <>
      <GlobalStyles />
      <Flex flexDirection="column" minHeight="100vh" flex={1}>
        <InitializePartnerEmbedScript />
        <SiteMessagingBanners />
        <HideOnRouteMatch
          paths={[
            ROUTE_PATHS.PREFERRED_LANGUAGE,
            ROUTE_PATHS.COMPANY_ENROLLMENT,
            ROUTE_PATHS.APPLY,
            ROUTE_PATHS.USER_ACTION_ALL_ROUTES,
            ROUTE_PATHS.ONBOARDING,
          ]}
        >
          <AppHeader />
        </HideOnRouteMatch>
        <main css={main_app_container}>
          <AllAppRoutes />
          <RedirectToUserActionPage />
          <ErrorContainer />
          <GlobalError showAPIErrorCodes />
          <RightPanel />
        </main>
        <HideOnRouteMatch
          paths={[
            ROUTE_PATHS.USER_ACTION_ALL_ROUTES,
            ROUTE_PATHS.EVENT_SETTINGS_CREATE,
            ROUTE_PATHS.EVENT_SETTINGS_EDIT,
            ROUTE_PATHS.EVENT_PARTICIPANTS_EDIT,
            ROUTE_PATHS.EVENT_REPORT,
            ROUTE_PATHS.COMPANY_ENROLLMENT,
            ROUTE_PATHS.APPLY,
            ROUTE_PATHS.ONBOARDING,
          ]}
        >
          <Footer />
        </HideOnRouteMatch>
        <HideOnRouteMatch
          paths={[
            ROUTE_PATHS.HOME,
            ROUTE_PATHS.PREFERRED_LANGUAGE,
            ROUTE_PATHS.USER_ACTION_ALL_ROUTES,
            ROUTE_PATHS.SIGNOUT,
          ]}
        >
          {/* TODO: implement a more scalable solution to handle new pages with more priority without remembering */}
          {/* to update paths here every time. Bonvoy login modal should be the last in user actions list */}
          {/* Ref: ST-68283 */}
          <RedirectToOnboardingPage />
          <BonvoyLogin />
        </HideOnRouteMatch>
      </Flex>
    </>
  );
}
PageLayoutInternal.displayName = 'PageLayout';

const PageLayout = React.memo(PageLayoutInternal);

const useAllowedRoutes = () => {
  const disallowedRoutes = useDisallowedRoutes();
  const allowedRoutes = appRouteList.filter(
    (route) => !disallowedRoutes.some((disallowed) => route.path.startsWith(disallowed)),
  );

  const isBookingDisabled = useDisableBooking();

  if (isBookingDisabled) {
    return allowedRoutes.filter((route) => {
      const notAllowedBookingRoutes = ['/flights', '/hotels', '/cars', '/rails'];
      return !notAllowedBookingRoutes.some((notAllowedRoute) => route.path.startsWith(notAllowedRoute));
    });
  }
  return allowedRoutes;
};

function RoutesContainer(): JSX.Element {
  const isUserLoggedIn = useIsLoggedIn();
  const isUserPreferredLanguageEnabled = useFeatureFlag('FE_USER_PREFERRED_LANGUAGE');
  const { customFieldType, customFieldValue } = useEmbedStore().embedParams;
  const brexJwtToken = customFieldType === CUSTOM_FIELD_TYPES.BREX_TOKEN ? customFieldValue : undefined;
  const onTravelerCurrencyProviderBrexBudgetError = useEmitEmbedErrors({ source: 'brexBudgetInfoQueryFetch' });
  const companionViewConfig = useCompanionViewConfig();

  useAddBreadcrumbsOnUrlChange();

  const allowedRoutes = useAllowedRoutes();

  const unAuthenticatedRoutes = allowedRoutes
    .filter((route) => route.isPublicRoute === true)
    .map(({ path, component: Component, search = '', exact = true }) => (
      <Route key={path + search + exact} component={Component} path={path + search} exact={exact} />
    ));

  useEffect(() => {
    /**
     * Hide the HTML-based spinner UI once routes are ready to be rendered.
     */
    try {
      window.dispatchEvent(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        new Event(window.AppMounted),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (!isUserLoggedIn) {
    return (
      <>
        <DefaultThemeStyle />

        <div className="page">
          <Switch>
            {unAuthenticatedRoutes}
            <Route component={NotFound} />
          </Switch>
          <Errors showAPIErrorCodes={false} />
          <GlobalError showAPIErrorCodes={false} />
        </div>
      </>
    );
  }
  if (isUserPreferredLanguageEnabled === undefined) {
    return (
      <LoaderContainer>
        <ThemeStylesWithWhiteLabelConfig />
        <CircularLoader size={50} />
      </LoaderContainer>
    );
  }

  return (
    <ComposeProviders
      providers={[
        [WebTelemetryProvider, { providerKey: Config.VITE_SEGMENT_WEB_KEY }],
        SurfaceTelemetryProvider,
        UrlEncodingKeyProvider,
        SiteMessagingContextProvider,
      ]}
    >
      <InitialisePendo />
      <InitiateFullStoryCapture />
      <MissingErrorDetailsTracker />
      <ThemeStylesWithWhiteLabelConfig />
      <TravelerProvider>
        <PrimaryTravelerUpdateReporter />
        <TravelerPreferredCurrencyProvider
          brexJwtToken={brexJwtToken}
          reportBrexBudgetEmbedError={onTravelerCurrencyProviderBrexBudgetError}
        >
          <ChatInitializer>
            <Flex>
              <RBAC allowedRoles={companionViewConfig.allowedRoles}>
                <HideOnRouteMatch
                  paths={[
                    ROUTE_PATHS.PREFERRED_LANGUAGE,
                    ROUTE_PATHS.USER_ACTION_ALL_ROUTES,
                    ROUTE_PATHS.SPOTTERNET_ALL,
                  ]}
                >
                  <Suspense fallback={null}>
                    <Companion />
                  </Suspense>
                </HideOnRouteMatch>
              </RBAC>
              <PageLayout />
            </Flex>
            <ErrorBoundary fallback={null}>
              <HideOnRouteMatch
                paths={[ROUTE_PATHS.PREFERRED_LANGUAGE, ROUTE_PATHS.USER_ACTION_ALL_ROUTES, ROUTE_PATHS.SPOTTERNET_ALL]}
              >
                <SupportMenu />
              </HideOnRouteMatch>
            </ErrorBoundary>
          </ChatInitializer>
        </TravelerPreferredCurrencyProvider>
      </TravelerProvider>
      <ToastRenderer />
      <UserIdentityTelemetry />
      <RouteChangeTelemetry />
      <ScreenDimensionsTelemetry />
      <SessionTimeoutModal />
      <UrlChangeNotifier />
    </ComposeProviders>
  );
}

// No need to add another re-render to main App component
// just due to router change, it's better to let the effect
// run without causing rerenders
function UrlChangeNotifier(): JSX.Element | null {
  useAddBreadcrumbsOnUrlChange();
  return null;
}

const RoutesContainerMemo = React.memo(RoutesContainer);

function ErrorContainer(): JSX.Element {
  const showAPIErrorCodes = useHasUserAccess(TMCAgentAndAboveRoles);

  // Prefetch Brex budget info to handle Brex token errors in advance
  useBrexBudgetInfoWithoutCard();

  // Emit API errors
  useAPIErrorListener({
    showAPIErrorCodesFeatureEnabled: showAPIErrorCodes,
  });

  return <Errors showAPIErrorCodes={showAPIErrorCodes} />;
}

function AllAppRoutes(): JSX.Element {
  const allowedRoutes = useAllowedRoutes();

  const routes = allowedRoutes.map(({ key, path, component: Component, search = '', exact = true }) => (
    <Route key={key} component={Component} path={path + search} exact={exact} />
  ));

  return (
    <Suspense fallback={<RouteLoadingBar />}>
      <Switch>
        {routes}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

const AppWithDynamicBasepath = () => {
  const isEmbed = Config.VITE_TARGET === 'embed';
  const urlParams = parseParams(window.location.search);
  useApplyAndFetchUserPreferredLanguageToApp();

  const { data: whiteLabelConfig } = useWhiteLabelConfig();

  const { pathname } = window.location;
  const isHeadlessRoute = pathname.split('?')[0].endsWith(ROUTE_PATHS.HEADLESS_AUTH);
  const loginIdentifier = '?code=';
  const isLoggingIn = pathname.includes(loginIdentifier);
  const basepath = (() => {
    if (isHeadlessRoute) {
      // Get the basepath while preserving the ending slash
      return pathname.slice(0, pathname.indexOf(ROUTE_PATHS.HEADLESS_AUTH));
    }
    if (isLoggingIn) {
      return pathname.slice(0, pathname.indexOf(loginIdentifier));
    }
    return '/';
  })();

  return (
    <ComposeProviders
      providers={[
        [BrowserRouter, { basename: isEmbed ? whiteLabelConfig?.clientRoutingBasePath ?? basepath : '/' }],
        [EmbedProvider, { isEmbed, params: urlParams }],
      ]}
    >
      <App />
    </ComposeProviders>
  );
};

export default AppWithDynamicBasepath;
