import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import { getFullNameFromNameObject } from '../transformers/common';

import type { Traveler } from '../types/api/v1/obt/common/traveler';
import type { PaymentInfoWithSupportedStatus } from '../types/common';
import type {
  ITravelerCheckoutDetails,
  ITravelerPersonalInfo,
  ITravelerInfo,
  IEmergencyContactInfo,
  ITravelerLoyaltyInfos,
  ITravelerLoyaltyInfoV2,
} from '../types/traveler';
import { CreditCardAccessTypeEnum } from '../types/traveler';
import type {
  IdentityDocument,
  PaymentInfo,
  Passport,
  NationalDoc,
  ImmigrationDocument,
  PaymentInfoApplicableToEnum,
  RedressNumber,
  KnownTravelerNumber,
} from '../types/api/v1/obt/common/user';
import { UserTitleEnum, PreferredPronounEnum } from '../types/api/v1/obt/common/user';
import type { LoyaltyInfo } from '../types/api/v1/obt/common/traveler_personal_info';
import { LoyaltyInfoTypeEnum } from '../types/api/v1/obt/common/traveler_personal_info';
import type { PhoneNumber } from '../types/api/v1/common/phone_number';
import { GenderEnum } from '../types/api/v1/common/gender';
import type {
  AirlinePref,
  AirPref,
  AirPrefMealPref,
  SeatLocationPref,
} from '../types/api/v1/obt/common/air_preference';
import { AirlinePrefFlightTypeEnum } from '../types/api/v1/obt/common/air_preference';
import { MealTypeEnum } from '../types/api/v1/obt/air/air_common';
import type { PostalAddress } from '../types/api/v1/common/postal_address';
import {
  emptyName,
  emptyPhoneNumber,
  emptySeatLocation,
  emptyTravelerPreferencesAir,
  airlineLoyaltyMap,
  carLoyaltyMap,
  hotelLoyaltyMap,
} from '../constants';
import { getCompareFunctionForLoyaltyInfo, isExpiredPaymentCard } from '../utils/common';
import type { RailCard } from '../types/api/v1/common/card';
import { CardCompany } from '../types/api/v1/common/card';
import { NameSuffix } from '../types/api/v1/common/name';
import type { UserId } from '../types/api/v1/common/user_id';

export default class TravelerDetailsResponseManager {
  constructor(readonly traveler: Traveler) {
    if (!traveler) {
      throw new Error('Invalid Traveler Fetch Response passed to TravelerDetailsResponseManager');
    }
  }

  public GetTraveler(): Traveler {
    return this.traveler;
  }

  public GetFullName(): string {
    const nameObj = this.traveler.user?.name;
    return nameObj ? getFullNameFromNameObject(nameObj) : '';
  }

  public GetGivenName(): string {
    return this.traveler.user?.name?.given ?? '';
  }

  public GetProfileUrl(): string {
    return this.traveler.user?.profilePicture?.url ?? '';
  }

  public GetUserBusinessId(): string {
    return this.traveler.userBusinessInfo?.id?.id ?? '';
  }

  public GetUserId(): UserId | undefined {
    return this.traveler.userOrgId.userId;
  }

  public IsLoyaltyBlocked(): boolean {
    return this.traveler.travelerPersonalInfo?.blockLoyalty ?? false;
  }

  public GetUserBusinessEmailId(): string {
    return this.traveler.userBusinessInfo?.email ?? '';
  }

  public GetOrganizationId(): string {
    return this.traveler.userBusinessInfo?.organizationId?.id ?? '';
  }

  public GetPassports(): Passport[] {
    const passports = this.GetIdentityDocs().map((identityDoc) => identityDoc.passport);
    return passports.filter((passport): passport is Passport => passport !== undefined);
  }

  public GetNationalDoc(): NationalDoc[] {
    const nationalDocs = this.GetIdentityDocs().map((identityDoc) => identityDoc.nationalDoc);
    return nationalDocs.filter((nationalDoc): nationalDoc is NationalDoc => nationalDoc !== undefined);
  }

  public GetMealPreference(): AirPrefMealPref {
    return this.traveler.travelerPersonalInfo?.travelPref?.airPref?.mealPref ?? { inclMealPrefs: [MealTypeEnum.AVML] };
  }

  public GetImmigrationDocuments(): ImmigrationDocument[] {
    const immigrationDocuments = this.GetIdentityDocs().map((identityDoc) => identityDoc.immigrationDoc);
    return immigrationDocuments.filter(
      (immigrationDoc): immigrationDoc is ImmigrationDocument => immigrationDoc !== undefined,
    );
  }

  public GetSortedLoyaltyInfo(type?: 'AIR' | 'HOTEL' | 'CAR' | 'RAIL' | 'LIMO' | 'MISC'): LoyaltyInfo[] {
    const loyaltyInfo = {
      AIR: this.GetAirLoyaltyInfo(),
      HOTEL: this.GetHotelLoyaltyInfo(),
      CAR: this.GetCarLoyaltyInfo(),
      RAIL: this.GetRailLoyaltyInfoFromRailcard(),
      LIMO: [],
      MISC: [],
    };
    return type
      ? loyaltyInfo[type]
      : [...loyaltyInfo.AIR, ...loyaltyInfo.HOTEL, ...loyaltyInfo.CAR, ...loyaltyInfo.RAIL];
  }

  public IsCentralCard = (paymentInfo: PaymentInfo): boolean =>
    paymentInfo?.accessType === CreditCardAccessTypeEnum.CENTRALISED;

  public IsPersonalCard = (paymentInfo: PaymentInfo): boolean =>
    paymentInfo?.accessType === CreditCardAccessTypeEnum.PERSONAL || paymentInfo?.accessType === undefined;

  public GetPaymentInfo(includePersonal = true, includeCentral = false): PaymentInfo[] {
    return (
      this.traveler.user?.paymentInfos.filter(
        (paymentInfo) =>
          (includePersonal && this.IsPersonalCard(paymentInfo)) || (includeCentral && this.IsCentralCard(paymentInfo)),
      ) ?? []
    );
  }

  public GetSortedPaymentInfo(applicableTo: PaymentInfoApplicableToEnum, onlyPersonalPayments = false): PaymentInfo[] {
    const paymentInfo = cloneDeep(this.traveler.user?.paymentInfos ?? []).filter(
      (payment) =>
        payment.card.company !== CardCompany.AIR_TRAVEL_UATP &&
        payment.applicableTo?.includes(applicableTo) &&
        !isExpiredPaymentCard(payment.card.expiryMonth, payment.card.expiryYear),
    );
    const sortedPaymentInfo = paymentInfo.sort((paymentInfo1, paymentInfo2) => {
      if (paymentInfo1.applicableTo?.includes(applicableTo)) return -1;
      if (paymentInfo2.applicableTo?.includes(applicableTo)) return 1;
      return 0;
    });

    if (onlyPersonalPayments) {
      return sortedPaymentInfo.filter((payment) => this.IsPersonalCard(payment));
    }

    return sortedPaymentInfo;
  }

  public GetSortedPaymentInfoWithUATP(
    applicableTo: PaymentInfoApplicableToEnum,
    onlyPersonalPayments = false,
  ): PaymentInfo[] {
    const paymentInfo = cloneDeep(this.traveler.user?.paymentInfos ?? []).filter(
      (payment) =>
        payment.applicableTo?.includes(applicableTo) &&
        !isExpiredPaymentCard(payment.card.expiryMonth, payment.card.expiryYear),
    );
    const sortedPaymentInfo = paymentInfo.sort((paymentInfo1, paymentInfo2) => {
      if (paymentInfo1.applicableTo?.includes(applicableTo)) return -1;
      if (paymentInfo2.applicableTo?.includes(applicableTo)) return 1;
      return 0;
    });

    if (onlyPersonalPayments) {
      return sortedPaymentInfo.filter((payment) => this.IsPersonalCard(payment));
    }

    return sortedPaymentInfo;
  }

  public GetSortedPaymentInfoWithSupportedStatus(
    applicableTo: PaymentInfoApplicableToEnum,
    allowedCompanies: CardCompany[],
  ): PaymentInfoWithSupportedStatus[] {
    const paymentInfos = this.GetSortedPaymentInfo(applicableTo);

    return paymentInfos
      .map((item) => ({ ...item, isNotSupported: !allowedCompanies.includes(item.card.company) }))
      .sort((paymentInfoA, paymentInfoB) => {
        if (paymentInfoA.isNotSupported === paymentInfoB.isNotSupported) {
          return 0;
        }
        return !paymentInfoA.isNotSupported ? -1 : 1;
      });
  }

  public GetCheckoutPersonalDetails(): ITravelerCheckoutDetails {
    return {
      passports: this.GetPassports(),
      immigrationDocs: this.GetImmigrationDocuments(),
      knownTravelerNumber: this.GetKnownTravelerNumber(),
      redressNumber: this.GetRedressNum(),
    };
  }

  public GetTravelerInfo(): ITravelerInfo {
    return {
      personal: this.GetPersonalInfo(),
      loyalty: {
        air: this.GetAirLoyaltyInfo(),
        hotel: this.GetHotelLoyaltyInfo(),
        car: this.GetCarLoyaltyInfo(),
        rail: this.GetRailLoyaltyInfo(),
      },
      paymentMethods: this.GetPaymentInfo(),
    };
  }

  private GetRailLoyaltyInfoFromRailcard(): ITravelerLoyaltyInfoV2[] {
    return this.GetRailLoyaltyInfo().map((railcard) => ({
      appliedTo: [railcard.name],
      id: railcard.spotnanaCode,
      issuedBy: railcard.vendor,
      type: 4,
      cardNumber: railcard.cardNumber,
      expiryDate: railcard.expiryDate?.iso8601,
    }));
  }

  public GetTravelerLoyaltyInfo(): ITravelerLoyaltyInfos {
    return {
      air: this.GetAirLoyaltyInfo(),
      hotel: this.GetHotelLoyaltyInfo(),
      car: this.GetCarLoyaltyInfo(),
      rail: this.GetRailLoyaltyInfoFromRailcard(),
    };
  }

  public GetPersonalInfo(): ITravelerPersonalInfo {
    const personal = this.traveler.user;

    return {
      title: personal?.title ?? UserTitleEnum.TITLE_UNKNOWN,
      name: {
        given: personal?.name?.given ?? '',
        middle: personal?.name?.middle ?? '',
        family1: personal?.name?.family1 ?? '',
        family2: personal?.name?.family2 ?? '',
        suffix: personal?.name?.suffix ?? NameSuffix.NAME_SUFFIX_UNKNOWN,
        preferred: personal?.name?.preferred ?? '',
      },
      bio: {
        gender: personal?.gender ?? GenderEnum.UNKNOWN,
        preferredPronoun: personal?.preferredPronoun ?? PreferredPronounEnum.PREFERRED_PRONOUN_UNKNOWN,
        dob: personal?.dob?.iso8601 ?? '',
      },
      contactInfo: {
        phoneNumbers: this.GetPersonalContactNumbers(),
        email: this.GetEmailId(),
      },
      address: this.GetAddresses(),
      travelIDs: {
        passports: this.GetPassports(),
        knownTravelerNumber: this.GetKnownTravelerNumber(),
        redressNumber: this.GetRedressNum(),
        ktn: this.GetKnownTravelerNumberV2(),
        redress: this.GetRedressNumV2(),
        nationalDoc: this.GetNationalDoc(),
      },
      emergencyContactInfo: this.GetEmergencyContactInfo(),
      profilePicture: personal?.profilePicture?.url ? personal.profilePicture : undefined,
      tier: this.traveler.tier,
      preferredLanguage: personal?.preferredLanguage,
    };
  }

  public GetAirTravelPreferences(): AirPref {
    const travelPref = this.traveler.travelerPersonalInfo?.travelPref;
    if (travelPref) {
      const airPref = travelPref.airPref && { ...travelPref.airPref };
      if (airPref) {
        airPref.seatLocationPrefs = this.AddSeatPrefData();
        airPref.airlinePrefs = this.AddAirlinePref();
      }
      return airPref ?? emptyTravelerPreferencesAir;
    }
    return emptyTravelerPreferencesAir;
  }

  public GetRailCardPreferences(): RailCard[] {
    return this.traveler.travelerPersonalInfo?.travelPref?.railCards ?? [];
  }

  /** *******************************
   ****** Private methods **********
   ******************************* */

  private GetPersonalContactNumbers(): PhoneNumber[] {
    return this.traveler.user?.phoneNumbers ?? [];
  }

  private GetEmailId(): string {
    return this.traveler.user?.email ?? '';
  }

  private GetIdentityDocs(): IdentityDocument[] {
    return this.traveler.user?.identityDocs ?? [];
  }

  private GetKnownTravelerNumber(): string {
    const knownTravelerNumbers = this.GetIdentityDocs()
      .filter((identityDoc) => identityDoc.knownTravelerNumber !== undefined)
      .map((identityDoc) => identityDoc.knownTravelerNumber);
    return first(knownTravelerNumbers) ?? '';
  }

  private GetKnownTravelerNumberV2(): KnownTravelerNumber | undefined {
    const knownTravelerNumbers = this.GetIdentityDocs()
      .filter((identityDoc) => identityDoc.ktn !== undefined)
      .map((identityDoc) => identityDoc.ktn);
    return first(knownTravelerNumbers);
  }

  private GetRedressNum(): string {
    const redressNumbers = this.GetIdentityDocs()
      .filter((identityDoc) => identityDoc.redressNumber !== undefined)
      .map((identityDoc) => identityDoc.redressNumber);
    return first(redressNumbers) ?? '';
  }

  private GetRedressNumV2(): RedressNumber | undefined {
    const redressNumbers = this.GetIdentityDocs()
      .filter((identityDoc) => identityDoc.redress !== undefined)
      .map((identityDoc) => identityDoc.redress);
    return first(redressNumbers);
  }

  private GetLoyaltyInfo(): LoyaltyInfo[] {
    return this.traveler.travelerPersonalInfo?.loyaltyInfos ?? [];
  }

  GetAirLoyaltyInfo(): LoyaltyInfo[] {
    const loyaltyInfos = this.GetLoyaltyInfo().filter((program) => program.type === LoyaltyInfoTypeEnum.AIR);
    return loyaltyInfos.sort(getCompareFunctionForLoyaltyInfo(airlineLoyaltyMap));
  }

  private GetHotelLoyaltyInfo(): LoyaltyInfo[] {
    const loyaltyInfos = this.GetLoyaltyInfo().filter((program) => program.type === LoyaltyInfoTypeEnum.HOTEL);
    return loyaltyInfos.sort(getCompareFunctionForLoyaltyInfo(hotelLoyaltyMap));
  }

  private GetCarLoyaltyInfo(): LoyaltyInfo[] {
    const loyaltyInfos = this.GetLoyaltyInfo().filter((program) => program.type === LoyaltyInfoTypeEnum.CAR);
    return loyaltyInfos.sort(getCompareFunctionForLoyaltyInfo(carLoyaltyMap));
  }

  private GetRailLoyaltyInfo(): RailCard[] {
    const railcards = [...this.GetRailCardPreferences()].sort((railcard1, railcard2) =>
      railcard1.name.localeCompare(railcard2.name),
    );
    return railcards;
  }

  private AddAirlinePref(): AirlinePref[] {
    const airlinePref = this.traveler.travelerPersonalInfo?.travelPref?.airPref?.airlinePrefs ?? [];
    const allFlightsPref = airlinePref.find((pref) => pref.flightType === AirlinePrefFlightTypeEnum.ALL) ?? {
      flightType: AirlinePrefFlightTypeEnum.ALL,
      airlines: [],
    };
    return [allFlightsPref];
  }

  private GetAddresses(): PostalAddress[] {
    return this.traveler.user?.addresses ?? [];
  }

  private GetEmergencyContactInfo(): IEmergencyContactInfo {
    const { user } = this.traveler;
    return {
      ...user?.emergencyContactInfo,
      email: user?.emergencyContactInfo?.email ?? '',
      designation: user?.emergencyContactInfo?.designation ?? '',
      phoneNumber: user?.emergencyContactInfo?.phoneNumber ?? { ...emptyPhoneNumber },
      name: user?.emergencyContactInfo?.name ?? { ...emptyName },
    };
  }

  private AddSeatPrefData(): SeatLocationPref[] {
    const seatLocationPref = this.traveler.travelerPersonalInfo?.travelPref?.airPref?.seatLocationPrefs ?? [];
    const HR3 = seatLocationPref.find((locationPref) => locationPref.maxFlightDurationInHours === 3) ?? {
      ...emptySeatLocation,
      cabins: [],
      maxFlightDurationInHours: 3,
    };
    const HR6 = seatLocationPref.find((locationPref) => locationPref.maxFlightDurationInHours === 6) ?? {
      ...emptySeatLocation,
      cabins: [],
      maxFlightDurationInHours: 6,
    };
    const HR24 = seatLocationPref.find((locationPref) => locationPref.maxFlightDurationInHours === 24) ?? {
      ...emptySeatLocation,
      cabins: [],
      maxFlightDurationInHours: 24,
    };
    return [HR3, HR6, HR24];
  }

  public GetNationality(): string {
    const { user } = this.traveler;
    const initialPassports = this.GetPassports();
    let nationality = '';
    if (initialPassports.length > 0) {
      nationality = initialPassports[0].issueCountry;
    } else if (user?.nationality) {
      nationality = user?.nationality;
    }
    return nationality;
  }
}
