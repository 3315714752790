import { css } from '@emotion/react';
import { breakpoints, cssSelectors, margins, themed } from '../utils/styling';
import { cornerRadii } from '../utils/styling/utilityTokens';

type SizeConfig = {
  containerSize: number;
  svgSize: number;
  mobilePadding: number;
  generalPadding: number;
};

const sizeConfig: Record<'small' | 'regular', SizeConfig> = {
  small: {
    containerSize: 20,
    svgSize: 15,
    mobilePadding: 6,
    generalPadding: 2,
  },
  regular: {
    containerSize: 24,
    svgSize: 18,
    mobilePadding: 4,
    generalPadding: 2,
  },
};

/**
 * This mixin Sets the color for the Checkbox
 * @returns
 */
export const checkbox_color_mixin = (color: string) => css`
  color: ${color};
  svg {
    color: ${color};
  }
`;

/**
 * This mixin Sets the size and click-area for the checkbox
 * For Mobile we typically have a larger click area
 * @returns
 */
export const checkbox_size_mixin = (config: SizeConfig, labelMargin: string) => css`
  svg {
    font-size: ${config.containerSize}px;
  }

  padding: ${config.mobilePadding}px;
  margin-right: calc(${labelMargin} - ${config.mobilePadding}px);
  margin-top: -${config.mobilePadding}px;

  ${breakpoints.from('tablet')} {
    padding: ${config.generalPadding}px;
    margin-top: -${config.generalPadding}px;
  }

  .CustomBorder {
    width: ${config.svgSize}px;
  }
`;

/**
 * This mixin crops the svg and wraps it in a div with similar padding to recreate original effect
 * @returns
 */
export const icon_size_mixin = (containerWidthInPx: number, svgBaseWidthInPx: number) => {
  const diffOnOneSide = (containerWidthInPx - svgBaseWidthInPx) / 2;

  return css`
    padding: ${diffOnOneSide}px;

    .Container {
      width: ${svgBaseWidthInPx}px;

      svg {
        transform: translate(-${diffOnOneSide}px, -${diffOnOneSide}px);
      }
    }
  `;
};

export const checkbox = themed(
  ({ palette }) => css`
    ${cornerRadii.buttonSmall}

    ${checkbox_color_mixin(palette.border.dark)}
  
    &.WithLabel {
      ${margins.xl.half}
    }

    .CustomBorder {
      aspect-ratio: 1 / 1;
      position: absolute;
      border-radius: 3px;
    }

    &:hover {
      background: ${palette.bg.hover};
      ${checkbox_color_mixin(palette.border.active)}
    }

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      ${checkbox_color_mixin(palette.border.brand)}
    }

    &.Mui-disabled {
      ${checkbox_color_mixin(palette.bg.buttonDisabled)}

      &.Mui-checked,
      &.MuiCheckbox-indeterminate {
        ${checkbox_color_mixin(palette.border.dark)}
      }

      .CustomBorder {
        border: solid 2px ${palette.border.dark};
      }
    }

    &.size-regular {
      ${checkbox_size_mixin(sizeConfig.regular, '0.75rem')}
    }

    &.size-small {
      ${checkbox_size_mixin(sizeConfig.small, '0.5rem')}
    }

    input:focus-visible {
      /** on keyboard focus, apply styles to svg container */
      & + * {
        ${cornerRadii.buttonSmall}
        outline: 2px solid ${palette.border.keyboardFocus};
      }
      & ~ .MuiTouchRipple-root {
        visibility: hidden;
      }
    }
  `,
);

export const icon_wrapper = themed(
  ({ palette }) => css`
    display: inline-grid;

    .Container {
      aspect-ratio: 1 / 1;
      position: relative;
      background: ${palette.text.buttonLabel};
      border-radius: 3px;

      svg {
        position: absolute;
      }
    }

    .size-regular & {
      ${icon_size_mixin(sizeConfig.regular.containerSize, sizeConfig.regular.svgSize)}
    }

    .size-small & {
      ${icon_size_mixin(sizeConfig.small.containerSize, sizeConfig.small.svgSize)}
    }
  `,
);

export const checkbox_with_label = css`
  user-select: none;
  align-items: flex-start;
  vertical-align: top;

  .BlocksCheckbox-subtext {
    display: block;
  }

  &.Mui-disabled {
    cursor: not-allowed !important;
  }
`;

export const checkbox_group = css`
  ${margins.xl.point25}

  &${cssSelectors.not.firstOfType} {
    /* multiple groups in the same row need to have spacing as prescribed by design guide  */
    ${margins.xl.m2}
  }

  & > .BlocksCheckboxGroup-label {
    ${margins.yb.half}
  }

  .BlocksCheckbox-root {
    margin: 0 0 0 0;
  }

  &.size-regular {
    .BlocksCheckbox-root${cssSelectors.not.lastChild} {
      ${margins.yb.m1}
    }
  }
  &.size-small {
    .BlocksCheckbox-root${cssSelectors.not.lastChild} {
      ${margins.yb.point75}
    }
  }
`;
