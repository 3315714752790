/** A pattern that matches safe  URLs. */
const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
/** A pattern that matches safe data URLs. */
const DATA_URL_PATTERN =
  /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i;

export const DEFAULT_LINK_URL = 'https://';

export function sanitizeUrl(url: string): string {
  const trimmedUrl = String(url).trim();
  if (trimmedUrl.match(SAFE_URL_PATTERN) || trimmedUrl.match(DATA_URL_PATTERN)) return trimmedUrl;

  return DEFAULT_LINK_URL;
}

// Source: https://stackoverflow.com/a/8234912/2013580
const urlRegExp =
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export function validateUrl(url: string): boolean {
  return url === DEFAULT_LINK_URL || urlRegExp.test(url);
}
