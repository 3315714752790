import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const ChatAssistantPage = lazy(() => import('./chatAssistant/ChatAssistantPage'));
const ApiClientPage = lazy(() => import('./ApiClientPage'));

export const platformRoutes: IRouteConfig[] = [
  {
    key: 'api-client',
    path: '/platform/api-client',
    component: ApiClientPage,
  },
  {
    key: 'chat-assistant',
    path: '/platform/chat-assistant',
    component: ChatAssistantPage,
  },
];
