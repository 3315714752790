import { assign, createMachine } from 'xstate';
import type { IUseGetRequestHeader } from '../../hooks';
import { mutatePaymentSetupForAllPaymentSourceIds } from '../../utils/payment/mutatePaymentSetupForAllPaymentSourceIds';
import type { AirAncillariesResponse } from '../../types/api/v1/obt/air/air_ancillaries';
import { PaymentSetupResponseStatus } from '../../types/api/v2/obt/model/payment-setup-response-status';
import revalidateItineraryViaCvv from '../../utils/Flights/revalidateItineraryViaCvv';
import { airRevalidateItinerary, invalidateAllAirSeatMapResponses } from '../../queries/flight';
import { unableToModifyErrorCode } from '../../constants/flights';
import type {
  BeginExchangingEvent,
  ContextAfterRevalidateItinerary,
  FlightExchangingContext,
  FlightExchangingEvents,
  FlightExchangingTypeState,
  SuccessModifyPnrEvent,
} from './types';
import type {
  PaymentSetupResponse,
  IAirRevalidateItineraryRequest,
  ITraveler,
  ListBookingPaymentSourcesResponse,
  ICvvRequirementInfo,
} from '../../types';
import { MoneyUtil } from '../../utils/Money';
import { airModifyPnr } from '../../queries/airExchange';
import type { SuccessRevalidateItineraryEvent } from '../shared/types';
import isFareExpiredError from '../shared/guards/isFareExpiredError';
import isBookingFailedError from '../shared/guards/isBookingFailedError';
import isEmptyRevalidateItineraryResponse from '../shared/guards/isEmptyRevalidateItineraryResponse';
import isFareChange from '../shared/guards/isFareChange';
import isExchangeRefundableOrEven from '../shared/guards/isExchangeRefundableOrEven';
import { AirSearchRequestBookingType } from '../../types/api/v1/obt/air/air_search_request';
import { getErrorCodeFromEvent } from '../shared/utils/getErrorCodeFromEvent';

const defaultContext: FlightExchangingContext = {
  ancillaryResponseId: '',
  seatMapResponseId: '',
  travelers: [],
  attemptNo: 0,
  bookingCharges: [],
  waiverCode: '',
  ticketDesignator: '',
  revalidateRequest: {} as IAirRevalidateItineraryRequest,
  cvvRequirement: {} as ICvvRequirementInfo,
  paymentSourceIds: [],
  listBookingPaymentSourcesResponse: {} as ListBookingPaymentSourcesResponse,
  airAncillariesResponse: {} as AirAncillariesResponse,
  requestHeader: {} as IUseGetRequestHeader,
  primaryTraveler: {} as ITraveler,
  vgsFormMap: {},
  mutatePaymentSetup: () => Promise.resolve({} as PaymentSetupResponse),
};

const setEventValuesToContext = (event: BeginExchangingEvent) => ({
  tripId: { id: event.tripId?.id || '' },
  oldTotalFare: event.totalFare,
  ancillaryResponseId: event.ancillaryResponseId,
  seatMapResponseId: event.seatMapResponseId,
  travelers: event.travelers,
  attemptNo: event.attemptNo,
  bookingCharges: event.bookingCharges,
  waiverCode: event.waiverCode,
  newPenalty: event.newPenalty,
  tourCodeInfo: event.tourCodeInfo,
  ticketDesignator: event.ticketDesignator,
  endorsementInfo: event.endorsementInfo,
  cvvRequirement: event.cvvRequirement,
  paymentSourceIds: event.paymentSourceIds,
  airAncillariesResponse: event.airAncillariesResponse,
  listBookingPaymentSourcesResponse: event.listBookingPaymentSourcesResponse,
  requestHeader: event.requestHeader,
  primaryTraveler: event.primaryTraveler,
  vgsFormMap: event.vgsFormMap,

  revalidateRequest: {
    ancillaryResponseId: event.ancillaryResponseId,
    seatMapResponseId: '',
    travelers: event.travelers,
    tripId: event.tripId,
    attemptNo: event?.attemptNo,
    otherServiceInfos: [],
    bookingCharges: event.bookingCharges,
    waiverCode: event.waiverCode,
    newPenalty: event.newPenalty,
    ticketDesignator: event.ticketDesignator,
    tourCodeInfo: event.tourCodeInfo,
    endorsementInfo: event.endorsementInfo,
    /**
     * Exchange should be regular booking
     */
    bookingType: AirSearchRequestBookingType.NORMAL_BOOKING,
    qcEnabled: false,
    pnrRemarks: event.pnrRemarks,
    isIntermediate: false,
  },
});

/**
 * Use https://stately.ai/viz/5274736a-134d-4d1e-a2d2-67b7815b7f8d to visualize the below machine.
 * (Please update the above if any changes are done to the machine)
 */

const flightExchangingMachine = createMachine<
  FlightExchangingContext,
  FlightExchangingEvents,
  FlightExchangingTypeState
>(
  {
    predictableActionArguments: true,
    id: 'exchangingFlight',
    initial: 'idle',
    context: defaultContext,
    states: {
      idle: {
        meta: {
          message: 'Initial setup context',
        },
        on: {
          BEGIN_EXCHANGE: [
            {
              target: 'revalidateItinerary',
              actions: assign((_context, event) => setEventValuesToContext(event)),
            },
          ],
        },
      },
      revalidateItinerary: {
        tags: ['loading'],
        meta: {
          message: 'Run revalidate itinerary BE request',
        },
        invoke: {
          id: 'airRevalidateItinerary',
          src: 'revalidateItineraryService',
          onDone: [
            {
              cond: 'isEmptyRevalidateItineraryResponse',
              target: 'failure',
              actions: assign({
                // Known issue
                // Assign action behaving strangely:https://xstate.js.org/docs/guides/typescript.html#troubleshooting
                error: (_context, _event) => new Error('Revalidate itinerary response is empty'),
              }),
            },
            {
              cond: 'isFareChange',
              target: 'fareChanged',
              actions: assign((_context, event) => ({
                revalidateItineraryResponse: event.data,
              })),
            },
            {
              target: 'modifyPnr',
              actions: assign({
                revalidateItineraryResponse: (_context, event) => event.data,
              }),
            },
          ],
          onError: [
            {
              cond: 'isFareExpiredError',
              target: 'fareExpired',
            },
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
              actions: assign({ error: (_context, event) => event.data }),
            },
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
        exit: assign((_context, event) => ({
          attemptNo:
            (event as SuccessRevalidateItineraryEvent).data !== null &&
            (event as SuccessRevalidateItineraryEvent).data.attemptNo !== undefined
              ? (event as SuccessRevalidateItineraryEvent).data.attemptNo + 1
              : undefined,
        })),
      },
      fareExpired: {
        type: 'final',
        meta: {
          message: 'Revalidate itinerary failed with error fare expired',
        },
      },
      bookingFailed: {
        on: {
          ON_CANCEL_BOOKING_FAILED: {
            target: 'idle',
          },
        },
        meta: {
          message: 'Revalidate itinerary failed with error booking failed',
        },
      },
      unableToModify: {
        on: {
          ON_CLOSE_UNABLE_TO_MODIFY: {
            target: 'idle',
          },
        },
        meta: {
          message: 'Air modify book failed with unable to modify error',
        },
      },
      fareChanged: {
        tags: ['loading'],
        meta: {
          message: 'Revalidate itinerary success but total fare was changed',
        },
        on: {
          CONFIRM_NEW_REVALIDATE_FIRE: [
            {
              cond: 'isExchangeRefundableOrEven',
              target: 'idle',
              actions: assign((context) => ({
                oldTotalFare: MoneyUtil.parse(context.revalidateItineraryResponse?.totalFare),
              })),
            },
            {
              target: 'revalidateItinerary',
              actions: assign((context) => ({
                oldTotalFare: MoneyUtil.parse(context.revalidateItineraryResponse?.totalFare),
              })),
            },
          ],
          CANCEL_NEW_FARE: {
            target: 'idle',
          },
        },
      },
      modifyPnr: {
        tags: ['loading'],
        invoke: {
          id: 'airModifyPnr',
          src: 'airModifyPnrService',
          onDone: [
            {
              cond: 'isRequired3dSecure2',
              target: '3dSecure2',
              actions: assign({
                redirectUrl: (_, event) =>
                  (event as SuccessModifyPnrEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].redirectUrl,
                cardId: (_, event) =>
                  (event as SuccessModifyPnrEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].cardId,
                sessionId: (_, event) =>
                  (event as SuccessModifyPnrEvent)?.data.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].sessionId,
              }),
            },
            {
              target: 'success',
              actions: assign({
                pnrNumber: (_, event) => (event as SuccessModifyPnrEvent)?.data?.pnrNumber,
                pnrId: (_, event) => (event as SuccessModifyPnrEvent)?.data?.pnrId,
                pnrStatus: (_, event) => (event as SuccessModifyPnrEvent)?.data?.pnrStatus,
              }),
            },
          ],
          onError: [
            {
              cond: 'isFareExpiredError',
              target: 'fareExpired',
            },
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
            },
            {
              cond: 'isUnableToModify',
              target: 'unableToModify',
              actions: assign({ error: (_context, event) => event.data }),
            },
            {},
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
      },
      failure: {
        on: {
          BEGIN_EXCHANGE: [
            {
              target: 'revalidateItinerary',
              actions: assign((_context, event) => ({
                error: undefined, // Reset error
                ...setEventValuesToContext(event),
              })),
            },
          ],
        },
        meta: {
          message: 'The flight exchanging failed',
        },
      },
      '3dSecure2': {
        meta: {
          message: 'Need to redirect to 3d secure 2 page (or open WebView on mobile)',
        },
        on: {
          CONFIRM_PNR: [
            {
              target: 'modifyPnr',
              actions: assign({
                is3ds2Success: (_, event) => event?.is3ds2Success,
              }),
            },
          ],
        },
      },
      success: {
        type: 'final',
        meta: {
          message: 'The flight exchanging succeeded',
        },
      },
    },
  },
  {
    services: {
      revalidateItineraryService: (context, _event) => {
        const { cvvRequirement, vgsFormMap, requestHeader, revalidateRequest, paymentSourceIds } = context;
        const selectedPaymentSourceId = paymentSourceIds?.[0] || '';
        const vgsForm = vgsFormMap[selectedPaymentSourceId]?.form;

        if (cvvRequirement.status === PaymentSetupResponseStatus.SupplierCvvRequired) {
          if (!vgsForm) {
            throw new Error('vgsForm not available');
          }
          revalidateItineraryViaCvv(revalidateRequest, requestHeader, vgsForm);
        }

        return airRevalidateItinerary(revalidateRequest);
      },
      airModifyPnrService: async (context, _event) => {
        const {
          paymentSourceIds,
          airAncillariesResponse,
          listBookingPaymentSourcesResponse,
          primaryTraveler,
          requestHeader,
          cvvRequirement,
          vgsFormMap,
          mutatePaymentSetup,
        } = context;

        const typedContext = context as ContextAfterRevalidateItinerary;
        const { cardId, sessionId, is3ds2Success } = typedContext;
        const modifyPnrRequest = {
          bookingId: typedContext.revalidateItineraryResponse.bookingId,
          tripData: { tripId: { id: typedContext.tripId.id } },
          postPaymentVerificationInfo:
            cardId && sessionId && is3ds2Success
              ? {
                  threeDSecure2PostVerificationInfo: {
                    results: [
                      {
                        cardId,
                        sessionId,
                        success: is3ds2Success,
                      },
                    ],
                  },
                }
              : undefined,
        };

        let paymentSetupResponse = null;
        if (cvvRequirement.status === PaymentSetupResponseStatus.CvvRequired) {
          paymentSetupResponse = await mutatePaymentSetupForAllPaymentSourceIds({
            paymentSourceIds,
            vgsFormMap,
            listBookingPaymentSourcesResponse,
            primaryTraveler,
            mutatePaymentSetup,
            requestHeaders: requestHeader.headers,
            paymentSetupId: {
              ancillaryResponseId: airAncillariesResponse.ancillaryResponseId ?? '',
              pnrId: typedContext.pnrId,
            },
          });
        }

        let result = null;
        if (
          paymentSetupResponse === null ||
          paymentSetupResponse?.[0] === null ||
          paymentSetupResponse?.[0]?.status === PaymentSetupResponseStatus.Ok
        ) {
          result = await airModifyPnr(modifyPnrRequest);
          invalidateAllAirSeatMapResponses();

          return result;
        }
        return result;
      },
    },
    guards: {
      isRequired3dSecure2: (_context, event): boolean => {
        const typedEvent = event as SuccessModifyPnrEvent;
        return !!typedEvent.data.paymentVerificationInfo?.threeDSecure2VerificationInfo?.verificationInfoList[0];
      },
      isFareExpiredError,
      isBookingFailedError,
      isEmptyRevalidateItineraryResponse,
      isFareChange,
      isExchangeRefundableOrEven,
      isUnableToModify: (_context, event): boolean => {
        const errorCodeFromEvent = getErrorCodeFromEvent(event);
        return errorCodeFromEvent === unableToModifyErrorCode;
      },
    },
  },
);

export const flightExchangeMachine = flightExchangingMachine.withContext(defaultContext);
