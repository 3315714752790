import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import keyBy from 'lodash/keyBy';
import type {
  AirSeatMapRequestLoyaltyInfo,
  AirSeatMapRequestPaxInfo,
  AirSeatMapRequest,
} from '../types/api/v1/obt/air/air_seat_map_request';
import { PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import type { LoyaltyInfo } from '../types/api/v1/obt/common/traveler_personal_info';
import { LoyaltyInfoTypeEnum } from '../types/api/v1/obt/common/traveler_personal_info';
import type { LoyaltyInfo as LoyaltyInfoV2 } from '../types/api/v2/obt/model/loyalty-info';
import { LoyaltyInfoTypeEnum as LoyaltyInfoTypeEnumV2 } from '../types/api/v2/obt/model/loyalty-info';
import { airlineAllianceMap, allianceAirlines, partnerAirlines } from '../constants/flights/allianceAirline';
import { hotelLoyaltyMap, carLoyaltyMap, airlineLoyaltyMap } from '../constants';
import type {
  IAirlineCodes,
  IAirlineLoyaltyOptions,
  IAirlineSelectedLoyalty,
  IItineraryAirline,
  IMarketingAirline,
  ILoyaltyInfoAndOperatingAirline,
  ILoyaltyIssuedBy,
  IPerTravellerLoyaltyInput,
  IAirSearchUrlPaxInfoWithUserOrgIdOrOwnerInfo,
  IAirSearchUrlPaxInfoWithBookingOwner,
  IAirSearchUrlPaxInfoWithUserOrgId,
  IAirSearchFormPaxInfo,
  IGetAirAncillariesRequestInput,
} from '../types/flight';
import { LoyaltyValidityEnum } from '../types/flight';
import type { ILoyaltyInfoType, ITravelerLoyaltyInfo, Option, LoyaltyToOptionOptions, LoyaltyMap } from '../types';
import { ILoyaltyInfoTypeEnum } from '../types';
import type { AirAncillariesRequest } from '../types/api/v1/obt/air/air_ancillaries';

export const getAllianceAirlines = (alliance: string | undefined): string[] =>
  allianceAirlines.reduce((airlines, allianceAirline) => {
    if (allianceAirline.alliance.toLowerCase() === alliance?.toLowerCase() ?? '') {
      airlines.push(allianceAirline.airlineCode);
    }
    return airlines;
  }, [] as string[]);

const getPartnerAirlines = (airline: string): string[] => partnerAirlines[airline] ?? [];

export const getTravelerAllianceLoyalties = (
  airline: IAirlineCodes,
  travelerLoyaltyOptions: LoyaltyInfo[],
): LoyaltyInfo[] => {
  const marketingAlliance = airlineAllianceMap[airline.marketing];
  const operatingAlliance = airlineAllianceMap[airline.operating];

  const marketingAirlines = getAllianceAirlines(marketingAlliance);
  const operatingAirlines = getAllianceAirlines(operatingAlliance);

  const airlines = marketingAirlines.concat(operatingAirlines);

  const uniqueAirlines = [...new Set(airlines)];
  const allAllianceLoyalties = uniqueAirlines.reduce((airlineAllLoyaties, airlineCode) => {
    const loyalty = travelerLoyaltyOptions.find(
      (loyaltyOption) => loyaltyOption.issuedBy === airlineCode || loyaltyOption?.appliedTo.includes(airlineCode),
    );
    if (loyalty) {
      airlineAllLoyaties.push(loyalty);
    }
    return airlineAllLoyaties;
  }, [] as LoyaltyInfo[]);
  return allAllianceLoyalties;
};

export const getSelectedLoyaltyOptions = (
  travelerLoyaltyOptions: LoyaltyInfo[],
  itineraryAllAirlines: IItineraryAirline[],
): IAirlineSelectedLoyalty[] => {
  const travelerAirLoyaltyOptions = travelerLoyaltyOptions.filter(
    (travelerLoyalty) => travelerLoyalty.type === LoyaltyInfoTypeEnum.AIR,
  );
  return itineraryAllAirlines.reduce((loyaltyInfos, itineraryAirline) => {
    const { legIndex, flightIndex, airline } = itineraryAirline;

    /* If marketing airline loyalty present in traveler's profile, apply it */
    let loyaltyInfo = travelerAirLoyaltyOptions.find(
      (loyaltyOption) => loyaltyOption.issuedBy === itineraryAirline.airline.marketing,
    );

    /* If marketing airline loyalty not present in traveler's profile, apply operating airline loyalty */
    if (!loyaltyInfo) {
      loyaltyInfo = travelerAirLoyaltyOptions.find(
        (loyaltyOption) => loyaltyOption.issuedBy === itineraryAirline.airline.operating,
      );
    }

    // if loyalty options for both marketing and operating airline not present, apply alliance loyalty
    if (!loyaltyInfo) {
      const alliance = airlineAllianceMap[airline.marketing] || airlineAllianceMap[airline.operating];
      if (alliance) {
        const allAllianceLoyalties = getTravelerAllianceLoyalties(airline, travelerAirLoyaltyOptions);
        if (allAllianceLoyalties.length) {
          /* If traveler doesn't have loyalty program for selected airline ,
          set first loyalty belonging to same alliance as default loyalty program. */
          [loyaltyInfo] = allAllianceLoyalties;
        }
      }
    }

    const marketingAlliancePartners = getPartnerAirlines(airline.marketing);
    const operatingAlliancePartners = getPartnerAirlines(airline.operating);

    // if marketing, operating and alliance level loyalty not present, apply partner airline loyalties
    if (!loyaltyInfo) {
      loyaltyInfo = travelerAirLoyaltyOptions.find(
        (loyaltyOption) =>
          operatingAlliancePartners.includes(loyaltyOption.issuedBy) ||
          marketingAlliancePartners.includes(loyaltyOption.issuedBy),
      );
    }

    if (loyaltyInfo) {
      // set airline loyalty if the traveler has loyalty program for selected airline
      loyaltyInfos.push({
        legIndex,
        flightIndex,
        airline,
        loyaltyInfo,
      });
    } else {
      /* If no loyalty found, set to undefined */
      loyaltyInfos.push({
        legIndex,
        flightIndex,
        airline,
        loyaltyInfo: undefined,
      });
    }

    return loyaltyInfos;
  }, [] as IAirlineSelectedLoyalty[]);
};

export const getAirlineLoyaltyMap = (
  selectedLoyalties: IAirlineSelectedLoyalty[],
): Map<IMarketingAirline, ILoyaltyInfoAndOperatingAirline> => {
  const selectedAirlineLoyaltyMap = new Map();
  selectedLoyalties.forEach((selectedLoyaltyInfo) => {
    const { airline, loyaltyInfo } = selectedLoyaltyInfo;
    if (!selectedAirlineLoyaltyMap.has(airline.marketing)) {
      selectedAirlineLoyaltyMap.set(airline.marketing, { loyaltyInfo, operating: airline.operating });
    }
  });
  return selectedAirlineLoyaltyMap;
};

export const getSeatMapLoyaltyInfo = (
  itineraryAllAirlines: IAirlineSelectedLoyalty[],
): AirSeatMapRequestLoyaltyInfo[] => {
  const seatMapLoyaltyInfo = itineraryAllAirlines
    .filter((item) => item.loyaltyInfo !== undefined)
    .map(({ legIndex, flightIndex, loyaltyInfo }) => ({ legIndex, flightIndex, loyaltyInfo }));

  return seatMapLoyaltyInfo;
};

/** @deprecated */
export const getAllianceAirlineLoyalties = (
  airline: IAirlineCodes,
  travelerLoyaltyOptions: LoyaltyInfo[],
): LoyaltyInfo[] => {
  const isMarketingOperatingAirlineSame = airline.marketing === airline.operating;
  const marketingAlliance = airlineAllianceMap[airline.marketing];
  const marketingAirlines = getAllianceAirlines(marketingAlliance);
  const operatingAlliance = airlineAllianceMap[airline.operating];
  const marketingAlliancePartners = getPartnerAirlines(airline.marketing);
  const operatingAlliancePartners = getPartnerAirlines(airline.operating);

  let operatingAirlines: string[] = [];
  if (!isMarketingOperatingAirlineSame) {
    operatingAirlines = getAllianceAirlines(operatingAlliance);
  }

  const airlines = marketingAirlines
    .concat(operatingAirlines)
    .concat(operatingAlliancePartners)
    .concat(marketingAlliancePartners);

  const uniqueAirlines = [...new Set(airlines)];

  let allAllianceLoyalties = [] as LoyaltyInfo[];

  if (uniqueAirlines.length) {
    allAllianceLoyalties = uniqueAirlines.reduce((airlineAllLoyaties, airlineCode) => {
      if (airlineLoyaltyMap[airlineCode]) {
        const loyaltyInfo = travelerLoyaltyOptions.find(
          (loyaltyOption) => loyaltyOption.issuedBy === airlineCode || loyaltyOption?.appliedTo.includes(airlineCode),
        );
        const loyalty = loyaltyInfo ?? { type: 1, id: '', issuedBy: airlineCode, appliedTo: [] };
        airlineAllLoyaties.push(loyalty);
      }
      return airlineAllLoyaties;
    }, [] as LoyaltyInfo[]);
  }

  if (!marketingAlliance) {
    if (airlineLoyaltyMap[airline.marketing]) {
      const loyaltyInfo = travelerLoyaltyOptions.find(
        (loyaltyOption) =>
          loyaltyOption.issuedBy === airline.marketing || loyaltyOption?.appliedTo.includes(airline.marketing),
      );
      allAllianceLoyalties.push(loyaltyInfo ?? { type: 1, id: '', issuedBy: airline.marketing, appliedTo: [] });
    }
  }

  if (!operatingAlliance && !isMarketingOperatingAirlineSame) {
    if (airlineLoyaltyMap[airline.operating]) {
      const loyaltyInfo = travelerLoyaltyOptions.find((loyaltyOption) => loyaltyOption.issuedBy === airline.operating);
      allAllianceLoyalties.push(loyaltyInfo ?? { type: 1, id: '', issuedBy: airline.operating, appliedTo: [] });
    }
  }

  return allAllianceLoyalties;
};

/**  @deprecated */
export const getApplicableLoyaltyOptions = (
  itineraryAllAirlines: IItineraryAirline[],
  travelerLoyaltyOptions: LoyaltyInfo[],
): IAirlineLoyaltyOptions[] =>
  itineraryAllAirlines.map((itineraryAirline) => {
    const { airline, legIndex, flightIndex } = itineraryAirline;
    const loyaltyInfos = getAllianceAirlineLoyalties(airline, travelerLoyaltyOptions);
    return { legIndex, flightIndex, airline, loyaltyInfos };
  });

export function getLoyaltyOptions(
  loyaltyOptions: IAirlineSelectedLoyalty[] | undefined,
): AirSeatMapRequestLoyaltyInfo[] {
  if (!loyaltyOptions) {
    return [];
  }

  const seatMapRequest: AirSeatMapRequestLoyaltyInfo[] = [];
  loyaltyOptions.forEach((loyaltyOption) => {
    if (loyaltyOption.loyaltyInfo) {
      seatMapRequest.push({
        legIndex: loyaltyOption.legIndex,
        flightIndex: loyaltyOption.flightIndex,
        loyaltyInfo: {
          type: loyaltyOption.loyaltyInfo.type,
          id: loyaltyOption.loyaltyInfo.id,
          issuedBy: loyaltyOption.loyaltyInfo.issuedBy,
          appliedTo: [],
        },
      });
    }
  });
  return seatMapRequest;
}

function getPassengerInfosFromPaxInfos(
  passengers: IAirSearchUrlPaxInfoWithUserOrgIdOrOwnerInfo[],
): AirSeatMapRequestPaxInfo[] {
  return passengers.map((paxInfo) => {
    const loyalty = paxInfo.loyalty || [];
    const passengerInfo = {
      paxType: paxInfo.paxType,
      paxAge: paxInfo.paxAge,
      loyaltyInfo: loyalty.map((loyaltyInfo) => ({
        legIndex: loyaltyInfo.leg,
        flightIndex: loyaltyInfo.flight,
        loyaltyInfo: loyaltyInfo.loyalty,
      })),
    };

    if ('bookingOwner' in paxInfo) {
      const paxInfoWithBookingOwner = paxInfo as IAirSearchUrlPaxInfoWithBookingOwner;
      const nonProfileUserInfo = {
        legalEntityId: paxInfoWithBookingOwner.bookingOwner.userBusinessInfo?.legalEntityId,
        organizationId: paxInfoWithBookingOwner.bookingOwner.userBusinessInfo?.organizationId,
      };
      return { ...passengerInfo, nonProfileUserInfo };
    }

    if ('userOrgId' in paxInfo) {
      const paxInfoWithUserOrgId = paxInfo as IAirSearchUrlPaxInfoWithUserOrgId;
      return { ...passengerInfo, userOrgId: paxInfoWithUserOrgId.userOrgId };
    }

    throw new Error('paxInfo should have either userOrgId or bookingOwner');
  });
}

export interface IGetAirSeatMapRequestInput extends IGetAirAncillariesRequestInput {
  pnrId?: string;
}

export function getAirSeatMapRequest({
  searchId,
  rateOptionId,
  passengers,
  pnrId,
}: IGetAirSeatMapRequestInput): AirSeatMapRequest {
  const passengerInfos: AirSeatMapRequestPaxInfo[] = getPassengerInfosFromPaxInfos(passengers);
  const eligiblePassengerInfos = passengerInfos.filter(
    (paxInfo) => paxInfo.paxType !== PassengerTypeEnum.INFANT_ON_LAP,
  );

  return {
    searchId,
    rateOptionId: [rateOptionId],
    passengerInfos: eligiblePassengerInfos,
    pnrId,
  };
}

export function getAirAncillariesRequest({
  searchId,
  rateOptionId,
  passengers,
}: IGetAirAncillariesRequestInput): AirAncillariesRequest {
  const passengerInfos: AirSeatMapRequestPaxInfo[] = getPassengerInfosFromPaxInfos(passengers);

  return {
    searchId,
    rateOptionId,
    passengerInfos,
  };
}

export const getCarApplicableLoyaltyOptions = (
  travelerLoyaltyOptions: LoyaltyInfo[],
  allLoyaltyOptions: LoyaltyInfo[],
): LoyaltyInfo[] =>
  allLoyaltyOptions.map((loyaltyOption) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === loyaltyOption.issuedBy,
    );
    return existingLoyalty ?? loyaltyOption;
  });

/**
 * @deprecated Use getAvailableLoyaltyIssuersForAirline instead. Check usage of
 * getAvailableLoyaltyIssuersForAirline in code.
 */
export const getAirApplicableLoyaltyOptionsOld = (
  travelerLoyaltyOptions: LoyaltyInfo[],
  airline: IAirlineCodes,
  allLoyaltyOptions?: LoyaltyInfo[],
): LoyaltyInfo[] => {
  if (airline) {
    const loyaltyInfos = getAllianceAirlineLoyalties(airline, travelerLoyaltyOptions);
    return loyaltyInfos.map((loyaltyOption) => {
      const existingLoyalty = travelerLoyaltyOptions.find(
        (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === loyaltyOption.issuedBy,
      );
      return existingLoyalty ?? loyaltyOption;
    });
  }
  return (allLoyaltyOptions ?? []).map((loyaltyOption) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === loyaltyOption.issuedBy,
    );
    return existingLoyalty ?? loyaltyOption;
  });
};

export const getAirApplicableLoyaltyOptions = (
  travelerLoyaltyOptions: LoyaltyInfo[],
  loyaltyMap: LoyaltyMap,
): LoyaltyInfo[] => {
  const applicableLoyaltyPrograms = Object.entries(loyaltyMap);
  const loyaltyInfos = applicableLoyaltyPrograms.map((applicableLoyaltyProgram) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === applicableLoyaltyProgram[0],
    );
    return {
      type: LoyaltyInfoTypeEnum.AIR,
      id: existingLoyalty?.id ?? '',
      issuedBy: applicableLoyaltyProgram[0],
      appliedTo: existingLoyalty?.appliedTo ?? [],
    };
  });

  return loyaltyInfos.map((loyaltyOption) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === loyaltyOption.issuedBy,
    );
    return existingLoyalty ?? loyaltyOption;
  });
};

export const getHotelApplicableLoyaltyOptions = (
  travelerLoyaltyOptions: LoyaltyInfo[],
  loyaltyMap: LoyaltyMap,
): LoyaltyInfo[] => {
  const applicableLoyaltyPrograms = Object.entries(loyaltyMap);

  const loyaltyInfos = applicableLoyaltyPrograms.map((applicableLoyaltyProgram) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === applicableLoyaltyProgram[0],
    );
    return {
      type: LoyaltyInfoTypeEnum.HOTEL,
      id: existingLoyalty?.id ?? '',
      issuedBy: applicableLoyaltyProgram[0],
      appliedTo: existingLoyalty?.appliedTo ?? [],
    };
  });

  return loyaltyInfos.map((loyaltyOption) => {
    const existingLoyalty = travelerLoyaltyOptions.find(
      (currentLoyaltyOption) => currentLoyaltyOption.issuedBy === loyaltyOption.issuedBy,
    );
    return existingLoyalty ?? loyaltyOption;
  });
};

export const getAirlinesUniqueByOperatingMarketing = (airlines: IItineraryAirline[]): IItineraryAirline[] => {
  const uniqAirlines = uniqBy(airlines, (a) => `${a.airline.marketing}-${a.airline.operating}`);
  return uniqAirlines;
};

export const getAirlineAvailableForLoyaltySelection = (
  itineraryAllAirlines: IItineraryAirline[],
): IItineraryAirline[] => {
  const uniqueMarketingAirlines = uniqBy(itineraryAllAirlines, 'airline.marketing');
  return uniqueMarketingAirlines;
};

export const getAvailableLoyaltyIssuersForAirline = (airline: IAirlineCodes): string[] => {
  const { marketing, operating } = airline;

  const marketingAlliance = airlineAllianceMap[marketing];
  const marketingAllianceAirlines = getAllianceAirlines(marketingAlliance);
  const operatingAlliance = airlineAllianceMap[operating];
  const operatingAllianceAirlines = marketing !== operating ? getAllianceAirlines(operatingAlliance) : [];
  const marketingAlliancePartners = getPartnerAirlines(marketing);
  const operatingAlliancePartners = getPartnerAirlines(operating);

  const allAirlines = uniq([
    ...marketingAllianceAirlines,
    ...operatingAllianceAirlines,
    ...marketingAlliancePartners,
    ...operatingAlliancePartners,
    marketing,
    operating,
  ]);

  const airlinesWhereLoyaltyIsAvailable = allAirlines.filter((airlineCode) => !!airlineLoyaltyMap[airlineCode]);

  return airlinesWhereLoyaltyIsAvailable;
};

export const getEmptyLoyaltyForIssuer = (issuedBy: ILoyaltyIssuedBy): LoyaltyInfo => ({
  type: LoyaltyInfoTypeEnum.AIR,
  id: '',
  issuedBy,
  appliedTo: [],
});

function transformSelectionsToLoyaltyList(airlineMap: Record<string, IItineraryAirline>) {
  return (travelerSelection: IAirSearchFormPaxInfo): LoyaltyInfo[] =>
    travelerSelection.loyalty
      ?.map((loyaltyInfoItem) => {
        const appliedTo = airlineMap[`${loyaltyInfoItem.leg}-${loyaltyInfoItem.flight}`]?.airline.marketing;
        return {
          ...loyaltyInfoItem.loyalty,
          ...(appliedTo ? { appliedTo: [appliedTo] } : undefined),
        };
      })
      // remove items that without appliedTo, as airlineMap might not have the flightinfo in some cases
      .filter((x) => Boolean(x.appliedTo)) || [];
}

function dedupeLoyaltyList(eachTravelerLoyaltyInfos: LoyaltyInfo[]): LoyaltyInfo[] {
  const selectedLoyaltiesForTravelerByIssuer: Record<ILoyaltyIssuedBy, LoyaltyInfo> = {};

  eachTravelerLoyaltyInfos.forEach((loyaltyItem) => {
    const loyaltyIssuer = loyaltyItem.issuedBy;
    if (!selectedLoyaltiesForTravelerByIssuer[loyaltyIssuer]) {
      // If this issuer doesn't exist, then add an entry.
      selectedLoyaltiesForTravelerByIssuer[loyaltyIssuer] = loyaltyItem;
    } else {
      // if same loyalty number already exists for the particular user, add flight code to existing item
      selectedLoyaltiesForTravelerByIssuer[loyaltyIssuer].appliedTo = uniq([
        ...selectedLoyaltiesForTravelerByIssuer[loyaltyIssuer].appliedTo,
        ...loyaltyItem.appliedTo,
      ]);
    }
  });

  return Object.values(selectedLoyaltiesForTravelerByIssuer);
}

/**
 * Returns a 2D array, where each internal array represents the loyalty list for each specific user
 * it is ordered by the same order as incoming `travelerSelections`
 */
export function getPerTravellerLoyaltyInfo({
  travelerSelections,
  airlines,
}: IPerTravellerLoyaltyInput): LoyaltyInfo[][] {
  const mappedAirlinesToFlightConfig = keyBy(airlines, (flight) => `${flight.legIndex}-${flight.flightIndex}`);

  const perTravellerLoyaltyList = travelerSelections
    /* Step 1: transform travelerSelections list to a list of loyalties per traveller */
    .map(transformSelectionsToLoyaltyList(mappedAirlinesToFlightConfig))
    /* Step 2: process loyalty list - deduplicate it & merge appliedTo of each item */
    .map(dedupeLoyaltyList);

  return perTravellerLoyaltyList;
}

export const getLoyaltyMapOfType = (loyaltyType?: ILoyaltyInfoType): Record<string, string> => {
  switch (loyaltyType) {
    case ILoyaltyInfoTypeEnum.AIR:
      return airlineLoyaltyMap;
    case ILoyaltyInfoTypeEnum.HOTEL:
      return hotelLoyaltyMap;
    case ILoyaltyInfoTypeEnum.CAR:
      return carLoyaltyMap;
    default:
      return {};
  }
};

export function loyaltyToOption(loyaltyProgram: ITravelerLoyaltyInfo, options?: LoyaltyToOptionOptions): Option;
export function loyaltyToOption(loyaltyProgram: undefined, options?: LoyaltyToOptionOptions): null;
export function loyaltyToOption(
  loyaltyProgram: ITravelerLoyaltyInfo | undefined,
  options?: LoyaltyToOptionOptions,
): Option | null;
export function loyaltyToOption(
  loyaltyProgram: ITravelerLoyaltyInfo | undefined,
  options?: LoyaltyToOptionOptions,
): Option | null {
  if (!loyaltyProgram) {
    return null;
  }
  const hardCodedLoyaltyMap = getLoyaltyMapOfType(loyaltyProgram?.type);
  const loyaltyMap = options?.loyaltyMap ?? hardCodedLoyaltyMap;
  const loyaltyProgramName = loyaltyMap[loyaltyProgram.issuedBy] ?? hardCodedLoyaltyMap[loyaltyProgram.issuedBy];
  const valueKey = options?.valueKey ?? 'id';

  return {
    label: loyaltyProgramName || loyaltyProgram.issuedBy,
    value: loyaltyProgram[valueKey],
  };
}

export const loyaltyMapToOptions = (loyaltyMap: Record<string, string>): Option[] =>
  Object.keys(loyaltyMap).map((key) => ({
    label: loyaltyMap[key],
    value: key,
  }));

export function travelToLoyaltyType(travelType: 'flight' | 'hotel' | 'car' | 'rail'): ILoyaltyInfoType {
  switch (travelType) {
    case 'flight':
      return ILoyaltyInfoTypeEnum.AIR;
    case 'hotel':
      return ILoyaltyInfoTypeEnum.HOTEL;
    case 'car':
      return ILoyaltyInfoTypeEnum.CAR;
    case 'rail':
      return ILoyaltyInfoTypeEnum.RAIL;
    default:
      return ILoyaltyInfoTypeEnum.UNRECOGNIZED;
  }
}

export function LoyaltyInfoTypeEnumV2ToV1Mapper(loyaltyEnum: LoyaltyInfoTypeEnumV2): ILoyaltyInfoType {
  switch (loyaltyEnum) {
    case LoyaltyInfoTypeEnumV2.Air:
      return ILoyaltyInfoTypeEnum.AIR;
    case LoyaltyInfoTypeEnumV2.Car:
      return ILoyaltyInfoTypeEnum.CAR;
    case LoyaltyInfoTypeEnumV2.Hotel:
      return ILoyaltyInfoTypeEnum.HOTEL;
    case LoyaltyInfoTypeEnumV2.Rail:
      return ILoyaltyInfoTypeEnum.RAIL;
    case LoyaltyInfoTypeEnumV2.UnknownType:
      return ILoyaltyInfoTypeEnum.UNKNOWN_TYPE;
    default:
      return ILoyaltyInfoTypeEnum.UNRECOGNIZED;
  }
}

export function LoyaltyInfoV2ToV1Mapper(loyaltyInfo: LoyaltyInfoV2): LoyaltyInfo {
  return {
    id: loyaltyInfo.id ?? '',
    type: LoyaltyInfoTypeEnumV2ToV1Mapper(loyaltyInfo.type),
    appliedTo: loyaltyInfo.appliedTo ?? [],
    issuedBy: loyaltyInfo.issuedBy ?? '',
  };
}

export function getAreAllLoyaltyValidated(passengers: IAirSearchFormPaxInfo[]) {
  return passengers.every((pax) => {
    const paxLoyalty = pax.loyalty || [];
    return paxLoyalty.every((flightLoyalty) => {
      if (!flightLoyalty.loyalty) {
        return true;
      }
      return (
        flightLoyalty.validationStatus === LoyaltyValidityEnum.VALID ||
        flightLoyalty.validationStatus === LoyaltyValidityEnum.INVALID
      );
    });
  });
}

export function getAreAllSelectedLoyaltiesValid(passengers: IAirSearchFormPaxInfo[]) {
  return passengers.every((pax) => {
    const paxLoyalty = pax.loyalty || [];
    return paxLoyalty.every((flightLoyalty) => {
      if (!flightLoyalty.loyalty) {
        return true;
      }
      return flightLoyalty.validationStatus === LoyaltyValidityEnum.VALID;
    });
  });
}
export function getNonEmptyMembershipId(passengers: IAirSearchFormPaxInfo[]): string {
  const firstLoyaltyWithNonEmptyId = passengers.find(({ loyalty }) => {
    return loyalty?.some((loyaltyInfo) => loyaltyInfo.loyalty && loyaltyInfo.loyalty.id);
  });

  return firstLoyaltyWithNonEmptyId?.loyalty?.[0]?.loyalty?.id ?? '';
}
