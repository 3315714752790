import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  countryCodeMapper,
  invalidateReadProfile,
  useAuth,
  useProcessAuthorization,
  useUpdateUserAction,
} from 'obt-common';

import { Typography } from '@spotnana/blocks/src/Typography';
import { CircularProgress } from '@spotnana/blocks/src/CircularProgress';
import { Select } from '@spotnana/blocks/src/Select/Select';
import { Button } from '@spotnana/blocks/src/Button';
import { Checkbox } from '@spotnana/blocks/src/Checkbox';

import { translateLabelValuePairs } from 'src/app/admin/shared/utils';
import { useExternalAuthCodeContext } from 'src/components/ExternalAuthCodeProvider/ExternalAuthCodeProvider';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { button_container, circular_progress_container, country_select } from './BonvoyLinkModal.styles';
import { BonvoyLoginModal } from './BonvoyLoginModal';
import { MailingListConsentValue } from '../types';

interface IBonvoyLinkModalProps {
  marriottAuthCode: string;
  userId: string;
}

export function BonvoyLinkModal({ marriottAuthCode, userId }: IBonvoyLinkModalProps) {
  const [isLinked, setIsLinked] = useState(false);
  const [countryCode, setCountryCode] = useState<string | undefined>();
  const [isOptIn, setIsOptIn] = useState(false);
  const { t: tCommon } = useTranslation('COMMON');
  const { t: tt } = useTranslation('WEB');
  const translatedCountryOptions = translateLabelValuePairs(countryCodeMapper, tCommon);
  const { refetchUserBasicInfo } = useAuth();
  const { resetAuthState } = useExternalAuthCodeContext();

  const { mutateAsync: processAuthorizationResponse, error: processAuthorizationResponseError } =
    useProcessAuthorization(userId);
  const { mutateAsync: updateUserActionRequest, isLoading: updateUserActionRequestLoading } =
    useUpdateUserAction(userId);

  const handleLinkMarriottAccount = useCallback(
    async (code: string) => {
      await processAuthorizationResponse({ source: 'MARRIOTT', code, originDomain: window.location.origin });
      setIsLinked(true);
    },
    [processAuthorizationResponse],
  );

  const handleUpdateOptInSettings = useCallback(async () => {
    if (!countryCode) return;

    try {
      await updateUserActionRequest({
        actionName: IncompleteUserActionName.MARRIOTT_MAILING_LIST_CONSENT,
        input: isOptIn ? MailingListConsentValue.Yes : MailingListConsentValue.No,
      });
    } catch (e) {
      console.error(e);
    }

    invalidateReadProfile({ userId: { id: userId } });
    await refetchUserBasicInfo();
    resetAuthState();
  }, [countryCode, updateUserActionRequest, isOptIn, userId, refetchUserBasicInfo, resetAuthState]);

  const handleRetry = useCallback(async () => {
    resetAuthState();
  }, [resetAuthState]);

  const handleUpdateCountryCode = useCallback((option?: (typeof translatedCountryOptions)[number]) => {
    setCountryCode(option?.value);

    // if selected country is US, opt-in is true by default. Ref: ST-63767
    setIsOptIn(option?.value === 'US');
  }, []);

  const handleUpdateOptIn = useCallback(() => {
    setIsOptIn((prevState) => !prevState);
  }, []);

  const selectedCountryOption = useMemo(
    () => translatedCountryOptions.find((option) => option.value === countryCode),
    [countryCode, translatedCountryOptions],
  );

  useEffect(() => {
    if (marriottAuthCode) {
      handleLinkMarriottAccount(marriottAuthCode);
    }
  }, [handleLinkMarriottAccount, marriottAuthCode]);

  if (processAuthorizationResponseError) {
    return (
      <BonvoyLoginModal
        title={tt('An error has occurred.')}
        footer={
          <div css={button_container}>
            <div>
              <Button fullWidth variant="primary" onClick={handleRetry}>
                {tt('Try again')}
              </Button>
            </div>
          </div>
        }
      >
        <Typography variant="body2" kind="regular">
          {tt('Apologies, an error occurred. Let’s try again from beginning.')}
        </Typography>
      </BonvoyLoginModal>
    );
  }

  if (isLinked) {
    return (
      <BonvoyLoginModal
        title={tt('Your account is now linked!')}
        footer={
          <div css={button_container}>
            <div>
              <Button
                fullWidth
                variant="primary"
                onClick={handleUpdateOptInSettings}
                disabled={!countryCode || updateUserActionRequestLoading}
                loading={updateUserActionRequestLoading}
              >
                {tt('Let’s get started')}
              </Button>
            </div>
          </div>
        }
      >
        <Typography variant="body2" kind="regular">
          {tt(
            'Before you begin, please select your country/region of residence and indicate your communication preference below.',
          )}
        </Typography>
        <Select
          css={country_select}
          options={translatedCountryOptions}
          onChange={handleUpdateCountryCode}
          label={tt('Country/Region')}
          variant="single"
          controlledValue={selectedCountryOption}
          placeholder={tt('Select from menu')}
          required
          search
        />
        <Checkbox
          size="small"
          onChange={handleUpdateOptIn}
          checked={isOptIn}
          label={tt(
            'I would like to receive personalized communications, including offers, details about promotions, and travel-related products from Business Access by Marriott Bonvoy via email.',
          )}
        />
      </BonvoyLoginModal>
    );
  }

  return (
    <BonvoyLoginModal title={tt('Please wait it’s processing now')}>
      <Typography variant="body2" kind="regular">
        {tt(
          'Currently linking your Marriott Bonvoy account with Business Access by Marriott Bonvoy. Your patience is appreciated.',
        )}
      </Typography>
      <div css={circular_progress_container}>
        <CircularProgress size={20} data-testid="linking-loader-indicator" />
      </div>
    </BonvoyLoginModal>
  );
}
