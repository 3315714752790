import { useFeatureFlag } from 'obt-common';
import { PropsWithChildren } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

interface HideOnRouteMatchProps extends PropsWithChildren<{}> {
  paths: string[];
}

export const HideOnRouteMatch = ({ children, paths }: HideOnRouteMatchProps): JSX.Element | null => {
  const isUserPreferredLanguageEnabled = useFeatureFlag('FE_USER_PREFERRED_LANGUAGE');
  const { pathname } = useLocation();
  if (isUserPreferredLanguageEnabled === undefined) {
    return null;
  }
  const match = paths.some((path) => matchPath(pathname, { path })?.isExact);
  return <>{isUserPreferredLanguageEnabled && match ? null : children}</>;
};
