import { MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import first from 'lodash/first';

import Box, { Flex } from '@spotnana/pixel-react/dist/Box';
import { Button } from '@spotnana/pixel-react/dist/Button';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { Typography as BlocksTypography } from '@spotnana/blocks/src/Typography';
import {
  usePrimaryTravelerId,
  stringifyParams,
  IUserOrgId,
  removeEmptyValuesFromObject,
  useFeatureFlag,
  parseParams,
  RBAC,
  RoleInfoTypeEnum,
  useTMCListQuery,
  getFullAssetPath,
  createUserNameFromFullName,
} from 'obt-common';
import { OrganizationBasicInfo, TravelerBasicInfo, TripBasicInfo } from 'obt-common/types/api/v2/obt/model';

import { getSubtext } from 'src/v1-components/shared/AutoCompleteSubtext';
import { Skeleton } from '@spotnana/blocks/src/Skeleton';
import { flex } from '@spotnana/blocks/src/utils';
import { Ellipsis } from '@spotnana/blocks/src/Ellipsis';
import { Avatar } from '@spotnana/blocks/src/Avatar';
import { Icon } from '@spotnana/blocks/src/Icon';
import { Persona } from 'obt-common/types/api/v2/obt/model/persona';
import DotSeperator from 'src/v2-components/shared/DotSeparator';
import ClickableBox from '../ClickableBox';
import { EntityInfo, isOrganizationBasicInfo, isTravelerBasicInfo, isTripBasicInfo } from '../../../app/agent/types';
import {
  name_logo_container,
  name_logo_persona_container,
  name_trip_button_container,
  option_container,
} from './EntityAutocompleteOptions.styles';

const COMPANY_NAME_LOGO_VISIBILITY_ALLOWED_ROLES = [
  RoleInfoTypeEnum.GLOBAL_ADMIN,
  RoleInfoTypeEnum.GLOBAL_AGENT,
  RoleInfoTypeEnum.TMC_ADMIN,
  RoleInfoTypeEnum.TMC_AGENT,
];

interface EntityAutocompleteOptionProps<T = EntityInfo> {
  option: T;
  isHighlighted: boolean;
  isCompanyTripsPage?: boolean;
  isTravelerAutocomplete?: boolean;
  onClick?: VoidFunction;
}

type PersonaColors = 'link' | 'error' | 'warning' | 'primary' | undefined;

const TRAVELER_PERSONA_VARIANTS: Record<Persona, PersonaColors> = {
  [Persona.Employee]: 'link',
  [Persona.Adhoc]: 'warning',
  [Persona.Guest]: 'warning',
  [Persona.Personal]: 'primary',
  [Persona.UnknownPersona]: undefined,
  [Persona.Relative]: undefined,
};

const PersonaLabel = ({ persona }: { persona: Persona | undefined }) => {
  const { t: tt } = useTranslation('WEB');
  const personaLabel = getSubtext({ persona, tt });

  if (!persona || !personaLabel) {
    return null;
  }

  return (
    <BlocksTypography color={TRAVELER_PERSONA_VARIANTS[persona]} variant="body3" kind="medium">
      {personaLabel}
    </BlocksTypography>
  );
};

const EntityAutocompleteProfileOption = ({
  option,
  isCompanyTripsPage = false,
  isTravelerAutocomplete = false,
  onClick,
}: EntityAutocompleteOptionProps<TravelerBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { search } = useLocation();
  const { tripType } = parseParams(search);
  const usePreferredName = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_PREFERRED_NAME');
  const isCompanyTripsPageV3FeatureEnabled = useFeatureFlag('FE_TMC_COMPANY_TRIPS_V3');
  const isDisplayCompanyNameLogoEnabled = useFeatureFlag('FE_INFRA_AUTOCOMPLETE_TMC_NAME');
  const { setPrimaryTravelerId } = usePrimaryTravelerId();
  const { data: tmcList, isFetching: isFetchingTmcList } = useTMCListQuery({
    isSpotnanaAgentOrAdmin: true,
    companyIds: [option.userOrgId?.organizationId?.id as string],
    options: { enabled: isDisplayCompanyNameLogoEnabled && Boolean(option.userOrgId?.organizationId?.id) },
  });
  const userCompany = useMemo(
    () => tmcList?.find((tmc) => tmc.id.id === option.userOrgId?.organizationId?.id),
    [tmcList, option.userOrgId?.organizationId?.id],
  );

  const { t: tt } = useTranslation('WEB');

  const goToProfile: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (event.target instanceof HTMLElement && event.target.innerText !== tt('Go to trips')) {
      history.push({ pathname: '/profile/personal', search: stringifyParams({ profileId: option.userOrgId }) });
    }
  };

  const goToTrips: MouseEventHandler<HTMLButtonElement> = () => {
    if (option.userOrgId) {
      if (isCompanyTripsPage) {
        const searchParamObj = isCompanyTripsPageV3FeatureEnabled
          ? {
              tripType,
              userId: option.userOrgId.userId?.id,
              searchText: option.fullName,
            }
          : {
              applyUserFilter: true,
              userId: option.userOrgId.userId,
              selectedOption: option,
            };
        history.push({
          pathname: '/company-trips',
          search: stringifyParams(searchParamObj),
        });
      } else {
        setPrimaryTravelerId(removeEmptyValuesFromObject(option.userOrgId) as IUserOrgId);
        history.push({
          pathname: '/trips',
        });
      }
    }
  };

  const onClickHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (onClick) {
      onClick();
    } else if (isCompanyTripsPage) {
      goToTrips(event);
    } else goToProfile(event);
  };

  const { persona, email, name, fullName } = option;
  const travelerName = usePreferredName && name ? createUserNameFromFullName(name) : fullName;
  const personaLabel = getSubtext({ persona, tt });
  return (
    <button type="button" onClick={onClickHandler} css={option_container}>
      <Avatar
        color="primary"
        size="small"
        alt=""
        src={option.profilePicture?.url ?? ''}
        title={fullName}
        text={!option.profilePicture?.url ? fullName : undefined}
        badge={
          option.tier === 'SEAT1A' && (
            <img
              src={getFullAssetPath('/v1-assets/images/seat1a-logo', 'svg')}
              alt={`Seat 1A ${tt('USER')}`}
              title={`Seat 1A ${tt('USER')}`}
              style={{
                height: '8px',
                width: '8px',
                borderRadius: '100%',
              }}
            />
          )
        }
      />
      <div css={name_trip_button_container}>
        <BlocksTypography variant="body2" kind="medium">
          {travelerName}
        </BlocksTypography>
        <BlocksTypography css={flex.init} variant="body3" color="secondary">
          <Ellipsis title={email} width={180}>
            {email}
          </Ellipsis>
        </BlocksTypography>
        <RBAC
          allowedRoles={
            isDisplayCompanyNameLogoEnabled ? [RoleInfoTypeEnum.GLOBAL_ADMIN, RoleInfoTypeEnum.GLOBAL_AGENT] : []
          }
        >
          {option.userOrgId?.tmcBasicInfo?.contractingTmc?.name && (
            <BlocksTypography
              css={flex.init}
              as="span"
              variant="body3"
              color="secondary"
              title={tt('TMC: {{tmc}}', { tmc: option.userOrgId.tmcBasicInfo.contractingTmc.name })}
            >
              {isDisplayCompanyNameLogoEnabled ? (
                <Ellipsis title={tt('TMC: {{tmc}}', { tmc: option.userOrgId.tmcBasicInfo.contractingTmc.name })}>
                  {tt('TMC: {{tmc}}', { tmc: option.userOrgId.tmcBasicInfo.contractingTmc.name })}
                </Ellipsis>
              ) : (
                option.userOrgId.tmcBasicInfo.contractingTmc.name
              )}
            </BlocksTypography>
          )}
        </RBAC>
        {isDisplayCompanyNameLogoEnabled ? (
          <div css={name_logo_persona_container}>
            {isFetchingTmcList ? (
              <Skeleton width={100} height={16} />
            ) : (
              <span css={name_logo_container}>
                <RBAC allowedRoles={COMPANY_NAME_LOGO_VISIBILITY_ALLOWED_ROLES}>
                  {userCompany?.organizationLogo?.url && (
                    <img height={20} width={20} src={userCompany?.organizationLogo?.url} alt="" />
                  )}
                  {userCompany?.name && (
                    <BlocksTypography css={flex.init} variant="body3" color="secondary">
                      <Ellipsis title={userCompany.name} width={200}>
                        {userCompany.name}
                      </Ellipsis>
                    </BlocksTypography>
                  )}
                </RBAC>
                {persona && personaLabel && (
                  <>
                    <DotSeperator />
                    <PersonaLabel persona={persona} />
                  </>
                )}
              </span>
            )}
          </div>
        ) : (
          <PersonaLabel persona={persona} />
        )}
      </div>
      {!isCompanyTripsPage && !isTravelerAutocomplete && (
        <Button
          variation="ghost"
          style={{ borderRadius: '32px' }}
          px="16px"
          addonBefore={<Icon fontSize={20} name="LuggageCarryOn" />}
          onClick={goToTrips}
          className="trip-btn"
        >
          {tt('Go to trips')}
        </Button>
      )}
    </button>
  );
};

const EntityAutocompleteTripOption = ({
  option,
  isCompanyTripsPage = false,
}: EntityAutocompleteOptionProps<TripBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { t: tt } = useTranslation('WEB');

  const goToTrip: MouseEventHandler<HTMLButtonElement> = () => {
    history.push({
      pathname: `/trips/${option.tripId}`,
      search: stringifyParams({
        userId: first(option?.userOrgIds),
        redirectFrom: isCompanyTripsPage ? 'company-trips' : 'trips',
      }),
    });
  };

  return (
    <button type="button" onClick={goToTrip} css={option_container}>
      <Flex style={{ wordBreak: 'break-word' }}>
        <Box px="10px">
          <Avatar color="primary" size="small" alt="" text={option.tripName ?? ''} />
        </Box>
        <Box px="10px">
          <Box mb="4px">
            <Typography variation="small" fontWeight="semiBold" color="text.label" lineHeight="16px">
              {option.tripName}
            </Typography>
            <Typography variation="small" style={{ fontStyle: 'italic' }} color="text.blockedGrey" lineHeight="16px">
              - {tt('In trips')}
            </Typography>
          </Box>
          {option.pnrId && (
            <Typography variation="label" lineHeight="16px" as="p">
              {tt('PNR ID')}: {option.pnrId} / {tt('Source')} {tt('PNR ID')}: {option.sourcePnrId}
            </Typography>
          )}
        </Box>
      </Flex>
    </button>
  );
};

const EntityAutocompleteCompanyOption = ({
  option,
  isHighlighted,
}: EntityAutocompleteOptionProps<OrganizationBasicInfo>): JSX.Element => {
  const history = useHistory();
  const { t: tt } = useTranslation('WEB');

  const goToCompany: MouseEventHandler<HTMLDivElement> = () => {
    history.push({
      pathname: '/admin/company/overview',
      search: stringifyParams({ organizationId: { id: option.orgId } }),
    });
  };

  return (
    <ClickableBox p="12px" borderRadius="sm" bg={isHighlighted ? 'bg.blueGray' : undefined} onClick={goToCompany}>
      <Flex style={{ wordBreak: 'break-word' }}>
        <Box px="10px">
          <Avatar size="small" alt="" text={option.orgName} />
        </Box>
        <Box px="10px">
          <Typography variation="small" fontWeight="semiBold" color="text.label" lineHeight="16px">
            {option.orgName}
          </Typography>
          <Typography variation="small" style={{ fontStyle: 'italic' }} color="text.blockedGrey" lineHeight="16px">
            - {tt('In company')}
          </Typography>
        </Box>
      </Flex>
    </ClickableBox>
  );
};

const EntityAutocompleteOption = ({
  option,
  isHighlighted,
  isCompanyTripsPage,
  onClick,
  isTravelerAutocomplete,
}: EntityAutocompleteOptionProps): JSX.Element => {
  if (isTravelerBasicInfo(option)) {
    return (
      <EntityAutocompleteProfileOption
        option={option}
        isHighlighted={isHighlighted}
        isCompanyTripsPage={isCompanyTripsPage}
        onClick={onClick}
        isTravelerAutocomplete={isTravelerAutocomplete}
      />
    );
  }
  if (isOrganizationBasicInfo(option)) {
    return <EntityAutocompleteCompanyOption option={option} isHighlighted={isHighlighted} />;
  }
  if (isTripBasicInfo(option)) {
    return (
      <EntityAutocompleteTripOption
        option={option}
        isHighlighted={isHighlighted}
        isCompanyTripsPage={isCompanyTripsPage}
      />
    );
  }
  return <></>;
};

export default EntityAutocompleteOption;
