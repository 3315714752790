import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { Action, History, LocationDescriptorObject } from 'history';
import { parseParams, stringifyParams } from 'obt-common';
import useEmbedStore from './useEmbedStore';
import { EmbedEventTypes, EmbedParams } from './types';

/*
  Implementation for React Router v5

  TODO: Upgrade component to React Router v6
  To upgrade component you should replace useHistory hook with combination of useLocation and useNavigationType:
  const location = useLocation();
  const action = useNavigationType();

  useEffect(() => {
    reportEvent({
      type: EmbedEventTypes.NAVIGATION,
      payload: {
        navigationType: action.toLowerCase() as Lowercase<Action>,
        pathname,
        search,
      },
    });
  }, [ location, action ]);
 */

const appendEmbedParams = (route: LocationDescriptorObject, embedParams: EmbedParams): LocationDescriptorObject => {
  const { search } = route;
  const params = parseParams(search || '');

  return {
    ...route,
    search: stringifyParams({
      ...embedParams,
      ...params,
    }),
  };
};

const modifyHistoryRoute = (
  route: string | LocationDescriptorObject,
  embedParams: EmbedParams,
): LocationDescriptorObject => {
  let routeObj = route as LocationDescriptorObject;
  if (typeof route === 'string') {
    const path = route.split('?');
    routeObj = { pathname: path[0], search: path[1] };
  }

  return appendEmbedParams(routeObj, embedParams);
};

const modifyHistoryMethod = (history: History, method: 'push' | 'replace', embedParams: EmbedParams) => {
  const oldMethod = history[method];
  Object.assign(history, {
    [method](route: string | LocationDescriptorObject) {
      oldMethod(modifyHistoryRoute(route, embedParams));
    },
  });
};

const useEmbedHistory = (): void => {
  const history = useHistory();
  const { reportEvent, embedParams, isEmbed } = useEmbedStore();

  useEffect(() => {
    if (isEmbed) {
      modifyHistoryMethod(history, 'push', embedParams);
      modifyHistoryMethod(history, 'replace', embedParams);
    }

    // TODO: FIX_ESLINT_VIOLATION
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, embedParams]);

  useEffect(() => {
    if (isEmbed) {
      history.listen(({ pathname, search }, action) => {
        reportEvent({
          type: EmbedEventTypes.NAVIGATION,
          payload: {
            navigationType: action.toLowerCase() as Lowercase<Action>,
            pathname,
            search,
          },
        });
      });
    }
  }, [history, reportEvent, isEmbed]);
};

export default useEmbedHistory;
