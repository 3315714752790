import { env } from '../env';

export const isProductionEnvironment = env.NODE_ENV === 'production';

export default {
  NODE_ENV: env.NODE_ENV,
  VITE_TARGET: env.VITE_TARGET,
  VITE_ENVIRONMENT: env.VITE_ENVIRONMENT,
  VITE_API_BASE_URL: env.VITE_API_BASE_URL,
  VITE_MAP_KEY: env.VITE_MAP_KEY,
  VITE_ZIPKIN_BASE_URL: env.VITE_ZIPKIN_BASE_URL,
  VITE_DEBUGTOOL_BASE_URL: env.VITE_DEBUGTOOL_BASE_URL,
  VITE_COGNITO_POOL_ID: env.VITE_COGNITO_POOL_ID,
  VITE_COGNITO_CLIENT_ID: env.VITE_COGNITO_CLIENT_ID,
  VITE_COGNITO_DOMAIN: env.VITE_COGNITO_DOMAIN,
  VITE_VGS_VAULT_ID: env.VITE_VGS_VAULT_ID,
  VITE_SHERPA_APP_ID: env.VITE_SHERPA_APP_ID,
  VITE_IOS_MAP_KEY: env.VITE_IOS_MAP_KEY,
  VITE_ANDROID_MAP_KEY: env.VITE_ANDROID_MAP_KEY,
  VITE_CODEPUSH_IOS_KEY: env.VITE_CODEPUSH_IOS_KEY,
  VITE_CODEPUSH_ANDROID_KEY: env.VITE_CODEPUSH_ANDROID_KEY,
  VITE_INSTABUG_TOKEN: env.VITE_INSTABUG_TOKEN,
  VITE_WEB_URL: env.VITE_WEB_URL,
  VITE_SEGMENT_WEB_KEY: env.VITE_SEGMENT_WEB_KEY,
  VITE_SEGMENT_MOBILE_KEY: env.VITE_SEGMENT_MOBILE_KEY,
  VITE_FRESHCHAT_APP_ID: env.VITE_FRESHCHAT_APP_ID,
  VITE_FRESHCHAT_APP_KEY: env.VITE_FRESHCHAT_APP_KEY,
  VITE_FRESHCHAT_DOMAIN: env.VITE_FRESHCHAT_DOMAIN,
  VITE_FRESHCHAT_WEB_KEY: env.VITE_FRESHCHAT_WEB_KEY,
  VITE_CENTER_INTERCEPT_URL: env.VITE_CENTER_INTERCEPT_URL,
  VITE_CENTER_POST_INTERCEPT_REDIRECT_AUTH_URL: env.VITE_CENTER_POST_INTERCEPT_REDIRECT_AUTH_URL,
  VITE_HOST_APP_DASHBOARD_URL: env.VITE_HOST_APP_DASHBOARD_URL,
  VITE_ANDROID_BUNDLE_ID: env.VITE_ANDROID_BUNDLE_ID,
  VITE_IOS_APP_STORE_ID: env.VITE_IOS_APP_STORE_ID,
  VITE_IOS_BUNDLE_ID: env.VITE_IOS_BUNDLE_ID,
  VITE_IS_E2E_TEST_RUN: env.VITE_IS_E2E_TEST_RUN,
  VITE_SENTRY_DSN: env.VITE_SENTRY_DSN,
  VITE_OPENREPLAY_ID: env.VITE_OPENREPLAY_ID,
  VITE_LOGROCKET_ID: env.VITE_LOGROCKET_ID,
  VITE_ENABLE_AXE: env.VITE_ENABLE_AXE,
  VITE_AMADEUS_CHECKOUT_ENV: env.VITE_AMADEUS_CHECKOUT_ENV,
  VITE_PUBLIC_URL: env.VITE_PUBLIC_URL,
  VITE_HARNESS_FF_SDK: env.VITE_HARNESS_FF_SDK,
  VITE_PENDO_WEB_API_KEY: env.VITE_PENDO_WEB_API_KEY,
  VITE_FULL_STORY_ORG_KEY: env.VITE_FULL_STORY_ORG_KEY,
  VITE_EXCLUDED_DOMAINS_FOR_WHITELABELLING: env.VITE_EXCLUDED_DOMAINS_FOR_WHITELABELLING,
  VITE_AMADEUS_SDK_JS: env.VITE_AMADEUS_SDK_JS,
  VITE_AMADEUS_SDK_CSS: env.VITE_AMADEUS_SDK_CSS,
  VITE_GENESYS_CHAT_CLIENT_ID: env.VITE_GENESYS_CHAT_CLIENT_ID,
  VITE_TWILIO_SSO_ID: env.VITE_TWILIO_SSO_ID,
  VITE_TWILIO_INSIGHTS_URL: env.VITE_TWILIO_INSIGHTS_URL,
  VITE_SPOTNANA_IDP_URL: env.VITE_SPOTNANA_IDP_URL,
  VITE_I18N_ORG_RESOURCE_KEY_MAP: env.VITE_I18N_ORG_RESOURCE_KEY_MAP,
};
