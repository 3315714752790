import type { AxiosError } from 'axios';

import { webSessionStorage } from '../../utils/SessionStorage';
import { tokenExchange } from '../../constants';
import { parseParams } from '../../utils';
import { endPoints } from '../endpoints';
import { StorageKeys } from '../../types/storage';

import onResponseError from './onResponseError';

// These errors if returned by an API call (except `getAuthConfig`)
// should make FE force logout the user and notify them of error
const barrableErrors = [
  'USER_NOT_AUTHENTICATED',
  'USER_NOT_FOUND',
  'ORGANIZATION_NOT_FOUND',
  'MULTIPLE_USERS_FOUND_WITH_EMAIL',
];

export const isUnauthorized = (error: AxiosError): boolean =>
  barrableErrors.includes(error.response?.data.errorCode) && error.config.url === endPoints.getLoggedInUserBasicInfo;

function onUnauthorized(listener: (error: AxiosError) => void) {
  return onResponseError((error) => {
    if (isUnauthorized(error as AxiosError)) {
      try {
        const { search } = window.location;
        const { idp } = parseParams(search);
        // If isUnauthorized error but idp is still in parameter, we are handling
        // a third party auth entry point. We need to store the idp before logging out.
        if (idp && !tokenExchange.includes(idp)) {
          webSessionStorage.setItem(StorageKeys.THIRD_PARTY_AUTH_IDP, idp);
        }
      } catch {
        // If window.location is undefined, do nothing, the code is
        // not applicable in that case.
      } finally {
        listener(error as AxiosError);
      }
    }
  });
}

export default onUnauthorized;
