import first from 'lodash/first';
import type { UserOrgId } from '../types/api/v1/obt/common/user_org_id';
import type { PostalAddress } from '../types/api/v1/common/postal_address';
import { PhoneNumberTypeEnum, PhoneNumberCountryCodeSourceEnum } from '../types/api/v1/common/phone_number';
import { RateComponentType, RateRefundable } from '../types/api/v1/obt/car/car_search_response';
import type { Amenities, CarExtra, Location, OperatingSchedule, Vendor } from '../types/api/v1/obt/car/car_common';
import {
  CarExtraRateUnit,
  CarExtraType,
  CounterLocation,
  EngineType,
  mapCarExtraTypeV2toV1,
} from '../types/api/v1/obt/car/car_common';
import { CarExtraTypeEnum, ITransmissionType } from '../types/car';
import type {
  ICarSummary,
  ILocationDetails,
  IPnrRequestCarDetails,
  IPolicyDetails,
  IThumbnailImage,
  ICarRate,
  ICarLocationDetails,
} from '../types/car';
import { MoneyUtil } from '../utils/Money';
import { underScoreToSpace, getLocationFullAddressV2, getLocationShortAddressV2 } from '../utils/common';
import type { Length } from '../types/api/v1/common/length';
import { LengthUnitEnum } from '../types/api/v1/common/length';
import { carExtrasLabelMap, carRateDetailsFareTypeEnumToCommonFareType, dateFormats } from '../constants';
import type { FareTypeEnum } from '../types/api/v1/obt/air/air_search_response';
import { getDiff } from '../date-utils';
import { SpotnanaError } from '../api/SpotnanaError';
import type { CarItineraryId } from '../types/api/v2/obt/model/car-itinerary-id';
import type { PolicyInfo } from '../types/api/v1/obt/common/policy_info';
import { PolicyInfoApprovalType, Predicate } from '../types/api/v1/obt/common/policy_info';
import { defineCommonMessage, defineMessage } from '../translations/defineMessage';
import type { CarLocationDetails } from '../types/api/v2/obt/model/car-location-details';
import type { CarSearchResponse } from '../types/api/v2/obt/model/car-search-response';
import type { CarSearchItinerary } from '../types/api/v2/obt/model/car-search-itinerary';
import { Transmission as TransmissionV2 } from '../types/api/v2/obt/model/transmission';
import { CarRateType } from '../types/api/v2/obt/model/car-rate-type';
import { CarRateComponentType } from '../types/api/v2/obt/model/car-rate-component-type';
import type {
  Money,
  PostalAddress as PostalAddressV2,
  Vendor as VendorV2,
  CarAmenities,
  UserOrgId as UserOrgIdV2,
  Length as LengthV2,
  PolicyInfo as PolicyInfoV2,
} from '../types';
import { PaymentSourceType } from '../types';
import type { PnrPolicyInfo } from '../types/api/v2/obt/model/pnr-policy-info';
import { CarRateDetailsFareTypeEnum } from '../types/api/v2/obt/model/car-rate-details';
import { PolicyType } from '../types/api/v1/obt/policy/policy_common';
import type { Preference as PreferenceV2 } from '../types/api/v2/obt/model/preference';
import type { Preference } from '../types/api/v1/obt/common/common';
import { PreferredType } from '../types/api/v1/obt/common/common';
import type { CarExta } from '../types/api/v2/obt/model/car-exta';
import { CarRefundableType } from '../types/api/v2/obt/model/car-refundable-type';
import type { OperatingSchedule as OperatingScheduleV2 } from '../types/api/v2/obt/model/operating-schedule';

export default class CarSearchResponseManagerV2 {
  readonly numberOfCars: number;

  constructor(readonly data: CarSearchResponse) {
    if (!data) {
      throw new Error('Invalid data passed to CarSearchResponseManagerV2');
    }
    this.numberOfCars = this.data.cars?.length || 0;
  }

  public GetCars(showOutofPolicy = true): CarSearchItinerary[] {
    return (
      this.data.cars?.filter((carInfo) =>
        showOutofPolicy
          ? true
          : !carInfo?.policyInformation?.ruleResultInfos?.some((info) => info?.violationInfos?.length),
      ) ?? []
    );
  }

  public GetPnrRequestCarDetails(carIndex: number): IPnrRequestCarDetails {
    if (carIndex >= this.numberOfCars) {
      throw new Error('Invalid car index passed to GetPnrRequestCarDetails');
    }
    return { carId: this.getCarId(carIndex) };
  }

  public GetCarItineraryId(carIndex: number): CarItineraryId {
    return { ...this.GetPnrRequestCarDetails(carIndex), searchId: this.GetSearchId() };
  }

  public GetSearchId(): string {
    return this.data.searchId ?? '';
  }

  public GetAvailableCarVendors(): Record<string, string> | undefined {
    if (!this.data.metadata?.availableVendors) {
      return undefined;
    }

    const carVendors: Record<string, string> = {};
    this.data.metadata?.availableVendors?.forEach((vendor) => {
      if (vendor.code) {
        carVendors[vendor.code] = vendor.name;
      }
    });
    return carVendors;
  }

  private getSelectedCar(carId: string): CarSearchItinerary | undefined {
    return first(this.data.cars?.filter((car) => car.carId === carId));
  }

  public GetCarSummary(carId: string): ICarSummary {
    const selectedCar = this.getSelectedCar(carId);
    const showOnlyBaseFare = this.getShowOnlyBaseFare();

    if (!selectedCar) {
      throw new Error('Invalid car id passed to GetCarSummary');
    }

    return {
      displayName: selectedCar.carSpec?.displayName ?? '',
      modelName: '', // model info not present
      thumbnail: this.getThumbnailImage(carId),
      type: selectedCar.carSpec ? `${selectedCar.carSpec?.type}` : '',
      vendor: this.getVendorInfo(selectedCar.vendor),
      amenities: this.getAmenitiesInfo(selectedCar.carSpec?.amenities),
      carExtras: this.getCarExtras(selectedCar.rateInfo?.carExtras),
      approxCost: this.GetApproximateTotalCost(carId),
      pickupDetails: CarSearchResponseManagerV2.getLocationDetails(selectedCar.pickupParams?.pickupLocation),
      dropOffDetails: CarSearchResponseManagerV2.getLocationDetails(selectedCar.dropOffParams?.dropOffLocation),
      policyDetails: this.getPolicyDetails(carId),
      policyViolationMessage: '', // deprecated
      policyInfo: selectedCar.policyInformation && this.getPolicyInfo(selectedCar.policyInformation),
      transmission: this.getTransmission(carId),
      pickupDistance: this.getDistance(selectedCar.pickupParams?.pickupDistance),
      dropOffDistance: this.getDistance(selectedCar.dropOffParams?.dropOffDistance),
      preferredType: [], // deprecated
      publishedTotalCost: this.GetPublishedTotalCost(carId),
      negotiatedSavings: this.GetPublishedSavingsCost(carId),
      fareType: this.GetFareType(carId),
      pickupLocation: this.getLocation(selectedCar.pickupParams?.pickupLocation),
      dropOffLocation: this.getLocation(selectedCar.dropOffParams?.dropOffLocation),
      /**
       * dailyCostMobile and publishedCostMobile to be removed once showFareIncludingTaxesAndFees filter is in place for mobile
       */
      dailyCostMobile: this.GetCarDailyFare(carId, showOnlyBaseFare),
      publishedDailyCostMobile: this.GetCarDailyPublishedFare(carId, showOnlyBaseFare),
      dailyCostIncludingTaxes: this.GetCarDailyFare(carId, false),
      dailyCostExcludingTaxes: this.GetCarDailyFare(carId, true),
      publishedDailyCostIncludingTaxes: this.GetCarDailyPublishedFare(carId, false),
      publishedDailyCostExcludingTaxes: this.GetCarDailyPublishedFare(carId, true),
      carRateDifference: this.GetCarModifyRateDifference(carId),
      carModifyAverageRateDifference: this.GetCarModifyAverageRateDifference(carId),
      preferences: selectedCar.preferences ? this.getPreferences(selectedCar.preferences) : [],
      engineType: selectedCar?.carSpec?.engineType ? EngineType[selectedCar.carSpec.engineType] : -1,
      rewardPointsEarned: [], // reward points information is not available
    };
  }

  public GetCarCO2EmissionValue(carId: string): number {
    const selectedCar = this.getSelectedCar(carId);
    return selectedCar?.co2Emission?.co2EmissionValue ?? 0;
  }

  private getDistance(length: LengthV2 | undefined): Length | undefined {
    if (!length) {
      return undefined;
    }

    return {
      unit: length.unit,
      length: length.length,
    };
  }

  // verify
  private getVendorInfo(vendor: VendorV2 | undefined): Vendor {
    if (!vendor) {
      return {
        code: '',
        name: '',
        email: '',
      };
    }

    const { code, name, email, phone = {} } = vendor;

    return {
      code: code ?? '',
      name,
      email,
      phone: {
        type: phone.type ? PhoneNumberTypeEnum[phone.type] : PhoneNumberTypeEnum.UNKNOWN_TYPE,
        isoCountryCode: phone.isoCountryCode ?? '',
        countryCode: phone.countryCode ?? 0,
        nationalNumber: phone.nationalNumber ?? 0,
        extension: phone.extension ?? '',
        italianLeadingZero: phone.italianLeadingZero ?? false,
        numberOfLeadingZeros: phone.numberOfLeadingZeros ?? 0,
        rawInput: phone.rawInput ?? '',
        countryCodeSource: phone.countryCodeSource
          ? PhoneNumberCountryCodeSourceEnum[phone.countryCodeSource]
          : PhoneNumberCountryCodeSourceEnum.UNSPECIFIED,
        preferredDomesticCarrierCode: phone.preferredDomesticCarrierCode ?? '',
      },
    };
  }

  private getAmenitiesInfo(amenities: CarAmenities | undefined): Amenities {
    return {
      numSeatBelts: amenities?.numSeatBelts || 0,
      numLargeBags: amenities?.numLargeBags || 0,
      numSmallBags: amenities?.numSmallBags || 0,
      numSeats: amenities?.numSeats || 0,
      numDoors: amenities?.numDoors || 0,
    };
  }

  private getCarExtras(carExtras: CarExta[] | undefined): CarExtra[] {
    if (!carExtras) {
      return [];
    }

    return carExtras.map((carExtra) => {
      const result: CarExtra = {
        type:
          carExtra.carExtraType && carExtra.carExtraType in mapCarExtraTypeV2toV1
            ? mapCarExtraTypeV2toV1[carExtra.carExtraType]
            : CarExtraType.UNKNOWN_TYPE,
        rateUnit: carExtra.carExtraRateUnit
          ? CarExtraRateUnit[carExtra.carExtraRateUnit]
          : CarExtraRateUnit.UNKNOWN_RATE_UNIT,
        amount: MoneyUtil.convertV2MoneyToMoneyV1(carExtra.amount),
        text: '', // not available
      };
      return result;
    });
  }

  private getLocation(carLocation: CarLocationDetails | undefined): Location | undefined {
    if (!carLocation || !carLocation.counterLocation) {
      return undefined;
    }

    const location: Location = {
      counterLocation: CounterLocation.UNRECOGNIZED,
      address: this.getAddress(carLocation.address),
      coordinates: carLocation.coordinates
        ? {
            latitude: carLocation.coordinates.latitude,
            longitude: carLocation.coordinates.longitude,
          }
        : undefined,
      operatingSchedule: this.getOperatingSchedule(carLocation.operatingSchedule),
      contactInfo: carLocation.contactInfo
        ? {
            phone: carLocation.contactInfo.phone || [],
            fax: carLocation.contactInfo.fax || [],
            email: carLocation.contactInfo.email || [],
            emergencyPhone: carLocation.contactInfo.emergencyPhone || [],
          }
        : undefined,
      weeklyOperatingSchedule: carLocation.weeklyOperatingSchedule
        ? carLocation.weeklyOperatingSchedule.map((schedule) => ({
            interval: schedule.interval ? [{ min: schedule.interval.min, max: schedule.interval.max }] : [],
            dayOfWeek: schedule.dayOfWeek || '',
          }))
        : undefined,
    };

    return location;
  }

  private getAddress(address: PostalAddressV2 | undefined): PostalAddress | undefined {
    if (!address) return undefined;

    return {
      revision: address.revision || 0,
      regionCode: address.regionCode,
      languageCode: address.languageCode || '',
      postalCode: address.postalCode || '',
      sortingCode: address.sortingCode || '',
      administrativeArea: address.administrativeArea || '',
      locality: address.locality || '',
      sublocality: address.sublocality || '',
      addressLines: address.addressLines,
      recipients: address.recipients || [],
      organization: address.organization || '',
      isDefault: address.isDefault || false,
      description: address.description || '',
      timezone: address.timezone,
      continentCode: address.continentCode,
    };
  }

  private getOperatingSchedule(operatingSchedule: OperatingScheduleV2 | undefined): OperatingSchedule | undefined {
    if (!operatingSchedule || !operatingSchedule.interval || !operatingSchedule.dayOfWeek) {
      return undefined;
    }

    return {
      dayOfWeek: operatingSchedule.dayOfWeek,
      interval: [{ min: operatingSchedule.interval.min, max: operatingSchedule.interval.max }],
    };
  }

  private getPreferences(preferences: PreferenceV2[]): Preference[] {
    return preferences.map((preference) => ({
      preferredType: PreferredType[preference.preferredType],
      blockedReason: preference.blockedReason ?? '',
      label: preference.label ?? '',
    }));
  }

  // verify logic
  private getPolicyInfo(policyInformation: PnrPolicyInfo): PolicyInfo | undefined {
    const firstPolicy = first(policyInformation.policies);
    let isSoftApprovalRequired = false;
    let isHardApprovalRequired = false;

    const policyInfo: PolicyInfo = {
      violationMessage: '', // deprecated
      flagMessage: '', // deprecated
      policyType: firstPolicy?.policyType ? PolicyType[firstPolicy.policyType] : PolicyType.UNKNOWN_TYPE,
      policyName: firstPolicy?.policyName ?? '',
      ruleResultInfos:
        policyInformation.ruleResultInfos?.map((ruleResultInfo) => {
          return {
            violationInfos:
              ruleResultInfo.violationInfos?.map((violation) => ({
                predicateString: violation.predicateString ? violation.predicateString : '',
                expectedValue: violation.expectedValue,
                actualValue: violation.actualValue,
                predicate: violation.predicate ? Predicate[violation.predicate] : Predicate.UNKNOWN_PREDICATE_STRING, // verify
              })) ?? [],
            subViolationInfos:
              ruleResultInfo.subViolationInfos?.map((violation) => ({
                predicateString: violation.predicateString ? violation.predicateString : '',
                expectedValue: violation.expectedValue,
                actualValue: violation.actualValue,
                predicate: violation.predicate ? Predicate[violation.predicate] : Predicate.UNKNOWN_PREDICATE_STRING, // verify
              })) ?? [],
            actions:
              ruleResultInfo.actions?.map((action) => {
                if ('alertOnSelection' in action && action.alertOnSelection?.message) {
                  return { alertOnSelection: { message: action.alertOnSelection.message } };
                }
                if ('flag' in action && action.flag?.message) {
                  return { flag: { message: action.flag.message } };
                }
                if ('hide' in action) {
                  return { hide: action.hide };
                }
                if ('preventBooking' in action && action.preventBooking?.prevent && action.preventBooking?.reason) {
                  const { prevent, reason } = action.preventBooking; // double check
                  return { preventBooking: { prevent, reason } };
                }
                if ('takeApproval' in action && !!action.takeApproval) {
                  const { numHierarchyLevels, positionTitles, userOrgIds, allRequired, hardApprovalRequired } =
                    action.takeApproval;
                  isSoftApprovalRequired = isSoftApprovalRequired || !hardApprovalRequired;
                  isHardApprovalRequired = isHardApprovalRequired || !!hardApprovalRequired;
                  return {
                    takeApproval: {
                      numHierarchyLevels: numHierarchyLevels || 0,
                      positionTitle: positionTitles || [],
                      userOrgIds: userOrgIds?.map(this.getUserOrgIdV1) ?? [],
                      allRequired: !!allRequired,
                      hardApprovalRequired: !!hardApprovalRequired,
                      defaultApprovers: [], // not present
                    },
                  };
                }
                throw new SpotnanaError('Invalid action type');
              }) ?? [],
          };
        }) ?? [],
      version: 0,
      approvalType: PolicyInfoApprovalType.UNKNOWN,
    };

    if (isHardApprovalRequired) {
      policyInfo.approvalType = PolicyInfoApprovalType.HARD_APPROVAL;
    } else if (isSoftApprovalRequired) {
      policyInfo.approvalType = PolicyInfoApprovalType.SOFT_APPROVAL;
    }

    return policyInfo;
  }

  private getUserOrgIdV1(userOrgId: UserOrgIdV2): UserOrgId {
    return {
      userId: {
        id: userOrgId.userId?.id || '',
      },
      organizationId: {
        id: userOrgId.organizationId?.id || '',
      },
      agencyId: '', // deprecated
      organizationAgencyId: {
        id: '', // deprecated
      },
      tmcBasicInfo: {
        contractingTmc: {
          id: userOrgId.tmcBasicInfo?.contractingTmc?.id,
          name: userOrgId.tmcBasicInfo?.contractingTmc?.name || '',
          logo: {
            url: userOrgId.tmcBasicInfo?.contractingTmc?.logo?.url,
            dimensions: {
              width: userOrgId.tmcBasicInfo?.contractingTmc?.logo?.dimensions?.width || 0,
              height: userOrgId.tmcBasicInfo?.contractingTmc?.logo?.dimensions?.height || 0,
            },
            data: userOrgId.tmcBasicInfo?.contractingTmc?.logo?.data,
          },
        },
        bookingTmc: {
          id: userOrgId.tmcBasicInfo?.contractingTmc?.id,
          name: userOrgId.tmcBasicInfo?.contractingTmc?.name || '',
          logo: {
            url: userOrgId.tmcBasicInfo?.bookingTmc?.logo?.url,
            dimensions: {
              width: userOrgId.tmcBasicInfo?.bookingTmc?.logo?.dimensions?.width || 0,
              height: userOrgId.tmcBasicInfo?.bookingTmc?.logo?.dimensions?.height || 0,
            },
            data: userOrgId.tmcBasicInfo?.bookingTmc?.logo?.data,
          },
        },
      },
    };
  }

  public GetSearchDateTime(): { pickup: string; drop: string } {
    const pickup = this.data.cars?.[0].pickupParams?.pickupTime?.iso8601 ?? '';
    const drop = this.data.cars?.[0].dropOffParams?.dropOffTime?.iso8601 ?? '';
    return { pickup, drop };
  }

  private getMoneyUtil(amount: Money | undefined): MoneyUtil {
    return amount ? MoneyUtil.parse(MoneyUtil.convertV2MoneyToMoneyV1(amount)) : MoneyUtil.parse(amount);
  }

  public GetApproximateTotalCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find(
      (component) => component.componentType === CarRateComponentType.ApproximateTotalPrice,
    )?.amount;
    return this.getMoneyUtil(amount);
  }

  private GetPublishedBaseCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find(
      (component) => component.componentType === CarRateComponentType.PublishedBaseFare,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  public GetCarModifyRateDifference(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find(
      (component) => component.componentType === CarRateComponentType.CarModifyRateDifference,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  public GetCarModifyAverageRateDifference(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find(
      (component) => component.componentType === CarRateComponentType.CarModifyAverageRateDifference,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  // TODO: Do not round down the fare
  public GetCarDailyFare(carId: string, excludeTaxes: boolean): MoneyUtil {
    const dailyCost = this.GetDailyCost(carId, excludeTaxes);
    const approxCost = this.GetApproximateTotalCost(carId);
    const totalNumberOfDays = this.GetCarBookingDaysCount();
    if (dailyCost.isZero()) {
      if (!totalNumberOfDays) return approxCost;
      const carDailyCost = Math.floor(approxCost.getAmount() / totalNumberOfDays);
      return MoneyUtil.create(carDailyCost, approxCost.getCurrency());
    }
    return dailyCost;
  }

  public GetCarDailyPublishedFare(carId: string, excludeTaxes: boolean): MoneyUtil {
    const dailyPublishedCost = this.GetPublishedDailyCost(carId, excludeTaxes);
    const publishedTotalCost = this.GetPublishedTotalCost(carId);
    const totalNumberOfDays = this.GetCarBookingDaysCount();
    if (dailyPublishedCost.isZero()) {
      if (!totalNumberOfDays) return publishedTotalCost;
      const carDailyCost = Math.floor(publishedTotalCost.getAmount() / totalNumberOfDays);
      return MoneyUtil.create(carDailyCost, publishedTotalCost.getCurrency());
    }
    return dailyPublishedCost;
  }

  private GetPublishedDailyCost(carId: string, excludeTaxes: boolean): MoneyUtil {
    const car = this.getSelectedCar(carId);

    const amount = car?.rateInfo?.carRateComponents?.find((component) =>
      excludeTaxes
        ? component.componentType === CarRateComponentType.PublishedDailyBaseFare
        : component.componentType === CarRateComponentType.PublishedDailyTotal,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  private GetDailyCost(carId: string, excludeTaxes: boolean): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find((component) =>
      excludeTaxes
        ? component.componentType === CarRateComponentType.ApproximateDailyAverageBase
        : component.componentType === CarRateComponentType.ApproximateDailyAverageTotal,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  private GetPublishedTaxCost(carId: string): MoneyUtil {
    const car = this.getSelectedCar(carId);
    const amount = car?.rateInfo?.carRateComponents?.find(
      (component) => component.componentType === CarRateComponentType.PublishedTotalTax,
    )?.amount;

    return this.getMoneyUtil(amount);
  }

  public GetPublishedTotalCost(carId: string): MoneyUtil {
    return this.GetPublishedBaseCost(carId).add(this.GetPublishedTaxCost(carId));
  }

  public GetPublishedSavingsCost(carId: string): MoneyUtil {
    const publishedCost = this.GetPublishedTotalCost(carId);
    const approximatePrice = this.GetApproximateTotalCost(carId);

    return publishedCost.isPositive()
      ? publishedCost.subtract(approximatePrice)
      : MoneyUtil.create(0, approximatePrice.getCurrency());
  }

  public GetFareType(carId: string): FareTypeEnum {
    const car = this.getSelectedCar(carId);
    return carRateDetailsFareTypeEnumToCommonFareType[car?.rateInfo?.fareType ?? CarRateDetailsFareTypeEnum.Unknown];
  }

  public GetCarRates(carId: string): ICarRate[] {
    const car = this.getSelectedCar(carId);
    const rates =
      car?.rateInfo?.carRateComponents?.forEach((component) => {
        return {
          label: underScoreToSpace(component.componentType),
          price: this.getMoneyUtil(component.amount),
          extraMileageCharge: this.getMoneyUtil(component.extraMileageCharge),
          mileageAllowance: {
            length: component?.mileageAllowance?.length ?? -1,
            unit: LengthUnitEnum[component?.mileageAllowance?.unit ?? -1],
          },
        };
      }) ?? [];
    return rates;
  }

  public GetCarRatesV2(carId: string): Record<string, ICarRate> {
    const car = this.getSelectedCar(carId);
    const rates: Record<string, ICarRate> = {};
    car?.rateInfo?.carRateComponents?.forEach((component) => {
      let key = RateComponentType.UNKNOWN_TYPE;
      if (component.componentType in RateComponentType) {
        key = RateComponentType[component.componentType as keyof typeof RateComponentType];
      }
      rates[key] = {
        label: underScoreToSpace(component.componentType),
        price: this.getMoneyUtil(component.amount),
        extraMileageCharge: this.getMoneyUtil(component.extraMileageCharge),
        mileageAllowance: {
          length: component?.mileageAllowance?.length ?? -1,
          unit: LengthUnitEnum[component?.mileageAllowance?.unit ?? -1],
        },
      };
    });
    return rates;
  }

  public GetCarExtraRates(carId: string): Record<string, ICarRate> {
    const selectedCar = this.getSelectedCar(carId);
    const carExtras = selectedCar?.rateInfo ? this.getCarExtras(selectedCar.rateInfo.carExtras) : [];
    const rates: Record<string, ICarRate> = {};
    carExtras.forEach((component) => {
      rates[component.type] = {
        label: carExtrasLabelMap[component.type] ?? carExtrasLabelMap[CarExtraTypeEnum.UNKNOWN_TYPE],
        price: MoneyUtil.parse(component?.amount),
        extraMileageCharge: MoneyUtil.zeroMoney(),
        mileageAllowance: {
          length: -1,
          unit: LengthUnitEnum[-1],
        },
      };
    });
    return rates;
  }

  public GetCarBookingDaysCount(): number {
    const { pickup, drop } = this.GetSearchDateTime();
    return CarSearchResponseManagerV2.GetCarBookingDaysCount(pickup, drop);
  }

  static GetCarBookingDaysCount(pickupIso8601?: string, dropIso8601?: string): number {
    if (!pickupIso8601 || !dropIso8601) {
      throw new SpotnanaError('Both pickup and drop date-times should be available!');
    }

    return Math.ceil(
      getDiff(pickupIso8601, dropIso8601, 'days', dateFormats.ISO_WO_SECONDS, dateFormats.ISO_WO_SECONDS, true),
    );
  }

  static getLocationDetails(location?: CarLocationDetails | undefined): ILocationDetails {
    if (location) {
      return {
        fullAddress: getLocationFullAddressV2(location?.address),
        shortAddress: getLocationShortAddressV2(location?.address),
        coordinates: location.coordinates,
        operatingTimes: location.operatingSchedule?.interval
          ? [
              {
                min: location.operatingSchedule.interval.min,
                max: location.operatingSchedule.interval.max,
              },
            ]
          : [],
        // counterLocation: location.counterLocation ? `${location.counterLocation}` : '',
        counterLocation: '',
        weeklyOperatingSchedule:
          location?.weeklyOperatingSchedule?.map((schedule) => ({
            interval: [
              {
                min: schedule.interval.min,
                max: schedule.interval.max,
              },
            ],
            dayOfWeek: schedule.dayOfWeek ?? '',
          })) ?? undefined,
        contactInfo: location.contactInfo ?? undefined,
      };
    }
    return {
      fullAddress: '',
      shortAddress: '',
      operatingTimes: [],
      counterLocation: '',
      weeklyOperatingSchedule: undefined,
      contactInfo: undefined,
    };
  }

  static getLocationDetailsV2(location?: CarLocationDetails): ICarLocationDetails {
    if (location) {
      return {
        fullAddress: getLocationFullAddressV2(location?.address),
        shortAddress: getLocationShortAddressV2(location?.address),
        coordinates: location?.coordinates,
        operatingTimes: location?.operatingSchedule ?? undefined,
        counterLocation: location?.counterLocation ?? undefined,
        weeklyOperatingSchedule: location?.weeklyOperatingSchedule ?? undefined,
        contactInfo: location?.contactInfo ?? undefined,
      };
    }
    return {
      fullAddress: '',
      shortAddress: '',
      operatingTimes: undefined,
      counterLocation: '',
      weeklyOperatingSchedule: undefined,
      contactInfo: undefined,
    };
  }

  private getThumbnailImage(carId: string): IThumbnailImage {
    const car = this.getSelectedCar(carId);
    const imageGroup = first(car?.carSpec?.imageGroups);
    const image = first(imageGroup?.images);

    return {
      image: {
        data: image?.data,
        dimensions: {
          width: image?.dimensions?.width || 0,
          height: image?.dimensions?.height || 0,
        },
        url: image?.url,
      },
      caption: imageGroup?.caption,
    };
  }

  private getPolicyDetails(carId: string): IPolicyDetails {
    const car = this.getSelectedCar(carId);
    const firstCarRateComponent = first(car?.rateInfo?.carRateComponents);
    const mileageCharge = firstCarRateComponent?.extraMileageCharge ?? { amount: 0, currencyCode: '' };
    const mileage = mileageCharge.amount === 0 ? defineMessage('Unlimited mileage') : '';
    const payPolicy = car?.rateInfo?.rateType ?? CarRateType.Unknown;
    let payAt = '';
    switch (payPolicy) {
      case CarRateType.Prepaid:
        payAt = defineMessage('Prepaid');
        break;
      case CarRateType.Guaranteed:
        payAt = defineMessage('Pay at pick-up');
        break;
      case CarRateType.Unknown:
        payAt = defineMessage('NA');
        break;
      default:
        break;
    }
    const rates = this.GetCarRates(carId);
    const cancellationPolicies = car?.cancellationPolicy ?? [];
    const cancellation = cancellationPolicies.map((policy) => ({
      amount:
        policy.penalty && 'carCancellationAmount' in policy.penalty && policy.penalty.carCancellationAmount
          ? this.getMoneyUtil(policy.penalty.carCancellationAmount)
          : undefined,
      percent:
        policy.penalty && 'carCancellationAmountPercent' in policy.penalty
          ? policy.penalty.carCancellationAmountPercent
          : undefined,
      hoursBefore:
        policy.deadline && 'carCancellationDeadlineHoursBefore' in policy.deadline
          ? policy.deadline.carCancellationDeadlineHoursBefore
          : undefined,
      absoluteDeadline:
        policy.deadline && 'carCancellationDeadlineAbsolute' in policy.deadline
          ? policy.deadline.carCancellationDeadlineAbsolute
          : undefined,
    }));
    const refundable = car?.rateInfo?.refundable ? RateRefundable[car?.rateInfo?.refundable] : undefined;
    return {
      mileage,
      payAt,
      cancellation,
      rates,
      refundable,
    };
  }

  private getTransmission(carId: string): ITransmissionType {
    const car = this.getSelectedCar(carId);
    const transmission = car?.carSpec?.transmission ?? TransmissionV2.UnknownTransmission;
    switch (transmission) {
      case TransmissionV2.AutoUnspecifiedDrive:
      case TransmissionV2.Auto4Wd:
      case TransmissionV2.AutoAwd:
        return ITransmissionType.AUTO;
      case TransmissionV2.Manual4Wd:
      case TransmissionV2.ManualAwd:
      case TransmissionV2.ManualUnspecifiedDrive:
        return ITransmissionType.MANUAL;
      default:
        break;
    }
    return ITransmissionType.UNKNOWN;
  }

  private getCarId(carIndex: number): string {
    return this.data?.cars?.[carIndex].carId ?? '';
  }

  public getShowOnlyBaseFare(): boolean {
    return this.data.metadata?.showOnlyBaseFare ?? false;
  }

  // TODO: Update
  static isSoftApprovalRequired = (_policyInfo: PolicyInfoV2 | undefined): boolean => true;

  static isHardApprovalRequired = (_policyInfo: PolicyInfoV2 | undefined): boolean => true;

  public getRentalInclusions(carId: string) {
    const car = this.getSelectedCar(carId);

    const payPolicy = car?.rateInfo?.rateType ?? CarRateType.Unknown;
    const isPayAtPickup = payPolicy === CarRateType.Guaranteed || payPolicy === CarRateType.Unknown;

    const firstCarRateComponent = first(car?.rateInfo?.carRateComponents);
    const mileageCharge = firstCarRateComponent?.extraMileageCharge ?? { amount: 0, currencyCode: '' };
    const isUnlimitedMileageIncluded = mileageCharge.amount === 0;

    const isRefundable = car?.rateInfo?.refundable === CarRefundableType.Refundable;

    // const isInsuranceIncluded = ??;

    return [
      { type: 'PAY_AT_PICKUP', value: isPayAtPickup, label: defineCommonMessage('Pay at pick-up') },
      { type: 'UNLIMITED_MILEAGE', value: isUnlimitedMileageIncluded, label: defineCommonMessage('Unlimited mileage') },
      { type: 'REFUNDABLE', value: isRefundable, label: defineCommonMessage('Free cancellation') },
    ] as const;
  }

  public IsVirtualCardRate(carId: string): boolean {
    const car = this.getSelectedCar(carId);
    return car?.rateInfo?.allowedPaymentTypes?.includes(PaymentSourceType.VirtualCard) ?? false;
  }
}
