import type { UserActionUpdateResponse } from '@spotnana/types/openapi/models/user-action-update-response';

export enum IncompleteUserActionName {
  PRIVACY_CONSENT = 'PRIVACY_CONSENT',
  DATA_SYNC = 'DATA_SYNC',
  ONBOARDING = 'ONBOARDING',
  MARRIOTT_BONVOY_LINK = 'MARRIOTT_BONVOY_LINK',
  MARRIOTT_MAILING_LIST_CONSENT = 'MARRIOTT_MAILING_LIST_CONSENT',
}

export interface IncompleteUserAction {
  actionName: IncompleteUserActionName;
  displayConfig: {
    kvs: { key: string; value: string }[];
  };
}

export enum UserActionResponse {
  ACCEPT = 'yes',
  DECLINE = 'no',
}

export type UserOperation = keyof typeof UserActionResponse;

/**
 * Interface representing the properties of a user action.
 */
export interface IUserActionProps {
  /**
   * The name of the action.
   */
  actionName: IncompleteUserActionName;
  /**
   * The display configuration for the action.
   */
  displayConfig: Record<string, string>;

  /**
   * Callback to update the user action.
   * @param input
   * @returns
   */
  onUpdateUserAction: (input: `${UserActionResponse}`) => Promise<UserActionUpdateResponse>;

  /**
   * Callback to handle the completion of the user action.
   * Action components should call this to signal that the action is complete, and go to next action
   * Returns a Promise, which resolves when next action is ready to be displayed.
   */
  onActionComplete: () => Promise<void>;
}

export type BaseDisplayConfig = {
  headerText: string;
  contentHtml: string;
  acceptBtnText: string;
  declineBtnText: string;
};

export type DataSyncDisplayConfig = BaseDisplayConfig & {
  // logo config
  showCompanyLogo: string;
  showPoweredBySpotnana: string;
  // accept popup config
  acceptPopupHeaderText: string;
  acceptPopupContentHtml: string;
  acceptPopupProceedText: string;
  acceptPopupDenyText: string;
  // decline popup config
  declinePopupHeaderText: string;
  declinePopupContentHtml: string;
  declinePopupProceedText: string;
  declinePopupDenyText: string;
  // sync popup config
  syncInProgressText: string;
  syncFailedText: string;
  syncFailedProceedText: string;
  syncSucceededText: string;
};
