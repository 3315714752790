import { ROUTE_PATHS } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Typography } from '@spotnana/blocks/src/Typography';
import { debug_tool_link_container } from '../DebugTool/styles';

export function ApiClient() {
  const history = useHistory();
  const { t: tt } = useTranslation('WEB');
  return (
    <button
      type="button"
      css={debug_tool_link_container}
      onClick={() => {
        history.push(ROUTE_PATHS.API_CLIENT);
      }}
    >
      <Typography variant="body1">{tt('API client')}</Typography>
    </button>
  );
}
