import { useMutation, useQuery } from 'react-query';
import type { UseQueryOptions } from 'react-query';

import type { EntityId } from '@spotnana/types/openapi/models/entity-id';
import type { CompanyEnrollment } from '@spotnana/types/openapi/models/company-enrollment';
import type { CompanyEnrollmentRef } from '@spotnana/types/openapi/models/company-enrollment-ref';
import type {
  ListOfficesV2Request,
  ListOfficesV2Response,
  ListLegalEntityInfosRequest,
  ListLegalEntityInfosResponse,
} from '@spotnana/types/openapi/models';
import type { ArrayOfReference } from '@spotnana/types/openapi/models/array-of-reference';
import type { Question } from '@spotnana/types/openapi/models/question';
import type { ReadCompanyResponse } from '../../types/api/v2/obt/model/read-company-response';
import type { Company } from '../../types/api/v2/obt/model/company';
import type { CompanyBasicInfo } from '../../types/api/v2/obt/model/company-basic-info';
import type { ListOfficeResponse } from '../../types/api/v2/obt/model/list-office-response';
import type { LegalEntity } from '../../types/api/v2/obt/model/legal-entity';
import type { GetCustomFieldOptionsResponse } from '../../types/api/v2/obt/model/get-custom-field-options-response';
import type { PaginationRequestParams } from '../../types/api/v2/obt/model/pagination-request-params';
import type { ListOfficePaginatedResponse } from '../../types/api/v2/obt/model/list-office-paginated-response';

import type {
  CompanyId,
  CreateSafetyRestrictionsRequest,
  CustomFieldOptionsParam,
  FeatureConfigs,
  GetSafetyRestrictionsResponse,
  SpotnanaQueryMutationResult,
  SpotnanaQueryOptions,
  SpotnanaQueryResult,
} from '../../types';
import type { IApiRequestSubType } from '../../api/apiTypes';
import SpotnanaError from '../../api/SpotnanaError';
import api from '../../api';
import { defaultQueryClient } from '../defaultQueryClient';
import { stringifyParams } from '../../utils';
import type { GetCompanyWorkerTypeListResponse } from '../../types/api/v2/obt/model/get-company-worker-type-list-response';
import type { GetCompanyCountryListResponse } from '../../types/api/v2/obt/model/get-company-country-list-response';
import type { GetCompanyAccountingCodeListResponse } from '../../types/api/v2/obt/model/get-company-accounting-code-list-response';
import type { UpdateFeaturesRequest } from '../../types/api/v2/obt/model/update-features-request';
import type { ListLegalEntityPaginatedResponse } from '../../types/api/v2/obt/model/list-legal-entity-paginated-response';
import type { CreateCompanyRequest } from '../../types/api/v2/obt/model/create-company-request';

interface UpdateCompanyFeaturesRequestProps {
  companyId: string;
  request: UpdateFeaturesRequest;
}

const getCompanyKey = (companyId: string): string => `/v2/company/${companyId}`;

export const fetchCompany = async (companyId: string): Promise<ReadCompanyResponse> => {
  try {
    return (await api('GET', 'sharedCompany', {
      urlParam: `/${companyId}`,
    })) as ReadCompanyResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchCompany = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<ReadCompanyResponse> =>
  useQuery<ReadCompanyResponse, SpotnanaError>(
    getCompanyKey(companyId as string),
    () => fetchCompany(companyId as string),
    {
      enabled: enabled && !!companyId,
    },
  );

const getCompanyQueryKey = (companyId: string): string => `/v2/companies/${companyId}`;

export const getCompany = async (companyId: string): Promise<Company> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}`,
    })) as Company;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

/**
 * Fetch company details with V2 endpoint
 */
export const useGetCompany = (companyId: string | undefined, enabled = true): SpotnanaQueryResult<Company> =>
  useQuery<Company, SpotnanaError>(getCompanyQueryKey(companyId as string), () => getCompany(companyId as string), {
    enabled: enabled && !!companyId,
  });

const fetchCompanyBasicInfo = async (companyId: string, subType: IApiRequestSubType): Promise<CompanyBasicInfo> => {
  try {
    return (await api(
      'GET',
      'companyBaseUrl',
      {
        urlParam: `/${companyId}/basic-info`,
      },
      { subType },
    )) as CompanyBasicInfo;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const getCompanyBasicInfoKey = (companyId: string, subType: IApiRequestSubType): string =>
  `/v2/companyBasicInfo/${companyId}/${subType}`;

export const useFetchCompanyBasicInfo = (
  companyId: string | undefined,
  enabled = true,
  subType: IApiRequestSubType,
): SpotnanaQueryResult<CompanyBasicInfo> =>
  useQuery<CompanyBasicInfo, SpotnanaError>(
    getCompanyBasicInfoKey(companyId as string, subType),
    () => fetchCompanyBasicInfo(companyId as string, subType),
    {
      enabled: enabled && !!companyId,
    },
  );

const getMultipleCompanyBasicInfoKey = (
  companyIds: string[],
  subType: IApiRequestSubType,
): ['company-details-multiple', string] => [
  'company-details-multiple',
  companyIds.length ? [...companyIds, subType].sort().join(',') : 'empty-list',
];

interface UseFetchMultipleCompanyBasicInfoProps {
  companyIds: string[];
  subType: IApiRequestSubType;
}

const fetchMultipleCompanyBasicInfo = async (
  companyIds: string[],
  subType: IApiRequestSubType,
): Promise<CompanyBasicInfo[]> => {
  return Promise.all(
    companyIds.map((companyId) =>
      defaultQueryClient.fetchQuery({
        queryKey: getCompanyBasicInfoKey(companyId, subType),
        queryFn: () => fetchCompanyBasicInfo(companyId, subType),
      }),
    ),
  );
};

export const useFetchMultipleCompanyBasicInfo = (
  { companyIds, subType }: UseFetchMultipleCompanyBasicInfoProps,
  options?: UseQueryOptions<CompanyBasicInfo[], SpotnanaError>,
): SpotnanaQueryResult<CompanyBasicInfo[]> =>
  useQuery({
    ...options,
    queryKey: getMultipleCompanyBasicInfoKey(companyIds, subType),
    queryFn: () => fetchMultipleCompanyBasicInfo(companyIds, subType),
  });

export const createCompany = async (requestBody: CreateCompanyRequest): Promise<EntityId> => {
  try {
    const data = await api('POST', 'companyBaseUrl', {
      data: requestBody,
    });

    return data as EntityId;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useCreateCompanyMutation = (): SpotnanaQueryMutationResult<EntityId, CreateCompanyRequest> =>
  useMutation((request: CreateCompanyRequest) => createCompany(request));

export const updateCompany = async (companyId: string | undefined, requestBody: Company): Promise<void> => {
  try {
    await api('PUT', 'companyBaseUrl', {
      urlParam: `/${companyId}`,
      data: requestBody,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateCompanyMutation = (companyId: string | undefined): SpotnanaQueryMutationResult<void, Company> =>
  useMutation((request: Company) => updateCompany(companyId, request), {
    onSuccess: () => {
      defaultQueryClient.invalidateQueries(getCompanyQueryKey(companyId || ''));
    },
  });

const getCompanyLegalEntityKey = (organizationId: string, legalEntityId: string): string =>
  `/v2/companies/${organizationId}/legal-entities/${legalEntityId}`;

export const fetchCompanyLegalEntity = async (companyId: string, legalEntity: string): Promise<LegalEntity> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/legal-entities/${legalEntity}`,
    })) as LegalEntity;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};
export const useFetchCompanyLegalEntity = (
  companyId: string,
  legalEntityId: string,
  options: {
    enabled: boolean;
    refetchOnMount?: boolean | 'always';
  } = {
    enabled: true,
  },
): SpotnanaQueryResult<LegalEntity> =>
  useQuery<LegalEntity, SpotnanaError>(
    getCompanyLegalEntityKey(companyId, legalEntityId),
    () => fetchCompanyLegalEntity(companyId, legalEntityId),
    options,
  );

const fetchCompanyLegalEntitiesWithPagination = async (
  companyId: string,
  offset: number,
  limit: number,
): Promise<ListLegalEntityPaginatedResponse> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/legal-entity-list?offset=${offset}&limit=${limit}`,
    })) as ListLegalEntityPaginatedResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const getCompanyLegalEntitiesWithPaginationKeys = (companyId: string, offset: number, limit: number): string[] => [
  'v2',
  'legal-entities',
  companyId,
  offset.toString(),
  limit.toString(),
];

export const useFetchCompanyLegalEntitiesWithPagination = (
  companyId: string,
  offset: number,
  limit: number,
  enabled = true,
): SpotnanaQueryResult<ListLegalEntityPaginatedResponse> =>
  useQuery<ListLegalEntityPaginatedResponse, SpotnanaError>(
    getCompanyLegalEntitiesWithPaginationKeys(companyId, offset, limit),
    () => fetchCompanyLegalEntitiesWithPagination(companyId, offset, limit),
    {
      enabled: enabled && !!companyId,
      keepPreviousData: true,
    },
  );

const fetchLegalEntitiesV2 = async (
  companyId: string,
  request: ListLegalEntityInfosRequest,
): Promise<ListLegalEntityInfosResponse> => {
  try {
    return (await api('POST', 'companyBaseUrl', {
      urlParam: `/${companyId}/legal-entity-list`,
      data: request,
    })) as ListLegalEntityInfosResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const fetchLegalEntitiesV2Keys = (companyId: string, request: ListLegalEntityInfosRequest): string[] => [
  'v2',
  'legal-entities',
  companyId,
  JSON.stringify(request),
];

export const UseFetchLegalEntitiesV2 = (
  companyId: string,
  request: ListLegalEntityInfosRequest,
  enabled = true,
): SpotnanaQueryResult<ListLegalEntityInfosResponse> => {
  const queryKeys = fetchLegalEntitiesV2Keys(companyId, request);
  const fetchLegalEntities = () => fetchLegalEntitiesV2(companyId, request);

  return useQuery<ListLegalEntityInfosResponse, SpotnanaError>(queryKeys, fetchLegalEntities, {
    enabled: enabled && !!companyId,
    keepPreviousData: true,
  });
};

const getCompanyFeaturesKey = (companyId: string | undefined): string => `/v2/companies/${companyId}/features`;

const fetchCompanyFeatures = async (companyId: string | undefined): Promise<FeatureConfigs> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/features`,
    })) as FeatureConfigs;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useFetchCompanyFeatures = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<FeatureConfigs> =>
  useQuery<FeatureConfigs, SpotnanaError>(getCompanyFeaturesKey(companyId), () => fetchCompanyFeatures(companyId), {
    cacheTime: 0,
    enabled: !!companyId && enabled,
  });

const updateCompanyFeatures = async (companyId: string, request: UpdateFeaturesRequest): Promise<void> => {
  try {
    await api('PATCH', 'companyBaseUrl', {
      urlParam: `/${companyId}/features`,
      data: request,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateCompanyFeaturesMutation = (): SpotnanaQueryMutationResult<
  void,
  UpdateCompanyFeaturesRequestProps
> =>
  useMutation(
    ({ companyId, request }: UpdateCompanyFeaturesRequestProps) => updateCompanyFeatures(companyId, request),
    {
      onSuccess: (_res, { companyId }) => {
        defaultQueryClient.invalidateQueries(getCompanyFeaturesKey(companyId));
        defaultQueryClient.invalidateQueries(getCompanyBasicInfoKey(companyId, undefined));
      },
    },
  );

const fetchOfficeList = async (companyId: string): Promise<ListOfficeResponse> => {
  try {
    return (await api('GET', 'createOrganization', {
      urlParam: `/${companyId}/list-office`,
    })) as ListOfficeResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const getOfficeListKeys = (companyId: string): string[] => ['v2', 'office-list', companyId];

export const useFetchOfficeList = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<ListOfficeResponse> =>
  useQuery<ListOfficeResponse, SpotnanaError>(
    getOfficeListKeys(companyId ?? ''),
    () => fetchOfficeList(companyId ?? ''),
    {
      enabled: enabled && !!companyId,
    },
  );

const fetchCompanyQuestions = async (companyId: string): Promise<ArrayOfReference> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/questions`,
    })) as ArrayOfReference;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const getCompanyQuestionsKeys = (companyId: string): string[] => ['v2', 'questions', companyId];

export const useFetchCompanyQuestions = (companyId: string): SpotnanaQueryResult<ArrayOfReference> =>
  useQuery<ArrayOfReference, SpotnanaError>(
    getCompanyQuestionsKeys(companyId),
    () => fetchCompanyQuestions(companyId),
    { cacheTime: 0 },
  );

const fetchCompanyQuestionFromId = async (companyId: string, questionId: string): Promise<Question> => {
  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${companyId}/questions/${questionId}`,
    })) as Question;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const getCompanyQuestionFromIdKeys = (companyId: string, questionId: string): string[] => [
  'v2',
  'questions',
  companyId,
  questionId,
];

export const useFetchCompanyQuestionDetails = (
  companyId: string | undefined,
  questionId: string,
): SpotnanaQueryResult<Question> =>
  useQuery<Question, SpotnanaError>(
    getCompanyQuestionFromIdKeys(companyId ?? '', questionId),
    () => fetchCompanyQuestionFromId(companyId ?? '', questionId),
    { enabled: !!companyId && !!questionId },
  );

const fetchOfficeListWithPagination = async (
  companyId: string,
  paginationParams: PaginationRequestParams,
): Promise<ListOfficePaginatedResponse> => {
  try {
    return (await api('POST', 'createOrganization', {
      urlParam: `/${companyId}/offices`,
      data: { paginationParams },
    })) as ListOfficePaginatedResponse;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const getOfficeListWithPaginationKeys = (companyId: string, paginationParams: PaginationRequestParams): string[] => [
  'v2',
  'office-list',
  companyId,
  JSON.stringify(paginationParams),
];

export const useFetchOfficeListWithPagination = (
  companyId: string,
  paginationParams: PaginationRequestParams,
  enabled = true,
): SpotnanaQueryResult<ListOfficePaginatedResponse> =>
  useQuery<ListOfficePaginatedResponse, SpotnanaError>(
    getOfficeListWithPaginationKeys(companyId ?? '', paginationParams),
    () => fetchOfficeListWithPagination(companyId ?? '', paginationParams),
    {
      enabled: enabled && !!companyId,
      keepPreviousData: true,
    },
  );

const getOfficeListV2 = async (companyId: string, request: ListOfficesV2Request): Promise<ListOfficesV2Response> => {
  try {
    const response = await api('POST', 'companyBaseUrl', {
      urlParam: `/${companyId}/office-list`,
      data: request,
    });
    return response as ListOfficesV2Response;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

const getOfficeListV2Keys = (companyId: string, request: ListOfficesV2Request): string[] => [
  'v2',
  'office-list',
  companyId,
  JSON.stringify(request),
];

export const useFetchOfficeListV2 = (
  companyId: string,
  request: ListOfficesV2Request,
  enabled = true,
  keepPreviousData = true,
): SpotnanaQueryResult<ListOfficesV2Response> => {
  const queryKeys = getOfficeListV2Keys(companyId, request);
  const fetchOfficeListV2 = () => getOfficeListV2(companyId, request);

  return useQuery<ListOfficesV2Response, SpotnanaError>(queryKeys, fetchOfficeListV2, {
    enabled: enabled && !!companyId,
    keepPreviousData,
  });
};

export const fetchCountryRestriction = async (companyId: string): Promise<GetSafetyRestrictionsResponse> => {
  const result = await api('GET', 'companyBaseUrl', {
    urlParam: `/${companyId}/safety/location-restrictions`,
  });
  return result as GetSafetyRestrictionsResponse;
};

const getCountryRestrictionsQueryKey = (companyId: string): string =>
  `/v2/companies/${companyId}/safety/location-restrictions`;

export const useGetCountryRestrictionsQuery = (
  companyId: string,
  options?: SpotnanaQueryOptions<GetSafetyRestrictionsResponse, ReturnType<typeof getCountryRestrictionsQueryKey>>,
): SpotnanaQueryResult<GetSafetyRestrictionsResponse> =>
  useQuery(getCountryRestrictionsQueryKey(companyId), () => fetchCountryRestriction(companyId), options);

const updateCountryRestrictions = async (
  companyId: string,
  request: CreateSafetyRestrictionsRequest,
): Promise<void> => {
  await api('PUT', 'companyBaseUrl', {
    urlParam: `/${companyId}/safety/location-restrictions`,
    data: request,
  });
};

export const useUpdateCountryRestrictionsMutation = (
  companyId: string,
): SpotnanaQueryMutationResult<void, CreateSafetyRestrictionsRequest> =>
  useMutation(
    (requestBody: CreateSafetyRestrictionsRequest) =>
      companyId
        ? updateCountryRestrictions(companyId, requestBody)
        : Promise.reject(new SpotnanaError('Empty company id')),
    {
      onSuccess: () => {
        if (companyId) {
          defaultQueryClient.invalidateQueries(getCountryRestrictionsQueryKey(companyId));
        }
      },
    },
  );

const deleteCountryRestrictions = async (companyId: string, countryCode: string): Promise<void> => {
  await api('DELETE', 'companyBaseUrl', {
    urlParam: `/${companyId}/safety/location-restrictions/${countryCode}`,
  });
};

export const useDeleteCountryRestrictionsMutation = (companyId: string): SpotnanaQueryMutationResult<void, string> =>
  useMutation(
    (requestBody: string) =>
      companyId
        ? deleteCountryRestrictions(companyId, requestBody)
        : Promise.reject(new SpotnanaError('Empty company id')),
    {
      onSuccess: () => {
        if (companyId) {
          defaultQueryClient.invalidateQueries(getCountryRestrictionsQueryKey(companyId));
        }
      },
    },
  );

interface GetCustomFieldOptionsQueryParams {
  customFieldId?: string;
  optionsPreviewParam?: CustomFieldOptionsParam;
  companyId: string;
  searchText?: string;
  pageNumber?: string;
  pageSize?: string;
}

const getCustomFieldOptionsQueryKey = (
  request: GetCustomFieldOptionsQueryParams | undefined,
): [string, string, string, string, string] => [
  `/v2/companies/${request?.companyId}/custom-field-options`,
  request?.optionsPreviewParam ?? '',
  request?.customFieldId ?? '',
  request?.searchText ?? '',
  request?.pageNumber ?? '',
];

const getCustomFieldOptionsQuery = async (
  request: GetCustomFieldOptionsQueryParams | undefined,
): Promise<GetCustomFieldOptionsResponse> => {
  if (!request) {
    return {
      options: [],
      paginationParams: {},
    };
  }

  try {
    return (await api('GET', 'companyBaseUrl', {
      urlParam: `/${request.companyId}/custom-field-options?${stringifyParams({
        optionsPreviewParam: request.optionsPreviewParam,
        pageNumber: request.pageNumber,
        pageSize: request.pageSize,
        searchText: request.searchText,
        ...(request.customFieldId ? { customFieldId: request.customFieldId } : {}),
      })}`,
    })) as GetCustomFieldOptionsResponse;
  } catch (e) {
    throw new SpotnanaError(e as any);
  }
};

export const fetchCustomFieldOptionsQuery = (
  request: GetCustomFieldOptionsQueryParams,
): Promise<GetCustomFieldOptionsResponse> => {
  return defaultQueryClient.fetchQuery<GetCustomFieldOptionsResponse>(getCustomFieldOptionsQueryKey(request), () =>
    getCustomFieldOptionsQuery(request),
  );
};

export const useGetCustomFieldOptionsQuery = (
  request: GetCustomFieldOptionsQueryParams | undefined,
  options?: SpotnanaQueryOptions<GetCustomFieldOptionsResponse, ReturnType<typeof getCustomFieldOptionsQueryKey>>,
): SpotnanaQueryResult<GetCustomFieldOptionsResponse> =>
  useQuery(getCustomFieldOptionsQueryKey(request), () => getCustomFieldOptionsQuery(request), options);

const fetchCompanyWorkerTypesList = async (companyId: string): Promise<GetCompanyWorkerTypeListResponse> => {
  if (!companyId) {
    throw new SpotnanaError('No company id passed to fetch worker types of');
  }

  const result = await api('GET', 'companyBaseUrl', {
    urlParam: `/${companyId}/worker-type-list`,
  });
  return result as GetCompanyWorkerTypeListResponse;
};

const getCompanyWorkerTypesListKeys = (companyId: string): string[] => ['v2', 'worker-type-list', companyId];

export const useFetchCompanyWorkerTypesList = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<GetCompanyWorkerTypeListResponse> =>
  useQuery<GetCompanyWorkerTypeListResponse, SpotnanaError>(
    getCompanyWorkerTypesListKeys(companyId ?? ''),
    () => fetchCompanyWorkerTypesList(companyId ?? ''),
    {
      enabled: enabled && !!companyId,
    },
  );

const fetchCompanyCountryList = async (companyId: string): Promise<GetCompanyCountryListResponse> => {
  if (!companyId) {
    throw new SpotnanaError('No company id passed to fetch country list of');
  }

  const result = await api('GET', 'companyBaseUrl', {
    urlParam: `/${companyId}/country-list`,
  });
  return result as GetCompanyCountryListResponse;
};

const getCompanyCountryListKeys = (companyId: string): string[] => ['v2', 'country-list', companyId];

export const useFetchCompanyCountryList = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<GetCompanyCountryListResponse> =>
  useQuery<GetCompanyCountryListResponse, SpotnanaError>(
    getCompanyCountryListKeys(companyId ?? ''),
    () => fetchCompanyCountryList(companyId ?? ''),
    {
      enabled: enabled && !!companyId,
    },
  );

const fetchCompanyAccountingCodes = async (companyId: string): Promise<GetCompanyAccountingCodeListResponse> => {
  if (!companyId) {
    throw new SpotnanaError('No company id passed to fetch accounting codes of');
  }

  const result = await api('GET', 'companyBaseUrl', {
    urlParam: `/${companyId}/accounting-code-list`,
  });
  return result as GetCompanyAccountingCodeListResponse;
};

const getCompanyAccountingCodes = (companyId: string): string[] => ['v2', 'accounting-code', companyId];

export const useFetchCompanyAccountingCodes = (
  companyId: string | undefined,
  enabled = true,
): SpotnanaQueryResult<GetCompanyAccountingCodeListResponse> =>
  useQuery<GetCompanyAccountingCodeListResponse, SpotnanaError>(
    getCompanyAccountingCodes(companyId ?? ''),
    () => fetchCompanyAccountingCodes(companyId ?? ''),
    {
      enabled: enabled && !!companyId,
    },
  );

const enrollCompany = async (
  companyEnrollmentData: CompanyEnrollment,
  companyId: string,
): Promise<CompanyEnrollmentRef> => {
  try {
    const response = await api('POST', 'companyBaseUrl', {
      urlParam: `/${companyId}/enrollments`,
      data: companyEnrollmentData,
    });
    return response as CompanyEnrollmentRef;
  } catch (error) {
    console.error('Failed to enroll company:', error);
    throw error;
  }
};

export const useCompanyEnrollmentMutation = (
  companyId: string,
): SpotnanaQueryMutationResult<CompanyEnrollmentRef, CompanyEnrollment> =>
  useMutation((companyEnrollmentData: CompanyEnrollment) =>
    companyId ? enrollCompany(companyEnrollmentData, companyId) : Promise.reject(new SpotnanaError('Empty company id')),
  );

const getCompanyIdFromSubDomain = async (subDomain: string): Promise<CompanyId> => {
  try {
    const response = await api('GET', 'companyId', {
      params: { subdomain: subDomain },
    });
    return response as CompanyId;
  } catch (error) {
    console.error('Failed to get company ID from subdomain:', error);
    throw error;
  }
};

export const useFetchCompanyIdFromSubDomainQuery = (subDomain: string): SpotnanaQueryResult<CompanyId> =>
  useQuery(['subDomain', subDomain], () => getCompanyIdFromSubDomain(subDomain));
