import markerSDK, { ISetCustomData, MarkerWidget } from '@marker.io/browser';
import {
  Config,
  isSpotnanaAccount,
  isTestingAccount,
  ITraveler,
  ProcessedError,
  useFeatureFlag,
  useFetchCompanyBasicInfo,
  useLoggedInUser,
  useOrganizationReadBasicInfoQuery,
} from 'obt-common';
import { useEffect, useMemo, useState } from 'react';
import useApiTraceInfo from 'src/hooks/useApiTraceInfo';

// Destination: ST Jira Project (It is for feedback on Dev Enviornments)
const markerApiKeyDevEnv = '60ae52756e85d744d4aff585';

// Destination: INT Devrev Project (It is for Spotter feedback on Prod)
const markerApiInternalUsage = '65f0885776767487a6ebaa2a';

// Destination: EXT Jira Project (It is for external feedback on Prod)
const markerApiExternalUsage = '6242c4cf236d694b38897bd0';

interface IProps {
  primaryTraveler: ITraveler | undefined;
  error?: ProcessedError;
}

const useMarkerIo = ({ primaryTraveler, error }: IProps) => {
  const { getTraceInfo } = useApiTraceInfo();
  const isBugFilingFeatureEnabled = useFeatureFlag('FE_INFRA_BUG_FILLING');
  const isDebugToolLinkEnabled = useFeatureFlag('FE_INFRA_SHOW_DEBUG_LINK');
  const { data: loggedInUser } = useLoggedInUser();
  const email = loggedInUser?.userBusinessInfo?.email ?? loggedInUser?.user?.email ?? null;
  const name = loggedInUser?.user?.name;
  const isInternalUsage = Config.VITE_ENVIRONMENT !== 'production' || isTestingAccount(email);

  const [marker, setMarker] = useState<MarkerWidget>();

  const companyId = primaryTraveler?.userBusinessInfo?.organizationId?.id ?? '';
  const { data: companyBasicInfo } = useFetchCompanyBasicInfo(companyId, !!companyId, undefined);
  const primaryTravelerCompany = companyBasicInfo?.name ?? '';
  const primaryTravelerEmail = primaryTraveler?.userBusinessInfo?.email ?? primaryTraveler?.user?.email ?? '';
  const primaryTravelerUserId = primaryTraveler?.userOrgId?.userId?.id ?? '';
  const primaryTravelerOrgId = primaryTraveler?.userOrgId?.organizationId?.id ?? '';
  const primaryTravelerContractTmcId = primaryTraveler?.userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '';
  const { data: orgDetail } = useOrganizationReadBasicInfoQuery(primaryTraveler?.userBusinessInfo?.organizationId);
  const { data: contractTmcOrgDetail } = useOrganizationReadBasicInfoQuery(
    primaryTraveler?.userOrgId?.tmcBasicInfo?.contractingTmc?.id,
  );

  const destination = useMemo((): string => {
    // TODO: Figure out if this is still needed(enabled for ring0,ring1,ring2)
    if (!isDebugToolLinkEnabled) {
      return isInternalUsage ? markerApiInternalUsage : markerApiExternalUsage;
    }

    if (Config.VITE_ENVIRONMENT !== 'production' && Config.VITE_ENVIRONMENT !== 'preprod') {
      return markerApiKeyDevEnv;
    }

    if (isSpotnanaAccount(email)) {
      return markerApiInternalUsage;
    }

    return markerApiExternalUsage;
  }, [email, isDebugToolLinkEnabled, isInternalUsage]);

  useEffect(() => {
    if (isBugFilingFeatureEnabled && !marker) {
      markerSDK
        .loadWidget({
          destination,
          silent: true,
        })
        .then((widget) => {
          setMarker(widget);
        });
    }
  }, [isBugFilingFeatureEnabled, marker, destination]);

  useEffect(() => {
    if (marker && email) {
      marker.setReporter({ email, fullName: [name?.given, name?.family1].join(' ') });
    }
  }, [email, name, marker]);

  const handleMarkerIoFormSubmit = (): void => {
    if (!marker) {
      return;
    }

    let customData: ISetCustomData = {
      debugToolLinkQueue: JSON.stringify(getTraceInfo().debugToolLinkQueue),
      primaryTravelerCompany,
      primaryTravelerEmail,
      primaryTravelerUserId,
      primaryTravelerOrgId,
      primaryTravelerContractTmcId,
      primaryTravelerOrgName: orgDetail?.name ?? '',
      primaryTravelerContractTmcName: contractTmcOrgDetail?.name ?? '',
    };

    if (error) {
      const { errorCode, originalErrorMessage } = error;
      customData = {
        ...customData,
        errorCode,
        sourceErrorMessage: originalErrorMessage,
      };
    }

    marker.setCustomData(customData);
    marker.capture();
  };

  return { marker, handleMarkerIoFormSubmit };
};

export { useMarkerIo };
