import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { LoginState, StorageKeys, parseParams, stringifyParams, useAuth, webSessionStorage } from 'obt-common';

import Routes, { ROUTE_PATHS } from '../routes';

const publicRoutes = Routes.filter((route) => route.isPublicRoute === true).map((route) => route.path);

export function usePreserveURLForRedirectOnLogin() {
  const { pathname, search } = useLocation();

  // IDP parameter may or may not exist, we are removing it regardless
  const { idp, ...paramsWithoutIDP } = parseParams(search);

  // Store company ID specifically for easier fetching later on.
  // This is used for guest travelers to determine eligibility
  // to access the OBT.
  const { companyId = '' } = paramsWithoutIDP;

  const { loginState } = useAuth();

  useEffect(() => {
    /**
     * If user is logged out on a public route, we save the current URL in session storage
     */
    if (
      loginState === LoginState.LOGGED_OUT &&
      !publicRoutes.includes(pathname) &&
      !pathname.endsWith(ROUTE_PATHS.HEADLESS_AUTH)
    ) {
      const searchWithoutIDP = stringifyParams(paramsWithoutIDP);
      webSessionStorage.setItem(
        StorageKeys.AUTH_REDIRECT_URL_KEY,
        stringifyParams({ pathname, search: searchWithoutIDP }),
      );
      webSessionStorage.setItem(StorageKeys.AUTH_REDIRECT_COMPANY_ID_KEY, companyId);
    }
  }, [loginState, paramsWithoutIDP, pathname, companyId]);
}
