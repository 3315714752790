import { useEffect } from 'react';
import onApiError from '../api/events/onApiError';
import { reportEvent } from '../utils';
import { TelemetryEvents, TelemetryErrors, useTelemetry } from '../telemetry';
import type { IMissingErrorCodeEvent, ITranslationErrorCodeMissingEvent } from '../telemetry/types/errorMetadataEvents';
import { GenericErrors } from '../constants/internalAPIErrors';
import { isMissingTranslation } from './utils';

const useTrackErrorsWithMissingDetails = () => {
  const { trackEvent } = useTelemetry();

  useEffect(() => {
    const unsubscribe = onApiError((event) => {
      const { errorCode, debugToolLink, originalError } = event;

      if (isMissingTranslation(errorCode)) {
        const translationErrorEvent: ITranslationErrorCodeMissingEvent = {
          name: TelemetryEvents.TRANSLATION_ERROR_CODE_MISSING,
          data: {
            errorCode,
            debugToolLink,
            data: originalError,
          },
        };
        trackEvent(translationErrorEvent).catch((trackEventError) => {
          reportEvent(TelemetryErrors.TRANSLATION_ERROR_CODE_MISSING_ERROR, { message: trackEventError });
        });
      }

      if (errorCode === GenericErrors.MISSING_ERROR_CODE) {
        const missingErrorCodeEvent: IMissingErrorCodeEvent = {
          name: TelemetryEvents.MISSING_ERROR_CODE,
          data: {
            errorCode,
            debugToolLink,
            data: originalError,
          },
        };
        trackEvent(missingErrorCodeEvent).catch((trackEventError) => {
          reportEvent(TelemetryErrors.MISSING_ERROR_CODE_ERROR, { message: trackEventError });
        });
      }
    });

    return unsubscribe;
  }, [trackEvent]);

  return null;
};

export default useTrackErrorsWithMissingDetails;
