import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { reportEvent, useStorage, StorageKeys, IUserOrgId } from 'obt-common';

const NotFound: React.FC = () => {
  const { data: primaryTravelerId, isFetching: isFetchingPrimaryTravelerId } = useStorage<IUserOrgId>(
    StorageKeys.PRIMARY_TRAVELER_ID,
  );
  const { data: loggedInUserId, isFetching: isFetchingLoggedInUserId } = useStorage<IUserOrgId>(
    StorageKeys.LOGGED_IN_USER_ID,
  );

  if (isFetchingPrimaryTravelerId || isFetchingLoggedInUserId) {
    return <></>;
  }

  const travelerId = primaryTravelerId ?? loggedInUserId;

  reportEvent('NOT_FOUND_404', {
    traveler: { userId: travelerId?.userId?.id, organizationId: travelerId?.organizationId?.id },
    arranger: { userId: loggedInUserId?.userId?.id, organizationId: loggedInUserId?.organizationId?.id },
    path: window.location.pathname,
  });

  return <Redirect to="/" />;
};

export default NotFound;
