import { useState, useEffect, useRef } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { useHistory } from 'react-router';

import { parseParams } from 'obt-common';

type ISelector<UrlState, SelectedUrlState> = string | ((urlState: UrlState) => SelectedUrlState);

function getSelectedState<UrlState, SelectedUrlState>(
  urlState: UrlState,
  selector: ISelector<UrlState, SelectedUrlState>,
): SelectedUrlState {
  if (typeof selector === 'function') {
    return selector(urlState);
  }

  return get(urlState, selector);
}

/**
 * @deprecated use `useQueryParamSelector` instead!
 *
 * @param selector if selector is a string, it will be used as a path to get the value from urlState. if selector is a function,
 *    it will be called with urlState and the result will be used as the value. if selector is a function,
 *    it should be correctly memoized using useCallback or useMemo to avoid frequent subscribe and unsubscribe on history.
 * @returns selectedValue.
 */
function useUrlStateSelector<UrlState, SelectedUrlState>(
  selector: ISelector<UrlState, SelectedUrlState>,
): SelectedUrlState {
  const history = useHistory();
  const defaultUrlState = parseParams(history.location.search) as UrlState;
  const defaultSelectedState = getSelectedState<UrlState, SelectedUrlState>(defaultUrlState, selector);
  const selectedStateRef = useRef(defaultSelectedState);
  const [, rerender] = useState<boolean>(false);

  // transform selector to string to avoid re-rendering when selector is an array
  const selectorDep = Array.isArray(selector) ? selector.join() : selector;

  useEffect(
    () => {
      function reRenderIfSelectorValueChanged(search: string): void {
        const updatedUrlState = parseParams(search);
        const updatedSelectedState = getSelectedState<UrlState, SelectedUrlState>(updatedUrlState, selector);

        if (isEqual(updatedSelectedState, selectedStateRef.current)) {
          return;
        }

        selectedStateRef.current = updatedSelectedState;
        rerender((prevState) => !prevState);
      }

      const unsubscribe = history.listen((event) => {
        reRenderIfSelectorValueChanged(event.search);
      });

      // If selector is changed, re-compute value based on search and re-render if
      // required.
      reRenderIfSelectorValueChanged(history.location.search);

      return unsubscribe;
    },
    // Only re-run if selector is changed
    // eslint-disable-next-line
    [selectorDep],
  );

  return selectedStateRef.current;
}

export default useUrlStateSelector;
