import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounceCallback } from '@react-hook/debounce';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';

import {
  TravelerBasicInfo,
  useTravelerEntitySearchAutocompleteQuery,
  globalTranslationKeys,
  UserOrgId,
  RBAC,
  userRolesByFeature,
} from 'obt-common';
import type { AutocompleteFilter } from 'obt-common/types/api/v2/obt/model/autocomplete-filter';

import ClickAwayListener from '@spotnana/pixel-react/dist/ClickAwayListener';
import Divider from '@spotnana/pixel-react/dist/Divider';
import Typography from '@spotnana/pixel-react/dist/Typography';

import { Button } from '@spotnana/blocks/src/Button/Button';
import { Icon } from '@spotnana/blocks/src/Icon/Icon';
import { flex, margins } from '@spotnana/blocks/src/utils';
import { RoleInfoType } from 'obt-common/types/api/v1/obt/profile/role/roles_info';
import TravelerLoader from '../../../v1-components/shared/TravelerSelector/TravelerLoader';
import { AutocompleteExpandDirections } from '../../../constants/autocomplete';
import Autocomplete, { AutocompleteProps } from '../Autocomplete';

import NoResults from './NoResults';
import AutocompleteOption from './AutocompleteOption';
import AutocompleteInput from './AutocompleteInput';
import {
  add_traveler_container,
  add_traveler_row,
  create_new_traveler_button,
  getAutocompleteContainerBoxProps,
  getAutocompleteInputStylingProps,
  getAutocompletePopoverProps,
  large_icon,
  no_result_found_text,
} from './styles';
import ClickableBox from '../../../v1-components/shared/ClickableBox';

const { NO_TRAVELER_PROFILES_FOUND, NO_RESULTS_FOUND } = globalTranslationKeys;

type AutocompletePropsType = AutocompleteProps<TravelerBasicInfo>;

export interface ISelectTravelerProps {
  dataTestId?: string;
  onSelect(selection: TravelerBasicInfo | null): void;
  onClose: VoidFunction;
  onAddMe?: VoidFunction;
  error?: string;
  expandDirection?: AutocompleteExpandDirections;
  alreadySelectedTravelerIds?: UserOrgId[];

  openRegisterModal?: () => void;
  showRegisterTravelerOnFooter?: boolean;
  registerNewTravelerText?: string;
  outerContainerStyles?: AutocompleteProps<TravelerBasicInfo>['outerContainerStyles'];
  clickAwayListernerStyles?: CSSProperties;
  inputContainerWidth?: string;
  onCreateNewTraveler?: () => void;
  onSearchValueChange?: (value: string) => void;
  createNewTravelerButtonText?: string;
  searchFilters?: AutocompleteFilter[];
  allowedRoles?: RoleInfoType[];
}

export default function SelectTraveler({
  dataTestId,
  onSelect,
  onClose,
  onAddMe,
  error,
  expandDirection = AutocompleteExpandDirections.Right,
  alreadySelectedTravelerIds = [],

  openRegisterModal,
  showRegisterTravelerOnFooter = false,
  registerNewTravelerText,
  outerContainerStyles,
  clickAwayListernerStyles,
  inputContainerWidth,
  onCreateNewTraveler,
  onSearchValueChange,
  createNewTravelerButtonText,
  searchFilters,
  allowedRoles,
}: ISelectTravelerProps): JSX.Element {
  const { t } = useTranslation();
  const { t: tt } = useTranslation('WEB');
  const [searchValue, setSearchValue] = useState<string>('');
  const [autocompleteActive, setAutocompleteActive] = useState(true);

  // Debounce changes of search value that is passed to autocomplete query
  const [requestSearchValue, setRequestSearchValue] = useState<string>(searchValue);
  const setRequestSearchValueDebounced = useDebounceCallback(setRequestSearchValue, 400);
  useEffect(() => {
    setRequestSearchValueDebounced(searchValue);
  }, [searchValue, setRequestSearchValueDebounced]);

  const { data: travelers, isLoading: travelersLoading } = useTravelerEntitySearchAutocompleteQuery(
    requestSearchValue,
    searchFilters,
  );

  const displayedTravelers = useMemo(
    () =>
      (travelers ?? []).filter(
        (traveler) =>
          !alreadySelectedTravelerIds.find((selectedTravelerId) => isEqual(selectedTravelerId, traveler.userOrgId)),
      ),
    [travelers, alreadySelectedTravelerIds],
  );

  const handleSearchChange = useCallback<NonNullable<AutocompletePropsType['onInputChange']>>(
    (_event, updatedValue) => {
      setSearchValue(updatedValue);
      if (onSearchValueChange) {
        onSearchValueChange(updatedValue);
      }
    },
    [onSearchValueChange],
  );

  const handleSelect = useCallback<AutocompletePropsType['onSelection']>(
    (selection) => {
      onSelect(selection);
    },
    [onSelect],
  );

  const Footer = useCallback((): JSX.Element | null => {
    if (showRegisterTravelerOnFooter) {
      return (
        <RBAC allowedRoles={allowedRoles ?? [...userRolesByFeature.adminPages]}>
          <Divider />
          <ClickableBox as="button" bg="bg.blueGray" p="20px" onClick={openRegisterModal} className="full-width">
            <Typography color="primary" fontWeight="semiBold" css={[flex.init, flex.align.center]}>
              <Icon name="AddPlusCircleFill" css={[large_icon, margins.xr.half]} />
              {registerNewTravelerText || tt('Register new traveler')}
            </Typography>
          </ClickableBox>
        </RBAC>
      );
    }
    return null;
  }, [openRegisterModal, showRegisterTravelerOnFooter, tt, registerNewTravelerText, allowedRoles]);

  const renderNoResultsWithAction = (): JSX.Element => (
    <div>
      <Typography css={no_result_found_text} variation="body">
        {t(NO_RESULTS_FOUND)}
      </Typography>
      <div css={add_traveler_container}>
        <Button fullWidth variant="ghost" css={create_new_traveler_button} onClick={onCreateNewTraveler}>
          <div css={add_traveler_row}>
            <Icon name="AddPlusCircleFill" css={large_icon} />
            <Typography variation="body">{createNewTravelerButtonText || tt('Create new traveler')}</Typography>
          </div>
        </Button>
      </div>
    </div>
  );

  return (
    <ClickAwayListener onClickAway={onClose} style={clickAwayListernerStyles}>
      <Autocomplete
        outerContainerStyles={outerContainerStyles}
        id="select-traveler-v2"
        inputContainerWidth={inputContainerWidth}
        data-testid={dataTestId ?? 'select-traveler-v2'}
        inputValue={searchValue}
        getContainerBoxProps={(popupOpen): ReturnType<typeof getAutocompleteContainerBoxProps> =>
          getAutocompleteContainerBoxProps(popupOpen, expandDirection)
        }
        getInputStylingProps={getAutocompleteInputStylingProps}
        getPopoverProps={getAutocompletePopoverProps}
        getOptionLabel={(option): string => option.fullName}
        loading={travelersLoading}
        onInputChange={handleSearchChange}
        options={displayedTravelers}
        onSelection={handleSelect}
        renderLoadingState={(): JSX.Element => <TravelerLoader />}
        renderNoResultsState={
          onCreateNewTraveler
            ? renderNoResultsWithAction
            : (): JSX.Element => <NoResults message={t(NO_TRAVELER_PROFILES_FOUND)} />
        }
        renderOption={({ isHighlighted, option }): JSX.Element => (
          <AutocompleteOption travelerInfo={option} active={isHighlighted} />
        )}
        errorMessage={error}
        renderInput={(props): JSX.Element => <AutocompleteInput onAddMe={onAddMe} {...props} />}
        active={autocompleteActive}
        onDeactivate={(event, reason): void => {
          if (!['blur', 'toggleInput'].includes(reason)) {
            setAutocompleteActive(false);
            onClose();
          }
        }}
        footer={<Footer />}
      />
    </ClickAwayListener>
  );
}
