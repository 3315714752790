import type { IAuthenticatedUserBasicInfo } from '../../types';
import { isCompanyAdminOrArranger, isTMCUser, isUserOfSpotnana, isUserWithSpotnanaTMC } from '../../utils/User';

export type RBACPredicate = (userBasicInfo: IAuthenticatedUserBasicInfo) => boolean;

/**
 * A closure over spotnanaTmcId returning a predicate that checks for further restrictions imposed on few user roles
 * @returns (userBasicInfo: IAuthenticatedUserBasicInfo): boolean
 */
const checkForSeat1aRestrictions =
  () =>
  (userBasicInfo: IAuthenticatedUserBasicInfo): boolean => {
    const { existingUser } = userBasicInfo;
    if (!existingUser) {
      return false;
    }

    if (isTMCUser(existingUser)) {
      return isUserOfSpotnana(existingUser);
    }

    if (isCompanyAdminOrArranger(existingUser)) {
      return isUserWithSpotnanaTMC(existingUser);
    }

    return true;
  };

export { checkForSeat1aRestrictions };
