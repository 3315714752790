var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from 'lodash/get';
import React from 'react';
import { useTheme } from 'styled-components';
import { defaultIconProps } from './common';
const ChevronDown = (_a) => {
    var { primaryColor, secondaryColor, width, height, title } = _a, restProps = __rest(_a, ["primaryColor", "secondaryColor", "width", "height", "title"]);
    const theme = useTheme();
    const themeEnrichedPrimaryColor = get(theme, `colors.${primaryColor}`, primaryColor);
    return (React.createElement("svg", Object.assign({ width: width, height: height, viewBox: "0 0 14 8", fill: themeEnrichedPrimaryColor }, restProps),
        React.createElement("title", null, title !== null && title !== void 0 ? title : 'Down Icon'),
        React.createElement("g", null,
            React.createElement("path", { d: "M-5-8h24v24H-5z", fill: "none" }),
            React.createElement("path", { d: "M.293.293A1 1 0 011.613.21l.094.083L7 5.585 12.293.293a1 1 0 011.32-.083l.094.083a1 1 0 01.083 1.32l-.083.094-6 6a1 1 0 01-1.32.083l-.094-.083-6-6a1 1 0 010-1.414z" }))));
};
ChevronDown.defaultProps = defaultIconProps;
export default ChevronDown;
