import { divider_horizontal, divider_vertical } from './Divider.styles';

type DividerProps = {
  orientation?: 'vertical' | 'horizontal';
  disableMargin?: boolean;
};

export const Divider: React.FC<DividerProps> = ({ orientation, disableMargin }) => {
  if (orientation === 'vertical') {
    return <div css={divider_vertical} className={disableMargin ? 'no-margin' : undefined} />;
  }

  return <hr css={divider_horizontal} className={disableMargin ? 'no-margin' : undefined} />;
};
