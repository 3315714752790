import { useMemo } from 'react';
import { useAuth, useFeatureFlag, usePartnerFeatures } from 'obt-common';

import { useExternalAuthCodeContext } from 'src/components/ExternalAuthCodeProvider/ExternalAuthCodeProvider';
import { MARRIOTT_STATE_PARAM_VALUE } from 'src/components/ExternalAuthCodeProvider/constants';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { BonvoyRedirectModal } from './components/BonvoyRedirectModal';
import { BonvoyLinkModal } from './components/BonvoyLinkModal';
import { hasIncompleteUserAction } from '../../user/shared/utils';

/**
 * BonvoyLogin - Partner (Marriott) specific feature.
 * Checks if user has required (Marriott Bonvoy specific) login action then:
 *  - redirect to Marriott login page - show modal for redirecting and prevent closing it
 *  - link the Bonvoy account - show modal for linking and prevent closing it
 */
export function BonvoyLogin() {
  const isBonvoyEnabled = useFeatureFlag('FE_CORP_BONVOY_LOGIN');
  const { userBasicInfo } = useAuth();
  const { authCode, authState } = useExternalAuthCodeContext();
  const userId = userBasicInfo.existingUser?.userOrgId?.userId?.id ?? '';
  const { features } = usePartnerFeatures();
  const { linkedMarriottBonvoyRequired } = features;
  const userHasBonvoyLoginAction = useMemo(
    () => hasIncompleteUserAction(userBasicInfo, IncompleteUserActionName.MARRIOTT_BONVOY_LINK),

    [userBasicInfo],
  );

  if (!isBonvoyEnabled || !linkedMarriottBonvoyRequired || !userHasBonvoyLoginAction) {
    return null;
  }

  if (authCode && authState === MARRIOTT_STATE_PARAM_VALUE) {
    return <BonvoyLinkModal userId={userId} marriottAuthCode={authCode} />;
  }

  return <BonvoyRedirectModal userId={userId} />;
}
