/* eslint-disable */
import type { RailCard } from '../../common/card';
import type { DateTime, Duration } from '../../common/date_time';
import type { FareAmount } from '../../common/fare_amount';
import type { Latlng } from '../../common/latlng';
import type { Money } from '../../common/money';
import type { UserId } from '../../common/user_id';
import type { PolicyInfo } from '../common/policy_info';
import type { ProfileMetadata } from '../common/profile_metadata';
import type { Traveler } from '../common/traveler';
import type { NonProfileUserInfo, UserOrgId } from '../common/user_org_id';
import type { TaxBreakdown } from '../common/tax_breakdown';
import type { Voucher } from '../pnr/payment';
import { UserFacingStatus } from '../../../v1/obt/pnr/pnr';

export enum DeliveryOption {
  UNKNOWN_DELIVERY_OPTION = 0,
  /** ELECTRONIC_TICKET - E-Ticket is sent as PDF via email, or QR on app etc. */
  ELECTRONIC_TICKET = 1,
  /** KIOSK - Tickets will be available to collect from a self-service ticket machine. */
  KIOSK = 2,
  UNRECOGNIZED = -1,
}

/** Travel class. */
export enum TravelClass {
  UNKNOWN = 0,
  FIRST = 1,
  STANDARD = 2,
  BUSINESS = 3,
  SLEEPER = 4,
  STANDARD_PREMIUM = 5,
  BUSINESS_PREMIUM = 6,
  COACH = 7,
  ROOM = 8,
  EXECUTIVE = 9,
  UNRECOGNIZED = -1,
}

export enum FareComposition {
  FARE_COMPOSITION_UNKNOWN = 0,
  THROUGH = 1,
  DIRECT_SPLIT = 2,
  INTERCHANGE_SPLIT = 3,
  UNRECOGNIZED = -1,
}

export enum RailExchangeType {
  RAIL_EXCHANGE_TYPE_UNKNOWN = 0,
  AMEND_RESERVATION = 1,
  PAY_THE_DIFFERENCE = 2,
  REBOOK_AND_REFUND = 3,
  UNRECOGNIZED = -1,
}

/** Enum representing what kind of journey it is. */
export enum SearchType {
  ONE_WAY = 0,
  RETURN = 1,
  OPEN_RETURN = 2,
  UNRECOGNIZED = -1,
}

export enum RailThirdParty {
  UNKNOWN_THIRD_PARTY = 0,
  TRAINLINE = 1,
  AMTRAK = 2,
  UNRECOGNIZED = -1,
}

/** Type of Rail Station (Individual, Station Group, Metro) */
export enum StationType {
  INDIVIDUAL = 0,
  STATION_GROUP = 1,
  METRO = 2,
  BUS_STOP = 3,
  FERRY_TERMINAL = 4,
  UNRECOGNIZED = -1,
}

export enum PaymentMode {
  PAYMENT_FORM_UNKNOWN = 0,
  PRE_PAID = 1,
  PAID_AT_STATION = 2,
  UNRECOGNIZED = -1,
}

/** Information regarding the vehicle and operator. */
export interface Vehicle {
  type: VehicleType;
  /**
   * Name of the carrier.
   * Ex: Deutsche Bahn
   */
  carrierName: string;
  /**
   * Timetable ID
   * Ex: ICE 373
   */
  timetableId: string;
  /**
   * Name of the transport.
   * Ex: TGV
   */
  transportName: string;
}

export enum VehicleType {
  UNKNOWN_VEHICLE = 0,
  TRAIN = 1,
  BUS = 2,
  FERRY = 3,
  WALK = 4,
  TAXI = 5,
  TRAM = 6,
  METRO = 7,
  TUBE = 8,
  UNRECOGNIZED = -1,
}

export interface SourceRefInfo {
  /**
   * Reference Id of the station provided by third party(e.g. Trainline) for a given inventory
   * (e.g. ATOC). E.g: https://et2-trainlineapi.ttlnonprod.com/stations/op~U~a7344b9b-edf4-4cf1-a5fd-a807da73f2c8
   */
  stationReferenceId: string;
  /** Name of the inventory in which the given station is present. */
  inventoryName: string;
}

export interface StationInfo {
  /** Name of the station. */
  name: string;
  /** Unique code of the station. */
  code: string;
  /** City code for the station. */
  cityCode: string;
  /** Timezone for the station. */
  timeZone: string;
  /** Country code of the station. */
  countryCode: string;
  /** Location details of the station. */
  latLong?: Latlng;
  /** Type of Rail Station (Individual, Station Group, Metro) */
  stationType: StationType;
  /** Reference information provided for the station */
  sourceRefInfos: SourceRefInfo[];
  /** Name of the city where station belongs. */
  cityName: string;
  /** Continent code of the station. */
  continentCode: string;
  /** State code of the station. */
  stateCode: string;
}

/** Proto message containing details of the ticket. */
export interface TicketAsset {
  /** Provide details on what type of the ticket is. */
  type: TicketAssetType;
  /**
   * For ticket type collection number, it contains the reference number of the ticket.
   * For PDF, appl_pk_pass and html type, it contains the path where the actual content of the
   * ticket is.
   */
  reference: string;
  /** Provide some details on what the given asset contains e.g: Outward journey. */
  description: string;
}

export enum TicketAssetType {
  UNKNOWN_TYPE = 0,
  PDF = 1,
  APPLE_PK_PASS = 2,
  HTML = 3,
  /** COLLECTION_NUMBER - Asset number for ticket type whose delivery is Kiosk type. */
  COLLECTION_NUMBER = 4,
  UNRECOGNIZED = -1,
}

/** Contains recipient id and Url to set the delivery option. */
export interface DeliveryOptionKey {
  /** Recipient Id for delivery. */
  recipientId: string[];
  /** Url to set the delivery option. */
  url: string;
}

export interface DeliveryOptionDetails {
  /** Delivery option key. */
  delieveryOptionKey: string;
  deliveryOption: DeliveryOption;
  /**
   * Description about the deliver option such as TVM details.
   * e.g: "Your tickets cannot be collected from Lochgelly."
   */
  description: string;
}

export interface DeliveryPreferenceGroup {
  /** Delivery Options available for the set of legs. */
  deliveryOptionDetails: DeliveryOptionDetails[];
  /**
   * List of legs covered by the delivery preference group. These are the indexes in LegInfo field
   * in Itinerary details.
   */
  legs: number[];
}

export interface SeatPreferenceGroup {
  /** Base64 encoded string for SeatReservationKey Proto. */
  seatPreferenceGroupId: string;
  /** List of available position options. */
  positions: SeatPreferenceGroupPosition[];
  /** List of available direction options. */
  directions: SeatPreferenceGroupDirection[];
  /** List of available facility options. */
  facilities: SeatPreferenceGroupFacility[];
  /** List of available carriage type options. */
  carriageTypes: SeatPreferenceGroupCarriage[];
  /**
   * List of legs covered by the seat preference group. These are the indexes in LegInfo field in
   * Itinerary details.
   */
  legs: number[];
  /** List of available seat type options. */
  seatTypes: SeatPreferenceGroupSeatType[];
  /** List of available deck type options. */
  decks: SeatPreferenceGroupDeck[];
  /** List of specific seat location options. */
  seatLocationTypes: SeatPreferenceGroupSeatLocationType[];
  /** Seat map ID for the corresponding seat map request. */
  seatMapId: string;
}

/** Seat position option available with the corresponding id. */
export interface SeatPreferenceGroupPosition {
  id: string;
  positionType: string;
}

/** Seat direction option available with the corresponding id. */
export interface SeatPreferenceGroupDirection {
  id: string;
  directionType: string;
}

/** Seat facility option available with the corresponding id. */
export interface SeatPreferenceGroupFacility {
  id: string;
  facilityType: string;
}

/** Seat carriage type option available with the corresponding id. */
export interface SeatPreferenceGroupCarriage {
  id: string;
  carriageType: string;
}

/** Seat type option available with the corresponding id. */
export interface SeatPreferenceGroupSeatType {
  id: string;
  seatType: string;
}

/** Seat deck type option available with the corresponding id. */
export interface SeatPreferenceGroupDeck {
  id: string;
  deckType: string;
}

/** Seat location type option available with the corresponding id. */
export interface SeatPreferenceGroupSeatLocationType {
  id: string;
  seatLocationType: string;
}

export interface RailSearchKey {
  /** Workflow Id for the search workflow. */
  workflowId: string;
  /** Trace Id for the search workflow. */
  traceId: string;
  /** List of outward rate keys selected. */
  outwardRateKeys: string[];
  /** Search id to be used in navigation. */
  searchId: string;
  /**
   * Whether the given itinerary is reservable or not. This will be used for trainline reservation.
   *
   * @deprecated
   */
  reservability: boolean;
  /**
   * The trace id from the railBookItinerary Request. This is needed as we need
   * railBookItinerary request and response at multiple places.
   */
  bookItineraryTraceId: string;
  /**
   * The trace id of RailReserveItinerary Request. This is needed as we need
   * RailReserveItinerary request to fetch passengers.
   */
  reserveItineraryTraceId: string;
}

export interface RailNavigationKey {
  /** Trace id for the aggregated response. */
  traceId: string;
  /** Local date and time for the previous search. */
  dateTime?: DateTime;
}

export interface RailSeatMapKey {
  /** Seat map id from supplier. */
  seatMapId: string;
  /** Workflow id. */
  workflowId: string;
  /** Trace id. */
  traceId: string;
}

export interface RailRateKey {
  /** Journey Id. This will map to journey Id in trainline response. */
  journeyId: string;
  /** Alternative Id. This will map to alternative Id in trainline response. */
  alternativeId: string;
  /** Workflow Id for the rateKey. */
  workflowId: string;
  /** Trace Id for the rateKey. */
  traceId: string;
}

export interface RailRefundKey {
  /** Refund Id from the supplier. */
  refundId: string;
  /** Workflow Id for the refund details. */
  workflowId: string;
  /** Trace Id for refund details. */
  traceId: string;
}

/** Rate for the itinerary. */
export interface RateInfo {
  /** Base Amount. */
  base?: Money;
  /** Fees and Taxes. */
  fees?: Money;
  /** Rail Card Discount Amount */
  railCardDiscount?: Money;
  /** Total Amount. */
  total?: Money;
  /** Rate difference between new and old bookings (exchanges flow) */
  rateDifference?: Money;
  /** Rate difference between new and old bookings (exchanges flow) including taxes & fees */
  totalRateDifference?: Money;
  /** Commission amount on the booking. */
  commission?: Money;
  /** Refund amount. */
  refund?: Money;
  /** the date of transaction */
  transactionDate?: DateTime;
  /** Refund voucher details. */
  refundVoucher?: Voucher;
  /** Breakdown of the taxes. */
  taxBreakdown?: TaxBreakdown;
  /** Date and time of the transaction. */
  transactionDateTime?: DateTime;
}

export interface RelatedRateInfo {
  /** Rate information for the related itinerary. */
  rateInfo?: RateInfo;
}

/** Passenger for booking the ticket. */
export interface Passenger {
  /** User and org id for the passenger. This is mandatory field. */
  userOrgId?: UserOrgId;
  /** List of rail cards that can be used for booking. */
  discountCards: RailCard[];
  /**
   * Details of the traveler with mandatory fields: user.name.given & at-least
   * one address with region code in user.addresses[*].
   */
  traveler?: Traveler;
  /** Profile Metadata. */
  profileMetadata?: ProfileMetadata;
  /**
   * The details of the user in case of non-profile bookings.
   * Note: This should not be populated in case of user org id is there. Also,
   * this is for UI only for rail search.
   */
  nonProfileUserInfo?: NonProfileUserInfo;
}

export interface FareType {
  /** Description of this fare type. like Off-Peak Single (1st Class). */
  description: string;
  fareDetails: FareTypeFareDetail[];
  /** Summary of the fare like "Any time of day, return within 1 month.". */
  fareSummary: string;
}

/** Details about this fare. */
export interface FareTypeFareDetail {
  /** Name of the Attribute. */
  name: string;
  /** Description about this fare. */
  description: string;
}

export interface LegInfo {
  /** Leg Id. */
  legId: string;
  /**
   * Originating station name of the Leg.
   *
   * @deprecated
   */
  origin: string;
  /**
   * Destination station name of the Leg.
   *
   * @deprecated
   */
  destination: string;
  /** Time of departure of the leg. */
  departAt?: DateTime;
  /** Time of arrival of the leg. */
  arriveAt?: DateTime;
  /** Vehicle used in the leg. */
  vehicle?: Vehicle;
  /** Details of fare for this leg. */
  fareType?: FareType;
  /** Selected Seat Preferences for the leg. */
  seatPreferenceSelection?: LegInfoSeatPreferenceSelection;
  /** Name of the vendor for which current leg is reserved, e.g.:ATOC. */
  vendorName: string;
  /** CO2 emission for the current leg in gram for a single passenger. */
  co2EmissionGramsPerPassenger: number;
  /** Space allocated for each passenger. */
  allocatedSpaces: LegInfoAllocatedSpace[];
  /** List of passenger for this leg. */
  passengerRefs: number[];
  /** Details of origin station. */
  originInfo?: StationInfo;
  /** Details of destination station. */
  destinationInfo?: StationInfo;
  /** @deprecated */
  travelerInfo: TravelerRailInfo[];
  /** Carrier confirmation number for the segment */
  carrierConfirmationNumber: string;
  /** Traveler rail info for the segment */
  travelerRailInfo: TravelerRailInfo[];
  /** Travel class */
  travelClass: TravelClass;
  /** List of amenities available for the leg. */
  amenities: RailAmenity[];
}

/** Selected Seat Preferences for the itinerary. */
export interface LegInfoSeatPreferenceSelection {
  /** Seat Position Type. */
  positionType: string;
  /** Seat Carriage Type. */
  carriageType: string;
  /** Seat Direction. */
  direction: string;
  /** Seat Facilities. */
  facilities: string[];
  /** Seat Type. */
  seatType: string;
  /** Seat Deck Type. */
  deckType: string;
  /** Seat Location Type. */
  seatLocationType: string;
}

/** Space allocated for a passenger in the rail. */
export interface LegInfoAllocatedSpace {
  /** Seat Number. */
  seatNumber: string;
  /** Coach Number. */
  coachNumber: string;
  /**
   * Passenger for which the seat belongs. The index represent index of the passenger list present
   * in itinerary details.
   */
  passengerRef: number;
  /** Additional Properties of allocated seat. */
  additionalProperties: string[];
}

export interface Co2EmissionDetails {
  /** CO2 emission for the outward/inward leg in kilograms for a single passenger. */
  co2EmissionKilograms: number;
}

export interface RailStatistics {
  savingsMetadata?: RailStatisticsSavingsMetadata;
  /** List of available delivery options at the time of booking. */
  availableDeliveryOptions: DeliveryOption[];
}

/** Savings related metadata. */
export interface RailStatisticsSavingsMetadata {
  /** Total price of the booking. */
  totalPrice?: Money;
  /** Non-split price of the booking. */
  nonSplitPrice?: Money;
}

export interface JourneyInfo {
  /**
   * Originating station name of the Journey.
   *
   * @deprecated
   */
  origin: string;
  /**
   * Destination station name of the Journey.
   *
   * @deprecated
   */
  destination: string;
  /** Time of departure of the journey. */
  departAt?: DateTime;
  /** Time of arrival of the journey. */
  arriveAt?: DateTime;
  /**
   * List of legs covered in the Journey. These are the indexes in LegInfo field in Itinerary
   * details.
   */
  legs: number[];
  /**
   * Status of the journey. Note: It is formed using the journey status of all the passengers.
   * Hence, it will be cancelled only when all the passengers cancelled it.
   */
  status: JourneyInfoJourneyStatus;
  /** Details of origin station. */
  originInfo?: StationInfo;
  /** Details of destination station. */
  destinationInfo?: StationInfo;
  /** This int sets the sorting priority of the leg to determine its order on the trips page */
  sortingPriority: number;
  /** The policy info associated with this journey. */
  policyInfo?: PolicyInfo;
  /** CO2 emission details for this journey. */
  co2EmissionDetails?: Co2EmissionDetails;
  /** Indicates whether the journey is through or split. */
  fareComposition: FareComposition;
  /** Duration of the journey. */
  duration?: Duration;
  /** User facing journey status. */
  journeyStatus: UserFacingStatus;
}

/** Status of the journey. */
export enum JourneyInfoJourneyStatus {
  UNKNOWN = 0,
  /** INITIATED - When we first create the itinerary. */
  INITIATED = 1,
  /** CONFIRMED - When we received the confirm order event. */
  CONFIRMED = 2,
  /** CANCELLED - When we receive refund event for the journey. */
  CANCELLED = 3,
  /** REQUESTED - When we cancel/create order(post first order creation) through spotnana OBT. */
  REQUESTED = 4,
  /** PENDING - When the status of any operation such as create/cancel is pending on Third party. */
  PENDING = 5,
  UNRECOGNIZED = -1,
}

export interface SectionInfo {
  /** Vendor name. */
  vendorName: string;
  /** List of all fares in this section. */
  fares: FareInfo[];
  /** Rate information about the section. */
  rateInfo?: RateInfo;
  /** Status of the section. */
  status: SectionInfoSectionStatus;
  /** List of vendor confirmation number for this section. */
  vendorConfirmations: SectionInfoVendorConfirmation[];
  /** User facing section status. */
  sectionStatus: UserFacingStatus;
}

export enum SectionInfoSectionStatus {
  /** SECTION_STATUS_UNKNOWN - Unknown status */
  SECTION_STATUS_UNKNOWN = 0,
  /** INITIATED - When the section is first created. */
  INITIATED = 1,
  /** CONFIRMED - When the section is Confirmed. */
  CONFIRMED = 2,
  /** CANCELLED - When the section is Cancelled. */
  CANCELLED = 3,
  /** REQUESTED - When the order is awaiting confirmation from supplier. */
  REQUESTED = 4,
  UNRECOGNIZED = -1,
}

export interface SectionInfoVendorConfirmation {
  /** Vendor confirmation id. */
  vendorConfirmationId: string;
  /** List of passengers. */
  passengerRefs: number[];
}

export interface FareInfo {
  /** Fare details for this fare. */
  fareType?: FareType;
  fareLegs: FareInfoFareLegInfo[];
  /** Fare origin station info. */
  originInfo?: StationInfo;
  /** Fare destination station info. */
  destinationInfo?: StationInfo;
  /** Rate information about the fare. */
  rateInfo?: RateInfo;
  /** Route restrictions for the fare. */
  routeRestriction: string;
}

/** Information about the leg this fare represents. */
export interface FareInfoFareLegInfo {
  /** Index of leg in itinerary. */
  legIndex: number;
  /** Travel class for the leg. */
  travelClass: TravelClass;
}

export interface ItineraryDetails {
  /** Itinerary Id. */
  itineraryId: string;
  /** Search Type of the itinerary. */
  type: SearchType;
  /** Outward Journey. */
  outwardJourney?: JourneyInfo;
  /** Inward Journey. */
  inwardJourney?: JourneyInfo;
  /** Rate Information of the itinerary. */
  rateInfo?: RateInfo;
  /** Rail Discount Cards. */
  discountCards: RailCard[];
  /** List of legs covered in the Itinerary. */
  legInfos: LegInfo[];
  /**
   * Selected Delivery Options and the details of the ticket for the itinerary. All Delivery
   * options together will cover all the legs.
   */
  ticketDetails: ItineraryDetailsTicketDetail[];
  /** Details of the selected ancillaries for the itinerary. */
  ancillaries: Ancillary[];
  /**
   * List of passengers for the complete itinerary. It's possible that some passengers might not be
   * present in all the journeys(in case of outward and inward both are present).
   */
  passengers: Passenger[];
  /** Details of negotiated rail rates. */
  rateMetadata?: RateMetadata;
  /** Payment type. */
  paymentMode: PaymentMode;
  /** Policy info will contain info about the polices applied for this itinerary. */
  policyInfo?: PolicyInfo;
  /** List of tickets. */
  tickets: ItineraryDetailsTicket[];
  /** List of sections covered in the itinerary. */
  sections: SectionInfo[];
  /** Details for rail statistics during booking. */
  statistics?: RailStatistics;
  /** List of rate information for the related itinerary. */
  relatedRateInfos: RelatedRateInfo[];
  /** Exchange information for the itinerary. */
  exchangeInfo?: ExchangeInfo;
  /** Previous itinerary details. */
  previousItineraryDetails?: PreviousItineraryDetails;
}

export interface ItineraryDetailsTicket {
  /** Ticket number. */
  ticketNumber: string;
  /** List of legs covered by this ticket. */
  legs: number[];
  /** List of passenger for this ticket. */
  passengerRefs: number[];
}

/** Selected Ticket Delivery for the itinerary. */
export interface ItineraryDetailsTicketDetail {
  /**
   * Delivery Options selected for the section.
   *
   * @deprecated
   */
  deliveryOption: DeliveryOption;
  /**
   * List of legs covered by the delivery preference group. These are the indexes in LegInfo
   * field in Itinerary details.
   */
  legs: number[];
  /**
   * Containing details about the content and how to get the ticket. e.g. for e-ticket, it will
   * contain details of the path where pdf/apple pk pass is stored.
   */
  ticketAssets: TicketAsset[];
  /** Containing details about the ticket delivery. */
  deliveryDetails?: DeliveryOptionDetails;
}

export interface PreviousItineraryDetails {
  /** Search Type of the itinerary. */
  type: SearchType;
  /** Outward Journey. */
  outwardJourney?: JourneyInfo;
  /** Inward Journey. */
  inwardJourney?: JourneyInfo;
  /** List of legs covered in the Itinerary. */
  legInfos: LegInfo[];
  /** List of sections covered in the itinerary. */
  sections: SectionInfo[];
  /** Source reference for the itinerary. */
  sourceReference: string;
}

export interface ExchangeInfo {
  /** Exchange type. */
  exchangeType: RailExchangeType;
  /** Previous delivery option. */
  previousDeliveryOption: DeliveryOption;
  /** Related section information of the exchanged itinerary. */
  relatedSectionInfo?: ExchangeInfoRelatedSectionInfo;
}

export interface ExchangeInfoRelatedSectionInfo {
  /** Section indexes of the exchanged itinerary. */
  newSectionIndexes: number[];
  /** Section indexes of the previous itinerary. */
  oldSectionIndexes: number[];
}
/** Proto message containing ids to uniquely represent the travel card. */
export interface TravelCardKey {
  /** Travel card search id. */
  sourceSearchId: string;
  /** Travel card alternative id. */
  sourceAlternativeId: string;
}

export interface TravelCard {
  /** Base64 encoded string to uniquely identify the card. */
  travelCardKey: string;
  /** Fare details of the card. */
  fareType?: FareType;
  /** Vendor which provides the card. */
  vendorName: string;
  /** Price details of the card. */
  rateInfo?: RateInfo;
  /** Zones in which travel card is valid. */
  zoneValidity: string;
}

/**
 * Proto to encapsulate all the information related to reservation detail. It is 1-1 mapped to
 * reservability details of a section in Trainline.
 */
export interface SeatReservationKey {
  reserveability: SeatReservationKeyReserveAbility;
  /** The reservation offer id provided by Trainline. */
  sourceReservationOfferId: string;
  /** Section URI for which above reservation offer is. */
  sourceSectionHref: string;
}

/**
 * Enum to represent whether the reservation of itinerary is optional, mandatory or cannot be
 * done.
 */
export enum SeatReservationKeyReserveAbility {
  /** OPTIONAL - User may or may not reserve. */
  OPTIONAL = 0,
  /** MANDATORY - User has to reserve. */
  MANDATORY = 1,
  /** NONE - Reservation must not be done. */
  NONE = 2,
  UNRECOGNIZED = -1,
}

export interface RailAmenityKey {
  /** Section id from the supplier. */
  sectionId: string;
  /** Amenity id from the supplier. */
  amenityId: string;
}

export interface RailAmenity {
  /** Rail amenity type. */
  type: RailAmenityRailAmenityType;
  /** Amenity description. */
  description: string;
  /** Rail amenity key. */
  amenityKey: string;
  /** Amenity name. */
  name: string;
  /** Available/Selected quantity. */
  quantity: number;
  /** Price of the amenity. */
  price?: Money;
  /** List of legs this amenity applies to. */
  legs: number[];
}

export enum RailAmenityRailAmenityType {
  RAIL_AMENITY_TYPE_UNKNOWN = 0,
  WIFI = 1,
  LUGGAGE_INCLUDED = 2,
  ELECTRICAL_SOCKET = 3,
  CAFE = 4,
  SEAT_DISPLAY = 5,
  NEWSPAPER = 6,
  MEAL = 7,
  QUIET_CAR = 8,
  SEAT_SELECTION = 9,
  LOCAL_TRANSPORT = 10,
  LOUNGE = 11,
  LEATHER_SEATS = 12,
  BICYCLE = 13,
  PET = 14,
  FIRST_CLASS_COMFORT = 15,
  BUSINESS_PREMIER = 16,
  PERSONALIZED_SERVICE = 17,
  BOARDING = 18,
  WHEELCHAIR_ACCESS = 19,
  UNRECOGNIZED = -1,
}

export interface Ancillary {
  /** Type of the ancillary. */
  type: AncillaryType;
  /** Details of the travel cards. */
  travelCard?: TravelCard;
}

export enum AncillaryType {
  UNKNOWN_TYPE = 0,
  TRAVEL_CARD = 1,
  UNRECOGNIZED = -1,
}

/** Carries the loyalty information of rail */
export interface LoyaltyDetails {
  /** Loyalty program of carrier */
  loyaltyProgram: string;
  /** Loyalty number provided to traveler by the carrier */
  loyaltyNumber: string;
}

export interface TravelerRailInfo {
  /** Index of the travellers in the form in case of multi-passenger booking */
  travelerIndex: number;
  /** Loyalty details for this traveler. */
  loyaltyDetails?: LoyaltyDetails;
  /** Rail card details for the traveler. */
  railCard?: RailCard;
  /** User Id of the traveler. */
  userId?: UserId;
}

/** The metadata for the Rate entered through manual form */
export interface RateMetadata {
  /** Negotiated rate type , non-negotiated rate would be set to NONE */
  negotiatedRateType: RateMetadataNegotiatedRateType;
  /** The non negotiated rate or the market rate. */
  publishedRate?: FareAmount;
}

export enum RateMetadataNegotiatedRateType {
  NEGOTIATED_RATE_UNKNOWN = 0,
  NONE = 1,
  CORPORATE_RATE = 2,
  AGENCY_RATE = 3,
  UNRECOGNIZED = -1,
}
