import memoize from 'lodash/memoize';

function isCurrencyDiplaySupported(currencyDisplay: string | undefined): boolean {
  if (!currencyDisplay) {
    return true;
  }

  try {
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay,
    }).format(1000);
  } catch (e) {
    return false;
  }
  return true;
}

export default memoize(isCurrencyDiplaySupported);
