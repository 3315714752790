import { useFeatureFlag, useFetchCompanySupportConfig, useTmcId } from 'obt-common';
import { useLoggedInUserBasicInfo } from 'obt-common/providers/AuthProvider';
import { isGenesysAuthCloudConfigValid } from 'obt-common/utils/genesys/isGenesysAuthCloudConfigValid';
import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from 'src/routes';
import GenesysChatProvider from './GenesysChatProvider';
import InitializeAuthenticatedGenesysChat from './InitializeAuthenticatedGenesysChat';
import InitializeFreshchat from './InitializeFreshchat';
import { InitializeTwilioChat } from './InitializeTwilioChat';
import { InitializeZendeskChat } from './InitializeZendeskChat';

const ChatInitializer: FC<PropsWithChildren> = ({ children }) => {
  const tmcId = useTmcId();
  const location = useLocation();
  const loggedInUserBasicInfo = useLoggedInUserBasicInfo();
  const { data: supportConfigData, isLoading } = useFetchCompanySupportConfig(tmcId?.tmcId || '');
  // Determine if Twilio chat is enabled through feature flag or support configuration
  const isTwilioChatEnabled =
    useFeatureFlag('FE_ENABLE_TWILIO_CHAT') || supportConfigData?.supportConfig.twilioChatConfig?.enabled;
  if (isLoading || !supportConfigData) {
    return null;
  }
  const isGenesysCloudValid = isGenesysAuthCloudConfigValid(supportConfigData?.supportConfig);

  // Note: Genesys cloud redirect is handled in useRedirectAfterLoginIfNeeded.ts

  // Check if the current route matches any of the specified paths,
  // If the current route matches, return only the children components
  if (
    [ROUTE_PATHS.PREFERRED_LANGUAGE, ROUTE_PATHS.USER_ACTION_ALL_ROUTES, ROUTE_PATHS.SPOTTERNET_ALL].some((item) =>
      location.pathname.startsWith(item),
    )
  ) {
    return children as JSX.Element;
  }

  return (
    <>
      {/**
       * A feature flag has been introduced as a temporary fix to enable Twilio chat at the organization level.
       * If the flag is enabled, Twilio chat will be displayed regardless of the support configuration response.
       * Otherwise, we will rely on the support configuration to determine which chat widget to show.
       */}
      {isTwilioChatEnabled && <InitializeTwilioChat />}
      {isGenesysCloudValid ? (
        <>
          {!loggedInUserBasicInfo.isImpersonationSession && <InitializeAuthenticatedGenesysChat />}
          {children}
        </>
      ) : (
        <GenesysChatProvider>{children}</GenesysChatProvider>
      )}
      <InitializeFreshchat />
      <InitializeZendeskChat />
    </>
  );
};

export default ChatInitializer;
