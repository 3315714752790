import { e2eHeader } from '../../../utils/e2eHeader';
import addRequestInterceptor from '../addRequestInterceptor';

function injectE2EHeader() {
  return addRequestInterceptor({
    onFulfilled: async (config) => {
      const extraHeaders = await e2eHeader();

      config.headers = { ...config.headers, ...extraHeaders };

      return config;
    },
  });
}

export default injectE2EHeader;
