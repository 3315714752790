import type { Auth as AuthAmplify } from '@aws-amplify/auth';
import type { Hub as HubAmplify, HubCapsule } from '@aws-amplify/core';
import type { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import type { AxiosError } from 'axios';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import type { ReactElement } from 'react';
import * as React from 'react';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import type { OAuthTokenResponse } from '@spotnana/types/openapi/models/oauth-token-response';
import api from '../../api';
import { BREX_REWARDS_APPLICATION_ID, REDIRECTION_NEEDED_FOR_BE, brexIDP } from '../../constants/auth';
import useApplicationId from '../../core/applicationId/useApplicationId';
import { useOrgId } from '../../core/orgId/useOrgId';
import { useTmcId } from '../../core/tmcId';
import { webSessionStorage } from '../../utils/SessionStorage';
import { storage } from '../../utils/Storage';

import useStorage from '../../hooks/useStorage';
import { useProfileReadQuery } from '../../queries/profile';
import type { AuthenticatedUserBasicInfoExistingUser } from '../../types/api/v1/auth/services';
import type { UserOrgId } from '../../types/api/v1/obt/common/user_org_id';
import type { RoleInfoType } from '../../types/api/v1/obt/profile/role/roles_info';
import { Persona } from '../../types/api/v2/obt/model/persona';
import type { AmplifyError, IAuthenticatedUserBasicInfo } from '../../types/auth';
import type { InternalAPIError, SpotnanaQueryResult } from '../../types/common';
import { StorageKeys } from '../../types/storage';
import type { ITraveler, IUserOrgId, UserOrgIdOrNull } from '../../types/traveler';
import { clearSessionId, getImpersonationToken, logger } from '../../utils';
import refreshTokens from './refreshTokens';
import type { IAuthWorkflow, ITokens } from './types';
import { IAuthError, IAuthWorkflowType } from './types';
import useInjectAuthHeader from './useInjectAuthHeader';
import useOnApiLogout from './useOnApiLogout';
import {
  buildCustomStateForOAuthLogin,
  genesysAuthCodeCatch,
  genesysAuthRedirect,
  getAccessTokenFromStorage,
  getClientIdAndUserPoolIdFromAccessToken,
  getRefreshTokenFromStorage,
  shouldGenesysRedirect,
  storeTokensInStorage,
} from './utils';

enum AuthProviderErrorCodes {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
}

export enum LoginState {
  DEFAULT,
  REGISTERING,
  AUTHENTICATING,
  LOGGED_IN,
  LOGGED_OUT,
}

// TODO: Improve types for context
interface IAuthContext {
  loginState: LoginState;
  signUp: (username: string, password: string) => Promise<ISignUpResult>;
  confirmSignUp: (username: string, code: string) => Promise<void>;
  resendSignupCode: (username: string) => Promise<string>;
  userpassLogin: (username: string, password: string, persona?: Persona) => Promise<void>;
  ssoLogin: (key: string) => Promise<void>;
  cognitoLogin: () => Promise<void>;
  resetPassword: (username: string) => Promise<void>;
  resetPasswordSubmit: (username: string, code: string, password: string) => Promise<void>;
  emailOtp: (username: string, persona?: Persona) => Promise<CognitoUser>;
  emailOtpSubmit: (user: CognitoUser, code: string) => Promise<void>;
  getAuthToken: () => Promise<string>;
  setLogin: () => Promise<void>;
  logout: (preserveUserContext?: boolean) => Promise<void>;
  loggedInUserId: UserOrgIdOrNull;
  userBasicInfo: IAuthenticatedUserBasicInfo;
  authenticateUserByTokens: () => Promise<void>;
  authenticateUserByAuthCode: (
    authToken: OAuthTokenResponse,
    authCompanyId: string,
    authTmcId: string,
  ) => Promise<void>;
  reconfigureAuth: (config: any) => void;
  setUserBasicInfo: React.Dispatch<React.SetStateAction<IAuthenticatedUserBasicInfo>>;
  refetchUserBasicInfo: () => Promise<IAuthenticatedUserBasicInfo>;
}

interface IProps {
  amplifyConfig: any;
  Auth: typeof AuthAmplify;
  Hub?: typeof HubAmplify;
  disableVerifyUserSessionOnMount: boolean;
  workflow: IAuthWorkflow;
  children?: React.ReactNode;
}

interface IAmplifyConfigureProps {
  amplifyConfig: any;
  setAmplifyConfig: (updatedAmplifyConfig: any) => void;
  Auth: typeof AuthAmplify;
  children?: React.ReactNode;
}

interface IInitializeUserOnMountProps {
  Auth: typeof AuthAmplify;
  Hub?: typeof HubAmplify;
  disableVerifyUserSessionOnMount: boolean;
  setLogin: IAuthContext['setLogin'];
  reset: () => void;
  children?: React.ReactNode;
}

export const AuthContext = React.createContext<IAuthContext>({
  loginState: LoginState.DEFAULT,
  userBasicInfo: {},
} as IAuthContext);

const AmplifyConfigure: React.FC<IAmplifyConfigureProps> = ({
  amplifyConfig,
  setAmplifyConfig,
  Auth,
  children,
}): ReactElement => {
  const [isConfigured, setIsConfigured] = useState(false);
  useLayoutEffect(() => {
    async function configure() {
      try {
        const accessToken = await getAccessTokenFromStorage();

        if (accessToken) {
          const { clientId, userPoolId } = getClientIdAndUserPoolIdFromAccessToken(
            new CognitoAccessToken({ AccessToken: accessToken }),
          );
          if (clientId && userPoolId) {
            const updatedAmplifyConfig = {
              ...amplifyConfig,
              aws_user_pools_id: userPoolId,
              aws_user_pools_web_client_id: clientId,
            };
            genesysAuthCodeCatch();
            // Note: genesys auth code must be handled before Auth.config()
            //       or Amplify will handle and remove /?code=val in search string
            Auth.configure(updatedAmplifyConfig);
            setAmplifyConfig(updatedAmplifyConfig);
            return;
          }
        }
        genesysAuthCodeCatch();
        Auth.configure(amplifyConfig);
      } catch (error) {
        Auth.configure(amplifyConfig);
      } finally {
        setIsConfigured(true);
      }
    }

    configure();
    // TODO: FIX_ESLINT_VIOLATION
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return isConfigured ? <>{children}</> : <></>;
};

const InitializeUserOnMount: React.FC<IInitializeUserOnMountProps> = ({
  disableVerifyUserSessionOnMount,
  Auth,
  Hub,
  setLogin,
  reset,
  children,
}): JSX.Element => {
  useLayoutEffect(() => {
    // attempt to fetch the info of the user that was already logged in
    // attempt only when not authenticating via tokens
    if (!disableVerifyUserSessionOnMount) {
      Auth.currentAuthenticatedUser()
        .then(async () => {
          await setLogin(); // Must be handled upon authentication or user will feel the redirect
        })
        .catch((error: any) => {
          if (error.message !== REDIRECTION_NEEDED_FOR_BE) {
            reset();
          }
        });
    }

    if (Hub) {
      const authListener = async ({ payload }: HubCapsule): Promise<void> => {
        switch (payload.event) {
          case 'signIn':
            setLogin();
            break;
          case 'signIn_failure':
          case 'signUp_failure':
          case 'completeNewPassword_failure':
          case 'forgotPassword_failure':
          case 'tokenRefresh_failure': {
            const error = new Error(payload.data?.message ?? payload.event);
            error.name = payload.message ?? payload.event;
            error.stack = payload.data?.stack ?? error.stack;
            logger.error(error);
            break;
          }
          default:
            break;
        }
      };
      Hub.listen('auth', authListener);

      return (): void => {
        Hub.remove('auth', authListener);
      };
    }
    return (): void => {
      // added for consistent return
    };
    // TODO: FIX_ESLINT_VIOLATION
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

/* Auth is being passed as prop, because the common transpiled code was not behaving as expected.
 The storage logic was taking some time and Auth.currentAuthenticatedUser() always used to throw
 until and unless wrapped in setTimeOut, which is hacky approach
*/
export const AuthProvider: React.FC<IProps> = ({
  children,
  amplifyConfig,
  Auth,
  Hub,
  disableVerifyUserSessionOnMount,
  workflow,
}): ReactElement => {
  const amplifyAuthConfig = useRef(amplifyConfig);
  const setAmplifyAuthConfig = useCallback((updatedAmplifyConfig: any) => {
    amplifyAuthConfig.current = updatedAmplifyConfig;
  }, []);

  const [loginState, setLoginState] = useState<LoginState>(LoginState.DEFAULT);
  const [userBasicInfo, setUserBasicInfo] = useState<IAuthenticatedUserBasicInfo>({
    isUserRegistered: true,
    isImpersonationSession: false,
  });
  const { data: loggedInUserId, setData: setLoggedInUserId } = useStorage<IUserOrgId>(StorageKeys.LOGGED_IN_USER_ID);
  const { setApplicationId, removeApplicationId } = useApplicationId();
  const { tmcId, setTmcId, removeTmcId } = useTmcId();
  const { orgId, setOrgId, removeOrgId } = useOrgId();

  const { setData: setLoggedInUser } = useStorage<AuthenticatedUserBasicInfoExistingUser>(StorageKeys.CURRENT_USER);
  const getAuthTokensPromise = useRef<Promise<string> | null>(null);

  const reset = useCallback(async (): Promise<void> => {
    await storage.clearWithoutAppId();
    setLoginState(LoginState.LOGGED_OUT);
    await setLoggedInUserId(null);
  }, [setLoggedInUserId]);

  const logout = useCallback(
    async (preserveUserContext?: boolean): Promise<void> => {
      try {
        if (!preserveUserContext) {
          clearSessionId();

          /**
           * Before code reached `.finally()` will be page redirect!
           * So, `storage.clear()` won't be called and `APPLICATION_ID` will still exist in the storage.
           * see: ST-29516
           */
          await removeApplicationId().catch(noop);
          await removeTmcId().catch(noop);
          await removeOrgId().catch(noop);
        }
        await Auth.signOut().then(() => {
          const isThirdPartyAuth = webSessionStorage.getItem(StorageKeys.THIRD_PARTY_AUTH_IDP);

          // Don't reload the page if the user is in a headless auth flow since we need to call
          // reset and have LoginState be LOGGED_OUT in order to complete the flow. Reloading will
          // reset the state to DEFAULT and the user will be stuck in the headless auth flow.
          const isHeadlessAuthFlow = webSessionStorage.getItem(StorageKeys.HEADLESS_AUTH_REDIRECT_URL_KEY);
          if (isThirdPartyAuth && !isHeadlessAuthFlow) {
            window.location.reload();
          }
        });
      } catch (error) {
        // The error should not be thrown
      } finally {
        reset();
        storage.clear();
      }
    },
    [removeApplicationId, removeTmcId, removeOrgId, Auth, reset],
  );

  const setLogin = useCallback(async (): Promise<void> => {
    try {
      setLoginState(LoginState.AUTHENTICATING);
      const currentUserInfo = (await api('GET', 'getLoggedInUserBasicInfo')) as IAuthenticatedUserBasicInfo;
      if (currentUserInfo.isUserRegistered) {
        if (currentUserInfo.existingUser?.userOrgId) {
          setLoggedInUserId(currentUserInfo.existingUser.userOrgId);
          setLoggedInUser(currentUserInfo.existingUser);
          setLoginState(LoginState.LOGGED_IN);
          // Usually tmcId and orgId are set using the auth-config context, but some auth flows (e,g, token exchange) don't go through
          // the multi-app login flows so we need to set it here so the backend has the right account context. Reset both values to ensure
          // tmcId and orgId pair match. We also need to ensure we do not override the values while reloading an
          // impersonation session.
          const impersonationToken = await getImpersonationToken();
          if (!impersonationToken && (!tmcId || !orgId)) {
            setTmcId(currentUserInfo.existingUser.userOrgId.tmcBasicInfo?.contractingTmc?.id?.id ?? '');
            setOrgId(currentUserInfo.existingUser.userOrgId.organizationId?.id ?? '');
          }
        } else {
          throw new Error(AuthProviderErrorCodes.USER_NOT_FOUND);
        }
      } else {
        setLoginState(LoginState.REGISTERING);
      }
      try {
        if (await shouldGenesysRedirect()) {
          await genesysAuthRedirect();
          // For auth for authenticated Genesys chat
        }
      } catch (e) {
        logger.error(e as Error);
      }
      setUserBasicInfo(currentUserInfo);
    } catch (err: unknown) {
      const axiosError = err as AxiosError<InternalAPIError>;
      const axiosErrorResponseData = axiosError?.response?.data;
      const errorCode = axiosErrorResponseData?.errorMessages?.[0]?.errorCode ?? axiosErrorResponseData?.errorCode;

      if (
        // The interceptor injectHandleRedirectOn409 throws custom error REDIRECTION_NEEDED_FOR_BE when redirect is needed
        // for Brex to refresh the token. This is temporary solution, logic will be moved into user actions: ST-72824, ST-73249
        (err as Error)?.message !== REDIRECTION_NEEDED_FOR_BE &&
        (errorCode === 'LOGIN_NOT_PERMITTED_VIA_DIFFERENT_APPLICATION' || !errorCode)
      ) {
        // !errorCode for unknown error, e.g. network down
        // please refer to unit test "Should handle an error and logout the user if the /me API is failed"
        await logout();
        return;
      }

      throw err;
    }
  }, [setLoginState, setLoggedInUserId, setLoggedInUser, setTmcId, setOrgId, setUserBasicInfo, tmcId, orgId, logout]);

  const refetchUserBasicInfo = useCallback(async (): Promise<IAuthenticatedUserBasicInfo> => {
    const currentUserInfo = (await api('GET', 'getLoggedInUserBasicInfo')) as IAuthenticatedUserBasicInfo;
    setUserBasicInfo(currentUserInfo);
    return currentUserInfo;
  }, [setUserBasicInfo]);

  const handleError = useCallback((): void => {
    reset();
  }, [reset]);

  const getTokensFromTokenExchange = useCallback(async (): Promise<ITokens> => {
    if (workflow.type !== IAuthWorkflowType.TOKEN_EXCHANGE) {
      throw new Error(`Incorrect workflow type, required: ${IAuthWorkflowType.TOKEN_EXCHANGE}`);
    }

    const { access_token: initialAccessToken, refresh_token: initialRefreshToken } =
      await workflow.initiateTokenExchange();

    if (!initialAccessToken || !initialRefreshToken) {
      throw new Error(
        'INVALID_TOKEN: After calling a workflow.initiateTokenExchange(), no access token or refresh token was discovered',
      );
    }

    return refreshTokens(initialAccessToken, initialRefreshToken);
  }, [workflow]);

  const initiateLoginViaTokens = useCallback(
    async (accessToken: string, refreshToken: string, idToken: string, shouldSetLogin: boolean): Promise<void> => {
      const AccessToken = new CognitoAccessToken({ AccessToken: accessToken });
      const { clientId, userPoolId } = getClientIdAndUserPoolIdFromAccessToken(AccessToken);
      Auth.configure({
        ...amplifyConfig,
        aws_user_pools_id: userPoolId,
        aws_user_pools_web_client_id: clientId,
      });

      // eslint-disable-next-line no-underscore-dangle
      await storeTokensInStorage(accessToken, refreshToken, idToken, (Auth as any)._storage);

      await Auth.currentAuthenticatedUser();
      if (shouldSetLogin) {
        await setLogin();
      }

      return Promise.resolve();
    },
    [amplifyConfig, Auth, setLogin],
  );

  const authenticateUserByTokens = useCallback(async (): Promise<void> => {
    try {
      if (workflow.type !== IAuthWorkflowType.TOKEN_EXCHANGE) {
        throw new Error(`Incorrect workflow type, required: ${IAuthWorkflowType.TOKEN_EXCHANGE}`);
      }

      const {
        access_token: accessToken,
        refresh_token: refreshToken,
        id_token: idToken,
      } = await getTokensFromTokenExchange();

      return initiateLoginViaTokens(accessToken, refreshToken, idToken, true /** shouldSetLogin */);
    } catch (error) {
      handleError();
      return Promise.reject(error);
    }
  }, [workflow, getTokensFromTokenExchange, initiateLoginViaTokens, handleError]);

  const authenticateUserByAuthCode = useCallback(
    async (authToken: OAuthTokenResponse, authTokenCompanyId: string, authTokenTmcId: string): Promise<void> => {
      try {
        setTmcId(authTokenTmcId);
        setOrgId(authTokenCompanyId);

        // fetch refresh tokens
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          id_token: idToken,
        } = await refreshTokens(authToken.access_token, authToken.refresh_token ?? '');

        // Initiate login by creating and storing session, but don't try to fetch /me or any
        // endpoint data. This is because the hook used to set auth headers is inconsistently
        // able to fetch the tokens from storage in time for the first API call and will
        // sign the user out if it fails.
        return initiateLoginViaTokens(accessToken, refreshToken, idToken, false /** shouldSetLogin */);
      } catch (error) {
        handleError();
        return Promise.reject(error);
      }
    },
    [setTmcId, setOrgId, initiateLoginViaTokens, handleError],
  );

  const signUp = useCallback(
    (username: string, password: string): Promise<ISignUpResult> =>
      Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
        },
      }).catch((err: AmplifyError) => {
        handleError();
        throw err;
      }),
    [Auth, handleError],
  );

  const confirmSignUp = useCallback(
    (username: string, code: string): Promise<void> =>
      Auth.confirmSignUp(username, code).then(() => {
        /**
         * TODO: (Himesh) Verify if reportEvent requires auth token and
         * if yes, decide on how to report sign up event, as auth token wouldn't be available right now
         */
        // reportEvent('USER_SIGN_UP', { username });
      }),
    [Auth],
  );

  const resendSignupCode = useCallback((username: string): Promise<string> => Auth.resendSignUp(username), [Auth]);

  const userpassLogin = useCallback(
    async (username: string, password: string, persona?: Persona): Promise<void> => {
      try {
        const clientMetadata = { orgId: orgId ?? '', persona: persona ?? Persona.UnknownPersona };
        await Auth.signIn(username, password, clientMetadata);
        await setLogin();
      } catch (error) {
        const newError = error as AmplifyError;
        if (newError.code === 'UserNotFoundException') {
          newError.message = 'Invalid username or password';
        }
        if (!['UserNotConfirmedException', 'NotAuthorizedException'].includes(newError.code)) {
          throw newError;
        }
        reset();
        throw error;
      }
    },
    [Auth, setLogin, reset, orgId],
  );

  const ssoLogin = useCallback(
    async (key: string): Promise<void> => {
      try {
        if (key === brexIDP) {
          await setApplicationId(BREX_REWARDS_APPLICATION_ID);
        }
        await Auth.federatedSignIn({
          customProvider: key,
          customState: JSON.stringify(buildCustomStateForOAuthLogin()),
        });
      } catch (err) {
        handleError();
        throw err;
      }
    },
    [Auth, handleError, setApplicationId],
  );

  const cognitoLogin = useCallback(async (): Promise<void> => {
    setLoginState(LoginState.AUTHENTICATING);
    try {
      await Auth.federatedSignIn();
      return await setLogin();
    } catch (err) {
      return handleError();
    }
  }, [Auth, setLogin, handleError]);

  const emailOtp = useCallback(
    async (username: string, persona?: Persona) => {
      const clientMetadata = { orgId: orgId ?? '', persona: persona ?? Persona.UnknownPersona };

      Auth.configure({ ...amplifyConfig, authenticationFlowType: 'CUSTOM_AUTH' });
      const user = await Auth.signIn(username, undefined, clientMetadata).catch((err: AmplifyError) => {
        handleError();
        throw err;
      });

      // Verifying that amplify is also configured for email OTP.
      if (user.challengeName === 'CUSTOM_CHALLENGE') {
        return user;
      }
      handleError();
      return null;
    },
    [Auth, amplifyConfig, handleError, orgId],
  );

  const emailOtpSubmit = useCallback(
    async (user: CognitoUser, code: string): Promise<void> => {
      try {
        await Auth.sendCustomChallengeAnswer(user, code);
      } catch (error: unknown) {
        handleError();
        throw error;
      }
      return setLogin();
    },
    [Auth, handleError, setLogin],
  );

  const resetPassword = useCallback(
    (username: string): Promise<void> =>
      Auth.forgotPassword(username).catch((err: AmplifyError) => {
        handleError();
        throw err;
      }),
    [Auth, handleError],
  );

  const resetPasswordSubmit = useCallback(
    async (username: string, code: string, password: string): Promise<void> => {
      await Auth.forgotPasswordSubmit(username, code, password);
      await Auth.signIn(username, password);
      await Auth.signOut({ global: true });
    },
    [Auth],
  );

  const refreshAndStoreTokensForTokenExchange = useCallback(
    async (accessToken: string, refreshToken: string): Promise<void> => {
      // To avoid TS warnings
      if (workflow.type !== IAuthWorkflowType.TOKEN_EXCHANGE) {
        throw new Error(`Incorrect workflow type, required: ${IAuthWorkflowType.TOKEN_EXCHANGE}`);
      }

      try {
        const updatedTokens = await refreshTokens(accessToken, refreshToken);
        await storeTokensInStorage(
          updatedTokens.access_token,
          updatedTokens.refresh_token,
          updatedTokens.id_token,
          // eslint-disable-next-line no-underscore-dangle
          (Auth as any)._storage,
        );
      } catch (error) {
        // Refresh token expired, so initiate token exchange again
        if ((error as { errorCode: string }).errorCode === 'USER_NOT_AUTHORIZED') {
          const updatedTokens = await getTokensFromTokenExchange();
          await storeTokensInStorage(
            updatedTokens.access_token,
            updatedTokens.refresh_token,
            updatedTokens.id_token,
            // eslint-disable-next-line no-underscore-dangle
            (Auth as any)._storage,
          );
          return;
        }

        throw error;
      }
    },
    [Auth, getTokensFromTokenExchange, workflow.type],
  );

  const getAccessTokenOrRefreshTokens = useCallback(
    async (attemptCount = 0): Promise<string> => {
      const accessToken = await getAccessTokenFromStorage();
      const refreshToken = await getRefreshTokenFromStorage();

      try {
        const token = await Auth.currentSession();
        return token.getAccessToken().getJwtToken();
      } catch (error) {
        if (workflow.type !== IAuthWorkflowType.TOKEN_EXCHANGE) {
          throw error;
        }

        if (attemptCount > 0) {
          throw error;
        }

        if (!accessToken || !refreshToken) {
          throw error;
        }

        if ((error as { code: string }).code !== IAuthError.NotAuthorizedException) {
          throw error;
        }

        await refreshAndStoreTokensForTokenExchange(accessToken, refreshToken);
        return getAccessTokenOrRefreshTokens(attemptCount + 1);
      }
    },
    [Auth, workflow, refreshAndStoreTokensForTokenExchange],
  );

  const getAuthTokenWithoutErrorHandling = useCallback(async (): Promise<string> => {
    if (!getAuthTokensPromise.current) {
      getAuthTokensPromise.current = getAccessTokenOrRefreshTokens().finally(() => {
        getAuthTokensPromise.current = null;
      });
    }

    const token = await getAuthTokensPromise.current;
    return token;
  }, [getAccessTokenOrRefreshTokens]);

  const getAuthToken = useCallback(async (): Promise<string> => {
    try {
      const token = await getAuthTokenWithoutErrorHandling();
      return token;
    } catch (error) {
      reset();
      return '';
    }
  }, [getAuthTokenWithoutErrorHandling, reset]);

  const reconfigureAuth = useCallback(
    (partialConfig: unknown) => {
      const mergedConfig = merge(amplifyAuthConfig.current, partialConfig);
      Auth.configure(mergedConfig);
    },
    [Auth],
  );

  useInjectAuthHeader(() =>
    getAuthTokenWithoutErrorHandling().catch((err) => {
      reset();
      return Promise.reject(err);
    }),
  );
  useOnApiLogout(logout);

  const values = React.useMemo(
    () => ({
      loginState,
      signUp,
      confirmSignUp,
      resendSignupCode,
      userpassLogin,
      ssoLogin,
      cognitoLogin,
      resetPassword,
      resetPasswordSubmit,
      emailOtp,
      emailOtpSubmit,
      setLogin,
      logout,
      getAuthToken,
      loggedInUserId,
      userBasicInfo,
      authenticateUserByTokens,
      authenticateUserByAuthCode,
      reconfigureAuth,
      setUserBasicInfo,
      refetchUserBasicInfo,
    }),
    [
      loginState,
      signUp,
      confirmSignUp,
      resendSignupCode,
      userpassLogin,
      ssoLogin,
      cognitoLogin,
      resetPassword,
      resetPasswordSubmit,
      emailOtp,
      emailOtpSubmit,
      setLogin,
      logout,
      getAuthToken,
      loggedInUserId,
      userBasicInfo,
      authenticateUserByTokens,
      authenticateUserByAuthCode,
      reconfigureAuth,
      setUserBasicInfo,
      refetchUserBasicInfo,
    ],
  );

  return (
    <AmplifyConfigure amplifyConfig={amplifyAuthConfig.current} setAmplifyConfig={setAmplifyAuthConfig} Auth={Auth}>
      <InitializeUserOnMount
        Auth={Auth}
        Hub={Hub}
        disableVerifyUserSessionOnMount={disableVerifyUserSessionOnMount}
        setLogin={setLogin}
        reset={reset}
      >
        <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
      </InitializeUserOnMount>
    </AmplifyConfigure>
  );
};

// We also create a simple custom hook to read these values from. We want our React components
// to know as little as possible on how everything is handled, so we are not only abtracting them from
// the fact that we are using React's context, but we also skip some imports.
export const useAuth = (): IAuthContext => {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('`useAuth` hook must be used within a `AuthProvider` component');
  }
  return context;
};

// Ideally this should only be used by logged in routes
// to avoid null checks.
export function useLoggedInUserId(): IUserOrgId {
  const context = React.useContext(AuthContext);
  return context.loggedInUserId!;
}

// Helper hook which wraps useProfileReadQuery and gives all user info.
export function useLoggedInUser(): SpotnanaQueryResult<ITraveler> {
  const loggedInUserId = useLoggedInUserId();
  return useProfileReadQuery(loggedInUserId);
}

export function useIsLoggedIn(): boolean {
  const context = React.useContext(AuthContext);
  return !!context.loggedInUserId;
}

// Helper hook to get user basic info
export function useLoggedInUserBasicInfo(): IAuthenticatedUserBasicInfo {
  const context = React.useContext(AuthContext);
  return context.userBasicInfo!;
}

interface UseLoggedUserIdReturnType {
  companyId?: UserOrgId['organizationId'];
  userId?: UserOrgId['userId'];
  roles: RoleInfoType[];
}

export function useLoggedUserId(): UseLoggedUserIdReturnType {
  const { existingUser } = useLoggedInUserBasicInfo();

  return useMemo(
    () => ({
      companyId: existingUser?.userOrgId?.organizationId,
      userId: existingUser?.userOrgId?.userId,
      roles: existingUser?.roleInfos.map((role) => role.type) ?? [],
    }),
    [existingUser],
  );
}
