import { useMemo } from 'react';
import { matchPath, Redirect, useLocation, generatePath } from 'react-router-dom';

import { useAuth, useFeatureFlag, useHasUserAccess, userRolesByFeature } from 'obt-common';
import { stringifyParams } from 'obt-common/utils/common';
import { ROUTE_PATHS } from 'src/routes';
import { IncompleteUserActionName } from 'obt-common/types/userActions';
import { hasIncompleteUserAction } from '../../shared/utils';

/**
 * Redirects to Onboarding page if onboarding incomplete action is present
 */
export function RedirectToOnboardingPage() {
  const isOnboardingEnabled = useFeatureFlag('FE_CORP_ONBOARDING_IMPLEMENTATION');
  const { userBasicInfo } = useAuth();
  const { pathname, search } = useLocation();
  const userHasOnboardingAction = useMemo(
    () => hasIncompleteUserAction(userBasicInfo, IncompleteUserActionName.ONBOARDING),
    [userBasicInfo],
  );
  const userHasOnboardingAccess = useHasUserAccess(userRolesByFeature.onboarding);

  if (
    isOnboardingEnabled &&
    userHasOnboardingAction &&
    userHasOnboardingAccess &&
    !matchPath(pathname, generatePath(ROUTE_PATHS.ONBOARDING))
  ) {
    const redirectParams = {
      pathname: generatePath(ROUTE_PATHS.ONBOARDING),
      search: stringifyParams({ redirectUrl: `${pathname}${search}` }),
    };

    return <Redirect to={redirectParams} />;
  }

  return null;
}
