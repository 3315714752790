import { defineCommonMessage } from '../../../translations/defineMessage';
import { PaymentMethod } from '../../../types/api/v2/obt/model/payment-method';

import { getDateDiff } from '../../../date-utils';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import { MoneyUtil, getNameStringFromName, maskCardNumber } from '../../../utils';
import type { MiscInfo } from '../../../types/api/v2/obt/model/misc-info';

export class MiscPnrV3Manager extends PnrV3Manager {
  miscPnr?: MiscInfo;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.miscPnr = this.pnrData.miscPnr;
  }

  public totalDays(): number {
    const startDate = this.miscPnr?.startDateTime.iso8601 ?? '';
    const endDate = this.miscPnr?.endDateTime.iso8601 ?? '';
    return Math.max(getDateDiff(startDate, endDate), 1);
  }

  public miscDetails() {
    const { miscPnr } = this;
    const startDate = miscPnr?.startDateTime;
    const endDate = miscPnr?.endDateTime;

    const totalDays = `${this.totalDays()} Days`;
    const description = miscPnr?.description;
    const invoiceDate = this.pnrData.bookingHistory;

    const vendorConfirmationNumber = miscPnr?.vendorConfirmationNumber;

    const passengerNames =
      this.pnrData?.pnrTravelers?.map((pnrTraveler) => ({
        name: getNameStringFromName(pnrTraveler.personalInfo?.name),
      })) ?? [];

    return {
      startDate,
      endDate,
      totalDays,
      description: description ?? '',
      invoiceDate,
      vendorConfirmationNumber: vendorConfirmationNumber ?? '',
      passengerNames,
      bookingId: this.pnrId,
    };
  }

  public paymentInfos() {
    const { totalFareAmount, totalFare } = this.pnrData;

    const numPax = this.pnrData.pnrTravelers?.length ?? 1;

    const baseAmountPerPax = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.base).divide(numPax);
    const taxAmountPerPax = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.tax).divide(numPax);
    const totalAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFare);

    const paymentMethods =
      this.pnrData.paymentInfo?.map((paymentInfo) => {
        if (paymentInfo.fop.paymentMethod === PaymentMethod.CreditCard) {
          return {
            type: paymentInfo.fop.paymentMethod,
            name: maskCardNumber(paymentInfo.fop.card?.number ?? ''),
          };
        }
        return {
          type: paymentInfo.fop.paymentMethod,
          name: '',
        };
      }) ?? [];

    const extras = this.miscPnr?.rate.extras ?? [];
    const totalExtras = extras.reduce((accumulator, currentValue) => {
      return accumulator.add(MoneyUtil.convertV2MoneyToMoneyUtil(currentValue.amount));
    }, MoneyUtil.zeroMoney());

    return {
      paymentMethods,
      baseAmountPerPax,
      taxAmountPerPax,
      totalAmount,
      totalExtras,
    };
  }

  public pnrTitle() {
    const pnrTitle = defineCommonMessage('Other travel item');
    return pnrTitle;
  }
}
