import type { IAirSuggestion, IFlightSearchSegmentState } from './air-segments';
import type { BulkOperationFileType } from './api/v2/obt/model';

export interface Storage {
  getItem: (key: StorageKeys) => Promise<string | null | undefined>;
  getAllItems: () => Promise<Record<string, string | null | undefined>>;
  setItem: (key: StorageKeys, value: string) => Promise<void>;
  removeItem: (key: StorageKeys) => Promise<void>;
  clear: () => Promise<void>;
  clearWithoutAppId: () => Promise<void>;
}

export enum StorageKeys {
  TMC_ID = 'tmcId',
  APPLICATION_ID = 'applicationId',
  ORG_ID = 'orgId',
  CURRENT_USER = 'currentUser',
  DEMO_SHOWN_LOYALTY_SWIPEABLE_ROW = 'demoShownLoyaltySwipeableRow',
  DEMO_SHOWN_PASSPORT_SWIPEABLE_ROW = 'demoShownPassportSwipeableRow',
  DEMO_SHOWN_PAYMENT_SWIPEABLE_ROW = 'demoShownPaymentSwipeableRow',
  IMPERSONATION_TOKEN = 'spotnana-impersonationToken',
  LAST_SHOWN_FEEDBACK_TIME_SEC = 'LAST_SHOWN_FEEDBACK_TIME_SEC',
  LOGGED_IN_USER_ID = 'loggedInUserId',
  NOTIFICATION_ENABLED = 'notificationEnabled',
  PRIMARY_TRAVELER_ID = 'travelerId',
  PRODUCT_VERSION_CHANGE_REFRESH_TIME = 'productVersionChangeRefreshTime',
  SECONDARY_TRAVELER_IDS = 'secondaryTravelerIds',
  SHOULD_REDIRECT_REQUESTS_TO_SABRE_SIMULATOR = 'shouldRedirectRequestsToSabreSimulator',
  SKIP_REQUEST_NOTIFICATION_PERMISSION_KEY = 'skipNotificationPermissionRequest',
  VALID_REDIRECT_TO_SABRE_USER_LIST = 'validRedirectToSabreUserList',
  HEADLESS_AUTH_REDIRECT_URL_KEY = 'headlessAuthRedirectURLKey',
  AUTH_REDIRECT_URL_KEY = 'authRedirectURLKey',
  AUTH_REDIRECT_COMPANY_ID_KEY = 'authRedirectCompanyIdKey',
  THIRD_PARTY_AUTH_IDP = 'thirdPartyAuthIDP',
  PREFERRED_CURRENCY = 'preferredCurrency',
  SABRE_SIM_WORKFLOW_ID = 'sabreSimWorkflowId',
  SABRE_SIM_LEGS_DATE = 'sabreSimLegsDate',
  SSR_OSI_FORM_VALUES = 'ssrOsiFormValues',
  LAST_ACCESS_TIME = 'lastAccessTime',
  IS_SESSION_INACTIVE = 'isSessionInactive',
  URL_ENCODING_KEY = 'urlEncodingKey',
  URL_BEFORE_GENESYS_REDIRECT = 'urlBeforeGenesysRedirect',
  API_ENDPOINTS = 'apiEndpoints',
  APP_EXPIRED_RELOAD_TIME = 'appExpiredReloadTime',
  NEW_BULK_UPLOAD_IDS = 'newBulkUploadIds',
  LAST_SEARCHED_ITINERARY = 'lastSearchedItinerary',
  ABSOLUTE_SESSION_TIMEOUT_START_TIME = 'absoluteSessionTimeoutStartTime',
  HAS_DEFERRED_APP_UPDATE = 'hasDeferredAppUpdate',
  TOKEN_EXCHANGE_IDP = 'tokenExchangeIDP',
  TOKEN_EXCHANGE_ERROR_HANDLING = 'tokenExchangeErrorHandling',
}
export type StoredSegments = IFlightSearchSegmentState<IAirSuggestion[]>[];
export type StoredSSR = { code: string; info?: string; label: string };
export type StoredSSRWithExtraInfo = StoredSSR & {
  hasEditableTextField?: boolean;
  isEditableTextFieldRequired?: boolean;
  categoryType?: string;
};
export type SsrOsiStorage = {
  mealPreferences?: StoredSSRWithExtraInfo[];
  specialServiceRequests?: StoredSSRWithExtraInfo[];
  otherServiceInformation?: { info: string }[];
};
export type SsrOsiPerTraveler = Record<string, SsrOsiStorage>;
export type SsrOsiFormValues = Record<string, SsrOsiPerTraveler>;

export type UrlEncodingKey = string;

export type BulkUploadIdsStorage = {
  [K in (typeof BulkOperationFileType)[keyof typeof BulkOperationFileType]]?: string[];
};
