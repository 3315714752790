import { css } from '@emotion/react';
import { breakpoints, flex, margins } from '@spotnana/blocks/src/utils';

export const action_items = css`
  ${flex.init}
  ${flex.align.center}
  gap: 0.25rem;
`;

export const error_details = css`
  ${margins.yb.half}
`;

export const toast_container = css`
  ${breakpoints.from('tablet')} {
    width: 664px !important;
    max-width: 664px !important;
  }
`;
