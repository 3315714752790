import { css } from '@emotion/react';
import { flex, margins, paddings } from '@spotnana/blocks/src/utils/styling';

export const name_and_avatar_outer_container = css`
  ${flex.init}
  ${flex.column}
  ${flex.justify.center}
  overflow: hidden;
`;

export const name_and_avatar_container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const input_label = css`
  font-size: 0.75rem;
  color: var(--text-label);
  cursor: pointer;
  ${paddings.xl.point25}
  ${paddings.yb.point25}
`;

export const subtext_container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const company_line_container = css`
  ${flex.init}
  ${flex.align.center}
`;

export const company_logo_container = css`
  ${margins.xr.point25}
`;

export const company_name = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;
