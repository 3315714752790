import { getImpersonationToken, useAuth, useLoggedInUserId } from 'obt-common';
import Config from 'obt-common/utils/Config';
import { useEffect } from 'react';

declare global {
  interface Window {
    twilioChatSupport:
      | {
          user_id: string;
          user_name: string;
          user_email_id: string;
          user_language: string;
          traveller_name: string;
          traveller_email_id: string;
          traveller_tier: string;
          org_id: string;
          org_name: string;
          partner_tmc: string;
          partner_tmc_id: string;
        }
      | undefined;
  }
}

export function InitializeTwilioChat() {
  const { getAuthToken } = useAuth();

  // Retrieve logged-in user ID and organization ID
  const loggedInUserId = useLoggedInUserId();

  useEffect(() => {
    async function setupTwilioChat() {
      const companyId = loggedInUserId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '';
      await import('@spotnana/twilio-chat-app');
      let token = null;
      let impersonationToken = null;
      try {
        token = await getAuthToken();
        impersonationToken = await getImpersonationToken();
      } catch (err) {
        console.error(err);
        return;
      }

      // Initialize Twilio web chat with the necessary parameters
      window.Twilio.initWebchat({
        token,
        impersonationToken,
        companyId, // this value is needed for x-Tmc-Id
        serverUrl: Config.VITE_API_BASE_URL || 'http://localhost:3001',
        webUrl: Config.VITE_WEB_URL || 'http://localhost:3000',
        theme: { isLight: !0 },
      });
    }
    setupTwilioChat();
  }, [getAuthToken, loggedInUserId]);

  return <></>;
}
