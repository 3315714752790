import { FunctionInterpolation, Theme } from '@emotion/react';
import MuiButton, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { forwardRef, useMemo } from 'react';
import { Icon } from '../Icon';
import type { RemoveMuiProps, ValueOf } from '../types';
import {
  button_with_icon,
  button_regular,
  button_primary,
  button_primary_gray,
  button_base,
  button_blue,
  button_ghost_gray,
  button_ghost_error,
} from './Button.styles';
import { IconName } from '../Icon/types';
import { useBlocksTheme } from '../utils/styling/useBlocksTheme';
import { getColorAndVariant } from './config';

export const BUTTON_ROOT = 'BlocksButton-root';
type MuiButtonProps = RemoveMuiProps<
  ButtonProps,
  'children' | 'variant' | 'disableElevation' | 'endIcon' | 'startIcon' | 'color'
>;

type BaseProps = {
  /**
   * Is the button loading?
   * @default false
   */
  loading?: boolean;
  /**
   * The text to display when the button is in loading state
   * @default 'Loading...'
   */
  loadingText?: string;
  size?: 'small' | 'medium' | 'large';
  /**
   * Is the button in selected state? (with checkmark?)
   * @default false
   */
  selected?: boolean;
  /**
   * Icon to display on the left side of the button
   * @default undefined
   */
  startIcon?: IconName;
  /**
   * Icon to display on the right side of the button
   * @default undefined
   */
  endIcon?: IconName;
};

type GhostVariant = {
  /**
   * The variant to use.
   * @default primary
   */
  variant?: 'ghost';
  color?: 'blue' | 'gray' | 'error';
  /**
   * Is the button placed on the left or right edge of a view?
   * (for ex. top-right close button on a modal)
   *
   * We use this prop to conform to design system guideline of aligning
   * the edge of the button text with the edge of the container,
   * if the button is placed on a left or right edge of a view.
   *
   */
  edgePlacement?: 'left' | 'right';
};

type PrimaryVariant = {
  /**
   * The variant to use.
   * @default primary
   */
  variant?: 'primary';

  color?: 'gray';

  // to disallow this prop for non-ghost buttons
  edgePlacement?: undefined;
};

type SecondaryVariant = {
  /**
   * The variant to use.
   * @default primary
   */
  variant?: 'secondary';
  color?: undefined;

  // to disallow this prop for non-ghost buttons
  edgePlacement?: undefined;
};

type VariantProps = PrimaryVariant | SecondaryVariant | GhostVariant;

export type TButtonProps = BaseProps &
  VariantProps &
  MuiButtonProps & {
    /**
     * The content of the component.
     */
    children: React.ReactNode | string;
  };

/**
 * Versatile Button component that supports rendering
 * - Regular buttons (CTAs, ghost buttons, etc)
 * - Regular Buttons with optional icons that can be rendered at start or at end.
 *
 * Please don't use this component for navigation by embedding it inside `Link` (`react-router-dom` or `blocks`). Use `LinkButton` from `app/shared/components` instead.
 * For outside the app navigation, you can pass the `href` prop to this component. MUI will convert the button to an anchor tag.
 */

export const Button = forwardRef<HTMLButtonElement, TButtonProps>(
  (
    {
      variant = 'primary',
      selected = false,
      disabled = false,
      loading = false,
      size,
      type,
      className,
      startIcon,
      edgePlacement,
      endIcon,
      fullWidth,
      loadingText,
      ...variableProps
    }: TButtonProps,
    ref,
  ) => {
    const { t: tt } = useTranslation('WEB');
    const { palette } = useBlocksTheme();

    const { children, color: colorKey = 'primary', ...rest } = variableProps;
    const { variant: blocksVariant = 'primary', color: blocksColor } = useMemo(
      () => getColorAndVariant({ variant, color: colorKey, paletteMode: palette.mode }),
      [variant, colorKey, palette.mode],
    );
    const showStartIcon = ((startIcon || loading || selected) && !disabled) || (disabled && loading);
    const showEndIcon = endIcon && !loading && !selected;
    if (disabled || loading) {
      delete rest.onClick;
    }

    const renderButtonContent = () => {
      if (loading) {
        return (
          <>
            <CircularProgress aria-hidden size="" thickness={7} />
            <span className="BlocksButton-content BlocksButton-loading-content">{loadingText || tt('Loading...')}</span>
          </>
        );
      }
      if (disabled) {
        return <span className="BlocksButton-content BlocksButton-disabled-content">{children}</span>;
      }
      if (selected) {
        return (
          <>
            <Icon name="Check" className="BlocksButton-Icon" />
            <span className="BlocksButton-content BlocksButton-selected-content">{children}</span>
          </>
        );
      }
      return (
        <>
          {startIcon && <Icon name={startIcon} className="BlocksButton-Icon" />}
          <span className="BlocksButton-content">{children}</span>
          {showEndIcon && <Icon name={endIcon} className="BlocksButton-Icon" />}
        </>
      );
    };

    return (
      <MuiButton
        css={[
          button_base,
          (showStartIcon || showEndIcon) && button_with_icon,
          getButtonColorConfigClass(blocksColor, blocksVariant),
          button_regular,
        ]}
        aria-disabled={loading || disabled}
        disableRipple={loading || disabled}
        size={size}
        variant={renameVariant(blocksVariant)}
        type={type}
        className={clsx(
          className,
          BUTTON_ROOT,
          `BlocksButton-${blocksVariant}`,
          loading && 'BlocksButton-loading',
          (loading || disabled) && 'Mui-disabled',
          selected && !disabled && 'BlocksButton-selected',
          edgePlacement && `edge-placement-${edgePlacement}`,
          fullWidth && 'BlocksButton-fullWidth',
        )}
        disableFocusRipple
        ref={ref}
        {...rest}
      >
        {renderButtonContent()}
      </MuiButton>
    );
  },
);

/**
 * Helpers
 */
const mapCustomVariantsToMui = {
  primary: 'contained' as const,
  secondary: 'outlined' as const,
  ghost: 'text' as const,
};

/**
 * Doesn't accept any variant config that isn't defined in `mapCustomVariantsToMui`
 *
 * ex:
 * ```js
 *  renameVariant('tertiary'); // FAILS to compile
 * ```
 */
function renameVariant(variant: keyof typeof mapCustomVariantsToMui): ValueOf<typeof mapCustomVariantsToMui> {
  return mapCustomVariantsToMui[variant];
}

const buttonColorConfigClassesMap: Record<string, Record<string, FunctionInterpolation<Theme>>> = {
  primary: {
    primary: button_primary,
    gray: button_primary_gray,
  },
  secondary: {
    primary: button_primary,
  },
  ghost: {
    primary: button_primary,
    gray: button_ghost_gray,
    blue: button_blue,
    error: button_ghost_error,
  },
};

/**
 * Doesn't accept any color config that isn't defined in `buttonColorConfigClassesMap`
 *
 * ex:
 * ```js
 *  getButtonColorConfig('pink'); // FAILS to compile
 * ```
 */

function getButtonColorConfigClass(color: string, variant: string): FunctionInterpolation<Theme> {
  return buttonColorConfigClassesMap[variant][color];
}
