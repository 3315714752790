import { TelemetryEvents, parseParams, useTelemetry } from 'obt-common';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const RouteChangeTelemetry = (): JSX.Element | null => {
  const { trackEvent } = useTelemetry();
  const { pathname, search } = useLocation();
  const searchParams = parseParams(search);

  useEffect(() => {
    trackEvent({
      name: TelemetryEvents.ROUTE_CHANGE,
      data: {
        pathname,
        searchParams,
      },
    });
  }, [pathname, searchParams, trackEvent]);
  return null;
};
