import { css } from '@emotion/react';
import { flex, margins, themed } from '@spotnana/blocks/src/utils';
import { cornerRadii, paddings, spacings } from '@spotnana/blocks/src/utils/styling/utilityTokens';

export const option_container = themed(
  ({ palette }) => css`
    ${flex.init}
    ${flex.row}
    ${spacings.medium}
    ${paddings.x.compact}
    ${paddings.y.compact}
    ${cornerRadii.buttonSmall}
    width: 100%;
    :hover {
      background-color: ${palette.surface.background};
    }
  `,
);

export const name_trip_button_container = css`
  ${flex.init}
  ${flex.column}
  ${flex.align.start}
  ${flex.justify.between}
  ${spacings.smallest}
  ${margins.yb.point25}
  width: 50%;
`;

export const name_logo_persona_container = css`
  ${flex.init}
  ${flex.align.center}
  ${spacings.small}
  width: 300px;
`;

export const name_logo_container = css`
  ${flex.init}
  ${flex.row}
  ${flex.align.center}
  ${spacings.small}
  position: relative;
  top: -2px;
`;
