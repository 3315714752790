import { lightColorPalette, darkColorPalette, TUxColorSpec } from '../ux-specs/color';

export const makeBlocksPalette = (
  uxSpec: { light: Record<keyof TUxColorSpec, string>; dark: Record<keyof TUxColorSpec, string> } | undefined = {
    light: lightColorPalette,
    dark: darkColorPalette,
  },
) => {
  const palette = {
    light: Object.freeze(makeBasePalette(uxSpec.light as TUxColorSpec, 'light')),
    dark: Object.freeze(makeBasePalette(uxSpec.dark as TUxColorSpec, 'dark')),
  };
  return palette;
};

export const blocksPalette = makeBlocksPalette();

function makeBasePalette(uxSpec: TUxColorSpec, mode: 'light' | 'dark') {
  const uxSlice = {
    /**
     * Also exposes uxSpec for rare cases of needing to directly access grey10, etc outside of semantic nesting
     * This is internal to Blocks and should not be used directly
     * @deprecated
     */
    uxSpec,
  };
  const blocksPaletteBase = {
    ...uxSlice,
    border: {
      radiusRegular: '8px',
      radiusSmall: '6px',
      radiusXSmall: '4px',

      /**
       * Grey10
       *
       * - Card border when separator is line.light
       */
      light: uxSpec.grey10,
      /**
       * Grey20
       *
       * - Card border when separator is line.dark
       * - Avatar border
       * - Secondary Button border
       */
      medium: uxSpec.grey20,
      /**
       * Grey30
       *
       * - Checkbox Border
       * - Radio Button border
       */
      dark: uxSpec.grey30,
      hover: uxSpec.grey40,
      active: uxSpec.grey50,
      /**
       * Keyboard focus border
       */
      keyboardFocus: uxSpec.keyboardFocus,
      successSubtle: uxSpec.successBorder,
      warningSubtle: uxSpec.warningBorder,
      /**
       * Bold border in error Toast
       */
      error: uxSpec.error,
      /**
       * Light border in error Alert/Tag
       */
      errorSubtle: uxSpec.errorBorder,
      /**
       * Light border for info
       */
      infoSubtle: uxSpec.linkBorder,
      /**
       * Border color for primary
       */
      brandSubtle: uxSpec.brandBorder,

      success: uxSpec.success,
      /**
       * Bold border in warning Toast
       */
      warning: uxSpec.warning,
      /**
       * Border color for brand
       */
      brand: uxSpec.brand,
      /**
       * Bold border for info elements
       */
      info: uxSpec.link,
    } as const,

    line: {
      /**
       * Dividers on bg.surface
       */
      light: uxSpec.grey10,
      /**
       * Dividers on bg.light
       */
      dark: uxSpec.grey20,
    } as const,
    icon: {
      primary: uxSpec.grey50,
      secondary: uxSpec.grey40,
      disabled: uxSpec.grey30,
      success: uxSpec.success,
      warning: uxSpec.warning,
      error: uxSpec.error,
      infoLink: uxSpec.link,
      brand: uxSpec.brand,
      starRating: uxSpec.starRating,
      inverse: uxSpec.surface,
      /**
       * Icon color to be used for buttons with primary background (they need to have white color always, irrespective of mode)
       */
      buttonIcon: '#FFFFFF',
    } as const,
    surface: {
      /**
       * Default card background — like Flight Card, cards on Checkout, Admin pages or anywhere
       */
      base: uxSpec.surface,
      /**
       * Default page background
       */
      background: uxSpec.background,
      /**
       * Default background for Select Menus and Popovers
       */
      menu: uxSpec.surfaceMenu,
      overlay: uxSpec.overlay,
    } as const,
    bg: {
      hover: uxSpec.surfaceHover,
      disabled: uxSpec.background,
      successSubtle: uxSpec.successBackground,
      success: uxSpec.success,
      warningSubtle: uxSpec.warningBackground,
      warning: uxSpec.warning,
      errorSubtle: uxSpec.errorBackground,
      error: uxSpec.error,
      infoSubtle: uxSpec.linkBackground,
      info: uxSpec.link,
      /**
       * Brand main accent color
       *
       * Used for backgrounds of buttons etc
       */
      brand: uxSpec.brand,

      /**
       * Background version (extremely light) of brand accent color
       */
      brandSubtle: uxSpec.brandBackground,
      /**
       * Darkened version of brand main accent color
       *
       * Used for hover states of buttons etc
       */
      brandHover: uxSpec.brandHover,
      badgeActive: uxSpec.grey60,
      badgeDisabled: uxSpec.grey10,
      buttonDisabled: uxSpec.grey10,
      /**
       * Tooltip background color, always same (irrespective of mode)
       */
      tooltip: '#4E5056',
    } as const,
    shadow: {
      /**
       * All types of cards on search page, checkout page, trips page, etc and Modals
       */
      cardShadow: mode === 'light' ? '0px 0px 20px 0px rgba(140, 159, 177, 0.10)' : 'none',
      /**
       * Modal header, Nav buttons (anything sticky on top)
       */
      scrollTopShadow:
        mode === 'light' ? '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 1px 6px 0px rgba(16, 24, 40, 0.05)' : 'none',
      /**
       * Modal Footer (anything sticky on bottom)
       */
      scrollBottomShadow:
        mode === 'light' ? '0px -1px 2px 0px rgba(16, 24, 40, 0.05), 0px -1px 6px 0px rgba(16, 24, 40, 0.05)' : 'none',
      /**
       * Dropdown menu, Filters and filter sidebar, Toast, Dialog box (like in seat selection)
       */
      componentShadow:
        mode === 'light' ? '0px 2px 10px 0px rgba(16, 24, 40, 0.08), 0px 1px 4px 0px rgba(16, 24, 40, 0.15)' : 'none',
    },
    text: {
      /**
       * - Text in Secondary buttons
       * - Text in Ghost buttons
       * - Text to convey brand expressions
       */
      brand: uxSpec.brand,
      /**
       * - Headings
       * - Body copy
       */
      primary: uxSpec.grey60,
      /**
       * - All caps subheadings
       * - Nav Links
       * - Helper Text
       */
      secondary: uxSpec.grey50,
      disabled: uxSpec.grey40,
      error: uxSpec.error,
      warning: uxSpec.warning,
      success: uxSpec.success,
      focus: uxSpec.keyboardFocus,
      link: uxSpec.link,
      linkHover: uxSpec.linkHover,
      /**
       * Light Text color used on dark backgrounds like:
       * - bg.primary , bg.inverse and bg.xyzDark backgrounds
       */
      inverse: uxSpec.surface,
      /**
       * Light Text color used on dark backgrounds like:
       * - bg.primary , bg.inverse and bg.xyzDark backgrounds
       */
      secondaryInverse: uxSpec.background,
      /**
       * Headings inside popovers
       */
      tertiary: uxSpec.grey40,
      /**
       * Text color to be used for buttons with primary background (they need to have white color always, irrespective of mode)
       */
      buttonLabel: '#FFFFFF',
    } as const,
    inputs: {
      borderRadius: '8px',
      bgDefault: uxSpec.surface,
      bgHover: uxSpec.background,
      bgDisabled: uxSpec.background,
      borderDefault: uxSpec.grey10,
      borderHover: uxSpec.grey30,
      borderFocus: uxSpec.keyboardFocus,
      borderDisabled: uxSpec.grey30,
      borderExpanded: uxSpec.grey50,
      borderSelected: uxSpec.grey60,
      borderError: uxSpec.error,
      borderWarning: uxSpec.warning,
      borderSuccess: uxSpec.success,
      text: uxSpec.grey60,
      textLabel: uxSpec.grey50,
      textPlaceholder: uxSpec.grey40,
      textDisabled: uxSpec.grey40,
      textHint: uxSpec.grey50,
      textError: uxSpec.error,
    },
    mode,
  } as const;
  return blocksPaletteBase;
}
