import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';

import { addRequestInterceptor, useAuth } from 'obt-common';

import { Modal } from '@spotnana/blocks/src/Modal';
import { Typography } from '@spotnana/blocks/src/Typography';

import { TimeInSeconds } from './TimeInSeconds';
import { INTERVAL_THROTTLE_IN_MILLISECONDS, ONE_MINUTE_IN_MILLISECONDS } from '../constansts';
import { modal } from '../SessionTimeoutModal.styles';

interface SessionTimeoutModalContainerProps {
  idleSessionTimeoutMin: number;
}

export function IdleSessionTimeoutModalContainer({
  idleSessionTimeoutMin,
}: SessionTimeoutModalContainerProps): ReactElement | null {
  const { t: tt } = useTranslation('WEB');
  const [isPrompt, setIsPrompt] = useState(false);

  const { logout } = useAuth();

  // User will be prompted to abort sign-out 1 minute before session timeout
  const promptBeforeIdle = ONE_MINUTE_IN_MILLISECONDS;
  const timeout = idleSessionTimeoutMin * ONE_MINUTE_IN_MILLISECONDS - promptBeforeIdle;

  // Listen to user activity (click, scroll, etc.)
  const { reset, getRemainingTime } = useIdleTimer({
    crossTab: true, // required to make the idle timer work across tabs
    timeout,
    promptTimeout: promptBeforeIdle,
    throttle: INTERVAL_THROTTLE_IN_MILLISECONDS,
    onIdle() {
      logout();
    },
    onPrompt() {
      setIsPrompt(true);
    },
    onActive() {
      if (isPrompt) setIsPrompt(false); // required to close the inactivity prompt in other tabs after onContinue
    },
  });

  const onContinue = useCallback((): void => {
    setIsPrompt(false);
    reset();
  }, [reset]);

  useEffect(() => {
    const unsubscribe = addRequestInterceptor({
      onFulfilled(config) {
        reset(); // reset idle timer on every request
        return config;
      },
    });
    return unsubscribe;
  }, [reset]);

  if (!isPrompt) {
    return null;
  }

  return (
    <Modal
      css={modal}
      open
      hideCloseButton
      variant="medium"
      data-testId="modal_session-timeout"
      header={{ title: tt('Session timeout') }}
      footer={{ primaryAction: { text: tt('Yes, continue session'), onClick: onContinue } }}
    >
      <Typography variant="body1">
        <Trans
          t={tt}
          components={{ seconds: <TimeInSeconds getRemainingTime={getRemainingTime} />, br: <br />, b: <b /> }}
        >
          {
            '<b>Are you still there?</b><br /><br />Your session appears to be inactive. You will be automatically logged out in <seconds /> and any unsaved changes will be lost.'
          }
        </Trans>
      </Typography>
    </Modal>
  );
}
