import { FareComponent } from '../types/api/v1/common/money';
import { ItemType } from '../types/api/v1/obt/pnr/payment';
import { FareComponent as V2FareComponent } from '../types/api/v2/obt/model/fare-component';
import { ItemType as V2ItemType } from '../types/api/v2/obt/model/item-type';

export const V2ToV1ItemTypeMapper = {
  [V2ItemType.ServiceFee]: ItemType.SERVICE_FEE,
  [V2ItemType.TravelTicket]: ItemType.TRAVEL_TICKET,
  [V2ItemType.Seat]: ItemType.SEAT,
  [V2ItemType.Baggage]: ItemType.BAGGAGE,
  [V2ItemType.EarlyBird]: ItemType.EARLY_BIRD,
};

export const V2ToV1IFareComponentMapper = {
  [V2FareComponent.Base]: FareComponent.BASE,
  [V2FareComponent.Tax]: FareComponent.TAX,
};
