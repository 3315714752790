/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Feature enabled for company.
 * @export
 * @interface FeatureConfig
 */
export interface FeatureConfig {
  /**
   * Name of the feature.
   * @type {string}
   * @memberof FeatureConfig
   */
  name: FeatureConfigNameEnum;
  /**
   * Whether feature is enabled or not.
   * @type {boolean}
   * @memberof FeatureConfig
   * @deprecated
   */
  enabled: boolean;
  /**
   * Value Type of Feature.
   * @type {string}
   * @memberof FeatureConfig
   */
  valueType?: FeatureConfigValueTypeEnum;
  /**
   * Value associated with Feature.
   * @type {string}
   * @memberof FeatureConfig
   */
  value?: string;
  /**
   * Flag to indicate that feature value is overridable at lower level or not.
   * @type {boolean}
   * @memberof FeatureConfig
   */
  isOverridable?: boolean;
}

export const FeatureConfigNameEnum = {
  FlightResultsOop: 'FLIGHT_RESULTS_OOP',
  HotelResultsOop: 'HOTEL_RESULTS_OOP',
  RailResultsOop: 'RAIL_RESULTS_OOP',
  CarsResultsOop: 'CARS_RESULTS_OOP',
  PaymentAddCard: 'PAYMENT_ADD_CARD',
  MenuItemPolicies: 'MENU_ITEM_POLICIES',
  IntegrationExpensify: 'INTEGRATION_EXPENSIFY',
  TravelerSelection: 'TRAVELER_SELECTION',
  FeaturePersonalTravel: 'FEATURE_PERSONAL_TRAVEL',
  ProductObtRail: 'PRODUCT_OBT_RAIL',
  MenuItemCompanyRemarks: 'MENU_ITEM_COMPANY_REMARKS',
  HomepageAddUser: 'HOMEPAGE_ADD_USER',
  MenuItemCustomFields: 'MENU_ITEM_CUSTOM_FIELDS',
  PaymentUnusedCredits: 'PAYMENT_UNUSED_CREDITS',
  EmailUpdateByCompanyAdmin: 'EMAIL_UPDATE_BY_COMPANY_ADMIN',
  AdhocBookingSearchPage: 'ADHOC_BOOKING_SEARCH_PAGE',
  AdhocBookingSaveTraveler: 'ADHOC_BOOKING_SAVE_TRAVELER',
  AdminVirtualCard: 'ADMIN_VIRTUAL_CARD',
  CentralCardServiceFee: 'CENTRAL_CARD_SERVICE_FEE',
  ExpediaSourceEnabled: 'EXPEDIA_SOURCE_ENABLED',
  BookingComSourceEnabled: 'BOOKING_COM_SOURCE_ENABLED',
  HotelMedianEnable: 'HOTEL_MEDIAN_ENABLE',
  HideSearchCriteria: 'HIDE_SEARCH_CRITERIA',
  AdhocPaymentSource: 'ADHOC_PAYMENT_SOURCE',
  ServiceChargeConfigDisabled: 'SERVICE_CHARGE_CONFIG_DISABLED',
  HideSettingsMenuItemCompany: 'HIDE_SETTINGS_MENU_ITEM_COMPANY',
  HideSettingsMenuItemUsers: 'HIDE_SETTINGS_MENU_ITEM_USERS',
  HidePolicyApprovalSection: 'HIDE_POLICY_APPROVAL_SECTION',
  HidePolicyCreation: 'HIDE_POLICY_CREATION',
  HideSettingsTextFromMenu: 'HIDE_SETTINGS_TEXT_FROM_MENU',
  HideLeftNav: 'HIDE_LEFT_NAV',
  HidePolicyAirCarrier: 'HIDE_POLICY_AIR_CARRIER',
  HidePolicyAirCo2: 'HIDE_POLICY_AIR_CO2',
  HidePolicyHotelPriceLocationCustomisation: 'HIDE_POLICY_HOTEL_PRICE_LOCATION_CUSTOMISATION',
  HideProfilePreferencesAirlines: 'HIDE_PROFILE_PREFERENCES_AIRLINES',
  HideProfilePreferencesAlliances: 'HIDE_PROFILE_PREFERENCES_ALLIANCES',
  MoneySetConvertedCurrencyAsOriginalCurrency: 'MONEY_SET_CONVERTED_CURRENCY_AS_ORIGINAL_CURRENCY',
  ProductObtAirDisabled: 'PRODUCT_OBT_AIR_DISABLED',
  ProductObtHotelDisabled: 'PRODUCT_OBT_HOTEL_DISABLED',
  ProductObtCarDisabled: 'PRODUCT_OBT_CAR_DISABLED',
  ProductObtLimoDisabled: 'PRODUCT_OBT_LIMO_DISABLED',
  ProductObtConciergeDisabled: 'PRODUCT_OBT_CONCIERGE_DISABLED',
  EventBookingEnabled: 'EVENT_BOOKING_ENABLED',
  EmployeeLevelDesignatedApproverEnabled: 'EMPLOYEE_LEVEL_DESIGNATED_APPROVER_ENABLED',
  EnrichSearchResponseWithRewardPoints: 'ENRICH_SEARCH_RESPONSE_WITH_REWARD_POINTS',
  HideLeftNavEmbed: 'HIDE_LEFT_NAV_EMBED',
  HidePolicyAirNotAllowedToBeBooked: 'HIDE_POLICY_AIR_NOT_ALLOWED_TO_BE_BOOKED',
  HideTripFeeConfig: 'HIDE_TRIP_FEE_CONFIG',
  HideAgentFeeConfig: 'HIDE_AGENT_FEE_CONFIG',
  ShowServiceFeeTaxes: 'SHOW_SERVICE_FEE_TAXES',
  HideGuestBookingSetting: 'HIDE_GUEST_BOOKING_SETTING',
  UserCreationPersonaTypeOptions: 'USER_CREATION_PERSONA_TYPE_OPTIONS',
  AirSearchResultAirlineSortOrder: 'AIR_SEARCH_RESULT_AIRLINE_SORT_ORDER',
  HideSherpaVisaWidget: 'HIDE_SHERPA_VISA_WIDGET',
  AutoDeactivateGuest: 'AUTO_DEACTIVATE_GUEST',
  PolicyUserGroups: 'POLICY_USER_GROUPS',
  HideSupplierManagement: 'HIDE_SUPPLIER_MANAGEMENT',
  HideBookingRestrictionsByCountry: 'HIDE_BOOKING_RESTRICTIONS_BY_COUNTRY',
  LinkedMarriottBonvoyRequired: 'LINKED_MARRIOTT_BONVOY_REQUIRED',
  EditingDefaultPoliciesDisabled: 'EDITING_DEFAULT_POLICIES_DISABLED',
  DisallowMultiCityAddFlight: 'DISALLOW_MULTI_CITY_ADD_FLIGHT',
  RequireOnboardingOnEnrollment: 'REQUIRE_ONBOARDING_ON_ENROLLMENT',
  GeoCustomizations: 'GEO_CUSTOMIZATIONS',
  HidePreferredParentChains: 'HIDE_PREFERRED_PARENT_CHAINS',
  HidePreferredHotelBrands: 'HIDE_PREFERRED_HOTEL_BRANDS',
  HideRoomOptionsAndStayPreferences: 'HIDE_ROOM_OPTIONS_AND_STAY_PREFERENCES',
  HideConditionalRatesPreferences: 'HIDE_CONDITIONAL_RATES_PREFERENCES',
  HideHotelsInSupplierManagement: 'HIDE_HOTELS_IN_SUPPLIER_MANAGEMENT',
  HideHotelBrandsInSupplierManagement: 'HIDE_HOTEL_BRANDS_IN_SUPPLIER_MANAGEMENT',
  HidePolicyHotelStarRating: 'HIDE_POLICY_HOTEL_STAR_RATING',
  ProductObtHomepageOrder: 'PRODUCT_OBT_HOMEPAGE_ORDER',
  HideCompanySupplierMenu: 'HIDE_COMPANY_SUPPLIER_MENU',
  ExternalEventBookingUrl: 'EXTERNAL_EVENT_BOOKING_URL',
  HideIdleSessionTimeout: 'HIDE_IDLE_SESSION_TIMEOUT',
  LeCustomColumnsConfig: 'LE_CUSTOM_COLUMNS_CONFIG',
  ProfileElementsVisibility: 'PROFILE_ELEMENTS_VISIBILITY',
  ShowTripFeeConfig: 'SHOW_TRIP_FEE_CONFIG',
  ShowAgentFeeConfig: 'SHOW_AGENT_FEE_CONFIG',
  DefaultHotelSearchRadiusConfig: 'DEFAULT_HOTEL_SEARCH_RADIUS_CONFIG',
  EnrollmentDashboard: 'ENROLLMENT_DASHBOARD',
  PrivacyConfigHierarchy: 'PRIVACY_CONFIG_HIERARCHY',
  PolicyConfiguredInHrFeed: 'POLICY_CONFIGURED_IN_HR_FEED',
  CompanionViewConfig: 'COMPANION_VIEW_CONFIG',
} as const;

export type FeatureConfigNameEnum = (typeof FeatureConfigNameEnum)[keyof typeof FeatureConfigNameEnum];
export const FeatureConfigValueTypeEnum = {
  Int: 'INT',
  Double: 'DOUBLE',
  Bool: 'BOOL',
  String: 'STRING',
  Json: 'JSON',
} as const;

export type FeatureConfigValueTypeEnum = (typeof FeatureConfigValueTypeEnum)[keyof typeof FeatureConfigValueTypeEnum];
