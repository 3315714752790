export const adminRoutesMap = {
  USERS_ROLES: {
    key: 'admin-users-roles',
    path: '/admin/users/roles',
  },
  USERS: {
    key: 'admin-users',
    path: '/admin/users',
  },
  NON_EMPLOYEE_POLICIES: {
    key: 'admin-policies-nonemployee',
    path: '/admin/policies/nonemployee',
  },
  POLICIES: {
    key: 'admin-policies',
    path: '/admin/policies',
  },
  COMPANY_OVERVIEW: {
    key: 'admin-company-overview',
    path: '/admin/company/overview',
  },
  COMPANY_SERVICE_FEE_CONFIG: {
    key: 'admin-company-service-fee',
    path: '/admin/company/service-fee',
  },
  COMPANY_ENTITIES: {
    key: 'admin-company-entities',
    path: '/admin/company/entities',
  },
  COMPANY_OFFICES: {
    key: 'admin-company-offices',
    path: '/admin/company/offices',
  },
  COMPANY_DEPARTMENT: {
    key: 'admin-company-department',
    path: '/admin/company/department',
  },
  COMPANY_COSTCENTER: {
    key: 'admin-company-costcenter',
    path: '/admin/company/costcenter',
  },
  COMPANY_PAYMENT_METHODS: {
    key: 'admin-company-payment-methods',
    path: '/admin/company/payment-methods',
  },
  COMPANY_REMARKS: {
    key: 'admin-company-remarks',
    path: '/admin/company/remarks',
  },
  COMPANY_USER_UPLOAD: {
    key: 'admin-company-userupload',
    path: '/admin/company/userupload',
  },
  COMPANY_AUTHENTICATION_METHOD: {
    key: 'admin-company-authentication',
    path: '/admin/company/authentication',
  },
  COMPANY_PREFERRED_VENDORS: {
    key: 'admin-company-preferred-vendors',
    path: '/admin/company/preferred-vendors',
  },
  COMPANY_SUPPLIER_MANAGEMENT: {
    key: 'admin-company-supplier-management',
    path: '/admin/company/supplier-management/:vendorType(airline|hotel|hotelBrand|car)',
  },
  COMPANY_SUPPLIER_MANAGEMENT_ADD: {
    key: 'admin-company-supplier-management',
    path: '/admin/company/supplier-management/:vendorType(airline|hotel|hotelBrand|car)/add',
  },
  COMPANY_SUPPLIER_MANAGEMENT_DETAILS: {
    key: 'admin-company-supplier-management',
    path: '/admin/company/supplier-management/:vendorType(airline|hotel|hotelBrand|car)/details/:mode(view|edit)',
  },
  COMPANY_AIRLINE_PROGRAMS: {
    key: 'admin-company-airline-programs',
    path: '/admin/company/airline-programs',
  },
  COMPANY_SUPPLIER_PROGRAMS: {
    key: 'admin-company-supplier-programs',
    path: '/admin/company/supplier-programs',
  },
  COMPANY_SUPPLIERS: {
    key: 'admin-company-suppliers',
    path: '/admin/company/suppliers',
  },
  COMPANY_INTEGRATIONS: {
    key: 'admin-company-integrations',
    path: '/admin/company/integrations',
  },
  COMPANY_INTEGRATIONS_EXPENSE: {
    key: 'admin-company-integrations',
    path: '/admin/company/integrations/expense/:partner(expensify|chromeriver|brex)',
  },
  COMPANY_CUSTOM_FIELDS: {
    key: 'admin-company-customFields',
    path: '/admin/company/custom-fields',
  },
  COMPANY_CUSTOM_FIELDS_SETUP: {
    key: 'admin-company-customFields-setup',
    path: '/admin/company/custom-fields/setup',
  },
  COMPANY_SAFETY: {
    key: 'admin-company-safety',
    path: '/admin/company/safety',
  },
  COMPANY_BOOKING_RESTRICTIONS: {
    key: 'admin-company-booking-restrictions',
    path: '/admin/company/booking-restrictions',
  },
  COMPANY_SITE_MESSAGING: {
    key: 'admin-company-siteMessaging',
    path: '/admin/company/site-messaging',
  },
  COMPANY_SITE_MESSAGING_CREATE: {
    key: 'admin-company-siteMessaging-create',
    path: '/admin/company/site-messaging/create',
  },
  COMPANY_SITE_MESSAGING_DETAILS: {
    key: 'admin-company-siteMessaging-details',
    path: '/admin/company/site-messaging/:messageId/details/:type(view|edit)?',
  },
  COMPANY_DATA_ACTION_REQUESTS: {
    key: 'admin-company-data-action-requests',
    path: '/admin/company/data-action-requests',
  },
};
