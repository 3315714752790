import { ROUTE_PATHS } from 'src/routes';
import { useTranslation } from 'react-i18next';
import { Typography } from '@spotnana/blocks/src/Typography';
import { debug_tool_link_container } from './styles';

function DebugTool() {
  const { t: tt } = useTranslation('WEB');
  return (
    <button
      type="button"
      css={debug_tool_link_container}
      onClick={() => {
        window.open(ROUTE_PATHS.SUPPORT_DEBUG_TOOL);
      }}
    >
      <Typography variant="body1">{tt('Debug Tool')}</Typography>
    </button>
  );
}
export default DebugTool;
