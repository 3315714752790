/* tslint:disable */
/* eslint-disable */
/**
 * Spotnana API documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Card } from './card';

import { PaymentMetadata } from './payment-metadata';
import { PaymentMethod } from './payment-method';
import { CreditCardAccess } from './credit-card-access';

/**
 * Information about form of payment to be used to charge customer
 * @export
 * @interface FormOfPayment
 */
export interface FormOfPayment {
  /**
   * Type of payment used
   * @type {string}
   * @memberof FormOfPayment
   * @deprecated
   */
  type: FormOfPaymentTypeEnum;
  /**
   *
   * @type {Card}
   * @memberof FormOfPayment
   */
  card?: Card;
  /**
   * Additional info to be added if payment type is \'UNKNOWN\'.
   * @type {string}
   * @memberof FormOfPayment
   */
  additionalInfo?: string;
  /**
   *
   * @type {CreditCardAccessType}
   * @memberof FormOfPayment
   */
  accessType?: CreditCardAccess;
  /**
   *
   * @type {PaymentMethod}
   * @memberof FormOfPayment
   */
  paymentMethod?: PaymentMethod;
  /**
   *
   * @type {PaymentMetadata}
   * @memberof FormOfPayment
   */
  paymentMetadata?: PaymentMetadata;
}

export const FormOfPaymentTypeEnum = {
  Unknown: 'UNKNOWN',
  Card: 'CARD',
  Cash: 'CASH',
  Tfpay: 'TFPAY',
  Cheque: 'CHEQUE',
  BrexPoints: 'BREX_POINTS',
  QantasPoints: 'QANTAS_POINTS',
} as const;

export type FormOfPaymentTypeEnum = (typeof FormOfPaymentTypeEnum)[keyof typeof FormOfPaymentTypeEnum];
