import { useMemo } from 'react';
import { useAppBranding } from 'src/components/AppBrandingProvider/AppBrandingProvider';
import { filterInvalidTravelTypes } from 'src/components/AppBrandingProvider/helpers';

export type TAllowedSearchTabs = 'FLIGHTS' | 'HOTELS' | 'CARS' | 'RAILS' | 'OTHERS' | 'LIMO';

export const mapTravelTypeToRoute: Record<TAllowedSearchTabs, string> = {
  FLIGHTS: '/flights',
  HOTELS: '/hotels',
  CARS: '/cars',
  RAILS: '/rails',
  /**
   * TODO: These need to either be mapped to real existing routes,
   * or the feature needs to be extended to allow partial sum type,
   * having lesser options than what we have in harness config
   */
  LIMO: '/',
  OTHERS: '/',
};

export const allSearchTabs: TAllowedSearchTabs[] = Object.keys(mapTravelTypeToRoute) as TAllowedSearchTabs[];

export function useAllowedTravelTypes(): TAllowedSearchTabs[] {
  // fetch trip types from harness feature flag "useFeatureFlag" hook
  const { isGlobalCustomisationEnabled, homeOrder: travelTypeConfig, isReady } = useAppBranding();

  // take the list of trip types from feature flag which are in the shape of { name: FLIGHTS, order: 1 },
  // and convert it to an array of sorted strings ['FLIGHTS', 'HOTELS', 'CARS', 'RAILS'] based on order
  return useMemo(() => {
    if (!isReady) return [];
    /**
     * We are temporarily adding FF based disabling, making sure that app reverts to previous behaviour in case of any issues
     */
    if (!isGlobalCustomisationEnabled || travelTypeConfig?.length === 0) return allSearchTabs;
    return filterInvalidTravelTypes(travelTypeConfig);
  }, [travelTypeConfig, isGlobalCustomisationEnabled, isReady]);
}

export function useDisallowedRoutes(): string[] {
  const allowedRoutes = useAllowedTravelTypes();
  return useMemo(() => {
    return allSearchTabs
      .filter((eachTab) => !allowedRoutes.includes(eachTab))
      .map((eachTab) => mapTravelTypeToRoute[eachTab])
      .filter((eachRoute) => eachRoute !== '/');
  }, [allowedRoutes]);
}
