import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { FormGroup } from '@mui/material';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useId } from 'react';
import { useTranslation } from 'react-i18next';
import { VisuallyHidden } from '../VisuallyHidden';
import { Typography } from '../Typography';

import type { RemoveMuiProps } from '../types';
import { clsx } from '../utils';
import { flex } from '../utils/styling';
import { checkbox, checkbox_group, checkbox_with_label, icon_wrapper } from './Checkbox.styles';

type BaseProps = {
  /**
   * Small or Regular?
   */
  size?: 'small' | 'regular';
  /** Renders a readonly version of Checkbox */
  readOnly?: boolean;
  /** Renders a disabled version of Checkbox */
  disabled?: boolean;
};

type JustCheckboxProps = BaseProps & RemoveMuiProps<CheckboxProps, 'readOnly' | 'disabled'>;

type WithLabelCheckboxProps = BaseProps & {
  /**
   * Label text for the checkbox
   */
  label: string;
  /**
   * Optional subtext
   */
  subtext?: string;
  /**
   * Props for the div that wrapps the checkbox & subtext
   */
  wrapperDivProps?: React.HTMLAttributes<HTMLDivElement>;
  /**
   * Inner checkbox props
   */
  checkboxProps?: Omit<CheckboxProps, 'readOnly' | 'disabled'>;
} & RemoveMuiProps<FormControlLabelProps, 'control' | 'disableTypography'>;

export type TCheckboxProps = JustCheckboxProps | WithLabelCheckboxProps;

type TIconWrapperProps = {
  children: React.ReactNode;
};

/**
 * This Icon wrapper ensures that the background being used with the default MUI icon
 * is compatible across themes
 * @returns
 */
export function IconWrapper({ children }: TIconWrapperProps) {
  return (
    <span css={icon_wrapper}>
      <span className="Container">{children}</span>
    </span>
  );
}

const getCustomIconConfig = (disabled?: boolean) => ({
  // for disabled, override with custom icon
  icon: (
    <IconWrapper>
      {disabled ? <SquareRoundedIcon /> : <CheckBoxOutlineBlankIcon />}
      {disabled && <div className="CustomBorder" />}
    </IconWrapper>
  ),
  checkedIcon: (
    <IconWrapper>
      <CheckBoxIcon />
    </IconWrapper>
  ),
  indeterminateIcon: (
    <IconWrapper>
      <IndeterminateCheckBoxIcon />
    </IconWrapper>
  ),
});

const getSizeClassName = (size: TCheckboxProps['size']) => `size-${size}`;

export function Checkbox(unionProps: TCheckboxProps) {
  const WithLabelCheckbox_labelId = useId();
  const descId = `${WithLabelCheckbox_labelId}-description`;

  if ('label' in unionProps) {
    const { size = 'regular', subtext, checkboxProps = {}, disabled, label, readOnly, checked, ...props } = unionProps;

    return (
      <FormControlLabel
        disableTypography
        className="BlocksCheckbox-root"
        css={checkbox_with_label}
        control={
          <MuiCheckbox
            css={checkbox}
            {...checkboxProps}
            {...getCustomIconConfig(disabled || readOnly)}
            className={clsx('WithLabel', getSizeClassName(size), checkboxProps.className)}
            inputProps={{
              'aria-labelledby': WithLabelCheckbox_labelId,
              ...(subtext ? { 'aria-describedby': descId } : {}),
              ...(checkboxProps?.inputProps ?? {}),
            }}
            disableRipple
          />
        }
        disabled={disabled || readOnly}
        label={
          <div css={[flex.init, flex.column]}>
            <Typography
              color={disabled ? 'disabled' : 'primary'}
              variant={size === 'small' ? 'body2' : 'body1'}
              as="span"
              className={`BlocksCheckbox-label ${checked && 'is-selected'}`}
              id={WithLabelCheckbox_labelId}
            >
              {label}
            </Typography>
            {subtext && (
              <Typography
                color={disabled ? 'disabled' : 'secondary'}
                variant="body3"
                as="span"
                className="BlocksCheckbox-subtext"
                id={descId}
              >
                {subtext}
              </Typography>
            )}
          </div>
        }
        checked={checked}
        {...props}
      />
    );
  }

  const { size = 'regular', className, disabled, readOnly, ...checkboxProps } = unionProps;
  return (
    <MuiCheckbox
      css={checkbox}
      {...getCustomIconConfig(disabled || readOnly)}
      className={clsx(getSizeClassName(size), className)}
      {...checkboxProps}
      disableRipple
      inputProps={{ 'aria-labelledby': checkboxProps['aria-labelledby'], ...(checkboxProps?.inputProps ?? {}) }}
      disabled={disabled || readOnly}
      readOnly={readOnly}
    />
  );
}

export type TCheckboxGroupProps = {
  label: string;
  size?: WithLabelCheckboxProps['size'];
  items: Omit<WithLabelCheckboxProps, 'size'>[];
  required?: boolean;
};

export function CheckboxGroup({ size = 'regular', label, items, required }: TCheckboxGroupProps) {
  const sizeClassName = `size-${size}`;
  const groupLabelId = useId();
  const requiredTextId = useId();
  const { t: tt } = useTranslation('WEB');

  return (
    <FormControl required={required} css={checkbox_group} className={`BlocksCheckboxGroup-root ${sizeClassName}`}>
      <FormLabel required={required} id={groupLabelId} className="BlocksCheckboxGroup-label" aria-required={required}>
        <Typography color="primary" variant="body1" as="span">
          {label}
        </Typography>
        {required && <VisuallyHidden id={requiredTextId}>{tt('Required')}</VisuallyHidden>}
      </FormLabel>
      <FormGroup role="group" aria-labelledby={groupLabelId} aria-describedby={requiredTextId}>
        {items.map(({ label: itemLabel, value, ...props }) => (
          <Checkbox key={itemLabel + value} label={itemLabel} value={value} size={size} {...props} />
        ))}
      </FormGroup>
    </FormControl>
  );
}
