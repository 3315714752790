import type { AxiosError } from 'axios';

import { webSessionStorage } from '../utils/SessionStorage';
import { StorageKeys } from '../types';
import type { InternalAPIError } from '../types';

type WasErrorHandled = boolean;

function handleRedirectError(error: AxiosError<InternalAPIError>): WasErrorHandled {
  if (error.response?.status === 409 && error.response.data.errorCode === 'REFRESH_TOKEN_EXPIRED') {
    try {
      // Check if we whould retrigger the token exchange flow instead of redirecting.
      // This storage key will be set if `FE_CORP_EMBED_STORE_REDIRECT_ON_ERROR` is enabled and
      // there is a valid TOKEN_EXCHANGE_IDP in session storage.
      const shouldRetriggerTokenExchange = !!webSessionStorage.getItem(StorageKeys.TOKEN_EXCHANGE_ERROR_HANDLING);

      const tokenExchangeIdp = webSessionStorage.getItem(StorageKeys.TOKEN_EXCHANGE_IDP);

      if (shouldRetriggerTokenExchange && tokenExchangeIdp) {
        webSessionStorage.removeItem(StorageKeys.TOKEN_EXCHANGE_ERROR_HANDLING);
        const tokenExchangeUrl = new URL(window.location.href);
        tokenExchangeUrl.searchParams.set('idp', tokenExchangeIdp);

        // Re-add token exchange idp param and refresh the page.
        window.location.replace(tokenExchangeUrl);
      } else {
        // URL constructor will throw if string isn't a valid url
        // https://url.spec.whatwg.org/#example-5434421b
        window.location.replace(new URL(error.response.data.message));
      }
    } catch {
      console.error('Invalid URL returned for redirection from API:', error.response.data.message);
    }
    return true;
  }
  return false;
}

export default handleRedirectError;
