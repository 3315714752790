import { useFeatureFlag, useGetUserPersonalInfo, useIsLoggedIn, useLoggedInUserId } from 'obt-common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useApplyAndFetchUserPreferredLanguageToApp = (id?: string) => {
  const { i18n } = useTranslation();
  const isUserLoggedIn = useIsLoggedIn();
  const data = useLoggedInUserId();
  const userId = id || data?.userId?.id || '';
  const isUserPreferredLanguageEnabled = useFeatureFlag('FE_USER_PREFERRED_LANGUAGE');
  const { data: personalInfo, isLoading: isPersonalInfoLoading } = useGetUserPersonalInfo(
    userId,
    Boolean(isUserPreferredLanguageEnabled) && Boolean(userId),
  );

  useEffect(() => {
    if (isUserLoggedIn && userId && isUserPreferredLanguageEnabled && !isPersonalInfoLoading) {
      const preferredLanguage = personalInfo?.preferredLanguage;
      if (preferredLanguage) {
        i18n.changeLanguage(preferredLanguage);
      }
    }
  }, [isPersonalInfoLoading, isUserLoggedIn, isUserPreferredLanguageEnabled, personalInfo, userId, i18n]);

  return {
    preferredLanguage: personalInfo?.preferredLanguage,
    isPersonalInfoLoading,
    personalInfo,
  };
};
