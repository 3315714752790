/* eslint-disable import/prefer-default-export */
import type { FetchQueryOptions, QueryKey, UseMutationOptions, UseQueryOptions } from 'react-query';
import { useMutation, useQueries, useQuery } from 'react-query';
import get from 'lodash/get';
import type { ListUsersRequest } from '@spotnana/types/openapi/models/list-users-request';
import type { ListUsersResponse } from '@spotnana/types/openapi/models/list-users-response';
import type { OrganizationId } from '@spotnana/types/openapi/models/organization-id';
import { defaultQueryClient } from './defaultQueryClient';
import api from '../api';
import type { IResponseData } from '../api/apiTypes';
import { SpotnanaError } from '../api/SpotnanaError';
import type {
  CalculateServiceChargeRequest,
  CalculateServiceChargeResponse,
  IUserOrgId,
  PointsBalance,
  SpotnanaQueryMutationResult,
  SpotnanaQueryResult,
  UserTravelArrangers,
  IUseGetApproverInfoResult,
} from '../types';
import type { Traveler } from '../types/api/v1/obt/common/traveler';
import type {
  PreBookQuestionRequest,
  PreBookQuestionResponse,
} from '../types/api/v1/obt/policy/pre_search_and_book_question';
import type { CardDeleteRequest } from '../types/api/v1/obt/profile/payments/credit_cards_crud';
import type { ReadTravelerResponse } from '../types/api/v1/obt/profile/read_traveler_response';
import type { GetUserRolesResponse } from '../types/api/v1/obt/profile/role/role_services';
import type { UpdateTravelerRequest } from '../types/api/v1/obt/profile/update_traveler_request';
import type { ListTravelPreferenceNotes } from '../types/api/v2/obt/model/list-travel-preference-notes';
import type { LocationRestrictionValidationResult } from '../types/api/v2/obt/model/location-restriction-validation-result';
import type { CheckBookingRestrictionsByLocationRequest } from '../types/api/v2/obt/model/check-booking-restrictions-by-location-request';
import { removeEmptyValuesFromObject, createUserOrgIdUsingUserId, getProfileDisplayText } from '../utils/common';
import type { IApiRequestOptions } from '../api/types';
import type { TravelPreferences } from '../types/api/v2/obt/model/travel-preferences';
import type { NotificationPreferences } from '../types/api/v2/obt/model/notification-preferences';
import { invalidateTravelPreference } from './traveler';
import { TRAVELER_PREBOOK_QUESTIONS_KEY_ID } from '../constants/traveler';
import type { UserPersonalInfo } from '../types/api/v2/obt/model/user-personal-info';
import type { GetLanguagesResponse } from '../types/api/v2/obt/model/global_languages';
import type { GetManagedTravelersResponse } from '../types/api/v2/obt/model/get-managed-travelers-response';
import type { UpdateManagedTravelersRequest } from '../types/api/v2/obt/model/update-managed-travelers-request';
import type { AddManagedTravelersRequest } from '../types/api/v2/obt/model/add-managed-travelers-request';
import type { RemoveManagedTravelersRequest } from '../types/api/v2/obt/model/remove-managed-travelers-request';
import type { UpdateTravelArrangersStatusRequest } from '../types/api/v2/obt/model/update-travel-arrangers-status-request';
import type { UserId } from '../types/api/v2/obt/model/user-id';
import type { UpdateUnusedTicketStatusRequest } from '../types/api/v2/obt/model/update-unused-ticket-status';

// ToDo: there is a duplication version: travelerReadKey. we need to consider having only 1 useQuery for 1 endpoint
const profileDetailsKey = (userOrgId: IUserOrgId | undefined): unknown[] => ['read-traveler', userOrgId?.userId];
const getProfileUserRolesKey = (userOrgId: IUserOrgId | undefined): unknown[] => ['user-roles', userOrgId];
const getServiceFeeKey = (serviceFeeRequest: CalculateServiceChargeRequest | null): unknown[] => [
  'service-fee-request',
  serviceFeeRequest,
];
const getPointsBalanceKey = (userOrgId: IUserOrgId | undefined): unknown[] => ['user-points', userOrgId];
const getArrangersInfoKey = (userId: string): unknown[] => ['user-arrangers', userId];
const getManagedTravelerInfoKey = (userId: string): unknown[] => ['user-managed-travelers', userId];
const getTravelPreferencesNotesKey = (userId: string): unknown[] => ['travel-preferences-notes', userId];

const getTravelNotificationPreferencesKey = (userId: string): unknown[] => ['travel-notification-preferences', userId];
const getUserPersonalInfoKey = (userId: string): unknown[] => ['user-personal-info', userId];
const getUsersListKey = (userIds: UserId[]) => ['users-list', { userIds }] as const;

const travelerPreBookQuestionsKey = ({
  searchId,
  rateOptionId,
  userOrgId,
  tripId,
  travelType,
  itineraryKey,
}: PreBookQuestionRequest): unknown[] => {
  /** since tripId is a dependency for this query, we can add it to the keys array without worrying about falsy values */
  const keys: unknown[] = [TRAVELER_PREBOOK_QUESTIONS_KEY_ID, searchId, rateOptionId, userOrgId, tripId];
  if (travelType) {
    keys.push(travelType);
  }
  if (itineraryKey) {
    keys.push(itineraryKey);
  }
  return keys;
};

const fetchTravelerPreBookQuestionsInternal = async (
  request: PreBookQuestionRequest,
): Promise<PreBookQuestionResponse> => {
  const data = await api(
    'POST',
    'preBookQuestions',
    {
      data: request,
    },
    { allowParallelRequests: true },
  );
  return data as PreBookQuestionResponse;
};

type FetchProfileDetailsInternalProps = { userOrgId?: IUserOrgId };

export const fetchProfileDetailsInternal = async (
  { userOrgId }: FetchProfileDetailsInternalProps,
  axiosRequestOptionsOuter?: IApiRequestOptions,
): Promise<Traveler> => {
  const { allowParallelRequests = true, ...axiosRequestOptions } = axiosRequestOptionsOuter || {};
  const profileDetailsResponse = await api(
    'POST',
    'readTraveler',
    { data: { userOrgId: removeEmptyValuesFromObject(userOrgId) } },
    { allowParallelRequests, ...axiosRequestOptions },
  );
  return (profileDetailsResponse as ReadTravelerResponse)?.traveler as Traveler;
};

export const fetchServiceFee = async (
  serviceFeeRequest: CalculateServiceChargeRequest,
): Promise<CalculateServiceChargeResponse> => {
  const serviceFee = await api('POST', 'getServiceFee', { data: serviceFeeRequest });
  return serviceFee as CalculateServiceChargeResponse;
};

const fetchUserRoles = async (userOrgId: IUserOrgId | undefined): Promise<GetUserRolesResponse> => {
  const userRoles = await api(
    'POST',
    'getUserRoles',
    { data: { userOrgId: removeEmptyValuesFromObject(userOrgId) } },
    { allowParallelRequests: true },
  );

  return userRoles as GetUserRolesResponse;
};

const updateProfile = async (updateRequest: UpdateTravelerRequest): Promise<void> => {
  await api('POST', 'travelerUpdate', {
    data: updateRequest,
  });
};

const deletePayment = async (cardDeleteRequest: CardDeleteRequest): Promise<IResponseData | undefined> =>
  api('POST', 'creditCardDelete', {
    data: cardDeleteRequest,
  });

export const useProfileReadQuery = (
  userOrgId: IUserOrgId | undefined,
  options?: UseQueryOptions<Traveler, SpotnanaError, Traveler, QueryKey>,
): SpotnanaQueryResult<Traveler> => {
  const queryOptions = options || {};
  // Prevent read traveler if userOrgId is empty object `{}`
  const hasUserOrgId = !!userOrgId?.userId?.id;
  if (typeof queryOptions.enabled === 'boolean') {
    queryOptions.enabled = queryOptions.enabled && hasUserOrgId;
  } else {
    queryOptions.enabled = hasUserOrgId;
  }

  return useQuery<Traveler, SpotnanaError>(
    profileDetailsKey(userOrgId),
    () => fetchProfileDetailsInternal({ userOrgId }),
    queryOptions,
  );
};

export const useProfileReadQueryUsingUserId = (
  userId: string | undefined,
  options?: UseQueryOptions<Traveler, SpotnanaError, Traveler, QueryKey>,
): SpotnanaQueryResult<Traveler> => {
  const userOrgId = createUserOrgIdUsingUserId(userId);
  return useProfileReadQuery(userOrgId, options);
};

export const profileReadQueryFetch = (
  userOrgId: IUserOrgId | undefined,
  fetchQueryOptions?: FetchQueryOptions<Traveler, SpotnanaError, Traveler, QueryKey>,
): Promise<Traveler> =>
  defaultQueryClient.fetchQuery<Traveler, SpotnanaError>(
    profileDetailsKey(userOrgId),
    () => fetchProfileDetailsInternal({ userOrgId }),
    fetchQueryOptions,
  );

export const useServiceFeeReadQuery = (
  serviceFeeRequest: CalculateServiceChargeRequest,
  enabled: boolean,
): SpotnanaQueryResult<CalculateServiceChargeResponse> =>
  useQuery<CalculateServiceChargeResponse, SpotnanaError>(
    getServiceFeeKey(serviceFeeRequest),
    () => fetchServiceFee(serviceFeeRequest),
    {
      enabled,
      cacheTime: 0,
    },
  ) as SpotnanaQueryResult<CalculateServiceChargeResponse>;

export const useMultipleServiceFeeReadQuery = (
  serviceFeeRequests: (CalculateServiceChargeRequest | null)[],
): SpotnanaQueryResult<CalculateServiceChargeResponse>[] =>
  useQueries(
    serviceFeeRequests.map((serviceFeeRequest) => ({
      queryKey: getServiceFeeKey(serviceFeeRequest),
      queryFn: () => (serviceFeeRequest ? fetchServiceFee(serviceFeeRequest) : Promise.resolve(undefined)),
      /**
       * TODO: Using msw will allow query coverage
       */
      enabled: !!serviceFeeRequest && !!serviceFeeRequest.tripId,
    })),
  ) as SpotnanaQueryResult<CalculateServiceChargeResponse>[];

export const useReadMultipleProfilesWithUserOrgIdQuery = (
  userOrgIds: Array<IUserOrgId | undefined>,
  options?: UseQueryOptions,
): SpotnanaQueryResult<Traveler | undefined>[] =>
  useQueries(
    userOrgIds.map((userOrgId) => {
      return {
        queryKey: profileDetailsKey(userOrgId),
        queryFn: () => fetchProfileDetailsInternal({ userOrgId }),
        enabled: !!userOrgId,
        ...options,
      };
    }),
  ) as SpotnanaQueryResult<Traveler | undefined>[];

export const useUserRolesQuery = (userOrgId: IUserOrgId | undefined): SpotnanaQueryResult<GetUserRolesResponse> =>
  useQuery<GetUserRolesResponse, SpotnanaError>(getProfileUserRolesKey(userOrgId), () => fetchUserRoles(userOrgId), {
    enabled: !!userOrgId,
  });

export const useTravelerPreBookQuestions = (
  request: PreBookQuestionRequest,
  options?: UseQueryOptions<PreBookQuestionResponse, SpotnanaError, PreBookQuestionResponse, QueryKey>,
): SpotnanaQueryResult<PreBookQuestionResponse> =>
  useQuery<PreBookQuestionResponse, SpotnanaError>(
    travelerPreBookQuestionsKey(request),
    () => fetchTravelerPreBookQuestionsInternal(request),
    options,
  );

export const useMultipleTravelersPreBookQuestions = (
  preBookQuestionRequests: {
    request: PreBookQuestionRequest;
    enabled: boolean;
    keepPreviousData?: boolean;
  }[],
): SpotnanaQueryResult<PreBookQuestionResponse>[] =>
  useQueries(
    preBookQuestionRequests.map((preBookRequest) => {
      const { request, enabled, keepPreviousData } = preBookRequest;
      return {
        queryKey: travelerPreBookQuestionsKey(request),
        queryFn: () => fetchTravelerPreBookQuestionsInternal(request),
        ...{ enabled, keepPreviousData },
      };
    }),
  ) as SpotnanaQueryResult<PreBookQuestionResponse>[];

export const invalidateReadProfile = (userOrgId: IUserOrgId): void => {
  defaultQueryClient.invalidateQueries(profileDetailsKey(userOrgId));
};

export const invalidateProfileUserRoles = (userOrgId: IUserOrgId) => {
  defaultQueryClient.invalidateQueries(getProfileUserRolesKey(userOrgId));
};

// user personal info
export const invalidateGetUserPersonalInfo = (userId: string): void => {
  defaultQueryClient.invalidateQueries(getUserPersonalInfoKey(userId), { refetchInactive: true });
};

export const useUpdateProfile = () =>
  useMutation((requestBody: UpdateTravelerRequest) => updateProfile(requestBody), {
    onSuccess: (_data, requestBody) => {
      invalidateReadProfile(requestBody.userOrgId as IUserOrgId);
      invalidateGetUserPersonalInfo(requestBody.userOrgId.userId?.id as string);
    },
  });

export const useDeletePaymentCard = () =>
  useMutation((requestBody: CardDeleteRequest) => deletePayment(requestBody), {
    onSuccess: (_data, requestBody) => {
      invalidateReadProfile(requestBody.userOrgId as IUserOrgId);
    },
  });

export const prefetchProfileReadQuery = (userOrgId: IUserOrgId): Promise<void> =>
  defaultQueryClient.prefetchQuery(profileDetailsKey(userOrgId), () => fetchProfileDetailsInternal({ userOrgId }));

export const fetchPointsBalance = async (): Promise<PointsBalance[]> => {
  const data = await api('GET', 'getPaymentOptionBalance');
  return data as PointsBalance[];
};

export const usePointsBalance = (
  userOrgId: IUserOrgId | undefined,
  options?: UseQueryOptions<PointsBalance[], SpotnanaError, PointsBalance[], QueryKey>,
): SpotnanaQueryResult<PointsBalance[]> => {
  const optionsWithEnabled = {
    ...options,
    enabled: !!userOrgId && get(options, 'enabled', true),
  };

  return useQuery<PointsBalance[], SpotnanaError>(
    getPointsBalanceKey(userOrgId),
    () => fetchPointsBalance(),
    optionsWithEnabled,
  );
};

export const invalidatePointsBalance = (userOrgId: IUserOrgId): Promise<void> =>
  defaultQueryClient.invalidateQueries(getPointsBalanceKey(userOrgId));

export const fetchArrangersInfo = async (userId: string): Promise<UserTravelArrangers> => {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/travel-arrangers` });
  return data as UserTravelArrangers;
};

export const useArrangersInfo = (userId: string, enabled = true): SpotnanaQueryResult<UserTravelArrangers> =>
  useQuery<UserTravelArrangers, SpotnanaError>(getArrangersInfoKey(userId), () => fetchArrangersInfo(userId), {
    enabled,
  });
export const invalidateAllArrangersInfo = (): Promise<void> => defaultQueryClient.invalidateQueries('user-arrangers');

const fetchTravelPreferencesNotes = async (userId: string): Promise<ListTravelPreferenceNotes> => {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/travel-preference-notes` });
  return data as ListTravelPreferenceNotes;
};

export const useTravelPreferencesNotes = (
  userId: string,
  enabled = true,
): SpotnanaQueryResult<ListTravelPreferenceNotes> =>
  useQuery<ListTravelPreferenceNotes, SpotnanaError>(
    getTravelPreferencesNotesKey(userId),
    () => fetchTravelPreferencesNotes(userId),
    {
      enabled,
    },
  );

export const invalidateTravelPreferences = (userId: string): Promise<void> =>
  defaultQueryClient.invalidateQueries(getTravelPreferencesNotesKey(userId));

const addTravelerNote = async (userId: string, note: string): Promise<void> => {
  try {
    await api('POST', 'userBaseUrl', {
      urlParam: `/${userId}/travel-preference-notes`,
      data: { note },
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface IAddTravelerNoteRequest {
  userId: string;
  note: string;
}

export const useAddTravelerNote = (): SpotnanaQueryMutationResult<void, IAddTravelerNoteRequest> =>
  useMutation((request: IAddTravelerNoteRequest) => addTravelerNote(request.userId, request.note), {
    onSuccess(_, request) {
      invalidateTravelPreferences(request.userId);
    },
  });

const updateTncConsent = async (userId: string, tncConsentGiven: boolean): Promise<void> => {
  try {
    await api('PUT', 'userBaseUrl', {
      urlParam: `/${userId}/tnc-consent`,
      data: {
        tncConsentGiven,
      },
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface IUpdateTncConsentRequest {
  userId: string;
  tncConsentGiven: boolean;
}

export const useUpdateTncConsent = (): SpotnanaQueryMutationResult<void, IUpdateTncConsentRequest> =>
  useMutation((request: IUpdateTncConsentRequest) => updateTncConsent(request.userId, request.tncConsentGiven));

const deleteTravelerNote = async (userId: string, noteId: string): Promise<void> => {
  try {
    await api('DELETE', 'userBaseUrl', {
      urlParam: `/${userId}/travel-preference-notes/${noteId}`,
    });
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

interface IDeleteTravelerNoteRequest {
  userId: string;
  noteId: string;
}

export const useDeleteTravelerNote = (): SpotnanaQueryMutationResult<void, IDeleteTravelerNoteRequest> =>
  useMutation((request: IDeleteTravelerNoteRequest) => deleteTravelerNote(request.userId, request.noteId), {
    onSuccess(_, request) {
      invalidateTravelPreferences(request.userId);
    },
  });

export interface GetUserRestrictedLocationRequest extends CheckBookingRestrictionsByLocationRequest {
  userId: string;
}

const getUserRestrictedLocationUrlKey = (userId: string) => `/${userId}/check-restrictions/location`;

const getUserRestrictedLocation = async ({
  userId,
  ...rest
}: GetUserRestrictedLocationRequest): Promise<LocationRestrictionValidationResult[]> => {
  try {
    const result = (await api('POST', 'userBaseUrl', {
      urlParam: getUserRestrictedLocationUrlKey(userId),
      data: rest,
    })) as {
      results: LocationRestrictionValidationResult[];
    };

    return result?.results;
  } catch (e) {
    throw new SpotnanaError(e as Error);
  }
};

export const useUserRestrictedLocations = (): SpotnanaQueryMutationResult<
  LocationRestrictionValidationResult[],
  GetUserRestrictedLocationRequest
> =>
  useMutation(getUserRestrictedLocationUrlKey(''), (request: GetUserRestrictedLocationRequest) =>
    getUserRestrictedLocation(request),
  );

const updateTravelPreference = async (userId: string, travelPref: TravelPreferences): Promise<void> => {
  await api('PUT', 'userBaseUrl', {
    urlParam: `/${userId}/travel-preferences`,
    data: travelPref,
  });
};

export const useUpdateTravelPreference = (
  userId: string,
  options?: UseMutationOptions<void, unknown, TravelPreferences, unknown>,
) =>
  useMutation((travelPref: TravelPreferences) => updateTravelPreference(userId, travelPref), {
    onSuccess: (_data) => {
      invalidateTravelPreference(userId);
    },
    ...options,
  });

// travel notification preference
export const invalidateGetTravelNotificationPreference = (userId: string): void => {
  defaultQueryClient.invalidateQueries(getTravelNotificationPreferencesKey(userId));
};

const getTravelNotificationPreference = async (userId: string): Promise<NotificationPreferences> => {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/notification-preferences` });
  return data as NotificationPreferences;
};

export const useGetTravelNotificationPreference = (
  userId: string,
  enabled = true,
): SpotnanaQueryResult<NotificationPreferences> =>
  useQuery<NotificationPreferences, SpotnanaError>(
    getTravelNotificationPreferencesKey(userId),
    () => getTravelNotificationPreference(userId),
    {
      enabled,
    },
  );

const updateTravelNotificationPreference = async (
  userId: string,
  travelPref: NotificationPreferences,
): Promise<void> => {
  await api('PUT', 'userBaseUrl', {
    urlParam: `/${userId}/notification-preferences`,
    data: travelPref,
  });
};

export const useUpdateTravelNotificationPreference = (
  userId: string,
  options?: UseMutationOptions<void, unknown, NotificationPreferences, unknown>,
) =>
  useMutation(
    (notificationPreferences: NotificationPreferences) =>
      updateTravelNotificationPreference(userId, notificationPreferences),
    {
      onSuccess: (_data) => {
        invalidateGetTravelNotificationPreference(userId);
      },
      ...options,
    },
  );

const getUserPersonalInfo = async (userId: string): Promise<UserPersonalInfo> => {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/personal-info` });
  return data as UserPersonalInfo;
};

export const useGetUserPersonalInfo = (userId: string, enabled = true): SpotnanaQueryResult<UserPersonalInfo> =>
  useQuery<UserPersonalInfo, SpotnanaError>(getUserPersonalInfoKey(userId), () => getUserPersonalInfo(userId), {
    enabled,
  });

const updateUserPersonalInfo = async (userId: string, personalInfo: UserPersonalInfo): Promise<void> => {
  await api('PUT', 'userBaseUrl', {
    urlParam: `/${userId}/personal-info`,
    data: personalInfo,
  });
};

export const useUpdateUserPersonalInfo = (
  userId: string,
  userOrgId: IUserOrgId,
  options?: UseMutationOptions<void, unknown, UserPersonalInfo, unknown>,
) =>
  useMutation((personalInfo: UserPersonalInfo) => updateUserPersonalInfo(userId, personalInfo), {
    onSuccess: (...args) => {
      options?.onSuccess?.(...args);
      invalidateGetUserPersonalInfo(userId);
      invalidateReadProfile(userOrgId);
    },
    ...options,
  });

const getLanguagesList = async (): Promise<GetLanguagesResponse> => {
  const data = await api('GET', 'supportedLanguages');
  return data as GetLanguagesResponse;
};

export const useGetLanguagesList = (enabled = true): SpotnanaQueryResult<GetLanguagesResponse> =>
  useQuery<GetLanguagesResponse, SpotnanaError>(['global-languages'], () => getLanguagesList(), {
    enabled,
  });

export const fetchManagedTravelersInfo = async (userId: string): Promise<GetManagedTravelersResponse> => {
  const data = await api('GET', 'userBaseUrl', { urlParam: `/${userId}/managed-travelers` });
  return data as GetManagedTravelersResponse;
};

export const useManagedTravelersInfo = (
  userId: string,
  enabled = true,
): SpotnanaQueryResult<GetManagedTravelersResponse> =>
  useQuery<GetManagedTravelersResponse, SpotnanaError>(
    getManagedTravelerInfoKey(userId),
    () => fetchManagedTravelersInfo(userId),
    {
      enabled,
    },
  );

export const updateManagedTravelersNotifications = async (
  userId: string,
  emailPref: UpdateManagedTravelersRequest,
): Promise<void> => {
  await api('POST', 'userBaseUrl', { urlParam: `/${userId}/managed-travelers/update`, data: emailPref });
};

export const addManagedTravelers = async (
  userId: string,
  managedTravelersList: AddManagedTravelersRequest,
): Promise<void> => {
  await api('POST', 'userBaseUrl', { urlParam: `/${userId}/managed-travelers`, data: managedTravelersList });
};

export const deleteManagedTravelers = async (
  userId: string,
  managedTravelersList: RemoveManagedTravelersRequest,
): Promise<void> => {
  await api('POST', 'userBaseUrl', { urlParam: `/${userId}/managed-travelers/remove`, data: managedTravelersList });
};

export const approveOrDenyTravelArrangers = async (
  userId: string,
  arrangerList: UpdateTravelArrangersStatusRequest,
): Promise<void> => {
  await api('POST', 'userBaseUrl', { urlParam: `/${userId}/travel-arrangers/approve`, data: arrangerList });
};

// Benli. Not sure if required. Depends on search implementation
export const invalidateAllManagedTravelersInfo = (): Promise<void> =>
  defaultQueryClient.invalidateQueries('user-managed-travelers');

export const useGetApproverInfo = (travelers: UserId[]) => {
  const travelersRequest = travelers.map((approver) => createUserOrgIdUsingUserId(approver.id));
  const usersInfo: IUseGetApproverInfoResult = {};
  useReadMultipleProfilesWithUserOrgIdQuery(travelersRequest).forEach((queryResponse, userIndex) => {
    const userInfo = {
      name: getProfileDisplayText(queryResponse?.data?.user?.name, queryResponse?.data?.user?.email),
      email: queryResponse?.data?.userBusinessInfo?.email || '',
      id: queryResponse?.data?.userOrgId?.userId,
      profilePicture: queryResponse?.data?.user?.profilePicture,
    };
    usersInfo[travelers[userIndex].id] = {
      isFetched: queryResponse.isFetched,
      data: userInfo,
    };
  });
  return usersInfo;
};

export const invalidateUnusedCredits = (): void => {
  defaultQueryClient.invalidateQueries('travelerUnusedCredits');
};

const updateUnusedCredits = async (request: UpdateUnusedTicketStatusRequest): Promise<void> => {
  try {
    await api('POST', 'updateUnusedCredits', { data: request });
  } catch (err) {
    throw new SpotnanaError(err as Error);
  }
};

export const useUpdateUnusedCredits = (): SpotnanaQueryMutationResult<void, UpdateUnusedTicketStatusRequest> =>
  useMutation((req: UpdateUnusedTicketStatusRequest) => updateUnusedCredits(req), {
    onSuccess: () => invalidateUnusedCredits(),
  });

export const useFetchProfileMutation = (): SpotnanaQueryMutationResult<Traveler, IUserOrgId> => {
  return useMutation((userOrgId: IUserOrgId) => fetchProfileDetailsInternal({ userOrgId }));
};

export const useGetUsersList = <SelectedData = ListUsersResponse>(
  { userIds, pageSize, organizationId }: { userIds: UserId[]; pageSize: number; organizationId: OrganizationId },
  options?: UseQueryOptions<ListUsersResponse, SpotnanaError, SelectedData, ReturnType<typeof getUsersListKey>>,
) => {
  const requestData: ListUsersRequest = {
    organizationId,
    includeInactive: true,
    filters: [
      {
        userId: {
          userIds: userIds.map(({ id }) => id),
        },
      },
    ],
    pageNumber: 1,
    numResultsPerPage: pageSize,
  };

  return useQuery({
    queryKey: getUsersListKey(userIds),
    queryFn: (() =>
      api('POST', 'userBaseUrl', {
        urlParam: '/list',
        data: requestData,
      })) as () => Promise<ListUsersResponse>,
    enabled: Boolean(organizationId) && userIds.length > 0,
    ...options,
  });
};
