import type { RoleInfoType } from '../types/api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from '../types/api/v1/obt/profile/role/roles_info';

export type ReadonlyUserRoles = readonly RoleInfoType[];

const { GLOBAL_ADMIN, GLOBAL_AGENT, TMC_ADMIN, TMC_AGENT, COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TRAVEL_ARRANGER } =
  RoleInfoTypeEnum;

const globalUserRoles: ReadonlyUserRoles = [GLOBAL_ADMIN, GLOBAL_AGENT];
export const TMCUserRoles: ReadonlyUserRoles = [TMC_ADMIN, TMC_AGENT];
export const TMCAgentAndAboveRoles = [...globalUserRoles, ...TMCUserRoles];

export const userRolesByFeature: Readonly<{ [key: string]: ReadonlyUserRoles }> = {
  globalUserRoles,
  organizationSelector: [...globalUserRoles, ...TMCUserRoles],
  adminPages: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  travelerSelector: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TRAVEL_ARRANGER],
  analytics: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  reports: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  bookingHistory: [...globalUserRoles, ...TMCUserRoles],
  dutyOfCare: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  agentDesktop: [...TMCUserRoles, ...globalUserRoles],
  agentDesktopAssignees: [GLOBAL_AGENT, TMC_AGENT, TMC_ADMIN],
  partnerIndicator: [...TMCUserRoles, ...globalUserRoles],
  addTmcCompany: [GLOBAL_ADMIN],
  creditCardLegalEntities: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  seat1aUpdate: [...globalUserRoles, COMPANY_ADMIN, TMC_ADMIN],
  seat1aView: [COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TRAVEL_ARRANGER, ...globalUserRoles, ...TMCUserRoles],
  seat1aLegalEntityUpdate: [GLOBAL_ADMIN],
  seat1aLegalEntityView: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  allowedPccInL1Remark: [...globalUserRoles, TMC_ADMIN],
  personalBookings: [...globalUserRoles, ...TMCUserRoles],
  impersonateUser: [...globalUserRoles, ...TMCUserRoles],
  creditCardCurrency: [TMC_ADMIN, GLOBAL_ADMIN],
  delayedInvoiceWrite: [TMC_ADMIN, GLOBAL_ADMIN],
  delayedInvoiceReadOnly: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  companyLevelEdit: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  allowUnmaskingTMCCards: TMCUserRoles,
  allowLoadingTmcCards: [...globalUserRoles, ...TMCUserRoles],
  showCompanyInTravelerSearch: [...globalUserRoles, ...TMCUserRoles],
  genericVendorProgramUpdate: [COMPANY_ADMIN, GLOBAL_ADMIN, TMC_ADMIN],
  allowEditingEmployeeBaseInfo: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  tripRemarks: [
    RoleInfoTypeEnum.COMPANY_ADMIN,
    RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER,
    RoleInfoTypeEnum.GLOBAL_ADMIN,
    RoleInfoTypeEnum.GLOBAL_AGENT,
    RoleInfoTypeEnum.TMC_ADMIN,
    RoleInfoTypeEnum.TMC_AGENT,
    RoleInfoTypeEnum.TRAVEL_ARRANGER,
  ],
  tripRemarksSensitiveInfo: [
    RoleInfoTypeEnum.GLOBAL_ADMIN,
    RoleInfoTypeEnum.GLOBAL_AGENT,
    RoleInfoTypeEnum.TMC_ADMIN,
    RoleInfoTypeEnum.TMC_AGENT,
  ],
  rolesExcludingTraveler: [
    ...globalUserRoles,
    ...TMCUserRoles,
    COMPANY_ADMIN,
    COMPANY_TRAVEL_ARRANGER,
    TRAVEL_ARRANGER,
  ],
  manageFakeOrgs: [GLOBAL_ADMIN],
  specialServiceRequests: [...globalUserRoles, ...TMCUserRoles],
  seatExtraInfo: [...globalUserRoles, ...TMCUserRoles],
  editAgentSettings: [GLOBAL_ADMIN, TMC_ADMIN],
  serviceFee: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  globalAndTMCAdminRoles: [GLOBAL_ADMIN, TMC_ADMIN],
  globalTMCAndCompanyAdminRoles: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  companyTripsPageRoles: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER],
  companyAdminAndAboveRoles: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  eventsPageRoles: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN],
  eventsPageRolesWithAgentAndAdmin: [GLOBAL_ADMIN, TMC_ADMIN, COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TMC_AGENT],
  agentRoles: [GLOBAL_AGENT, TMC_AGENT],
  globalTmcAdminAndAgentRoles: [...globalUserRoles, ...TMCUserRoles],
  globalTmcAndCompanyRoles: [...globalUserRoles, ...TMCUserRoles, COMPANY_ADMIN],
  brexOfflineBookingRoles: [TMC_AGENT, GLOBAL_AGENT, GLOBAL_ADMIN],
  otherSpecialInformation: [TMC_AGENT, ...globalUserRoles],
  internalDebug: [GLOBAL_ADMIN],
  onboarding: [GLOBAL_ADMIN, COMPANY_ADMIN],
  travelerArrangerAndAbove: [
    ...globalUserRoles,
    ...TMCUserRoles,
    COMPANY_ADMIN,
    COMPANY_TRAVEL_ARRANGER,
    TRAVEL_ARRANGER,
  ],
  travelerArrangerOnly: [TRAVEL_ARRANGER],
};
