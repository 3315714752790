import { SpotnanaError } from '../api/SpotnanaError';
import type { MoneyUtil } from '../utils/Money';
import type { PaymentMethod } from './api/v1/common/money';
import type { PaymentSplitCriterion } from './api/v2/obt/model/payment-split-criterion';
import type { PaymentItem } from './api/v2/obt/model/payment-item';
import type { BookingPaymentSource } from './api/v2/obt/model/booking-payment-source';
import type { RewardsProgramMetadata } from './api/v2/obt/model/rewards-program-metadata';
import type { VirtualCardMetadata } from './api/v2/obt/model/virtual-card-metadata';
import type { PaymentRule } from './api/v2/obt/model/payment-rule';
import type { AllowedFopRule, ListBookingPaymentSourcesResponse } from './api/v2/obt/model';
import type { AllowedPaymentDetail } from './api/v2/obt/model/allowed-payment-detail';
import type { PaymentSetupResponseStatus } from './api/v2/obt/model/payment-setup-response-status';

export { PaymentSetupResponseStatus as IPaymentSetupResponseStatus } from './api/v2/obt/model/payment-setup-response-status';

export interface AddPaymentWebviewMessage {
  event: { type: 'ERROR'; data: any } | { type: 'CLOSE_WEBVIEW' };
}

export { CreditCardAccessType } from './api/v2/obt/model/credit-card-access-type';
export { PaymentMethod as PaymentMethodV2 } from './api/v2/obt/model/payment-method';

export enum ManualFormPaymentType {
  CHEQUE = 'CHEQUE',
  TF_PAY = 'TF_PAY',
  CENTRAL = 'CENTRAL',
  TMC = 'TMC',
  PERSONAL = 'PERSONAL',
  CASH = 'CASH',
  CARD = 'CARD',
  BREX_POINTS = 'BREX_POINTS',
  BREX_BUDGET = 'BREX_BUDGET',
  DIRECT_BILL = 'DIRECT_BILL',
}

export interface IManualFormAdditionalInfo {
  formOfPayment: ManualFormPaymentType;
}

export function isManualFormPaymentType(value: any): value is ManualFormPaymentType {
  return Object.values(ManualFormPaymentType).includes(value);
}

export function isIManualFormAdditionalInfo(data: any): data is IManualFormAdditionalInfo {
  return data instanceof Object && 'formOfPayment' in data && isManualFormPaymentType(data.formOfPayment);
}

export function assertIsIManualFormAdditionalInfo(data: unknown): asserts data is IManualFormAdditionalInfo {
  if (!isIManualFormAdditionalInfo(data)) {
    throw new SpotnanaError(`Value is not IManualFormAdditionalInfo: ${JSON.stringify(data)}`);
  }
}

export type { Money as MoneyV1 } from './api/v1/common/money';

export enum PaymentMethodEnumBrexBudget {
  BREX_BUDGET = 4,
}

export const IExtraPaymentItemType = {
  UnusedCredits: 'UNUSED_CREDITS',
  AirlineFees: 'AIRLINE_FEES',
} as const;

export type IExtraPaymentItemTypeType = (typeof IExtraPaymentItemType)[keyof typeof IExtraPaymentItemType];

export interface IExtraPaymentItem {
  itemType: IExtraPaymentItemTypeType;
}

export interface IPaymentRuleEnhanced extends PaymentRule {
  extraPaymentItems?: Array<IExtraPaymentItem>;
}
export interface IAllowedFopRuleEnriched extends AllowedFopRule {
  paymentRules?: Array<IPaymentRuleEnhanced>;
}

export interface IAllowedPaymentDetailEnriched extends AllowedPaymentDetail {
  allowedFopRules?: Array<IAllowedFopRuleEnriched>;
}

export interface IListBookingPaymentSourcesResponseEnriched extends ListBookingPaymentSourcesResponse {
  allowedPaymentDetails?: Array<IAllowedPaymentDetailEnriched>;
}

export interface PaymentItemInfo {
  key: string;
  fopRuleIndex: number;
  paymentRuleIndex: number;
  paymentItems: PaymentItem[];
  extraPaymentItems?: IExtraPaymentItem[];
  amount: MoneyUtil;
}

export interface IPaymentRulesSplitInfo extends IPaymentRuleEnhanced {
  paymentItemInfo: PaymentItemInfo;
  paymentSourceIds: string[];
  allowAddCard: boolean;
  splitCriterion: PaymentSplitCriterion;
  paymentSourcesExistButAreAllUnusable: boolean;
  addNewCardButtonText: string;
}

export interface IMergedPaymentRulesSplitInfo extends Omit<IPaymentRulesSplitInfo, 'paymentItemInfo'> {
  paymentItemInfos: PaymentItemInfo[];
}

export interface IFindPaymentRuleReturn {
  paymentRule: PaymentRule;
  fopRuleIndex: number;
  paymentRuleIndex: number;
}

export type PaymentMethodExtended = PaymentMethod | PaymentMethodEnumBrexBudget;

export interface IPointsSelectorConfig {
  isHidden: boolean;
  minLimit: number;
  maxLimit: number;
  disablePointSelection: boolean;
  steps: number[];
  insufficientPointsToBook: boolean;
}

export enum PointsType {
  BREX_POINT_TYPE = 'BREX_POINT_TYPE',
  QANTAS_POINT_TYPE = 'QANTAS_POINT_TYPE',
}

export interface IPaymentDetailsValues {
  remainingPointsBalance: number;
  cashToBePaid: MoneyUtil;
  pointsUsed: number;
  handlePointsSliderChange: (pointsUsed: number) => void;
  pointsCashEquivalent: MoneyUtil;
}

export type VirtualCardPaymentSource = Pick<BookingPaymentSource, 'id' | 'isUsable' | 'paymentAccess'> & {
  metadata: VirtualCardMetadata;
};

export type RewardsProgramPaymentSource = Pick<BookingPaymentSource, 'id' | 'isUsable'> & {
  metadata: RewardsProgramMetadata;
};
export interface IServiceFeeData {
  base: MoneyUtil;
  tax: MoneyUtil;
  total: MoneyUtil;
  showTax: boolean;
}

export interface ICvvRequirementInfo {
  isCvvRequired: boolean;
  paymentSourceId: string;
  status: PaymentSetupResponseStatus;
  cvvDocLink?: string;
}
