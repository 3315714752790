import flatten from 'lodash/flatten';
import first from 'lodash/first';
import isEqual from 'lodash/isEqual';
import { getDisplayPreBookAnswer } from '../../../utils/getDisplayPreBookAnswer';
import { CardCompany } from '../../../types/api/v2/obt/model/card-company';
import { PaymentMethod } from '../../../types/api/v2/obt/model/payment-method';
import { PreCheckoutQuestionType } from '../../../types/api/v2/obt/model/pre-checkout-question-type';
import type { PnrData } from '../../../types/api/v2/obt/model/pnr-data';
import { PnrType } from '../../../types/api/v2/obt/model/pnr-type';
import { ThirdPartySource } from '../../../types/api/v2/obt/model/third-party-source';
import { MoneyUtil, getNameStringFromName, maskCardNumber } from '../../../utils';
import { Persona } from '../../../types/api/v2/obt/model/persona';
import { UserFacingStatus } from '../../../types/api/v2/obt/model/user-facing-status';
import { defineCommonMessage } from '../../../translations/defineMessage';
import type { PnrPaymentMethod } from '../types';
import { PnrStatus } from '../../../types/api/v2/obt/model/pnr-status';

export interface PnrV3ManagerProps {
  readonly pnrData: PnrData;
  readonly pnrId: string;
}
export class PnrV3Manager {
  pnrId: string;

  pnrData: PnrData;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    if (!pnrData || !pnrId) {
      throw new Error('Invalid param passed to PnrV3Manager');
    }
    this.pnrData = pnrData;
    this.pnrId = pnrId;
  }

  public pnrType(): PnrType {
    const pnr = this.pnrData;

    switch (true) {
      case Boolean(pnr.airPnr):
        return PnrType.Air;
      case Boolean(pnr.hotelPnr):
        return PnrType.Hotel;
      case Boolean(pnr.carPnr):
        return PnrType.Car;
      case Boolean(pnr.railPnr):
        return PnrType.Rail;
      case Boolean(pnr.limoPnr):
        return PnrType.Limo;
      default:
        throw new Error('Invalid PNR type');
    }
  }

  public serviceFee() {
    const serviceFees = this.pnrData.serviceFees?.[0];
    const visibleToTraveler = serviceFees?.visibleToTraveler ?? true;

    const base = MoneyUtil.convertV2MoneyToMoneyUtil(serviceFees?.fare.base);
    const tax = MoneyUtil.convertV2MoneyToMoneyUtil(serviceFees?.fare.tax);
    const total = base.add(tax);

    return {
      fop: serviceFees?.fop,
      base,
      tax,
      total,
      visibleToTraveler,
    };
  }

  public paymentInfo() {
    const { totalFareAmount, totalFare } = this.pnrData;
    const baseAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.base);
    const taxAmount = MoneyUtil.convertV2MoneyToMoneyUtil(totalFareAmount?.tax);
    const totalAmount = totalFare ? MoneyUtil.convertV2MoneyToMoneyUtil(totalFare) : baseAmount.add(taxAmount); // This is needed because unknown bug where totalFare is undefined in PnrData but shows up correctly in tripData and obt calls to same pnr api.  Will need more investigation to determine root cause.

    const paymentMethods =
      this.pnrData.paymentInfo?.map((paymentInfo) => {
        if (paymentInfo.fop.paymentMethod === PaymentMethod.CreditCard) {
          return {
            type: paymentInfo.fop.paymentMethod,
            name: maskCardNumber(paymentInfo.fop.card?.number ?? ''),
          };
        }
        return {
          type: paymentInfo.fop.paymentMethod,
          name: '',
        };
      }) ?? [];

    return {
      baseAmount,
      taxAmount,
      totalAmount,
      paymentMethods,
    };
  }

  public travelerDetails() {
    const travelers = this.pnrData?.travelers?.map((pnrTraveler) => ({
      name: getNameStringFromName(pnrTraveler.user?.name),
      preferredName: getNameStringFromName(pnrTraveler.user?.name, { usePreferredName: true }),
      id: pnrTraveler.user?.id,
      loyaltyId: pnrTraveler.travelerPersonalInfo?.loyaltyInfos.map((l) => l.id).join(', ') ?? '',
    }));
    return travelers;
  }

  public travelData() {
    const { preBookAnswers } = this.pnrData;
    return (
      preBookAnswers?.answers?.map((preBookAnswer) => ({
        question: preBookAnswer.questionDisplayText ?? '',
        isOOPReasonCode:
          preBookAnswer.question?.questionType?.preCheckoutQuestionType === PreCheckoutQuestionType.OopReasonCode,
        answer: getDisplayPreBookAnswer(preBookAnswer),
        customFieldLocations: preBookAnswer.question?.customFieldLocations || [],
      })) ?? []
    );
  }

  public sourceInfo() {
    const { sourceInfo } = this.pnrData;
    const sourcePnrId = sourceInfo?.sourcePnrId ?? '';

    return {
      sourcePnrId,
      thirdPartySource: sourceInfo?.thirdParty || '',
      posDescriptor: sourceInfo?.posDescriptor,
    };
  }

  public isSpotnanaMerchant() {
    const isNotFareLogix = this.pnrData.sourceInfo?.thirdParty !== ThirdPartySource.FarelogixNdc;
    const hasCTC = !!this.pnrData.costToCustomer && this.pnrData.costToCustomer.payments.length > 0;
    return isNotFareLogix && hasCTC;
  }

  public isPnrOutOfPolicy() {
    return this.pnrData.policyInfo?.outOfPolicy ?? false;
  }

  public isPersonalBooking() {
    return first(this.pnrData?.pnrTravelers)?.persona === Persona.Personal;
  }

  public getPolicyInfo() {
    const { policyInfo } = this.pnrData;
    const isOutOfPolicy = policyInfo?.outOfPolicy === true;
    const ruleInfos = policyInfo?.appliedPolicyInfo?.ruleResultInfos ?? [];
    const violationInfos = flatten(ruleInfos.map((ruleResultInfo) => ruleResultInfo.violationInfos ?? []));
    return { isOutOfPolicy, violationInfos };
  }

  public isPnrOnHold() {
    const { bookingStatus } = this.pnrData;
    return bookingStatus === UserFacingStatus.HoldStatus;
  }

  public isPnrCancelled() {
    const USER_FACING_CANCELLED_STATUSES: UserFacingStatus[] = [
      UserFacingStatus.CancelledStatus,
      UserFacingStatus.VoidedStatus,
    ];

    const PNR_CANCELLED_STATUSES: PnrStatus[] = [PnrStatus.Cancelled, PnrStatus.CancelledByVendor, PnrStatus.Voided];

    const { pnrData } = this;
    if (pnrData && pnrData.airPnr) {
      // we split the airPnr based on legs and show the status of the each leg
      const legStatus = first(pnrData.airPnr.legs)?.legStatus;
      return USER_FACING_CANCELLED_STATUSES.includes(legStatus ?? UserFacingStatus.UnknownStatus);
    }

    const pnrStatus =
      pnrData.hotelPnr?.pnrStatus ??
      pnrData.carPnr?.pnrStatus ??
      pnrData.railPnr?.inwardJourney?.journeyStatus ??
      pnrData.railPnr?.outwardJourney.journeyStatus ??
      pnrData.limoPnr?.pnrStatus ??
      pnrData.miscPnr?.pnrStatus ??
      PnrStatus.Unknown;

    return PNR_CANCELLED_STATUSES.includes(pnrStatus);
  }

  public isVirtualCardBooking() {
    return !!first(this.pnrData.costOfGoodsSold?.payments)?.fop?.paymentMetadata?.virtualCardMetadata;
  }

  public paymentMethods(): PnrPaymentMethod[] | undefined {
    const paymentInfos = this.pnrData.paymentInfo;
    const visiblePaymentInfos = paymentInfos?.filter((paymentInfo) => {
      const { fop: serviceFeeFop, visibleToTraveler } = this.serviceFee();
      if (isEqual(paymentInfo.fop, serviceFeeFop) && !visibleToTraveler) {
        return false;
      }
      return true;
    });

    return visiblePaymentInfos?.map((paymentInfo) => {
      let description = defineCommonMessage('Unknown');
      let cardCompany: CardCompany = CardCompany.None;
      const { paymentMethod } = paymentInfo.fop;

      const totalChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.totalCharge);
      const refundChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.totalRefund);
      const netChargeAmount = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.netCharge);

      let netCharge = netChargeAmount;
      let showChargeAmount = !(
        totalChargeAmount.isAmountZero() &&
        refundChargeAmount.isAmountZero() &&
        netChargeAmount.isAmountZero()
      );

      const showTotalAndRefundCharge = !totalChargeAmount.equals(netChargeAmount);

      if (paymentInfo.fop.paymentMethod === PaymentMethod.CreditCard) {
        cardCompany = paymentInfo.fop.card?.company ?? CardCompany.None;
        description = defineCommonMessage('Credit card');
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.QantasPoints) {
        description = defineCommonMessage('Qantas points');
        netCharge = netChargeAmount.getQantasPoints()?.amount ? netChargeAmount : totalChargeAmount;
        showChargeAmount = !!netCharge.getQantasPoints()?.amount;
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.BrexPoints) {
        description = defineCommonMessage('Brex points');
        netCharge = netChargeAmount.getBrexPoints()?.amount ? netChargeAmount : totalChargeAmount;
        showChargeAmount = !!netCharge.getBrexPoints()?.amount;
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.FlightCredits) {
        description = defineCommonMessage('Flight credits');
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.DelayedInvoicing) {
        description = defineCommonMessage('Billed to company');
      }
      if (paymentInfo.fop.paymentMethod === PaymentMethod.Cash) {
        description = defineCommonMessage('Cash');
      }

      if (paymentInfo.fop.paymentMethod === PaymentMethod.VendorProgramPayment && 'card' in paymentInfo.fop) {
        description = defineCommonMessage('UATP');
        cardCompany = paymentInfo.fop.card?.company ?? CardCompany.None;
      }

      if (
        paymentInfo.fop.paymentMethod === PaymentMethod.VendorProgramPayment &&
        paymentInfo.fop.paymentMetadata &&
        paymentInfo.fop.paymentMetadata.vendorProgramPaymentMetadata &&
        'directBilling' in paymentInfo.fop.paymentMetadata.vendorProgramPaymentMetadata
      ) {
        description = defineCommonMessage('Direct billing');
      }

      const cardNumber = maskCardNumber(paymentInfo.fop.card?.number ?? '').slice(-9);

      return {
        paymentMethod,
        description,
        cardCompany,
        cardNumber,

        totalCharge: totalChargeAmount,
        netCharge,
        refundCharge: refundChargeAmount,

        showChargeAmount,
        showTotalAndRefundCharge,
      };
    });
  }

  public qantasPoints() {
    const paymentInfos = this.pnrData.paymentInfo ?? [];
    let qantasPoints = MoneyUtil.zeroMoney('AUD');
    let hasQantasPoints = false;
    paymentInfos.forEach((paymentInfo) => {
      if (paymentInfo.fop.paymentMethod === PaymentMethod.QantasPoints) {
        hasQantasPoints = true;
        if (paymentInfo.netCharge?.otherCoinage && paymentInfo.netCharge.otherCoinage.length > 0) {
          qantasPoints = qantasPoints.add(MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.netCharge));
        } else {
          qantasPoints = qantasPoints.add(MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.totalCharge));
        }
      }
    });

    return {
      hasQantasPoints,
      qantasPoints,
    };
  }

  public isOutsideBooking() {
    return this.pnrData.sourceInfo?.bookingSource === 'OFFLINE';
  }
}
