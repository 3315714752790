import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFeatureFlag, usePrimaryTraveler, parseParams, useGetApplicableSiteMessages } from 'obt-common';
import type { SiteMessage } from 'obt-common/types/api/v2/obt/model/site-message';
import { SiteMessageLocation } from 'obt-common/types/api/v2/obt/model/site-message-location';
import { SiteMessagePlatform } from 'obt-common/types/api/v2/obt/model/site-message-platform';
import { BookingType } from 'obt-common/types/api/v2/obt/model/booking-type';

import { parseSiteMessage } from 'obt-common/sitemessaging/utils';
import { useSiteMessagingContext } from 'src/v2-components/sitemessaging/SiteMessagingContextProvider';

import { getSearchContextForBookingType, getCheckoutContextForBookingType } from './utils';
import { routeConfigMap } from './config';
import { BannerMessage } from './BannerMessage';

export const SiteMessagingBanners = () => {
  const [messages, setMessages] = useState<SiteMessage[]>([]);
  const [dismissedMessages, setDismissedMessages] = useState<string[]>([]);
  const { data: user } = usePrimaryTraveler();
  const { pathname, search: searchPath } = useLocation();
  const isCustomSiteMessagingEnabled = useFeatureFlag('FE_CORP_COMPANY_SITE_MESSAGING');
  const showPersistentSiteMessage = useFeatureFlag('FE_CORP_SHOW_PERSISTENT_SITE_MESSAGES');
  const { ancillaryResponseId, setAncillaryResponseId } = useSiteMessagingContext();

  const { mutateAsync: getSiteMessages } = useGetApplicableSiteMessages();

  useEffect(() => {
    // If the feature flag is disabled, clear the messages state so they don't persist across pages.
    if (!isCustomSiteMessagingEnabled) {
      setMessages([]);
    }
    if (showPersistentSiteMessage) {
      setMessages([]);
      setDismissedMessages([]);
    }
    const params = parseParams(searchPath);

    const location = routeConfigMap[pathname]?.location;
    const bookingType = routeConfigMap[pathname]?.bookingType;

    let context;

    // If the location is not configured to display site messages,
    // clear the messages state so they don't persist across pages.
    if (!location) {
      setMessages([]);
    }

    // Only fetch messages if we have a user and the location
    // is configured to display site messages.
    if (user?.userOrgId?.userId && location) {
      // For search result pages, context is SearchContext.
      if (location === SiteMessageLocation.SearchResultPage && bookingType) {
        context = getSearchContextForBookingType(bookingType, params);
      }
      // For checkout pages, we set CheckoutContext.
      if (location === SiteMessageLocation.CheckoutPage && bookingType) {
        let itineraryId = '';
        if (bookingType === BookingType.Air) {
          itineraryId = ancillaryResponseId || '';
        } else {
          setAncillaryResponseId(null);
        }
        context = getCheckoutContextForBookingType(bookingType, params, itineraryId);
      }

      const request = {
        requestBody: {
          userId: user.userOrgId.userId,
          platform: SiteMessagePlatform.Desktop,
          location,
          bookingType,
          context,
        },
      };

      const isAirCheckout = bookingType === BookingType.Air && location === SiteMessageLocation.CheckoutPage;
      // Require valid ancillaryResponseId for air checkout pages.
      const shouldFetchMessages = !isAirCheckout || ancillaryResponseId;
      if (isCustomSiteMessagingEnabled && shouldFetchMessages) {
        getSiteMessages(request).then((returnedMessages) => setMessages(returnedMessages.messages));
      }
    }
  }, [
    user,
    getSiteMessages,
    pathname,
    searchPath,
    isCustomSiteMessagingEnabled,
    ancillaryResponseId,
    setAncillaryResponseId,
    showPersistentSiteMessage,
  ]);

  // Store the messageId in state so we don't show it again
  // in the current session. At a future point we can use this
  // to set a 'dismissed' animation style for dismissing the banners.
  const updateDismissedMessagesWithMessageId = (messageId: string) => {
    return () => {
      setDismissedMessages((prevMessages) => [...prevMessages, messageId]);
    };
  };

  // Filter out messages that have been dismissed by the user.
  const undismissedMessages = messages.filter((message: SiteMessage) => !dismissedMessages.includes(message.messageId));

  return (
    <div>
      {undismissedMessages.map((message: SiteMessage) => {
        const { title, body, learnMoreLink } = parseSiteMessage(message);
        return (
          <BannerMessage
            key={message.messageId}
            title={title}
            body={body}
            learnMoreLink={learnMoreLink}
            onDismiss={updateDismissedMessagesWithMessageId(message.messageId)}
          />
        );
      })}
    </div>
  );
};
