/* eslint-disable */
import type {
  GuaranteeType,
  HotelCo2EmissionDetail,
  HotelSpec,
  ImageSet,
  OccupancyDateParams,
  OccupancyDateParamsOccupancy,
  PromotionalOffer,
  Rate,
  RateStatistics,
  RateTypeV1,
} from '../../../v1/obt/hotel/hotel_common';
import { CardCompany } from '../../common/card';
import type { DateTime, Duration } from '../../common/date_time';
import type { Money } from '../../common/money';
import type { Preference, PreferredType, RewardPointsEarned } from '../common/common';
import type { PolicyInfo } from '../common/policy_info';
import { TermsAndConditions } from '../common/terms_and_conditions';
import type { ThirdPartySource } from '../supplier/third_party_info';

export enum BedTypeEnum {
  UNKNOWN_BED_TYPE = 0,
  DOUBLE = 1,
  FUTON = 2,
  KING = 3,
  MURPHY = 4,
  QUEEN = 5,
  SOFA = 6,
  TATAMI_MATS = 7,
  TWIN = 8,
  SINGLE = 9,
  FULL = 10,
  RUN_OF_THE_HOUSE = 11,
  DORM = 12,
  WATER = 13,
  PULL_OUT = 14,
  UNRECOGNIZED = -1,
}

export interface RoomAmenity {
  /** Type of Room amenity. */
  type: RoomAmenityRoomAmenityTypeEnum;
  /** Description of the amenity. */
  additionalInfo: string;
  /** Whether the amenity is complimentary. */
  complimentary: boolean;
}

export enum PrepaidQualifier {
  UNKNOWN_QUALIFIER = 0,
  INCLUDE_PREPAID = 1,
  EXCLUDE_PREPAID = 2,
  PREPAID_ONLY = 3,
}

export enum RoomAmenityRoomAmenityTypeEnum {
  UNKNOWN = 0,
  ADJOINING_ROOMS = 1,
  AIR_CONDITIONING = 2,
  ALARM_CLOCK = 3,
  ALL_NEWS_CHANNEL = 4,
  AM_FM_RADIO = 5,
  BABY_LISTENING_DEVICE = 6,
  BALCONY_LANAI_TERRACE = 7,
  BARBEQUE_GRILLS = 8,
  BATH_TUB_WITH_SPRAY_JETS = 9,
  BATHROBE = 10,
  BATHROOM_AMENITIES = 11,
  BATHROOM_TELEPHONE = 12,
  BATHTUB = 13,
  BATHTUB_ONLY = 14,
  BATHTUB_SHOWER_COMBINATION = 15,
  BIDET = 16,
  BOTTLED_WATER = 17,
  CABLE_TELEVISION = 18,
  COFFEE_TEA_MAKER = 19,
  COLOR_TELEVISION = 20,
  COMPUTER = 21,
  CONNECTING_ROOMS = 22,
  CONVERTERS_VOLTAGE_ADAPTORS = 23,
  COPIER = 24,
  CORDLESS_PHONE = 25,
  CRIBS = 26,
  DATA_PORT = 27,
  DESK = 28,
  DESK_WITH_LAMP = 29,
  DINING_GUIDE = 30,
  DIRECT_DIAL_PHONE_NUMBER = 31,
  DISHWASHER = 32,
  DOUBLE_BEDS = 33,
  DUAL_VOLTAGE_OUTLET = 34,
  ELECTRICAL_CURRENT_VOLTAGE = 35,
  ERGONOMIC_CHAIR = 36,
  EXTENDED_PHONE_CORD = 37,
  FAX_MACHINE = 38,
  FIRE_ALARM = 39,
  FIRE_ALARM_WITH_LIGHT = 40,
  FIREPLACE = 41,
  FREE_TOLL_FREE_CALLS = 42,
  FREE_CALLS = 43,
  FREE_CREDIT_CARD_ACCESS_CALLS = 44,
  FREE_LOCAL_CALLS = 45,
  FREE_MOVIES_VIDEO = 46,
  FULL_KITCHEN = 47,
  GRAB_BARS_IN_BATHROOM = 48,
  GRECIAN_TUB = 49,
  HAIRDRYER = 50,
  HIGH_SPEED_INTERNET_CONNECTION = 51,
  INTERACTIVE_WEB_TV = 52,
  INTERNATIONAL_DIRECT_DIALING = 53,
  INTERNET_ACCESS = 54,
  IRON = 55,
  IRONING_BOARD = 56,
  WHIRPOOL = 57,
  KING_BED = 58,
  KITCHEN = 59,
  KITCHEN_SUPPLIES = 60,
  KITCHENETTE = 61,
  KNOCK_LIGHT = 62,
  LAPTOP = 63,
  LARGE_DESK = 64,
  LARGE_WORK_AREA = 65,
  LAUNDRY_BASKET_CLOTHES_HAMPER = 66,
  LOFT = 67,
  MICROWAVE = 68,
  MINIBAR = 69,
  MODEM = 70,
  MODEM_JACK = 71,
  MULTILINE_PHONE = 72,
  NEWSPAPER = 73,
  NONSMOKING = 74,
  NOTEPADS = 75,
  OFFICE_SUPPLIES = 76,
  OVEN = 77,
  PAY_PER_VIEW_MOVIES_ON_TV = 78,
  PENS = 79,
  PHONE_IN_BATHROOM = 80,
  PLATES_AND_BOWLS = 81,
  POTS_AND_PANS = 82,
  PRAYER_MATS = 83,
  PRINTER = 84,
  PRIVATE_BATHROOM = 85,
  QUEEN_BED = 86,
  RECLINER = 87,
  REFRIGERATOR = 88,
  REFRIGERATOR_WITH_ICE_MAKER = 89,
  REMOTE_CONTROL_TELEVISION = 90,
  ROLLAWAY_BED = 91,
  SAFE = 92,
  SCANNER = 93,
  SEPARATE_CLOSET = 94,
  SEPARATE_MODEM_LINE_AVAILABLE = 95,
  SHOE_POLISHER = 96,
  SHOWER_ONLY = 97,
  SILVERWARE_UTENSILS = 98,
  SITTING_AREA = 99,
  SMOKE_DETECTORS = 100,
  SMOKING = 101,
  SOFA_BED = 102,
  SPEAKER_PHONE = 103,
  STEREO = 104,
  STOVE = 105,
  TAPE_RECORDER = 106,
  TELEPHONE = 107,
  TELEPHONE_FOR_HEARING_IMPAIRED = 108,
  TELEPHONES_WITH_MESSAGE_LIGHT = 109,
  TOASTER_OVEN = 110,
  TROUSER_PANT_PRESS = 111,
  TURN_DOWN_SERVICE = 112,
  TWIN_BED = 113,
  VAULTED_CEILINGS = 114,
  VCR_MOVIES = 115,
  VCR_PLAYER = 116,
  VIDEO_GAMES_AMENITY = 117,
  VOICE_MAIL = 118,
  WAKEUP_CALLS = 119,
  WATER_CLOSET = 120,
  WATER_PURIFICATION_SYSTEM = 121,
  WET_BAR = 122,
  WIRELESS_INTERNET_CONNECTION = 123,
  WIRELESS_KEYBOARD = 124,
  ADAPTOR_AVAILABLE_FOR_TELEPHONE_PC_USE = 125,
  AIR_CONDITIONING_INDIVIDUALLY_CONTROLLED_IN_ROOM = 126,
  BATHTUB_ANDWHIRLPOOL_SEPARATE = 127,
  TELEPHONE_WITH_DATA_PORTS = 128,
  CD_PLAYER = 129,
  COMPLIMENTARY_LOCAL_CALLS_TIME_LIMIT = 130,
  EXTRA_PERSON_CHARGE_FOR_ROLLAWAY_USE = 131,
  DOWN_FEATHER_PILLOWS = 132,
  DESK_WITH_ELECTRICAL_OUTLET = 133,
  ESPN_AVAILABLE = 134,
  FOAM_PILLOWS = 135,
  HBO_AVAILABLE = 136,
  HIGH_CEILINGS = 137,
  MARBLE_BATHROOM = 138,
  LIST_OF_MOVIE_CHANNELS_AVAILABLE = 139,
  PETS_ALLOWED = 140,
  OVERSIZED_BATHTUB = 141,
  SHOWER = 142,
  SINK_INROOM = 143,
  SOUNDPROOFED_ROOM = 144,
  STORAGE_SPACE = 145,
  TABLES_AND_CHAIRS = 146,
  TWOLINE_PHONE = 147,
  WALKIN_CLOSET = 148,
  WASHER_DRYER = 149,
  WEIGHT_SCALE = 150,
  WELCOME_GIFT = 151,
  SPARE_ELECTRICAL_OUTLET_AVAILABLE_AT_DESK = 152,
  NONREFUNDABLE_CHARGE_FOR_PETS = 153,
  REFUNDABLE_DEPOSIT_FOR_PETS = 154,
  SEPARATE_TUB_AND_SHOWER = 155,
  ENTRANCE_TYPE_TO_GUEST_ROOM = 156,
  CEILING_FAN = 157,
  CNN_AVAILABLE = 158,
  ELECTRICAL_ADAPTORS_AVAILABLE = 159,
  BUFFET_BREAKFAST = 160,
  ACCESSIBLE_ROOM = 161,
  CLOSETS_IN_ROOM = 162,
  DVD_PLAYER = 163,
  MINIREFRIGERATOR = 164,
  SEPARATE_LINE_BILLING_FOR_MULTILINE_PHONE = 165,
  SELFCONTROLLED_HEATING_COOLING_SYSTEM = 166,
  TOASTER = 167,
  ANALOG_DATA_PORT = 168,
  COLLECT_CALLS = 169,
  INTERNATIONAL_CALLS = 170,
  CARRIER_ACCESS = 171,
  INTERSTATE_CALLS = 172,
  INTRASTATE_CALLS = 173,
  LOCAL_CALLS = 174,
  LONG_DISTANCE_CALLS = 175,
  OPERATORASSISTED_CALLS = 176,
  CREDIT_CARD_ACCESS_CALLS = 177,
  CALLING_CARD_CALLS = 178,
  TOLL_FREE_CALLS = 179,
  UNIVERSAL_AC_DC_ADAPTORS = 180,
  BATHTUB_SEAT = 181,
  CANOPY_POSTER_BED = 182,
  CUPS_GLASSWARE = 183,
  ENTERTAINMENT_CENTER = 184,
  FAMILY_OVERSIZED_ROOM = 185,
  HYPOALLERGENIC_BED = 186,
  HYPOALLERGENIC_PILLOWS = 187,
  LAMP = 188,
  MEAL_INCLUDED_BREAKFAST = 189,
  MEAL_INCLUDED_CONTINENTAL_BREAKFAST = 190,
  MEAL_INCLUDED_DINNER = 191,
  MEAL_INCLUDED_LUNCH = 192,
  SHARED_BATHROOM = 193,
  TELEPHONE_TDD_TEXTPHONE = 194,
  WATER_BED = 195,
  EXTRA_ADULT_CHARGE = 196,
  EXTRA_CHILD_CHARGE = 197,
  EXTRA_CHILD_CHARGE_FOR_ROLLAWAY_USE = 198,
  MEAL_INCLUDED_FULL_AMERICAN_BREAKFAST = 199,
  FUTON = 200,
  MURPHY_BED = 201,
  TATAMI_MATS = 202,
  SINGLE_BED = 203,
  ANNEX_ROOM = 204,
  FREE_NEWSPAPER = 205,
  HONEYMOON_SUITES = 206,
  COMPLIMENTARY_HIGH_SPEED_INTERNET_IN_ROOM = 207,
  MAID_SERVICE = 208,
  PC_HOOKUP_IN_ROOM = 209,
  SATELLITE_TELEVISION = 210,
  VIP_ROOMS = 211,
  CELL_PHONE_RECHARGER = 212,
  DVR_PLAYER = 213,
  IPOD_DOCKING_STATION = 214,
  MEDIA_CENTER = 215,
  PLUG_AND_PLAY_PANEL = 216,
  SATELLITE_RADIO = 217,
  VIDEO_ON_DEMAND = 218,
  EXTERIOR_CORRIDORS = 219,
  GULF_VIEW = 220,
  ACCESSIBLE_ROOM_AMENITY = 221,
  INTERIOR_CORRIDORS = 222,
  MOUNTAIN_VIEW = 223,
  OCEAN_VIEW = 224,
  HIGH_SPEED_INTERNET_ACCESS_FEE = 225,
  HIGH_SPEED_WIRELESS = 226,
  PREMIUM_MOVIE_CHANNELS = 227,
  SLIPPERS = 228,
  FIRST_NIGHTERS_KIT = 229,
  CHAIR_PROVIDED_WITH_DESK = 230,
  PILLOW_TOP_MATTRESS = 231,
  FEATHER_BED = 232,
  DUVET = 233,
  LUXURY_LINEN_TYPE = 234,
  INTERNATIONAL_CHANNELS = 235,
  PANTRY = 236,
  DISHCLEANING_SUPPLIES = 237,
  DOUBLE_VANITY = 238,
  LIGHTED_MAKEUP_MIRROR = 239,
  UPGRADED_BATHROOM_AMENITIES = 240,
  VCR_PLAYER_AVAILABLE_AT_FRONT_DESK = 241,
  INSTANT_HOT_WATER = 242,
  OUTDOOR_SPACE = 243,
  HINOKI_TUB = 244,
  PRIVATE_POOL = 245,
  HIGH_DEFINITION_HD_FLAT_PANEL_TELEVISION_32_INCHES_OR_GREATER = 246,
  ROOM_WINDOWS_OPEN = 247,
  BEDDING_TYPE_UNKNOWN_OR_UNSPECIFIED = 248,
  FULL_BED = 249,
  ROUND_BED = 250,
  TV = 251,
  CHILD_ROLLAWAY = 252,
  DVD_PLAYER_AVAILABLE_AT_FRONT_DESK = 253,
  VIDEO_GAME_PLAYER = 254,
  VIDEO_GAME_PLAYER_AVAILABLE_AT_FRONT_DESK = 255,
  DINING_ROOM_SEATS = 256,
  FULL_SIZE_MIRROR = 257,
  MOBILE_CELLULAR_PHONES = 258,
  MOVIES = 259,
  MULTIPLE_CLOSETS = 260,
  PLATES_GLASSWARE = 261,
  SAFE_LARGE_ENOUGH_TO_ACCOMMODATE_A_LAPTOP = 262,
  BED_LINEN_THREAD_COUNT = 263,
  BLACKOUT_CURTAIN = 264,
  BLURAY_PLAYER = 265,
  DEVICE_WITH_MP3 = 266,
  NO_ADULT_CHANNELS_OR_ADULT_CHANNEL_LOCK = 267,
  NONALLERGENIC_ROOM = 268,
  PILLOW_TYPE = 269,
  SEATING_AREA_WITH_SOFA_CHAIR = 270,
  SEPARATE_TOILET_AREA = 271,
  WEB_ENABLED = 272,
  WIDESCREEN_TV = 273,
  OTHER_DATA_CONNECTION = 274,
  PHONELINE_BILLED_SEPARATELY = 275,
  SEPARATE_TUB_OR_SHOWER = 276,
  VIDEO_GAMES = 277,
  ROOF_VENTILATOR = 278,
  CHILDRENS_PLAYPEN = 279,
  PLUNGE_POOL = 280,
  DVD_MOVIES = 281,
  AIR_FILTRATION = 282,
  UNRECOGNIZED = -1,
}

export interface AdditionalDetail {
  additionalDetailType: AdditionalDetailAdditionalDetailType;
  text: string;
}

export enum AdditionalDetailAdditionalDetailType {
  ADDITIONAL_DETAIL_TYPE_UNKNOWN = 0,
  RATE_DESCRIPTION = 1,
  PROPERTY_DESCRIPTION = 2,
  PROPERTY_LOCATION = 3,
  ROOM_INFORMATION = 4,
  GUARANTEE_INFORMATION = 5,
  DEPOSIT_INFORMATION = 6,
  CANCELLATION_INFORMATION = 7,
  CHECK_IN_CHECK_OUT_INFORMATION = 8,
  EXTRA_CHARGE_INFORMATION = 9,
  TAX_INFORMATION = 10,
  SERVICE_CHARGE_INFORMATION = 11,
  PACKAGE_INFORMATION = 12,
  COMMISSION_INFORMATION = 13,
  MISCELLANEOUS_INFORMATION = 14,
  PROMOTIONAL_INFORMATION = 15,
  INCLUSION_INFORMATION = 16,
  AMENITY_INFORMATION = 17,
  LATE_ARRIVAL_INFORMATION = 18,
  LATE_DEPARTURE_INFORMATION = 19,
  ADVANCED_BOOKING_INFORMATION = 20,
  EXTRA_PERSON_INFORMATION = 21,
  AREAS_SERVED = 22,
  ONSITE_FACILITIES_INFORMATION = 23,
  OFFSITE_FACILITIES_INFORMATION = 24,
  ONSITE_SERVICES_INFORMATION = 25,
  OFFSITE_SERVICES_INFORMATION = 26,
  EXTENDED_STAY_INFORMATION = 27,
  CORPORATE_BOOKING_INFORMATION = 28,
  BOOKING_GUIDELINES = 29,
  GOVERNMENT_BOOKING_POLICY = 30,
  GROUP_BOOKING_INFORMATION = 31,
  RATE_DISCLAIMER_INFORMATION = 32,
  VISA_TRAVEL_REQUIREMENT_INFORMATION = 33,
  SECURITY_INFORMATION = 34,
  ONSITE_RECREATIONAL_ACTIVITIES_INFORMATION = 35,
  OFFSITE_RECREATIONAL_ACTIVITIES_INFORMATION = 36,
  GENERAL_MEETING_PLANNING_INFORMATION = 37,
  GROUP_MEETING_PLANNING_INFORMATION = 38,
  CONTRACT_NEGOTIATED_BOOKING_INFORMATION = 39,
  TRAVEL_INDUSTRY_BOOKING_INFORMATION = 40,
  MEETING_ROOM_DESCRIPTION = 41,
  PET_POLICY_DESCRIPTION = 42,
  MEAL_PLAN_DESCRIPTION = 43,
  FAMILY_PLAN_DESCRIPTION = 44,
  CHILDREN_INFORMATION = 45,
  EARLY_CHECKOUT_DESCRIPTION = 46,
  SPECIAL_OFFERS_DESCRIPTION = 47,
  CATERING_DESCRIPTION = 48,
  ROOM_DECOR_DESCRIPTION = 49,
  OVERSOLD_POLICY_DESCRIPTION = 50,
  LAST_ROOM_AVAILABILITY_DESCRIPTION = 51,
  ROOM_TYPE_UPGRADE_DESCRIPTION = 52,
  DRIVING_DIRECTIONS = 53,
  DRIVING_DIRECTIONS_FROM_THE_NORTH = 54,
  DRIVING_DIRECTIONS_FROM_THE_SOUTH = 55,
  DRIVING_DIRECTIONS_FROM_THE_EAST = 56,
  DRIVING_DIRECTIONS_FROM_THE_WEST = 57,
  SURCHARGE_INFORMATION = 58,
  MINIMUM_STAY_INFORMATION = 59,
  MAXIMUM_STAY_INFORMATION = 60,
  CHECK_IN_POLICY = 61,
  CHECK_OUT_POLICY = 62,
  EXPRESS_CHECK_IN_POLICY = 63,
  EXPRESS_CHECK_OUT_POLICY = 64,
  FACILITY_RESTRICTIONS = 65,
  CUSTOMS_INFORMATION_FOR_MATERIAL = 66,
  SEASONS = 67,
  FOOD_AND_BEVERAGE_MINIMUMS_FOR_GROUPS = 68,
  DEPOSIT_POLICY_FOR_MASTER_ACCOUNT = 69,
  DEPOSIT_POLICY_FOR_RESERVATIONS = 70,
  RESTAURANT_SERVICES = 71,
  SPECIAL_EVENTS = 72,
  CUISINE_DESCRIPTION = 73,
  UNRECOGNIZED = -1,
}

export interface Meals {
  /** List of meals included in the rate. */
  mealsIncluded: MealsMealType[];
  /** Type of meal plan available for the rate. */
  mealPlan: MealsMealPlanType;
  /** Number of travelers from whom meals are included in the rate. */
  numTravelers: number;
}

export enum MealsMealType {
  BREAKFAST = 0,
  LUNCH = 1,
  DINNER = 2,
  UNRECOGNIZED = -1,
}

export enum MealsMealPlanType {
  UNKNOWN = 0,
  ALL_INCLUSIVE = 1,
  AMERICAN = 2,
  BED_AND_BREAKFAST = 3,
  BUFFET_BREAKFAST = 4,
  CARIBBEAN_BREAKFAST = 5,
  CONTINENTAL_BREAKFAST = 6,
  ENGLISH_BREAKFAST = 7,
  EUROPEAN_PLAN = 8,
  FAMILY_PLAN = 9,
  FULL_BOARD = 10,
  FULL_BREAKFAST = 11,
  HALF_BOARD_MODIFIED_AMERICAN_PLAN = 12,
  AS_BROCHURED = 13,
  ROOM_ONLY = 14,
  SELF_CATERING = 15,
  BERMUDA = 16,
  DINNER_BED_AND_BREAKFAST_PLAN = 17,
  FAMILY_AMERICAN = 18,
  BREAKFAST_MEAL_PLAN = 19,
  MODIFIED = 20,
  LUNCH_MEAL_PLAN = 21,
  DINNER_MEAL_PLAN = 22,
  BREAKFAST_AND_LUNCH = 23,
  UNRECOGNIZED = -1,
}

export interface CancellationPolicy {
  /** Refundable status of the rate. */
  refundable: CancellationPolicyRefundableEnum;
  /** Terms are only present when refundable = true */
  terms: CancellationPolicyTerm[];
  /**
   * Refundable status that is currently applicable in the hotel timezone.
   * Refundable = FALSE even if it is partially refundable
   */
  currentlyRefundable: CancellationPolicyRefundableEnum;
  /** Cancellation term that is currently applicable in the hotel timezone. */
  currentlyApplicableTerm?: CancellationPolicyTerm;
}

export enum CancellationPolicyRefundableEnum {
  UNKNOWN = 0,
  TRUE = 1,
  FALSE = 2,
  UNRECOGNIZED = -1,
}

export interface CancellationPolicyTerm {
  /** Absolute amount levied as penalty. */
  amount?: Money;
  /** Percentage of total amount levied as penalty. */
  percentage?: number;
  /** Absolute deadline for cancellation. */
  absoluteDeadline?: DateTime;
  /** Deadline for cancellation relative to arrival. */
  durationBeforeArrival?: Duration;
  description: string;
}

export interface RoomView {
  type: RoomViewTypeEnum;
}

export enum RoomViewTypeEnum {
  UNKNOWN = 0,
  AIRPORT = 1,
  BAY = 2,
  CITY = 3,
  COURTYARD = 4,
  GOLF = 5,
  HARBOR = 6,
  INTERCOASTAL = 7,
  LAKE = 8,
  MARINA = 9,
  MOUNTAIN = 10,
  OCEAN = 11,
  POOL = 12,
  RIVER = 13,
  WATER = 14,
  BEACH = 15,
  GARDEN = 16,
  PARK = 17,
  FOREST = 18,
  RAIN_FOREST = 19,
  VARIOUS = 20,
  LIMITED = 21,
  SLOPE = 22,
  STRIP = 23,
  COUNTRYSIDE = 24,
  SEA = 25,
  GULF = 26,
  UNRECOGNIZED = -1,
}

/**
 * Proto message that converts sabre free text into structured room level info.
 * E.g. :
 * 1. Sabre Text : STE Ocean View
 *  Output :
 *   {
 *     "room_type_info" : "SUITE"
 *     "room_class" : ["STANDARD"]
 *     "type_class_description" : "STANDARD SUITE"
 *     "room_view" " "OCEAN"
 *   }
 * 2. Sabre Text: DLX ROOM WITH BALCONY
 *     Output :
 *    {
 *     "room_type_info" : "ROOM"
 *     "room_class" : ["DELUXE"]
 *     "type_class_description" : "DELUXE ROOM"
 *     "room_view" " "UNKNOWN"
 *     }
 * 3. Sabre Text: Corner KING ROOM WITH BALCONY
 *     Output :
 *    {
 *     "room_type_info" : "ROOM"
 *     "room_class" : []
 *     "type_class_description" : "CORNER ROOM"
 *     "room_view" " "UNKNOWN"
 *     }
 */
export interface RoomInfo {
  roomType: RoomInfoRoomType;
  roomClass: RoomInfoRoomClass[];
  typeClassDescription: string;
  /** View available from the room. */
  roomView?: RoomView;
}

export enum RoomInfoRoomType {
  UNKNOWN_ROOM = 0,
  ROOM = 1,
  SUITE = 2,
  VILLA = 3,
  APARTMENT = 4,
  COTTAGE = 5,
  STUDIO = 6,
  UNRECOGNIZED = -1,
}

export enum RoomInfoRoomClass {
  UNKNOWN = 0,
  STANDARD = 1,
  PREMIUM = 2,
  DELUXE = 3,
  BUSINESS = 4,
  PRESIDENTIAL = 5,
  SUPERIOR = 6,
  JUNIOR = 7,
  CLUB = 8,
  UNRECOGNIZED = -1,
}

export interface Accessibility {
  /** Type of accessibility feature. */
  type: AccessibilityAccessibilityType;
  /** Description of the accessibility feature if provided by the supplier. */
  additionalInfo: string;
  /** Whether the accessibility special request needs to be selected on checkout. */
  isSelectionRequired: boolean;
}

export enum AccessibilityAccessibilityType {
  ACCESSIBILITY_TYPE_UNKNOWN = 0,
  ACCESSIBLE_ROOM = 1,
}

export interface CardType {
  cardCompany: CardCompany;
  description: string;
}

export interface RateOption {
  priceValidateKey: string;
  description: string;
  rateInfo: RateInfo;
  meals: Meals;
  amenities: RoomAmenity[];
  /** Refundable status and cancellation policy of the rate. */
  cancellationPolicy: CancellationPolicy;
  /** Policy information about violations and approvals. */
  policyInfo?: PolicyInfo;
  /**
   * View available from the room.
   *
   * @deprecated
   */
  roomView?: RoomView;
  /** Guarantee type for payment for the rate. */
  guaranteeType: GuaranteeType;
  /** Bed information for the rate. */
  bedInfo: RateOptionBedInfo[];
  /** Display name of the rate option. */
  displayName: string;
  /** Room information for the rate. */
  roomInfo: RoomInfo;
  /** To be used internally regroup the rate options. */
  rateGroupKey: string;
  /**
   * This will contain features of the room such as a private pool, balcony,etc.
   * Room amenities that cannot be mapped to Spotnana enums.
   */
  additionalAmenities: string[];
  /** Boolean representing whether this room is prepaid or not. */
  isPrepaidRoom: boolean;
  /** List of card types supported for payment. */
  supportedCardTypes: CardType[];
  /** Number of rooms to be booked of the given type to accommodate the given pax count */
  numRoomsRequired: number;
  /** Maximum pax that can fit in one room */
  maxOccupancy?: OccupancyDateParamsOccupancy;
  /** Indicates whether the room is modifiable */
  isModifiable: boolean;
  /** Additional details which are part of the rate */
  additionalDetails: AdditionalDetail[];
  /** Used to convey if loyalty points could be earned with this rate. */
  earnLoyaltyPoints: boolean;
  /**
   * Information about the points earned on this hotel rate.
   * Currently populated only in case of Qantas Hotels.
   */
  rewardPointsEarned: RewardPointsEarned[];
  /**
   * Information about promotional offers that are applicable to the booking.
   * Currently populated only in case of Qantas Hotels.
   */
  promotionalOffers: PromotionalOffer[];
  /** Payment related metadata to be displayed on UI. */
  paymentDescription: string[];
  /** Indicates whether the fop is modifiable during modify booking. */
  isFopModifiable: boolean;
  /** Accessibility features associated with the rate option. */
  accessibilityInfo?: Accessibility[];
  /** The co2 emission detail for the room. */
  co2EmissionDetail?: HotelCo2EmissionDetail;
}

export interface RateOptionBedInfo {
  bedType: BedTypeEnum;
  count: number;
}

export interface RateInfo {
  totalRate?: Rate;
  /** Rate type for the rate. */
  rateType: RateTypeV1;
  /** Rates for all nights in stay date range */
  nightlyRate: Rate[];
  ratePlanName: string;
  /** Source from which the rate has been fetched, supplier or aggregator. */
  rateSource: RateInfoRateSource;
  /** Indicates whether the Credit card CVV is required while booking. */
  isCvvRequired: boolean;
  /** Average Rate for all the nights. */
  averageNightlyRate?: Rate;
  /** Refund amount. */
  refundAmount?: Money;
  /**
   * Enum to tell from which supplier the rate has been fetched.
   * Note: Rate source represents whose rate it is. E.g.: Sabre supplier can have booking_com
   * rate. In this case, rate supplier will be sabre and and rate source will be booking com.
   */
  rateSupplier: ThirdPartySource;
  /** Published rate available for this rate. */
  publishedRate?: Rate;
  /** Published rate available for this rate. */
  publishedNightlyRate?: Rate;
  /**
   * Populated only in case of Hotel Modify
   * Difference between current average_nightly_rate and PNR nightly rate
   */
  rateDifference?: Rate;
  /** Prepaid component for the rate. */
  prepaidRate?: Rate;
  /** Pay at property component for the rate. */
  postpaidRate?: Rate;
  /** Mainly used in pnr hotel details */
  penaltyAmount?: Money;
  /**
   * Rate Tag to be displayed on UI, if present (i.e set by travel admin)
   * Travel Admins can now configure their rate codes such as GOV at company level
   * and also add a custom rate tag to these codes (For ex: "Government Rates").
   * UI will then display the custom rate_tag for the appropriate rates.
   */
  rateTag: string;
}

export enum RateInfoRateSource {
  SABRE = 0,
  BOOKING_COM = 1,
  EXPEDIA = 2,
  OFFLINE = 3,
  HRS = 4,
  BCD = 5,
  QANTAS_HOTELS = 6,
  HOTEL_HUB = 7,
  MARRIOTT = 8,
  UNRECOGNIZED = -1,
}

export interface Room {
  /**
   * Bed type is mentioned inside RateOption.
   *
   * @deprecated
   */
  bedType: BedTypeEnum;
  description: string;
  rateOptions: RateOption[];
  /** Images for the room. */
  imageSets: ImageSet[];
  /** To be used by FE to group rooms. */
  roomGroupKey: string;
  /** Populated only in case of Hotel Modify Booked Room */
  penaltyAmount?: Money;
}

export interface HotelDetailsResponse {
  hotelDetailsId: string;
  /** Static information related to the property. */
  hotelSpec?: HotelSpec;
  /** List of rooms available for booking. */
  rooms: Room[];
  /** Occupancy values and dates for the hotel search. */
  occupancyDates?: OccupancyDateParams;
  /**
   * If hotel is preferred hotel or not.
   *
   * @deprecated
   */
  preferredType: PreferredType[];
  /**
   * Booked room. (Repeated to support multiple booked rooms in the future)
   * Populated only in case of Hotel Modify
   */
  bookedRooms: Room[];
  /** Statistics data for hotel rates e.g median rate */
  rateStatistics?: RateStatistics;
  /**
   * Toggles behaviour on whether we show the fare as base amount
   * or total amount depending on service location and legalEntity.
   */
  showOnlyBaseFare: boolean;
  /** Indicates whether hotel is preferred, blocked or not preferred. */
  preferences: Preference[];
  /** List of terms and conditions to be displayed on the checkout page. */
  termsAndConditions: TermsAndConditions[];
}
