/* eslint-disable */
import { UserOrgId } from '../../../v1/obt/common/user_org_id';
import { Traveler } from '../../../v1/obt/common/traveler';

export interface UpdateTravelerRequest {
  userOrgId: UserOrgId;
  type: UpdateTravelerRequestType[];
  traveler: Traveler;
}

export enum UpdateTravelerRequestTypeEnum {
  UNRECOGNIZED_TYPE = 0,
  TRAVEL_PREF = 1,
  LOYALTY_INFOS = 2,
  /** @deprecated */
  PAYMENT_INFOS = 3,
  IDENTITY_DOCS = 4,
  GENDER = 5,
  PHONE_NUMBERS = 6,
  NAME = 7,
  DOB = 8,
  POSTAL_ADDRESSES = 9,
  EMERGENCY_CONTACT_INFO = 10,
  EMAIL = 11,
  TITLE = 12,
  PROFILE_PICTURE = 13,
  RELATION = 14,
  TIER = 15,
  EMAIL_CONFIGURATION = 16,
  NATIONALITY = 17,
  EXTERNAL_ID = 18,
  PREFERRED_LANGUAGE = 19,
  PREFERRED_PRONOUN = 20,
  TRAVELER_NAME = 21,
  UNRECOGNIZED = -1,
}

export type UpdateTravelerRequestType =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | -1;
