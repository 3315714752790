import { useEffect, useState, useCallback } from 'react';
import logger from '../utils/logger';
import { storage } from '../utils/Storage';
import type { StorageKeys } from '../types/storage';

export async function fetchStorageValue<T>(storageKey: StorageKeys): Promise<T | null> {
  const storageValue = await storage.getItem(storageKey);

  if (!storageValue) {
    return null;
  }

  return JSON.parse(storageValue) as T;
}

export async function saveStorageValue<T>(storageKey: StorageKeys, data: T | null): Promise<void> {
  await storage.setItem(storageKey, JSON.stringify(data));
}

export async function removeStorageValue(storageKey: StorageKeys): Promise<void> {
  await storage.removeItem(storageKey);
}

export interface IUseStorage<T> {
  data: T | null;
  setData: (data: T | null) => Promise<void>;
  isFetching: boolean;
}

// Reads and writes from/to either localStorage (web) or
// AsyncStorage (React Native).
function useStorage<T>(storageKey: StorageKeys, initialStorageValue: T | null = null): IUseStorage<T> {
  const [storageValue, setStorageValue] = useState<T | null>(null);

  const [isFetching, setIsFetching] = useState(true);

  useEffect(
    () => {
      fetchStorageValue<T>(storageKey)
        .then((storageData) => {
          // Storage can hold boolean values, so consider null as empty value
          if (storageData === null) {
            setStorageValue(initialStorageValue);
          } else {
            setStorageValue(storageData as T);
          }
        })
        .catch((err) => {
          logger.error(new Error(`FAILED TO FETCH STORAGE KEY: ${storageKey}`, { cause: err }));
        })
        .finally(() => {
          setIsFetching(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageKey],
  );

  const setData = useCallback(
    async (data: T | null): Promise<void> => {
      setStorageValue(data);
      await saveStorageValue<T>(storageKey, data);
    },
    [storageKey],
  );

  return {
    data: storageValue,
    setData,
    isFetching,
  };
}

export default useStorage;
