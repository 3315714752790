import { SPOTNANA_ORG_ID } from '../../constants';
import { TMCUserRoles, userRolesByFeature } from '../../constants/rolesAccess';
import { defineMessage } from '../../translations/defineMessage';
import type { ITraveler, RoleConfig } from '../../types';
import { Tier, IPersonaEnum, RoleTypeEnum } from '../../types';
import type { AuthenticatedUserBasicInfoExistingUser } from '../../types/api/v1/auth/services';
import type { TierEnumNumerals } from '../../types/api/v1/obt/common/user_org_id';
import { PersonaEnum, TierEnum } from '../../types/api/v1/obt/common/user_org_id';
import type { RoleInfoType } from '../../types/api/v1/obt/profile/role/roles_info';
import { RoleInfoTypeEnum } from '../../types/api/v1/obt/profile/role/roles_info';
import type { UserBasicInfo } from '../../types/api/v2/obt/model/user-basic-info';

const { COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TRAVEL_ARRANGER } = RoleInfoTypeEnum;

export const isTMCUser = (existingUser: AuthenticatedUserBasicInfoExistingUser): boolean =>
  existingUser.roleInfos.map((role) => role.type).some((roleType) => TMCUserRoles.includes(roleType));

export const isCompanyAdminOrArranger = (existingUser: AuthenticatedUserBasicInfoExistingUser): boolean =>
  existingUser.roleInfos
    .map((role) => role.type)
    .some((roleType) => [COMPANY_ADMIN, COMPANY_TRAVEL_ARRANGER, TRAVEL_ARRANGER].includes(roleType));

export const isUserOfSpotnana = (existingUser: AuthenticatedUserBasicInfoExistingUser): boolean =>
  existingUser.pemail.includes('@spotnana.com');

export const isUserOfBrex = (email: string): boolean => email.includes('@brex.com');

export const isUserWithSpotnanaTMC = (existingUser: AuthenticatedUserBasicInfoExistingUser): boolean => {
  // using contractingTmc since the new v1 user org id type is updated
  const { userOrgId } = existingUser;
  const userTmcId = userOrgId?.tmcBasicInfo?.contractingTmc?.id?.id ?? '';
  return userTmcId === SPOTNANA_ORG_ID;
};

const userTierDisplayConfig = {
  [TierEnum.UNRECOGNIZED]: Tier.Basic,
  [TierEnum.BASIC]: Tier.Basic,
  [TierEnum.SEAT1A]: Tier.Seat1A,
};

export const getUserTierForDisplay = (tier: TierEnumNumerals): Tier => {
  if (tier === -1) {
    console.error(`Invalid tier value received: ${tier}`);
  }
  return userTierDisplayConfig[tier];
};

export const isPersonalTravelAllowed = (
  traveler: ITraveler | undefined,
  loggedInUser: ITraveler | undefined,
  loggedInUserRoleTypes: RoleInfoType[],
): boolean =>
  traveler?.persona?.toString() === IPersonaEnum.EMPLOYEE.toString() &&
  (loggedInUser?.userOrgId?.userId?.id === traveler?.userOrgId?.userId?.id ||
    loggedInUserRoleTypes.some((roletype) => userRolesByFeature.personalBookings.includes(roletype)));

export const isPersonalTravelerBooking = (traveler: ITraveler | undefined): boolean =>
  traveler?.persona === PersonaEnum.PERSONAL;

export const getUserFullnameWithEmail = (user: UserBasicInfo | undefined): string => {
  const name = [user?.name?.given, user?.name?.family1].filter(Boolean).join(' ');
  const email = user?.email ? `(${user.email})` : '';
  return [name, email].filter(Boolean).join(' ');
};

export const canLoggedInUserAccessTraveler = (
  userRolesInfo: RoleConfig | undefined,
  travelerIdInUrl: string,
): boolean => {
  if (!userRolesInfo) {
    return false;
  }

  if (
    userRolesInfo.roleType === RoleTypeEnum.GlobalAdmin ||
    userRolesInfo.roleType === RoleTypeEnum.GlobalAgent ||
    userRolesInfo.roleType === RoleTypeEnum.CompanyReportAdmin
  ) {
    return false;
  }

  if (
    userRolesInfo.roleType === RoleTypeEnum.TmcAdmin ||
    userRolesInfo.roleType === RoleTypeEnum.TmcAgent ||
    userRolesInfo.roleType === RoleTypeEnum.CompanyAdmin ||
    userRolesInfo.roleType === RoleTypeEnum.CompanyTravelArranger
  ) {
    return true;
  }

  const travelerArrangerBasicInfos = userRolesInfo?.roleMetadata?.travelArrangerMetadata?.arrangerFor ?? [];
  if (travelerArrangerBasicInfos) {
    const hasAccessToCurrentTraveler = travelerArrangerBasicInfos.find(
      (basicInfo) => basicInfo.userId === travelerIdInUrl,
    );

    if (hasAccessToCurrentTraveler) {
      return true;
    }

    return false;
  }

  return false;
};

export const roleTypeToRoleNameMap: Record<RoleInfoTypeEnum, string> = {
  [RoleInfoTypeEnum.UNKNOWN_TYPE]: defineMessage('Unknown type'),
  [RoleInfoTypeEnum.COMPANY_ADMIN]: defineMessage('Company admin'),
  [RoleInfoTypeEnum.COMPANY_TRAVEL_ARRANGER]: defineMessage('Company travel arranger'),
  [RoleInfoTypeEnum.TRAVEL_ARRANGER]: defineMessage('Travel arranger'),
  [RoleInfoTypeEnum.GLOBAL_ADMIN]: defineMessage('Global admin'),
  [RoleInfoTypeEnum.GLOBAL_AGENT]: defineMessage('Global agent'),
  [RoleInfoTypeEnum.TMC_ADMIN]: defineMessage('Tmc admin'),
  [RoleInfoTypeEnum.TMC_AGENT]: defineMessage('Tmc agent'),
  [RoleInfoTypeEnum.UNRECOGNIZED]: defineMessage('Unrecognized'),
};
