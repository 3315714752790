import { assign, createMachine } from 'xstate';
import { mutatePaymentSetupForAllPaymentSourceIds } from '../../utils/payment/mutatePaymentSetupForAllPaymentSourceIds';
import { PaymentSetupResponseStatus } from '../../types/api/v2/obt/model/payment-setup-response-status';
import { getTotalFareFromV2FareBreakdown } from '../../utils/Flights/getTotalFareFromV2FareBreakdown';
import type {
  BeginRetryEvent,
  ContextAfterInitialAction,
  ContextAfterRevalidateItinerary,
  FlightRetryPaymentContext,
  FlightRetryPaymentEvents,
  FlightRetryPaymentTypeState,
  SuccessRetryPaymentEvent,
} from './types';
import isBookingFailedError from '../shared/guards/isBookingFailedError';
import isEmptyRevalidateItineraryResponse from '../shared/guards/isEmptyRevalidateItineraryResponse';
import { retryPayment } from '../../queries/retryPayment';
import { retryPaymentRevalidate } from '../../queries/retryPaymentRevalidate';
import isFareChangeV2 from '../shared/guards/isFareChangeV2';
import type {
  ICvvRequirementInfo,
  ITraveler,
  ListBookingPaymentSourcesResponse,
  PaymentSetupResponse,
} from '../../types';
import type { IUseGetRequestHeader } from '../../hooks/useGetRequestHeader';

const defaultContext: FlightRetryPaymentContext = {
  pnrId: '',
  attemptNo: 0,
  cvvRequirement: {} as ICvvRequirementInfo,
  paymentSourceIds: [],
  listBookingPaymentSourcesResponse: {} as ListBookingPaymentSourcesResponse,
  requestHeader: {} as IUseGetRequestHeader,
  primaryTraveler: {} as ITraveler,
  vgsFormMap: {},
  mutatePaymentSetup: () => Promise.resolve({} as PaymentSetupResponse),
};

const setEventValuesToContext = (event: BeginRetryEvent) => {
  return {
    pnrId: event.pnrId,
    oldTotalFare: event.oldTotalFare,
    bookingPaymentDetails: event.bookingPaymentDetails,
    cvvRequirement: event.cvvRequirement,
    paymentSourceIds: event.paymentSourceIds,
    listBookingPaymentSourcesResponse: event.listBookingPaymentSourcesResponse,
    requestHeader: event.requestHeader,
    primaryTraveler: event.primaryTraveler,
    vgsFormMap: event.vgsFormMap,
    mutatePaymentSetup: event.mutatePaymentSetup,
  };
};

/**
 * Use https://stately.ai/viz/39d06695-b390-4cd5-8ae0-6bb664ab8e0d to visualize the below machine.
 * (Please update the above if any changes are done to the machine)
 */

const retryPaymentMachine = createMachine<
  FlightRetryPaymentContext,
  FlightRetryPaymentEvents,
  FlightRetryPaymentTypeState
>(
  {
    predictableActionArguments: true,
    id: 'retryingPayment',
    initial: 'idle',
    context: defaultContext,
    states: {
      idle: {
        meta: {
          message: 'Initial setup context',
        },
        on: {
          BEGIN_RETRY: [
            {
              target: 'retryPaymentRevalidate',
              actions: assign((_context, event) => setEventValuesToContext(event)),
            },
          ],
        },
      },
      retryPaymentRevalidate: {
        tags: ['loading'],
        meta: {
          message: 'Run revalidate itinerary BE request',
        },
        invoke: {
          id: 'retryPaymentRevalidate',
          src: 'retryPaymentRevalidateService',
          onDone: [
            {
              cond: 'isEmptyRevalidateItineraryResponse',
              target: 'failure',
              actions: assign({
                // Known issue
                // Assign action behaving strangely: https://xstate.js.org/docs/guides/typescript.html#troubleshooting
                error: (_context, _event) => new Error('Revalidate itinerary response is empty'),
              }),
            },
            {
              cond: 'isFareChangeV2',
              target: 'fareChanged',
              actions: assign((_context, event) => ({
                retryPaymentRevalidateResponse: event.data,
              })),
            },
            {
              target: 'retryPayment',
              actions: assign((_context, event) => ({
                retryPaymentRevalidateResponse: event.data,
              })),
            },
          ],
          onError: [
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
              actions: assign({ error: (_context, event) => event.data }),
            },
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
      },
      bookingFailed: {
        on: {
          ON_CANCEL_BOOKING_FAILED: {
            target: 'idle',
          },
        },
        meta: {
          message: 'Revalidate itinerary failed with error booking failed',
        },
      },
      fareChanged: {
        tags: ['loading'],
        meta: {
          message: 'Revalidate itinerary success but total fare was changed',
        },
        on: {
          CONFIRM_NEW_REVALIDATE_FIRE: {
            target: 'retryPaymentRevalidate',
            actions: assign((context) => {
              const fareBreakdown = context.retryPaymentRevalidateResponse?.fareBreakDown;

              if (!fareBreakdown) {
                return {
                  oldTotalFare: undefined,
                };
              }

              return {
                oldTotalFare: getTotalFareFromV2FareBreakdown(fareBreakdown),
              };
            }),
          },
          CANCEL_NEW_FARE: {
            target: 'idle',
            // TODO: This should redirect user to search page.
          },
        },
      },
      retryPayment: {
        tags: ['loading'],
        invoke: {
          id: 'retryPayment',
          src: 'retryPaymentService',
          onDone: [
            {
              cond: 'isRequired3dSecure2',
              target: '3dSecure2',
              actions: assign({
                redirectUrl: (_, event) =>
                  (event as SuccessRetryPaymentEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].redirectUrl,
                cardId: (_, event) =>
                  (event as SuccessRetryPaymentEvent)?.data?.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].cardId,
                sessionId: (_, event) =>
                  (event as SuccessRetryPaymentEvent)?.data.paymentVerificationInfo?.threeDSecure2VerificationInfo
                    ?.verificationInfoList[0].sessionId,
              }),
            },
            {
              target: 'success',
              actions: assign({
                pnrId: (_, event) => event.data.pnrId,
                paymentVerificationInfo: (_, event) => event.data.paymentVerificationInfo,
              }),
            },
          ],
          onError: [
            {
              cond: 'isBookingFailedError',
              target: 'bookingFailed',
            },
            {
              target: 'failure',
              actions: assign({ error: (_context, event) => event.data }),
            },
          ],
        },
      },
      failure: {
        on: {
          BEGIN_RETRY: [
            {
              target: 'retryPaymentRevalidate',
              actions: assign((_context, event) => ({
                error: undefined, // Reset error
                ...setEventValuesToContext(event),
              })),
            },
          ],
        },
        meta: {
          message: 'Retry Payment has failed',
        },
      },
      '3dSecure2': {
        meta: {
          message: 'Need to redirect to 3d secure 2 page (or open WebView on mobile)',
        },
        on: {
          CONFIRM_PNR: [
            {
              target: 'retryPayment',
              actions: assign({
                is3ds2Success: (_, event) => event?.is3ds2Success,
              }),
            },
          ],
        },
      },
      success: {
        type: 'final',
        meta: {
          message: 'Retry Payment was successful',
        },
      },
    },
  },
  {
    services: {
      retryPaymentRevalidateService: async (context, _event) => {
        const typedContext = context as ContextAfterInitialAction;
        const workingPayload = {
          bookingPaymentDetails: typedContext.bookingPaymentDetails,
        };

        const result = await retryPaymentRevalidate(typedContext.pnrId, workingPayload);
        return result;
      },
      retryPaymentService: async (context, _event) => {
        const {
          paymentSourceIds,
          listBookingPaymentSourcesResponse,
          primaryTraveler,
          requestHeader,
          cvvRequirement,
          vgsFormMap,
          mutatePaymentSetup,
        } = context;
        const typedContext = context as ContextAfterRevalidateItinerary;

        const { cardId, sessionId, is3ds2Success } = typedContext;

        const workingPayload = {
          bookingId: typedContext.retryPaymentRevalidateResponse.bookingId,
          postPaymentVerificationInfo:
            cardId && sessionId && is3ds2Success !== undefined
              ? {
                  threeDSecure2PostVerificationInfo: {
                    results: [
                      {
                        cardId,
                        sessionId,
                        success: is3ds2Success,
                      },
                    ],
                  },
                }
              : undefined,
        };

        let paymentSetupResponse = null;
        if (cvvRequirement.status === PaymentSetupResponseStatus.CvvRequired) {
          paymentSetupResponse = await mutatePaymentSetupForAllPaymentSourceIds({
            paymentSourceIds,
            vgsFormMap,
            listBookingPaymentSourcesResponse,
            primaryTraveler,
            mutatePaymentSetup,
            requestHeaders: requestHeader.headers,
            paymentSetupId: {
              pnrId: typedContext.pnrId,
            },
          });
        }

        let result = null;
        if (
          paymentSetupResponse === null ||
          paymentSetupResponse?.[0] === null ||
          paymentSetupResponse?.[0]?.status === PaymentSetupResponseStatus.Ok
        ) {
          result = await retryPayment(typedContext.pnrId, workingPayload);
        }
        return result;
      },
    },
    /**
     * This section handles potential errors in the retry payment process.
     * Fare expiration is not included because it is not a possible error for retry payment
     */
    guards: {
      isRequired3dSecure2: (_context, event): boolean => {
        const typedEvent = event as SuccessRetryPaymentEvent;
        return !!typedEvent.data.paymentVerificationInfo?.threeDSecure2VerificationInfo?.verificationInfoList[0];
      },
      isBookingFailedError,
      isEmptyRevalidateItineraryResponse,
      isFareChangeV2,
    },
  },
);

export const flightRetryPaymentMachine = retryPaymentMachine.withContext(defaultContext);
