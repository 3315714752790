import first from 'lodash/first';
import last from 'lodash/last';
// eslint-disable-next-line import/no-extraneous-dependencies
import { type FlightDetailInformation, type ScheduleChangeInfo } from '@spotnana/types/openapi/models';
import { defineCommonMessage } from '../../../translations/defineMessage';

import { PassengerType } from '../../../types/api/v2/obt/model/passenger-type';
import type { Air } from '../../../types/api/v2/obt/model/air';
import type { PnrV3ManagerProps } from '../PnrV3Manager';
import { PnrV3Manager } from '../PnrV3Manager';
import { getNameStringFromName, maskCardNumber } from '../../../utils/common';
import { TicketStatusEnum, TicketTicketTypeEnum } from '../../../types/api/v2/obt/model/ticket';
import { getAirlineNameFromAirlineCode, getLocationDetailsFromAirportCode } from '../../../utils/Flights';
import minutesToDurationString from '../../../date-utils/minutesToDurationString';
import getDateTimeDiff from '../../../date-utils/getDateTimeDiff';
import getDurationString from '../../../date-utils/getDurationString';
import getDurationMinutes from '../../../date-utils/getDurationMinutes';
import type { Flight } from '../../../types/api/v2/obt/model/flight';
import type { PnrDataAdditionalMetadata } from '../../../types/api/v2/obt/model/pnr-data-additional-metadata';
import type { LocationInfo } from './types';
import type { TravelerInfo } from '../../../types/api/v2/obt/model/traveler-info';
import { PaymentMethod } from '../../../types/api/v2/obt/model/payment-method';
import { MoneyUtil } from '../../../utils/Money';
import { AncillaryType } from '../../../types/api/v2/obt/model/ancillary-type';
import { UserFacingStatus } from '../../../types/api/v2/obt/model/user-facing-status';
import type { Cabin } from '../../../types/api/v2/obt/model/cabin';
import convertDateFormat from '../../../date-utils/convertDateFormat';
import { dateFormats } from '../../../constants/common';
import { getDiff, getTodayDateTimeInTimezone } from '../../../date-utils';

export class AirPnrV3Manager extends PnrV3Manager {
  airPnr?: Air;

  constructor({ pnrData, pnrId }: PnrV3ManagerProps) {
    super({ pnrData, pnrId });
    this.airPnr = this.pnrData.airPnr;
  }

  private getOriginLocationInfo(
    flight: Flight,
    flightAirlineMetadata: PnrDataAdditionalMetadata | undefined,
  ): LocationInfo {
    const airportInfo = getLocationDetailsFromAirportCode(flight.origin, flightAirlineMetadata?.airportInfo ?? []);
    return {
      ...airportInfo,
      date: flight.departureDateTime,
      terminal: flight.departureGate?.terminal || '',
    };
  }

  private getDestinationLocationInfo(
    flight: Flight,
    flightAirlineMetadata: PnrDataAdditionalMetadata | undefined,
  ): LocationInfo {
    const airportInfo = getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? []);
    return {
      ...airportInfo,
      date: flight.arrivalDateTime,
      terminal: flight.arrivalGate?.terminal || '',
    };
  }

  private destinationCityName() {
    const { airPnr } = this;

    const lastFlightoftheLeg = last(last(airPnr?.legs)?.flights);
    const airportCode = lastFlightoftheLeg?.destination ?? '';

    const airportInfo = getLocationDetailsFromAirportCode(
      airportCode,
      this.pnrData.additionalMetadata?.airportInfo ?? [],
    );

    return airportInfo.cityName;
  }

  public sortedLegs() {
    return this.pnrData.airPnr?.legs.sort((leg) => leg.sortingPriority || 0) ?? [];
  }

  public getInitialDepartureDateTimeISO() {
    return first(first(this.sortedLegs())?.flights)?.departureDateTime.iso8601 || '';
  }

  public cancelationNumbers() {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];
    const cancelationNumbers: string[] = [];
    travelerInfos.forEach((travelerInfo) => {
      const tickets = travelerInfo.tickets ?? [];
      tickets.forEach((ticket) => {
        if (ticket.vendorCancellationId) {
          cancelationNumbers.push(ticket.vendorCancellationId);
        }
      });
    });
    return cancelationNumbers;
  }

  public airTravelerDetails() {
    const travelers = this.pnrData.pnrTravelers ?? [];
    return travelers.map((travelerInfo, travelerInd) => {
      return {
        name: getNameStringFromName(travelerInfo.personalInfo?.name),
        preferredName: getNameStringFromName(travelerInfo.personalInfo?.name, { usePreferredName: true }),
        tsaNumber:
          travelerInfo.personalInfo?.identityDocs?.find((doc) => Object.keys(doc).includes('ktn'))?.ktn?.number ?? '-',
        id: travelerInfo.userId.id,
        loyaltyId:
          travelerInfo.loyalties
            ?.map((l) => {
              return `${l.issuedBy} #${l.id}`;
            })
            .join(', ') ?? '',
        ticketNumbers: this.airPnr?.travelerInfos
          ? this.airPnr?.travelerInfos[travelerInd].tickets?.map((ticket) => ticket.ticketNumber)
          : undefined,
      };
    });
  }

  public getPnrScheduleSummary() {
    const legs = this.sortedLegs();
    const firstLeg = first(legs);
    const lastLeg = last(legs);

    const dateTimeStart = first(firstLeg?.flights)?.departureDateTime.iso8601;
    const dateTimeEnd = last(lastLeg?.flights)?.arrivalDateTime.iso8601;

    const originInfo = this.getOriginLocationInfo(first(firstLeg?.flights)!, this.pnrData.additionalMetadata);
    const destinationInfo = this.getDestinationLocationInfo(last(lastLeg?.flights)!, this.pnrData.additionalMetadata);

    return {
      dateTimeStart,
      dateTimeEnd,
      originInfo,
      destinationInfo,
    };
  }

  public getIssuedTicketNumbers(traveler: TravelerInfo, flightIdx: number) {
    return (
      traveler.tickets
        ?.filter(
          (ticket) =>
            ticket.status === TicketStatusEnum.Issued &&
            Boolean(ticket.flightCoupons?.find((coupon) => coupon.flightIdx === flightIdx)),
        )
        ?.map((ticket) => ticket.ticketNumber) || []
    );
  }

  public getPassengerDetails(flightIdx: number, legIdx: number) {
    const travelerInfos = this.pnrData.airPnr?.travelerInfos ?? [];
    const pnrTravelers = this.pnrData.pnrTravelers ?? [];
    return travelerInfos.map((traveler) => {
      const userId = traveler.userId?.id;
      const requiredPnrTraveler = pnrTravelers.find((pnrTraveler) => pnrTraveler.userId.id === userId);
      const seatNumber = traveler.booking.seats?.find(
        (seat) => seat.legIdx === legIdx && seat.flightIdx === flightIdx,
      )?.number;
      const travelerName = getNameStringFromName(requiredPnrTraveler?.personalInfo?.name);
      const ticketNumbers = this.getIssuedTicketNumbers(traveler, flightIdx);

      const frequentFlyer =
        requiredPnrTraveler?.loyalties?.map((loyalty) => ({
          id: loyalty.id,
          issuedBy: loyalty.issuedBy,
        })) ?? [];

      const requiredSsr = traveler.specialServiceRequestInfos?.filter((ssr) => ssr.flightIndex === flightIdx);
      const specialServiceRequests =
        requiredSsr?.map((ssr) => ({
          name: ssr.code, // We need name here
          status: ssr.status,
        })) ?? [];

      return {
        seatNumber,
        ticketNumbers,
        travelerName,
        frequentFlyer,
        specialServiceRequests,
      };
    });
  }

  private getLayoverInfo(sortedLegIdx: number, flightIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const flight = leg.flights[flightIdx];
    const flightAirlineMetadata = this.pnrData.additionalMetadata;
    return flightIdx < leg.flights.length - 1
      ? {
          code: getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? []).code,
          city: getLocationDetailsFromAirportCode(flight.destination, flightAirlineMetadata?.airportInfo ?? [])
            .cityName,
          duration: minutesToDurationString(
            getDateTimeDiff(leg.flights[flightIdx + 1].departureDateTime.iso8601, flight.arrivalDateTime.iso8601),
          ),
        }
      : undefined;
  }

  private getAircraftDetails(sortedLegIdx: number, flightIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const flight = leg.flights[flightIdx];
    const flightAirlineMetadata = this.pnrData.additionalMetadata;
    return {
      marketingAirline:
        getAirlineNameFromAirlineCode(flight.marketing.airlineCode, flightAirlineMetadata?.airlineInfo ?? []) ?? '',
      airlineNumber: flight.marketing.num ?? '',
      operatingAirline:
        getAirlineNameFromAirlineCode(flight.operating.airlineCode, flightAirlineMetadata?.airlineInfo ?? []) ?? '',
      confirmationNumber: flight.vendorConfirmationNumber ?? '',
      bookingId: this.pnrId,
      cabin: this.cabinTypeToName(flight.cabin),
      aircraft: flight.equipment?.name ?? '',
      co2Emissions: (flight.co2EmissionDetail?.emissionValue || 0) * 1000,
      bookingCode: flight.bookingCode ?? '',
    };
  }

  private getLegCo2EmissionsInKg(sortedLegIdx: number) {
    const leg = this.sortedLegs()[sortedLegIdx];
    const co2EmissionsPerFlight = leg.flights.map((flight) => flight.co2EmissionDetail?.emissionValue);
    const co2Sum = co2EmissionsPerFlight.reduce((acc, curr) => (acc || 0) + (curr || 0), 0);
    const co2EmissionsInKg =
      !co2Sum || co2EmissionsPerFlight.some((co2EmissionValue) => co2EmissionValue === undefined)
        ? undefined
        : co2Sum * 1000;
    return co2EmissionsInKg ? Math.round(co2EmissionsInKg) : undefined;
  }

  private getFlightCo2EmissionsInKg(sortedLegIdx: number, flightIdx: number) {
    const flight = this.sortedLegs()[sortedLegIdx].flights[flightIdx];
    const co2Emissions = flight.co2EmissionDetail?.emissionValue ?? 0;
    const co2EmissionsInKg = co2Emissions * 1000;
    return Math.round(co2EmissionsInKg);
  }

  public getTotalCo2EmissionsInKg(legIdx: number, flightIdx?: number) {
    const totalTravelerForCo2Emission = (this.airPnr?.travelerInfos ?? []).filter(
      (traveler) => traveler.paxType !== PassengerType.InfantOnLap,
    ).length;

    const co2EmissionsInKg =
      flightIdx !== undefined ? this.getFlightCo2EmissionsInKg(legIdx, flightIdx) : this.getLegCo2EmissionsInKg(legIdx);

    return co2EmissionsInKg ? totalTravelerForCo2Emission * co2EmissionsInKg : undefined;
  }

  public airlineReference() {
    const allAirlineReferences = this.airPnr?.legs?.flatMap((leg) =>
      leg.flights.map((flight) => flight.vendorConfirmationNumber),
    );
    return [...new Set(allAirlineReferences)].join(', ');
  }

  public airDetails() {
    const flightAirlineMetadata = this.pnrData.additionalMetadata;

    const legsDetails = this.sortedLegs().map((leg, legIdx) => {
      const startDate = first(leg.flights)?.departureDateTime;
      const endDate = first(leg.flights)?.arrivalDateTime;
      let durationMinutes = 0;
      const scheduleChangeDetails = leg.scheduleChangeInfo;

      const flightDetails = leg.flights.map((flight, flightIdx) => {
        const aircraftDetails = this.getAircraftDetails(legIdx, flightIdx);
        const passengerDetails = this.getPassengerDetails(flightIdx, legIdx);
        durationMinutes += getDurationMinutes(flight.duration?.iso8601 ?? '');

        const layover = this.getLayoverInfo(legIdx, flightIdx);

        if (layover) {
          durationMinutes += getDateTimeDiff(
            leg.flights[flightIdx + 1].departureDateTime.iso8601,
            flight.arrivalDateTime.iso8601,
          );
        }
        const originalFlight: FlightDetailInformation | undefined = this.getOriginalFlight(
          scheduleChangeDetails,
          flightIdx,
        );

        const co2EmissionsInKg = this.getTotalCo2EmissionsInKg(legIdx, flightIdx);

        return {
          aircraftDetails,
          duration: getDurationString(flight.duration?.iso8601 ?? ''),
          airlineCode: flight.marketing.airlineCode,
          layover,
          origin: this.getOriginLocationInfo(flight, flightAirlineMetadata),
          destination: this.getDestinationLocationInfo(flight, flightAirlineMetadata),
          passengerDetails,
          originalFlight,
          co2EmissionsInKg,
          bookingStatus: leg.legStatus,
          isPnrOutOfPolicy: this.isPnrOutOfPolicy(),
        };
      });

      const allLegCabins = flightDetails.map((segment) => segment.aircraftDetails.cabin);

      const isMixedCabin = this.getIsMixedCabin(allLegCabins);

      const stops = (flightDetails.length ?? 1) - 1;

      const originName = flightDetails.length > 0 ? flightDetails[0].origin.airportName : '';
      const destinationName =
        flightDetails.length > 0 ? flightDetails[flightDetails.length - 1].destination.airportName : '';
      const originCode = flightDetails.length > 0 ? flightDetails[0].origin.code : '';
      const destinationCode = flightDetails.length > 0 ? flightDetails[flightDetails.length - 1].destination.code : '';

      return {
        originName,
        destinationName,
        originCode,
        destinationCode,
        startDate,
        endDate,
        flightDetails,
        duration: minutesToDurationString(durationMinutes),
        stops,
        isMixedCabin,
        fareType: leg.rateType || '',
      };
    });

    return {
      legsDetails,
      travelerDetails: this.airTravelerDetails(),
    };
  }

  private getOriginalFlight(scheduleChangeDetails: ScheduleChangeInfo | undefined, flightIndex: number) {
    if (
      !scheduleChangeDetails ||
      !scheduleChangeDetails.flightIndices ||
      !scheduleChangeDetails.disruptedFlightIndices ||
      !this.airPnr?.disruptedFlightDetails
    ) {
      return undefined;
    }

    const scheduleChangeIndex = scheduleChangeDetails.flightIndices.indexOf(flightIndex);
    if (scheduleChangeIndex === -1) {
      return undefined;
    }

    const disruptedFlightIndex = scheduleChangeDetails.disruptedFlightIndices[scheduleChangeIndex];
    if (disruptedFlightIndex === undefined) {
      return undefined;
    }
    return this.airPnr?.disruptedFlightDetails[disruptedFlightIndex];
  }

  public paymentInfo() {
    const allTickets = this.airPnr?.travelerInfos?.flatMap((traveler) => traveler.tickets);

    const transactionDate = undefined; // TODO find out if this is different from the reservation date, if not, remove this funciton and default to super. Update stencil code to remove dependency
    const paymentInfos = this.pnrData.paymentInfo ?? [];
    const formOfPayments = paymentInfos.map((paymentInfo) => {
      const isFopBrex = paymentInfo.fop.paymentMethod === PaymentMethod.BrexPoints;

      const netCharge = MoneyUtil.convertV2MoneyToMoneyUtil(paymentInfo.netCharge);

      if (!netCharge.isAmountZero()) {
        if (isFopBrex) {
          return {
            company: 'Brex Reward',
            paymentText: `${netCharge.getBrexPoints()?.amount} Brex Points`,
            amount: netCharge,
          };
        }

        return {
          company: paymentInfo.fop.card?.company ?? '',
          paymentText: maskCardNumber(paymentInfo.fop.card?.number ?? ''),
          amount: netCharge,
        };
      }

      return undefined;
    });

    const travelerInfos = this.airPnr?.travelerInfos;

    let reservationDate;
    if (travelerInfos && travelerInfos[0].tickets && travelerInfos[0].tickets.length > 0) {
      reservationDate = travelerInfos[0].tickets[0].issuedDateTime;
    }
    const ticketNumbers = travelerInfos ? travelerInfos[0].tickets?.map((ticket) => ticket.ticketNumber) : []; // TODO do we want only 1st traveler ticket numbers or all?
    return {
      ...super.paymentInfo(),
      formOfPayments,
      transactionDate,
      ticketNumbers,
      reservationDate,
      allTickets,
    };
  }

  public transactionDate() {
    const travelerInfos = this.airPnr?.travelerInfos;
    if (travelerInfos && travelerInfos[0].tickets && travelerInfos[0].tickets.length > 0) {
      return travelerInfos[0].tickets[0].issuedDateTime;
    }
    return undefined;
  }

  public vendorName() {
    const { legsDetails } = this.airDetails();
    const isSpotnanaMerchant = this.isSpotnanaMerchant();

    const allFlightSegments = legsDetails.flatMap((legDetails) => legDetails.flightDetails);

    const allAirCraftDetails = allFlightSegments.map((flightSegment) => flightSegment.aircraftDetails);

    const allAirlines = allAirCraftDetails.map((airCraftDetail) => airCraftDetail.marketingAirline);

    const filteredAirlines = Array.from(new Set(allAirlines));

    return isSpotnanaMerchant ? 'Spotnana' : filteredAirlines.join(', ');
  }

  public paymentDetailPerTraveler() {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];

    const pnrTravelers = this.pnrData.pnrTravelers ?? [];

    const paymentDetailPerTraveler = travelerInfos.map((traveler, travelerIdx) => {
      const fare = traveler.booking.itinerary.totalFlightsFare?.base;
      const tax = traveler.booking.itinerary.totalFlightsFare?.tax;
      const totalfare = traveler.booking.itinerary.totalFare?.base;
      const totalTax = traveler.booking.itinerary.totalFare?.tax;
      const airlineFees = traveler.booking.itinerary.totalAirlineFees;

      const travelerTotalFare = MoneyUtil.convertV2MoneyToMoneyUtil(totalfare).add(
        MoneyUtil.convertV2MoneyToMoneyUtil(totalTax),
      );

      const wifiAncillaryFare = traveler.booking.itinerary.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.Wifi,
      )?.totalFare;
      const earlyBirdAncillaryFare = traveler.booking.itinerary.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.EarlyBird,
      )?.totalFare;
      const carbonOffsetAncillaryFare = traveler.booking.itinerary.otherAncillaryFares?.find(
        (ancillaryFare) => ancillaryFare.type === AncillaryType.CarbonOffset,
      )?.totalFare;

      const otherCharges = MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary.otherCharges);

      const wifiAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(wifiAncillaryFare?.base);
      const wifiAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(wifiAncillaryFare?.tax);

      const earlyBirdAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(earlyBirdAncillaryFare?.base);
      const earlyBirdAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(earlyBirdAncillaryFare?.tax);

      const carbonOffsetAncillaryFareBase = MoneyUtil.convertV2MoneyToMoneyUtil(carbonOffsetAncillaryFare?.base);
      const carbonOffsetAncillaryFareTax = MoneyUtil.convertV2MoneyToMoneyUtil(carbonOffsetAncillaryFare?.tax);

      const exchangeInfo = this.exchangeInfo(travelerIdx);
      const exchangePenalty = MoneyUtil.convertV2MoneyToMoneyUtil(exchangeInfo?.penalty);

      const refundInfo = this.refundInfo(travelerIdx);
      const refundPenaltyBase = MoneyUtil.convertV2MoneyToMoneyUtil(refundInfo?.penalty?.base);
      const refundPenaltyTax = MoneyUtil.convertV2MoneyToMoneyUtil(refundInfo?.penalty?.tax);
      const refundPenalty = refundPenaltyBase.add(refundPenaltyTax);

      const allTravelerTickets = traveler.tickets?.filter(
        (ticket) =>
          ticket.ticketType === TicketTicketTypeEnum.Flight &&
          ticket.status === TicketStatusEnum.Issued &&
          (ticket.flightCoupons?.length ?? 0) > 0,
      );
      const allTicketNumbers = allTravelerTickets?.map((ticket) => ticket.ticketNumber).join(', ') ?? '';

      return {
        name: getNameStringFromName(pnrTravelers[travelerIdx].personalInfo?.name, { usePreferredName: true }),
        flightFare: MoneyUtil.convertV2MoneyToMoneyUtil(fare),
        feesAndTaxes: MoneyUtil.convertV2MoneyToMoneyUtil(tax),
        travelerTotalFare,
        seatFare: MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary.totalSeatFare),
        luggageFare: MoneyUtil.convertV2MoneyToMoneyUtil(traveler.booking.itinerary.totalLuggageFare),
        wifiAncillaryFare: wifiAncillaryFareBase.add(wifiAncillaryFareTax),
        earlyBirdAncillaryFare: earlyBirdAncillaryFareBase.add(earlyBirdAncillaryFareTax),
        carbonOffsetAncillaryFare: carbonOffsetAncillaryFareBase.add(carbonOffsetAncillaryFareTax),
        airlineFees: MoneyUtil.convertV2MoneyToMoneyUtil(airlineFees),
        otherCharges,
        exchangePenalty,
        refundPenalty,
        allTicketNumbers,
      };
    });

    return paymentDetailPerTraveler;
  }

  public exchangeInfo(travelerIndex: number) {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];
    const tickets = travelerInfos[travelerIndex].tickets ?? [];

    const newTicket = tickets.find((ticket) => !!ticket.exchangeInfo?.originalTicketNumber);

    return newTicket?.exchangeInfo;
  }

  public refundInfo(travelerIndex: number) {
    const travelerInfos = this.airPnr?.travelerInfos ?? [];
    const tickets = travelerInfos[travelerIndex].tickets ?? [];

    const refundedTicket = tickets.find((ticket) => ticket.status === TicketStatusEnum.Refunded);

    return refundedTicket?.refundInfo;
  }

  public cabinTypeToName(cabin: Cabin | undefined) {
    if (!cabin) {
      return '';
    }
    const filteredCabin = cabin.replaceAll('_', ' ');
    return filteredCabin.charAt(0).toUpperCase() + filteredCabin.slice(1).toLowerCase();
  }

  public getIsMixedCabin = (allCabins: string[]): boolean => {
    const cabinSet = new Set();
    allCabins.forEach((cabinType) => cabinSet.add(cabinType));
    return cabinSet.size > 1;
  };

  public isDelayedInvoicing() {
    return !!this.pnrData.invoiceDelayedBooking;
  }

  public getHoldInfo() {
    const holdDeadline = this.airPnr?.holdDeadline;

    if (!holdDeadline) {
      return { timeRemainingToAutoCancelHold: '', holdDeadlineTime: '' };
    }

    const { holdDeadline: holdDeadlineInfo } = holdDeadline;

    const formattedHoldDeadline = convertDateFormat(
      holdDeadlineInfo.iso8601,
      dateFormats.ISO,
      dateFormats.DAY_DATE_HR12_TIME,
    );

    const localCurrentDateTime = getTodayDateTimeInTimezone('UTC', dateFormats.ISO);

    const diffInHours = getDiff(
      localCurrentDateTime,
      holdDeadlineInfo.iso8601,
      'hours',
      dateFormats.ISO,
      dateFormats.ISO,
    );

    const diffInMinutes = getDiff(
      localCurrentDateTime,
      holdDeadlineInfo.iso8601,
      'minutes',
      dateFormats.ISO,
      dateFormats.ISO,
    );

    let timeRemainingToAutoCancelHold = '';
    let holdDeadlineTime = '';

    if (diffInHours >= 1) {
      timeRemainingToAutoCancelHold = `${diffInHours + 1} hours`;
    } else if (diffInHours === 0 && diffInMinutes > 0) {
      timeRemainingToAutoCancelHold = `${diffInMinutes} minutes`;
    }

    holdDeadlineTime = `${formattedHoldDeadline} UTC`;

    return { timeRemainingToAutoCancelHold, holdDeadlineTime };
  }

  public isPnrStatusCancelled() {
    return this.pnrData.bookingStatus === UserFacingStatus.CancelledStatus;
  }

  public hasExchangedTickets() {
    const { airPnr } = this;

    if (!airPnr) {
      return false;
    }

    const travelerInfos = airPnr.travelerInfos ?? [];

    const allTickets = travelerInfos.flatMap((travelerInfo) => travelerInfo.tickets ?? []);

    if (allTickets.length <= 0) {
      return false;
    }

    const allTicketNumbers = allTickets.map((ticket) => ticket.ticketNumber);

    const ticketNumbers = new Set(allTicketNumbers);

    return allTickets.some((ticket) => {
      const originalTicketnumber = ticket.exchangeInfo?.originalTicketNumber;

      if (!originalTicketnumber) {
        return false;
      }

      return ticketNumbers.has(originalTicketnumber);
    });
  }

  public pnrTitle() {
    const destinationCityName = this.destinationCityName();
    const pnrTitle = defineCommonMessage('Flight to {{destinationCityName}}');
    pnrTitle.values = {
      destinationCityName,
    };
    return pnrTitle;
  }

  public flightSavings() {
    const priceOptimizationMetadata = this?.airPnr?.airPriceOptimizationMetadata;
    const priceDrop = MoneyUtil.convertV2MoneyToMoneyUtil(priceOptimizationMetadata?.priceDrop);
    const penalty = MoneyUtil.convertV2MoneyToMoneyUtil(priceOptimizationMetadata?.penaltyPrice);
    const savings = priceDrop.subtract(penalty);
    return savings;
  }
}
