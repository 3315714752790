import { lazy } from 'react';
import { IRouteConfig } from 'src/app/types/routes';

const CompanyReportsPage = lazy(() => import('./reports/CompanyReportsPage'));
const TravelerReportsPage = lazy(() => import('./reports/TravelerReportsPage'));
const DutyOfCarePage = lazy(() => import('./DutyOfCarePage'));

export const analyticsRoutes: IRouteConfig[] = [
  {
    key: 'reports',
    path: '/reports',
    component: CompanyReportsPage,
  },
  {
    key: 'traveler-reports',
    path: '/traveler-reports',
    component: TravelerReportsPage,
  },
  {
    key: 'duty-of-care',
    path: '/dutyofcare',
    component: DutyOfCarePage,
    exact: true,
  },
];
