import type { UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from 'react-query';

import type { UpdatePccRequest } from '@spotnana/types/openapi/models/update-pcc-request';
import type { CreatePccResponse } from '@spotnana/types/openapi/models/create-pcc-response';
import type { CreatePccRequest } from '@spotnana/types/openapi/models/create-pcc-request';

import type { SupplierPccRulesConfig, SupplierType } from '@spotnana/types/openapi/models';
import Config from '../utils/Config';
import type { ReadSupportConfigResponse, ReadPccResponse } from '../types/api/v2/obt/model';
import type { ReadSupplierInfoResponse } from '../types/api/v2/obt/model/read-supplier-info-response';
import type { ListPccResponse } from '../types/api/v2/obt/model/list-pcc-response';
import type { ListPccRequest } from '../types/api/v2/obt/model/list-pcc-request';
import type { UpdateSupplierInfoRequest } from '../types/api/v2/obt/model/update-supplier-info-request';
import api, { getUrl } from '../api';
import type { SpotnanaQueryMutationResult, SpotnanaQueryResult } from '../types';
import SpotnanaError from '../api/SpotnanaError';
import { defaultQueryClient } from './defaultQueryClient';
import { useLoggedInUserId } from '../providers';
import type { SupportConfig } from '../types/api/v2/obt/model/support-config';
import type { GetZendeskChatTokenResponse } from '../types/api/v2/obt/model/get-zendesk-chat-token-response';

const getTmcSupplierInfoUrl = (organizationId: string): string => `/${organizationId}/supplier-info`;

export const getPccConfig = (organizationId: string, supplierId?: string): [string, string, string] => [
  'pccList',
  organizationId,
  supplierId ?? '',
];

const fetchTmcSupplierInfo = async (organizationId: string): Promise<ReadSupplierInfoResponse | undefined> => {
  if (organizationId) {
    try {
      const result = await api('GET', 'sharedCompany', {
        urlParam: getTmcSupplierInfoUrl(organizationId),
      });
      return result as ReadSupplierInfoResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchTmcSupplierInfo = (
  organizationId: string,
  enabled = true,
): SpotnanaQueryResult<ReadSupplierInfoResponse | undefined> =>
  useQuery<ReadSupplierInfoResponse | undefined, SpotnanaError>(
    getTmcSupplierInfoUrl(organizationId),
    () => fetchTmcSupplierInfo(organizationId),
    {
      enabled: enabled && !!organizationId,
    },
  );

export const invalidateSupplierInfo = (organizationId: string): void => {
  defaultQueryClient.invalidateQueries(getTmcSupplierInfoUrl(organizationId));
};

const getCompanySupportConfig = (organizationId: string): string => `/${organizationId}/support-config`;

const fetchCompanySupportConfig = async (organizationId: string): Promise<ReadSupportConfigResponse | undefined> => {
  if (organizationId) {
    try {
      const result = await api('GET', 'sharedCompany', {
        urlParam: getCompanySupportConfig(organizationId),
      });

      return result as ReadSupportConfigResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchCompanySupportConfig = <TData = ReadSupportConfigResponse | undefined>(
  organizationId: string,
  options?: UseQueryOptions<ReadSupportConfigResponse | undefined, SpotnanaError, TData>,
) =>
  useQuery<ReadSupportConfigResponse | undefined, SpotnanaError, TData>(
    getCompanySupportConfig(organizationId),
    () => fetchCompanySupportConfig(organizationId),
    {
      ...options,
      enabled: !!organizationId,
    },
  );

export function useSupportConfigForCurrentOrganization(
  options?: UseQueryOptions<ReadSupportConfigResponse | undefined, SpotnanaError, SupportConfig | undefined>,
) {
  const loggedInUserId = useLoggedInUserId();

  return useFetchCompanySupportConfig<SupportConfig | undefined>(
    loggedInUserId?.tmcBasicInfo?.bookingTmc?.id?.id ?? '',
    {
      ...options,
      select: (config) => config?.supportConfig,
    },
  );
}

export const getZendeskChatToken = (companyId: string): string => `/chat-token/${companyId}/zendesk`;

export function compileZendeskTokenFetchUrl(companyId: string): string {
  const urlParam = getZendeskChatToken(companyId);
  const urlPath = getUrl('zendeskChatToken', urlParam);
  const fullUrl = Config.VITE_API_BASE_URL + urlPath;
  return fullUrl;
}

export const fetchZendeskChatToken = async (companyId: string): Promise<GetZendeskChatTokenResponse | undefined> => {
  if (companyId) {
    try {
      const result = await api('POST', 'zendeskChatToken', {
        urlParam: getZendeskChatToken(companyId),
      });

      return result as GetZendeskChatTokenResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return Promise.resolve(undefined);
};

export const useFetchZendeskChatToken = (
  options?: UseQueryOptions<GetZendeskChatTokenResponse | undefined, SpotnanaError, GetZendeskChatTokenResponse>,
) => {
  const loggedInUserId = useLoggedInUserId();
  const companyId = loggedInUserId?.tmcBasicInfo?.bookingTmc?.id?.id ?? '';
  return useQuery<GetZendeskChatTokenResponse | undefined, SpotnanaError, GetZendeskChatTokenResponse>(
    getZendeskChatToken(companyId),
    () => fetchZendeskChatToken(companyId),
    {
      ...options,
      enabled: options?.enabled !== undefined ? options.enabled && !!companyId : !!companyId,
    },
  );
};

const updateSupplierInfo = async (
  organizationId: string,
  requestBody: UpdateSupplierInfoRequest,
): Promise<ReadSupplierInfoResponse> => {
  try {
    const result = await api('POST', 'sharedCompany', {
      urlParam: getTmcSupplierInfoUrl(organizationId),
      data: requestBody,
    });
    return result as ReadSupplierInfoResponse;
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useUpdateSupplierInfo = ({
  onSuccess,
}: {
  onSuccess?: (requestBody: UpdateSupplierInfoRequest) => void;
} = {}): SpotnanaQueryMutationResult<ReadSupplierInfoResponse, UpdateSupplierInfoRequest> =>
  useMutation((requestBody: UpdateSupplierInfoRequest) => updateSupplierInfo(requestBody.tmcId?.id, requestBody), {
    onSuccess: (_data, requestBody) => onSuccess?.(requestBody),
  });

const getPccUrl = (pccId: string): string => `/v2/pcc/${pccId}`;

export const fetchPccInfo = async (pccId: string): Promise<ReadPccResponse | undefined> => {
  if (pccId) {
    try {
      const result = await api('GET', 'pcc', {
        urlParam: `/${pccId}`,
      });
      return result as ReadPccResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return undefined;
};

const createPcc = async (request: CreatePccRequest): Promise<CreatePccResponse> => {
  const data = await api('POST', 'pcc', { data: request });
  return data as CreatePccResponse;
};

const updatePcc = async (request: UpdatePccRequest): Promise<void> => {
  await api('PUT', 'pcc', {
    urlParam: `/${request.pcc.pccId}`,
    data: request,
  });
};

export const useFetchPccQuery = (pccId: string): SpotnanaQueryResult<ReadPccResponse | undefined> =>
  useQuery<ReadPccResponse | undefined, SpotnanaError>(getPccUrl(pccId), () => fetchPccInfo(pccId));

export const useCreatePccMutation = (): SpotnanaQueryMutationResult<CreatePccResponse, CreatePccRequest> =>
  useMutation((request: CreatePccRequest) => createPcc(request), {
    onSuccess: (_res, requestBody) => {
      defaultQueryClient.invalidateQueries(getPccConfig(requestBody.tmcId.id, requestBody.supplier));
    },
  });

export const useUpdatePccMutation = (): SpotnanaQueryMutationResult<void, UpdatePccRequest> =>
  useMutation((request: UpdatePccRequest) => updatePcc(request), {
    onSuccess: (_res, requestBody) => {
      defaultQueryClient.invalidateQueries(getPccConfig(requestBody.pcc.tmcId.id, requestBody.pcc.supplier));
      defaultQueryClient.invalidateQueries(getPccUrl(requestBody.pcc.pccId));
    },
  });

const fetchPccList = async (request: ListPccRequest): Promise<ListPccResponse | undefined> => {
  if (request.tmcId.id) {
    try {
      const result = await api('POST', 'pccList', {
        data: request,
      });
      return result as ListPccResponse;
    } catch (error) {
      throw new SpotnanaError(error as Error);
    }
  }
  return undefined;
};

export const useFetchPccQueryList = ({
  isEnabled = true,
  ...request
}: ListPccRequest & {
  isEnabled?: boolean;
}): SpotnanaQueryResult<ListPccResponse | undefined> =>
  useQuery<ListPccResponse | undefined, SpotnanaError>(
    getPccConfig(request.tmcId.id, (request.supplier as string) ?? ''),
    () => fetchPccList(request),
    {
      enabled: isEnabled,
    },
  );

export const invalidatePccList = (): void => {
  defaultQueryClient.invalidateQueries('pccList');
};

const deletePcc = async (pccId: string): Promise<void> => {
  try {
    await api('DELETE', 'pcc', {
      urlParam: `/${pccId}`,
    });
  } catch (error) {
    throw new SpotnanaError(error as Error);
  }
};

export const useDeletePcc = (): SpotnanaQueryMutationResult<void, string> =>
  useMutation('deletePcc', (pccId) => deletePcc(pccId), {
    onSuccess: () => {
      defaultQueryClient.invalidateQueries({
        queryKey: ['pccList'],
      });
    },
  });

interface IFetchTmcConfigurationRequest {
  tmcId: string;
  supplierType: SupplierType;
}

function getFetchTmcConfigurationKey({ tmcId, supplierType }: IFetchTmcConfigurationRequest) {
  return ['tmcConfig', tmcId, supplierType];
}
async function fetchTmcConfiguration({
  tmcId,
  supplierType,
}: IFetchTmcConfigurationRequest): Promise<SupplierPccRulesConfig | undefined> {
  const result = await api('GET', 'tmcBaseURLV3', {
    urlParam: `/${tmcId}/supplier-types/${supplierType}/pcc-config`,
  });
  return result as SupplierPccRulesConfig;
}
export function useFetchTmcConfiguration({
  tmcId,
  supplierType,
}: IFetchTmcConfigurationRequest): SpotnanaQueryResult<SupplierPccRulesConfig | undefined> {
  return useQuery(getFetchTmcConfigurationKey({ tmcId, supplierType }), () =>
    fetchTmcConfiguration({ tmcId, supplierType }),
  );
}

interface IUpdateTmcConfigurationRequest {
  tmcId: string;
  supplierType: SupplierType;
  rulesConfig: SupplierPccRulesConfig;
}

async function updateTmcConfiguration({
  tmcId,
  supplierType,
  rulesConfig,
}: IUpdateTmcConfigurationRequest): Promise<void> {
  await api('POST', 'tmcBaseURLV3', {
    urlParam: `/${tmcId}/supplier-types/${supplierType}/pcc-config`,
    data: rulesConfig,
  });
}
export function useUpdateTmcConfiguration({
  tmcId,
  supplierType,
}: Omit<IUpdateTmcConfigurationRequest, 'rulesConfig'>): SpotnanaQueryMutationResult<void, SupplierPccRulesConfig> {
  return useMutation(
    'updateTmcConfiguration',
    (rulesConfig: SupplierPccRulesConfig) => updateTmcConfiguration({ tmcId, supplierType, rulesConfig }),
    {
      onSuccess: () => {
        defaultQueryClient.invalidateQueries({
          queryKey: getFetchTmcConfigurationKey({ tmcId, supplierType }),
        });
      },
    },
  );
}

interface IFetchOrgConfigurationRequest {
  tmcId: string;
  companyId: string | undefined;
  supplierType: SupplierType;
}

function getFetchOrgConfigurationKey({ tmcId, companyId, supplierType }: IFetchOrgConfigurationRequest) {
  return ['orgConfig', tmcId, companyId, supplierType];
}

async function fetchOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
}: IFetchOrgConfigurationRequest): Promise<SupplierPccRulesConfig | undefined> {
  if (!companyId) {
    return undefined;
  }
  try {
    const result = await api('GET', 'tmcBaseURLV3', {
      urlParam: `/${tmcId}/companies/${companyId}/supplier-types/${supplierType}/pcc-config`,
    });
    return result as SupplierPccRulesConfig;
  } catch (e) {
    return undefined;
  }
}

export function useFetchOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
}: IFetchOrgConfigurationRequest): SpotnanaQueryResult<SupplierPccRulesConfig | undefined> {
  return useQuery(
    getFetchOrgConfigurationKey({ tmcId, companyId, supplierType }),
    () => fetchOrgConfiguration({ tmcId, companyId, supplierType }),
    {
      enabled: !!companyId,
    },
  );
}

interface IUpdateOrgConfigurationRequest {
  tmcId: string;
  companyId: string | undefined;
  supplierType: SupplierType;
  rulesConfig: SupplierPccRulesConfig;
}

async function updateOrgConfiguration({
  tmcId,
  supplierType,
  companyId,
  rulesConfig,
}: IUpdateOrgConfigurationRequest): Promise<void> {
  if (!companyId) {
    return;
  }
  await api('POST', 'tmcBaseURLV3', {
    urlParam: `/${tmcId}/companies/${companyId}/supplier-types/${supplierType}/pcc-config`,
    data: rulesConfig,
  });
}
export function useUpdateOrgConfiguration({
  tmcId,
  companyId,
  supplierType,
}: Omit<IUpdateOrgConfigurationRequest, 'rulesConfig'>): SpotnanaQueryMutationResult<void, SupplierPccRulesConfig> {
  return useMutation(
    'updateOrgConfiguration',
    (rulesConfig: SupplierPccRulesConfig) => updateOrgConfiguration({ tmcId, supplierType, companyId, rulesConfig }),
    {
      onSuccess: () => {
        defaultQueryClient.invalidateQueries({
          queryKey: getFetchOrgConfigurationKey({ tmcId, companyId, supplierType }),
        });
      },
    },
  );
}
