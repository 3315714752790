import { PropsWithChildren, ReactElement, useLayoutEffect, useEffect } from 'react';
import { TokenExchangeTypes, EmbedEventTypes } from '@spotnana-tech/embed-helpers';

import { useAuth, tokenExchange, safeJsonStringify, useFeatureFlag, webSessionStorage, StorageKeys } from 'obt-common';
import { AxiosError } from 'axios';

export const TokenExchange = ({ children }: PropsWithChildren): ReactElement => {
  const { authenticateUserByTokens } = useAuth();
  const isStoreRedirectOnErrorEnabled = useFeatureFlag('FE_CORP_EMBED_STORE_REDIRECT_ON_ERROR') || false;
  // Using URLSearchParams because we are outside of BrowserRouter.
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const idp = params.get('idp');

  const loginViaTokens = async () => {
    try {
      await authenticateUserByTokens();
      params.delete('idp');
      window.location.replace(`${window.location.pathname}?${params.toString()}`);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      window.parent.postMessage(
        {
          from: 'spotnana-embed',
          type: TokenExchangeTypes.TOKEN_EXCHANGE_ERROR,
          payload: {
            errorCode: EmbedEventTypes.INVALID_TOKEN,
            errorMessage: err?.message,
            errorStack: err?.stack,
            data: safeJsonStringify((err as AxiosError)?.response?.data, ''),
            url: (err as AxiosError)?.request?.responseURL || (err as AxiosError)?.response?.config?.url,
          },
        },
        '*',
      );
    }
  };

  useLayoutEffect(() => {
    // Authenticate user if idp is present, even if user is already loggedin
    if (idp && tokenExchange.includes(idp)) {
      // Store the idp in session storage so we can use it to retrigger the token exchange flow later.
      webSessionStorage.setItem(StorageKeys.TOKEN_EXCHANGE_IDP, idp);
      loginViaTokens();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const shouldHandleError =
      !!webSessionStorage.getItem(StorageKeys.TOKEN_EXCHANGE_IDP) && isStoreRedirectOnErrorEnabled;

    // Harness values are refetched when auth state changes. Since this feature is related to auth
    // state changes, it may flip-flop, but we only care if it was true at least once for the flow.
    // After this feature is stable, we can remove this storage key in favor of simply checking if TOKEN_EXCHANGE_IDP is set.
    if (shouldHandleError) {
      webSessionStorage.setItem(StorageKeys.TOKEN_EXCHANGE_ERROR_HANDLING, 'true');
    }
  }, [isStoreRedirectOnErrorEnabled]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
