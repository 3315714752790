import Box, { BoxProps, Flex } from '@spotnana/pixel-react/dist/Box';
import CircularLoader from '@spotnana/pixel-react/dist/CircularLoader';
import Close from '@spotnana/pixel-react/dist/Icons/Close';
import Ellipsis from '@spotnana/pixel-react/dist/Ellipsis';
import Logo from '@spotnana/pixel-react/dist/Image';
import spotnanaTheme from '@spotnana/pixel-react/dist/utils/themes/theme';
import Typography from '@spotnana/pixel-react/dist/Typography';
import { Image, ImageV1, usePartnerFeatures } from 'obt-common';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import UserAvatar from '../../v2-components/shared/UserAvatar';
import {
  company_line_container,
  company_logo_container,
  company_name,
  input_label,
  name_and_avatar_container,
  name_and_avatar_outer_container,
  subtext_container,
} from './CurrentTraveler.styles';

interface IProps {
  loading: boolean;
  currentTraveler: string;
  onClear?: () => void;
  // TODO: NEEDS_DOCUMENTATION :: What is this?
  travelerIdString?: string;
  profilePicture?: ImageV1 | Image;
  companyLoading?: boolean;
  companyName?: string;
  email?: string;
  companyLogo?: Image;
  containerStyles?: Omit<BoxProps, 'theme' | 'color'>;
  disabled?: boolean;
  isSeat1a?: boolean;
  showCloseIcon?: boolean;
  label?: string;
  subtextRenderer?: () => JSX.Element;
  className?: string;
}

const { colors } = spotnanaTheme;

const CurrentTraveler: React.FC<IProps> = ({
  loading,
  currentTraveler,
  onClear,
  travelerIdString,
  profilePicture,
  companyLoading,
  companyLogo,
  companyName,
  containerStyles,
  disabled = false,
  isSeat1a = false,
  showCloseIcon = true,
  label,
  email,
  subtextRenderer,
  className,
}: IProps) => {
  const { t: tt } = useTranslation('WEB');
  const { features } = usePartnerFeatures();
  const { travelerSelection: isTravelerSelectionEnabled } = features;
  const getUserAvatar = (): JSX.Element => {
    return (
      <UserAvatar
        isSeat1a={isSeat1a}
        size="small"
        title={loading ? '' : currentTraveler}
        imageSrc={loading ? '' : profilePicture?.url}
      />
    );
  };

  const getUserNameLineHeight = (): number | string => {
    if (!companyLoading && companyName) {
      return 'inherit';
    }
    if (email) {
      return 'inherit';
    }
    return 2;
  };

  const getUserName = (): JSX.Element => {
    return (
      <Ellipsis
        containerStyles={{ lineHeight: getUserNameLineHeight() }}
        title={currentTraveler}
        dataTestId="traveler_name"
      >
        <Typography variation="small">{currentTraveler}</Typography>
      </Ellipsis>
    );
  };

  const getSubtextContent = (): JSX.Element => {
    if (companyLoading) {
      return <CircularLoader size={12} />;
    }

    if (companyName) {
      return (
        <div css={company_line_container} className={className}>
          {companyLogo?.url && (
            <span css={company_logo_container}>
              <Logo
                data-testid="company-logo"
                src={companyLogo.url}
                width={14}
                height={14}
                alt={`${companyName} logo`}
                style={{ borderRadius: '100%' }}
              />
            </span>
          )}
          <Typography css={company_name} whiteSpace="nowrap" variation="label" color={colors.text.blockedGrey}>
            {companyName}
          </Typography>
        </div>
      );
    }

    if (email) {
      return (
        <div css={subtext_container} className={className}>
          <Typography variation="label" color={colors.border.blockedGrey}>
            {email}
          </Typography>
        </div>
      );
    }

    return <></>;
  };

  const getUserAvatarAndName = (): JSX.Element => {
    return (
      <div css={name_and_avatar_outer_container} className={className}>
        {label && <div css={input_label}>{label}</div>}
        <div css={name_and_avatar_container}>
          {getUserAvatar()}
          <Box px="8px">
            {loading ? tt('Fetching') : getUserName()}
            {subtextRenderer ? subtextRenderer() : getSubtextContent()}
          </Box>
        </div>
      </div>
    );
  };

  return (
    <Flex
      p={2}
      border={disabled ? 'none' : 'thinSolid'}
      borderColor={disabled ? '' : 'border.lightGray2'}
      borderRadius="md"
      alignItems="center"
      justifyContent="space-between"
      data-testid="box_change-traveler"
      className={className}
      {...containerStyles}
    >
      {getUserAvatarAndName()}
      {!disabled && showCloseIcon && isTravelerSelectionEnabled && (
        <Box
          cursor="pointer"
          role="button"
          as="button"
          aria-label={tt('Remove traveler')}
          data-testid="remove-traveler"
          onClick={(e: React.SyntheticEvent) => {
            e.preventDefault();
            onClear?.();
          }}
          ml="4px"
          p="8px"
        >
          <Close width={12} height={12} primaryColor={colors.border.regular} />
        </Box>
      )}
    </Flex>
  );
};

export default CurrentTraveler;
