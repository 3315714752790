import {
  AirlinePrefFlightTypeEnum as AirlinePrefFlightTypeEnumV1,
  FarePrefFareTypeEnum,
  SeatLocationPrefPositionEnum as SeatLocationPrefPositionEnumV1,
} from '../types/api/v1/obt/common/air_preference';
import type {
  IAddressValidationState,
  IEmergencyContactValidationState,
  ILabelValidationState,
  ILoyaltyValidationState,
  IPassportValidationState,
  IPaymentValidationState,
  IPersonalValidationState,
  IPhoneNumberValidationState,
  ICurrencyValidationState,
  ITravelDocValidationState,
} from '../validations/traveler.types';
import type {
  IAirPref,
  ICardCompanyList,
  IEmailConfiguration,
  IEmergencyContactInfo,
  INationalDoc,
  IPhoneNumber,
  IUserGender,
  IUserSuffix,
  IUserTitle,
} from '../types/traveler';
import { ICabinEnum } from '../types/traveler';
import type { LoyaltyInfo } from '../types/api/v1/obt/common/traveler_personal_info';
import { LoyaltyInfoTypeEnum } from '../types/api/v1/obt/common/traveler_personal_info';
import type { Passport } from '../types/api/v1/obt/common/user';
import { NationalDocType, UserTitleEnum } from '../types/api/v1/obt/common/user';

import { CardCompany } from '../types/api/v1/common/card';
import { GenderEnum } from '../types/api/v1/common/gender';
import type { Name } from '../types/api/v1/common/name';
import { NameSuffix } from '../types/api/v1/common/name';
import type { PostalAddress } from '../types/api/v1/common/postal_address';
import { PreDefinedAnswers } from '../types/api/v1/obt/policy/user_defined_entity';
import { Relation } from '../types/api/v1/obt/common/relative';
import type { PassengerType as PassengerTypeV1 } from '../types/api/v1/obt/air/air_common';
import { AllianceEnum, PassengerTypeEnum } from '../types/api/v1/obt/air/air_common';
import type { Location } from '../types/api/v1/common/location';
import type { AirPref, IKnownTravelerNumber, ILabelValuePair, TravelPref } from '../types';
import {
  AirlinePrefFlightTypeEnum,
  AlliancePrefAlliancesEnum,
  FarePrefFairTypeEnumV2,
  FarePrefFareTypeEnumV1,
  FarePrefFareTypesEnumV2,
  NationalDocTypeEnum,
  SeatAmenityPrefAirSeatAmenityTypeEnumV1,
  SeatAmenityPrefSeatAmenityTypesEnumV2,
  SeatLocationPrefCabinsEnum,
  SeatLocationPrefPositionEnum,
  SeatLocationPrefPositionEnumV2,
  PassengerType,
} from '../types';
import type { CarPref } from '../types/api/v2/obt/model/car-pref';
import type { HotelPref } from '../types/api/v2/obt/model/hotel-pref';
import type { RailPref } from '../types/api/v2/obt/model/rail-pref';
import type { SeatPref } from '../types/api/v2/obt/model/seat-pref';
import type { NotificationPreferences } from '../types/api/v2/obt/model/notification-preferences';
import { NotificationType } from '../types/api/v2/obt/model/notification-type';
import { defineMessage } from '../translations/defineMessage';

export const genderList: IUserGender[] = [
  { value: GenderEnum.MALE, label: 'MALE' },
  { value: GenderEnum.FEMALE, label: 'FEMALE' },
];

export const nonbinaryGenderList: IUserGender[] = [
  { value: GenderEnum.UNSPECIFIED, label: 'UNSPECIFIED' },
  { value: GenderEnum.UNDISCLOSED, label: 'UNDISCLOSED' },
];

export const allGendersList: IUserGender[] = [...genderList, ...nonbinaryGenderList];

export const unknownTitle: IUserTitle = { value: UserTitleEnum.TITLE_UNKNOWN, label: '-' };

export const userTitles: IUserTitle[] = [
  { value: UserTitleEnum.MR, label: defineMessage('Mr.') },
  { value: UserTitleEnum.MRS, label: defineMessage('Mrs.') },
  { value: UserTitleEnum.MS, label: defineMessage('Ms.') },
  { value: UserTitleEnum.MASTER, label: defineMessage('Mstr') },
  { value: UserTitleEnum.MISS, label: defineMessage('Miss') },
  { value: UserTitleEnum.PROFESSOR, label: defineMessage('Prof.') },
  { value: UserTitleEnum.CAPTAIN, label: defineMessage('Cpt.') },
  { value: UserTitleEnum.DR, label: defineMessage('Dr.') },
  { value: UserTitleEnum.REVEREND, label: defineMessage('Rev.') },
  { value: UserTitleEnum.HONOURABLE, label: defineMessage('Hon.') },
  { value: UserTitleEnum.SIR, label: defineMessage('Sir') },
  { value: UserTitleEnum.LADY, label: defineMessage('Lady') },
  { value: UserTitleEnum.AMBASSADOR, label: defineMessage('Amb.') },
  { value: UserTitleEnum.LORD, label: defineMessage('Lord') },
  { value: UserTitleEnum.BRIGADIER, label: defineMessage('Brig.') },
  { value: UserTitleEnum.SENATOR, label: defineMessage('Sen.') },
  { value: UserTitleEnum.DAME, label: defineMessage('Dame') },
  { value: UserTitleEnum.JUSTICE, label: defineMessage('J') },
];

export const nonBinaryUserTitles: IUserTitle[] = [{ value: UserTitleEnum.MX, label: defineMessage('Mx.') }];

export const userSuffixes: IUserSuffix[] = [
  { value: NameSuffix.NAME_SUFFIX_UNKNOWN, label: 'UNKNOWN_TITLE' },
  { value: NameSuffix.SR, label: 'SR_SUFFIX' },
  { value: NameSuffix.JR, label: 'JR_SUFFIX' },
  { value: NameSuffix.MD, label: 'MD_SUFFIX' },
  { value: NameSuffix.PHD, label: 'PHD_SUFFIX' },
  { value: NameSuffix.II, label: 'II_SUFFIX' },
  { value: NameSuffix.III, label: 'III_SUFFIX' },
  { value: NameSuffix.IV, label: 'IV_SUFFIX' },
  { value: NameSuffix.DO, label: 'DO_SUFFIX' },
  { value: NameSuffix.ATTY, label: 'ATTY_SUFFIX' },
];

export const paymentCardCompanyList: ICardCompanyList[] = [
  { value: CardCompany.AIR_TRAVEL_UATP, label: 'Air Travel UATP', dividerColor: '#2CB04A' },
  { value: CardCompany.AMEX, label: 'American Express', dividerColor: '#1552CB' },
  { value: CardCompany.CARTE_BLANCHE, label: 'Carte Blanche', dividerColor: '#3589C5' },
  { value: CardCompany.DINERS_CLUB, label: 'Diners Club', dividerColor: '#0071B3' },
  { value: CardCompany.DISCOVER, label: 'Discover', dividerColor: '#F95B1E' },
  { value: CardCompany.JCB, label: 'JCB', dividerColor: '#9B002B' },
  { value: CardCompany.MASTERCARD, label: 'Mastercard', dividerColor: '#ee0006' },
  { value: CardCompany.VISA, label: 'Visa', dividerColor: '#0055a1' },
];

export const vgsCardTypeMap = {
  visa: CardCompany.VISA,
  dinersclub: CardCompany.DINERS_CLUB,
  jcb: CardCompany.JCB,
  mastercard: CardCompany.MASTERCARD,
  amex: CardCompany.AMEX,
  discover: CardCompany.DISCOVER,
  visaelectron: 'visaelectron',
  maestro: 'maestro',
  dankort: 'dankort',
  unionpay: CardCompany.UNION_PAY,
  forbrugsforeningen: 'forbrugsforeningen',
  elo: 'elo',
};

export const defaultSelectOption = { value: 0, label: 'Select...' };

export const emptyPersonalInfoError: IPersonalValidationState = {
  error: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffixName: '',
    preferredName: '',
    email: '',
    address: '',
  },
  isValid: null,
};

export const emptyName: Name = {
  given: '',
  middle: '',
  family1: '',
  family2: '',
  suffix: NameSuffix.NAME_SUFFIX_UNKNOWN,
  preferred: '',
};

export const emptyPhoneNumber: IPhoneNumber = {
  type: 0,
  isoCountryCode: '',
  countryCode: 0,
  countryCodeSource: 0,
  extension: '',
  italianLeadingZero: false,
  nationalNumber: 0,
  numberOfLeadingZeros: 0,
  preferredDomesticCarrierCode: '',
  rawInput: '',
};

export const emptyTravelerPhoneNumberError: IPhoneNumberValidationState = {
  error: {
    countryCode: '',
    phoneNumber: '',
  },
  isValid: null,
};

export const emptyTravelerAddress: PostalAddress = {
  revision: 0,
  recipients: [],
  regionCode: '',
  languageCode: '',
  postalCode: '',
  sortingCode: '',
  administrativeArea: '',
  locality: '',
  sublocality: '',
  addressLines: [],
  organization: '',
  description: '',
  isDefault: false,
};

export const emptyLocation: Location = {
  name: '',
  stateName: '',
  countryName: '',
  countryCode: '',
  latlong: { latitude: 0, longitude: 0 },
  googlePlaceId: '',
};

export const emptyTravelerAddressError: IAddressValidationState = {
  error: {
    addressName: '',
    addressLine1: '',
    country: '',
    city: '',
    postalcode: '',
    state: '',
  },
  isValid: null,
};

export const emptyTravelerPassport: Passport = {
  type: 1,
  docId: '',
  issuedDate: undefined,
  expiryDate: { iso8601: '' },
  birthDate: { iso8601: '' },
  issueCountry: '',
  nationalityCountry: '',
};

export const emptyTravelerNationalDoc: INationalDoc = {
  docId: '',
  issuedDate: undefined,
  expiryDate: undefined,
  issueCountry: '',
  type: NationalDocType.TYPE_UNKNOWN,
};

export const emptyKTNResdressData: IKnownTravelerNumber = {
  number: '',
  issueCountry: '',
};

export const emptyTravelerPassportError: IPassportValidationState = {
  error: {
    passportId: '',
    issueCountry: '',
    nationalityCountry: '',
    expiryDate: '',
  },
  isValid: null,
};

export const emptyTravelDocError: ITravelDocValidationState = {
  error: {
    docId: '',
    issueCountry: '',
    nationalityCountry: '',
    expiryDate: '',
    type: '',
    consent: '',
  },
  isValid: null,
};

export const emptyTravelerLoyalty: LoyaltyInfo = {
  id: '',
  appliedTo: [],
  issuedBy: '',
  type: LoyaltyInfoTypeEnum.UNKNOWN_TYPE,
};

export const emptyTravelerLoyaltyError: ILoyaltyValidationState = {
  error: {
    program: '',
    cardNumber: '',
  },
  isValid: null,
};

export const emptyPaymentDataError: IPaymentValidationState = {
  error: {
    name: '',
    number: '',
    expiryDate: '',
    cvv: '',
    applicableTo: '',
    ownershipLabel: '',
  },
  isValid: null,
};

export const emptyCardLabelError: ILabelValidationState = {
  error: null,
  isValid: null,
};

export const emptyCurrencyError: ICurrencyValidationState = {
  error: undefined,
  isValid: true,
};

export const emptyEmergencyContact: IEmergencyContactInfo = {
  name: { ...emptyName },
  email: '',
  designation: '',
  phoneNumber: { ...emptyPhoneNumber },
};

export const emptyEmergencyContactErrorState: IEmergencyContactValidationState = {
  error: {
    firstName: '',
    lastName: '',
    designation: '',
    email: '',
  },
  isValid: null,
};

export const emptySeatLocation = {
  position: SeatLocationPrefPositionEnumV1.AISLE_OR_WINDOW,
  maxFlightDurationInHours: 3,
  isBulkHeadPref: false,
  cabins: [],
};

export const emptyTravelerPreferencesAir: IAirPref = {
  homeAirport: '',
  airlinePrefs: [{ flightType: AirlinePrefFlightTypeEnumV1.ALL, airlines: [] }],
  farePref: { fareTypes: [] },
  seatLocationPrefs: [
    { ...emptySeatLocation, maxFlightDurationInHours: 3 },
    { ...emptySeatLocation, maxFlightDurationInHours: 6 },
    { ...emptySeatLocation, maxFlightDurationInHours: 24 },
  ],
  seatAmenityPref: { seatAmenityTypes: [] },
  alliancePref: { alliances: [] },
};

const emptySeatLocationV2 = {
  position: SeatLocationPrefPositionEnum.AisleOrWindow,
  maxFlightDurationInHours: 3,
  isBulkHeadPref: false,
  cabins: [],
};

export const emptyTravelerPreferencesAirV2: AirPref = {
  airlinePrefs: [{ flightType: AirlinePrefFlightTypeEnum.All, airlines: [] }],
  alliancePref: { alliances: [] },
  farePref: { fareTypes: [], fairType: undefined },
  homeAirport: '',
  seatLocationPrefs: [
    { ...emptySeatLocationV2, maxFlightDurationInHours: 3 },
    { ...emptySeatLocationV2, maxFlightDurationInHours: 6 },
    { ...emptySeatLocationV2, maxFlightDurationInHours: 24 },
  ],
  numStopPref: {
    numOfStops: -1,
  },
  seatAmenityPref: { seatAmenityTypes: [] },
  preferredAirports: [],
  mealPref: {
    exclMealPrefs: [],
    inclMealPrefs: [],
  },
};

export const emptyRailSeatPref: SeatPref = {
  hasAccessibility: false,
  seatTypes: [],
  seatLocations: [],
  deckLevels: [],
  seatDirections: [],
};

export const emptyTravelerPreferencesRail: RailPref = {
  preferredRailStations: [],
  seatPreference: emptyRailSeatPref,
  travelClasses: [],
  coachPreferences: [],
  conditionalRates: [],
};

export const emptyTravelerPreferencesHotel: HotelPref = {
  hotelParentChains: [],
  hotelBrands: [],
  hotelAmenityTypes: [],
  conditionalRates: [],
  roomPreference: {
    isMobilityAccessible: false,
    bedCount: undefined,
    roomType: undefined,
    mostImportantFact: undefined,
    roomLocation: undefined,
    pillowType: undefined,
    roomAmenityPrefs: [],
  },
};

export const emptyTravelerPreferencesCar: CarPref = {
  vendors: [],
  carTypes: [],
  engineTypes: [],
  transmissionTypes: [],
  conditionalRates: [],
};

export const emptyTravelerPreferences: TravelPref = {
  airPref: emptyTravelerPreferencesAirV2,
  hotelPref: emptyTravelerPreferencesHotel,
  carPref: emptyTravelerPreferencesCar,
  railPref: emptyTravelerPreferencesRail,
  railCards: [],
};

export const emptyNotificationPreferences: NotificationPreferences = {
  preferences: [
    {
      emailPreference: {
        ccEmails: [],
        enabled: false,
      },
      notificationType: NotificationType.BookingNotification,
    },
    {
      emailPreference: {
        ccEmails: [],
        enabled: false,
      },
      notificationType: NotificationType.FlightNotification,
    },
  ],
};

export const allianceV1ToV2 = {
  [AllianceEnum.ONEWORLD]: AlliancePrefAlliancesEnum.Oneworld,
  [AllianceEnum.SKYTEAM]: AlliancePrefAlliancesEnum.Skyteam,
  [AllianceEnum.STAR_ALLIANCE]: AlliancePrefAlliancesEnum.StarAlliance,
} as Record<AllianceEnum, AlliancePrefAlliancesEnum>;

export const allianceV2ToV1 = Object.fromEntries(
  (Object.keys(allianceV1ToV2) as unknown as (keyof typeof allianceV1ToV2)[]).map((x) => [
    allianceV1ToV2[x],
    Number(x) as AllianceEnum,
  ]),
) as Record<AlliancePrefAlliancesEnum, AllianceEnum>;

type CabinV1 = (typeof ICabinEnum)[keyof typeof ICabinEnum];

export const seatLocationPrefCabinsV1ToV2 = {
  [ICabinEnum.BUSINESS]: SeatLocationPrefCabinsEnum.Business,
  [ICabinEnum.ECONOMY]: SeatLocationPrefCabinsEnum.Economy,
  [ICabinEnum.FIRST]: SeatLocationPrefCabinsEnum.First,
  [ICabinEnum.PREMIUM_ECONOMY]: SeatLocationPrefCabinsEnum.PremiumEconomy,
  [ICabinEnum.UNKNOWN_CABIN]: SeatLocationPrefCabinsEnum.UnknownCabin,
  [ICabinEnum.UNRECOGNIZED]: SeatLocationPrefCabinsEnum.Unrecognized,
} as Record<CabinV1, SeatLocationPrefCabinsEnum>;

export const seatLocationPrefCabinsV2ToV1 = Object.fromEntries(
  (Object.keys(seatLocationPrefCabinsV1ToV2) as unknown as (keyof typeof seatLocationPrefCabinsV1ToV2)[]).map((x) => [
    seatLocationPrefCabinsV1ToV2[x],
    Number(x) as CabinV1,
  ]),
) as Record<SeatLocationPrefCabinsEnum, CabinV1>;

export const seatLocationPrefPositionV1ToV2 = {
  [SeatLocationPrefPositionEnumV1.AISLE]: SeatLocationPrefPositionEnumV2.Aisle,
  [SeatLocationPrefPositionEnumV1.AISLE_OR_WINDOW]: SeatLocationPrefPositionEnumV2.AisleOrWindow,
  [SeatLocationPrefPositionEnumV1.WINDOW]: SeatLocationPrefPositionEnumV2.Window,
  [SeatLocationPrefPositionEnumV1.UNKNOWN_POSITION]: SeatLocationPrefPositionEnumV2.UnknownPosition,
  [SeatLocationPrefPositionEnumV1.UNRECOGNIZED]: SeatLocationPrefPositionEnumV2.Unrecognized,
} as Record<SeatLocationPrefPositionEnumV1, SeatLocationPrefPositionEnumV2>;

export const seatLocationPrefPositionV2ToV1 = Object.fromEntries(
  (Object.keys(seatLocationPrefPositionV1ToV2) as unknown as (keyof typeof seatLocationPrefPositionV1ToV2)[]).map(
    (x) => [seatLocationPrefPositionV1ToV2[x], Number(x) as SeatLocationPrefPositionEnumV1],
  ),
) as Record<SeatLocationPrefPositionEnumV2, SeatLocationPrefPositionEnumV1>;

export const seatAmenityPrefAirSeatAmenityTypeV1ToV2 = {
  [SeatAmenityPrefAirSeatAmenityTypeEnumV1.FLAT_BED]: SeatAmenityPrefSeatAmenityTypesEnumV2.FlatBed,
  [SeatAmenityPrefAirSeatAmenityTypeEnumV1.IN_SEAT_POWER]: SeatAmenityPrefSeatAmenityTypesEnumV2.InSeatPower,
  [SeatAmenityPrefAirSeatAmenityTypeEnumV1.WIFI]: SeatAmenityPrefSeatAmenityTypesEnumV2.Wifi,
  [SeatAmenityPrefAirSeatAmenityTypeEnumV1.UNRECOGNIZED]: SeatAmenityPrefSeatAmenityTypesEnumV2.Unrecognized,
  [SeatAmenityPrefAirSeatAmenityTypeEnumV1.UNKNOWN_AIR_SEAT_AMENITY_TYPE]:
    SeatAmenityPrefSeatAmenityTypesEnumV2.UnknownAirSeatAmenityType,
} as Record<SeatAmenityPrefAirSeatAmenityTypeEnumV1, SeatAmenityPrefSeatAmenityTypesEnumV2>;

export const seatAmenityPrefAirSeatAmenityTypeV2ToV1 = Object.fromEntries(
  (
    Object.keys(
      seatAmenityPrefAirSeatAmenityTypeV1ToV2,
    ) as unknown as (keyof typeof seatAmenityPrefAirSeatAmenityTypeV1ToV2)[]
  ).map((x) => [seatAmenityPrefAirSeatAmenityTypeV1ToV2[x], Number(x) as SeatAmenityPrefAirSeatAmenityTypeEnumV1]),
) as Record<SeatAmenityPrefSeatAmenityTypesEnumV2, SeatAmenityPrefAirSeatAmenityTypeEnumV1>;

export const farePreferenceV1ToV2 = {
  [FarePrefFareTypeEnumV1.CHANGEABLE]: FarePrefFareTypesEnumV2.Changeable,
  [FarePrefFareTypeEnumV1.CHEAPEST]: FarePrefFareTypesEnumV2.Cheapest,
  [FarePrefFareTypeEnumV1.EDITABLE]: FarePrefFareTypesEnumV2.Editable,
  [FarePrefFareTypeEnumV1.FULLY_REFUNDABLE]: FarePrefFareTypesEnumV2.FullyRefundable,
  [FarePrefFareTypeEnumV1.REFUNDABLE]: FarePrefFareTypesEnumV2.Refundable,
  [FarePrefFareTypeEnumV1.UNKNOWN_TYPE]: FarePrefFareTypesEnumV2.UnknownType,
  [FarePrefFareTypeEnumV1.UNRECOGNIZED]: FarePrefFareTypesEnumV2.Unrecognized,
} as Record<FarePrefFareTypeEnumV1, FarePrefFareTypesEnumV2>;

export const fairPreferenceV1ToV2 = {
  [FarePrefFareTypeEnum.CHANGEABLE]: FarePrefFairTypeEnumV2.Changeable,
  [FarePrefFareTypeEnum.CHEAPEST]: FarePrefFairTypeEnumV2.Cheapest,
  [FarePrefFareTypeEnum.EDITABLE]: FarePrefFairTypeEnumV2.Editable,
  [FarePrefFareTypeEnum.FULLY_REFUNDABLE]: FarePrefFairTypeEnumV2.FullyRefundable,
  [FarePrefFareTypeEnum.REFUNDABLE]: FarePrefFairTypeEnumV2.Refundable,
  [FarePrefFareTypeEnum.UNKNOWN_TYPE]: FarePrefFairTypeEnumV2.UnknownType,
  [FarePrefFareTypeEnum.UNRECOGNIZED]: FarePrefFairTypeEnumV2.Unrecognized,
} as Record<FarePrefFareTypeEnum, FarePrefFairTypeEnumV2>;

export const farePreferenceV2ToV1 = Object.fromEntries(
  (Object.keys(farePreferenceV1ToV2) as unknown as (keyof typeof farePreferenceV1ToV2)[]).map((x) => [
    farePreferenceV1ToV2[x],
    Number(x) as FarePrefFareTypeEnumV1,
  ]),
) as Record<FarePrefFareTypesEnumV2, FarePrefFareTypeEnumV1>;

export const emptyAlertsPreferences: IEmailConfiguration = {
  confirmationEmailsPreference: {
    ccEmails: [],
    emailsDisabled: true,
  },
  flightStatsEmailsPreference: { ccEmails: [], emailsDisabled: true },
};

export const ifEmptyObjectAlertsPreferences: IEmailConfiguration = {
  confirmationEmailsPreference: {
    ccEmails: [],
    emailsDisabled: false,
  },
  flightStatsEmailsPreference: { ccEmails: [], emailsDisabled: false },
};

export const travelerSearchTranslationKeys = {
  SEARCH_BY_EMAIL: 'SEARCH_BY_EMAIL',
  SEARCH_BY_NAME: 'SEARCH_BY_NAME',
  FIRST_NAME: 'FIRST_NAME',
  MIDDLE_NAME: 'MIDDLE_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  NO_PROFILE: 'NO_PROFILE',
  SELECT_TRAVELER: 'SELECT_TRAVELER',
};

export const travelerCheckoutTranslationKeys = {
  PERSONAL_INFO_TITLE: 'PERSONAL_INFO_TITLE',
  LEGAL_NAME: 'LEGAL_NAME',
  KNOWN_TRAVELER_NUM: 'KNOWN_TRAVELER_NUM',
  REDRESS_NUM: 'REDRESS_NUM',
  ADD_LOYALTY: 'ADD_LOYALTY',
  LOYALTY_INFO_TITLE: 'LOYALTY_INFO_TITLE',
  LOYALTY_PROGRAM_AVAILABLE: 'LOYALTY_PROGRAM_AVAILABLE',
  LOYALTY_PROGRAM_NOT_AVAILABLE: 'LOYALTY_PROGRAM_NOT_AVAILABLE',
  PAYMENT_DETAILS_TITLE: 'PAYMENT_DETAILS_TITLE',
  USING_CREDIT_CARD: 'USING_CREDIT_CARD',
  COMPLETE_PROFILE_INFORMATION: 'COMPLETE_PROFILE_INFORMATION',
  DOES_NOT_QUALIFY_FOR_LOYALTY: 'DOES_NOT_QUALIFY_FOR_LOYALTY',
};

export const travelerTabsTranslationKeys = {
  PROFILE: 'PROFILE',
  TRAVELER: 'TRAVELER',
  PERSONAL: 'PERSONAL',
  SAVING: 'SAVING',
  WORK: 'WORK',
  TRAVEL_PREFERENCES: 'TRAVEL_PREFERENCES',
  LOYALTY: 'LOYALTY',
  PAYMENT: 'PAYMENT',
  EDIT: 'EDIT',
  CANCEL: 'CANCEL',
  SAVE: 'SAVE',
  DONE: 'DONE',
  LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
};

export const travelerPersonalTranslationKeys = {
  CHANGE_TRAVELER: 'CHANGE_TRAVELER',
  BASIC_HEADER: 'BASIC_HEADER',
  TITLE: 'TITLE',
  FIRST: 'FIRST',
  MIDDLE: 'MIDDLE',
  LAST: 'LAST',
  SUFFIX: 'SUFFIX',
  BIO_TITLE: 'BIO_TITLE',
  GENDER: 'GENDER',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  UNSPECIFIED: 'UNSPECIFIED',
  UNDISCLOSED: 'UNDISCLOSED',
  UNKNOWN_TITLE: 'UNKNOWN_TITLE',
  MR_TITLE: 'MR_TITLE',
  MISS_TITLE: 'MISS_TITLE',
  MASTER_TITLE: 'MASTER_TITLE',
  MRS_TITLE: 'MRS_TITLE',
  MS_TITLE: 'MS_TITLE',
  UNKNOWN_SUFIX: 'UNKNOWN_SUFIX',
  SR_SUFFIX: 'SR_SUFFIX',
  JR_SUFFIX: 'JR_SUFFIX',
  ATTY_SUFFIX: 'ATTY_SUFFIX',
  II_SUFFIX: 'II_SUFFIX',
  III_SUFFIX: 'III_SUFFIX',
  IV_SUFFIX: 'IV_SUFFIX',
  MD_SUFFIX: 'MD_SUFFIX',
  PHD_SUFFIX: 'PHD_SUFFIX',
  DO_SUFFIX: 'DO_SUFFIX',
  DOB: 'DOB',
  CONTACT_INFO_TITLE: 'CONTACT_INFO_TITLE',
  PHONE_NUMBER_MODAL_TITLE: 'PHONE_NUMBER_MODAL_TITLE',
  MOBILE: 'MOBILE',
  PHONE: 'PHONE',
  PHONE_COUNTRY_CODE: 'PHONE_COUNTRY_CODE',
  PERSONAL_EMAIL: 'PERSONAL_EMAIL',
  ADDRESS_TITLE_MODAL: 'ADDRESS_TITLE_MODAL',
  ADDRESS_TITLE: 'ADDRESS_TITLE',
  ADDRESS_NAME: 'ADDRESS_NAME',
  ADDRESS_LINE1: 'ADDRESS_LINE1',
  ADDRESS_LINE2: 'ADDRESS_LINE2',
  EMAIL_ADDRESSES: 'EMAIL_ADDRESSES',
  ARRANGER_SETTINGS_TITLE: 'ARRANGER_SETTINGS_TITLE',
  DEFAULT_ADDRESS: 'DEFAULT_ADDRESS',
  COUNTRY: 'COUNTRY',
  CITY: 'CITY',
  STATE: 'STATE',
  POSTALCODE: 'POSTALCODE',
  TRAVEL_ID_TITLE: 'TRAVEL_ID_TITLE',
  PASSPORT: 'PASSPORT',
  PASSPORT_ID: 'PASSPORT_ID',
  PASSPORT_NATIONALITY_COUNTRY: 'PASSPORT_NATIONALITY_COUNTRY',
  PASSPORT_BIRTH_DATE: 'PASSPORT_BIRTH_DATE',
  PASSPORT_ISSUE_DATE: 'PASSPORT_ISSUE_DATE',
  ISSUE_DATE_NOT_AVAILABLE: 'ISSUE_DATE_NOT_AVAILABLE',
  PASSPORT_ISSUE_COUNTRY: 'PASSPORT_ISSUE_COUNTRY',
  PASSPORT_EXPIRY_DATE: 'PASSPORT_EXPIRY_DATE',
  KNOWN_TRAVELER_NUM: 'KNOWN_TRAVELER_NUM',
  REDRESS_NUM: 'REDRESS_NUM',
  EMPTY_PERSONAL_INFORMATION: 'EMPTY_PERSONAL_INFORMATION',
  EMERGENCY_CONTACT_MODAL_TITLE: 'EMERGENCY_CONTACT_MODAL_TITLE',
  EMERGENCY_CONTACT_TITLE: 'EMERGENCY_CONTACT_TITLE',
  EMERGENCY_CONTACT_PHONE: 'EMERGENCY_CONTACT_PHONE',
  EMERGENCY_CONTACT_EMAIL: 'EMERGENCY_CONTACT_EMAIL',
  EMERGENCY_CONTACT_DESIGNATION: 'EMERGENCY_CONTACT_DESIGNATION',
  SAVED: 'SAVED',
  ADD_PROFILE_PHOTO: 'ADD_PROFILE_PHOTO',
  CHANGE_PROFILE_PHOTO: 'CHANGE_PROFILE_PHOTO',
  UPLOAD_PROFILE_PHOTO: 'UPLOAD_PROFILE_PHOTO',
  UPLOAD_NEW_PHOTO: 'UPLOAD_NEW_PHOTO',
  SAVE_PHOTO: 'SAVE_PHOTO',
  REMOVE_PHOTO: 'REMOVE_PHOTO',
  PROFILE_PICTURE_MODAL_MESSAGE: 'PROFILE_PICTURE_MODAL_MESSAGE',
  FIRST_NAME: 'FIRST_NAME',
  MIDDLE_NAME: 'MIDDLE_NAME',
  LAST_NAME: 'LAST_NAME',
  WORK_EMAIL: 'WORK_EMAIL',
  MOBILE_PHONE_NUMBER: 'MOBILE_PHONE_NUMBER',
  COUNTRY_CODE: 'COUNTRY_CODE',
  PHONE_NUMBER: 'PHONE_NUMBER',
  MOBILE_PHONE_NUMBERS: 'MOBILE_PHONE_NUMBERS',
  ADD_PHONE_NUMBER: 'ADD_PHONE_NUMBER',
  DELETE_PHONE_NUMBER: 'DELETE_PHONE_NUMBER',
  DELETE_PHONE_NUMBER_CONFIRMATION: 'DELETE_PHONE_NUMBER_CONFIRMATION',
  DELETE_PASSPORT: 'DELETE_PASSPORT',
  DELETE_PASSPORT_CONFIRMATION: 'DELETE_PASSPORT_CONFIRMATION',
  LOYALTY_PROGRAMS: 'LOYALTY_PROGRAMS',
  ADD_LOYALTY_PROGRAM: 'ADD_LOYALTY_PROGRAM',
  DELETE_LOYALTY_PROGRAM: 'DELETE_LOYALTY_PROGRAM',
  DELETE_LOYALTY_PROGRAM_CONFIRMATION: 'DELETE_LOYALTY_PROGRAM_CONFIRMATION',
  SAVE_PHONE_NUMBER: 'SAVE_PHONE_NUMBER',
  SELECT_DOT: 'SELECT_DOT',
  ADD_PASSPORT: 'ADD_PASSPORT',
  YOU_HAVE_ZERO_PASSPORTS_ADDED: 'YOU_HAVE_ZERO_PASSPORTS_ADDED',
  YOU_HAVE_ZERO_LOYALTY_PROGRAMS_ADDED: 'YOU_HAVE_ZERO_LOYALTY_PROGRAMS_ADDED',
  AIR_LOYALTY_PROGRAMS_TAB: 'AIR_LOYALTY_PROGRAMS_TAB',
  HOTEL_LOYALTY_PROGRAMS_TAB: 'HOTEL_LOYALTY_PROGRAMS_TAB',
  CAR_LOYALTY_PROGRAMS_TAB: 'CAR_LOYALTY_PROGRAMS_TAB',
  PASSPORT_ISSUING_COUNTRY: 'PASSPORT_ISSUING_COUNTRY',
  NATIONALITY_ISSUING_COUNTRY: 'NATIONALITY_ISSUING_COUNTRY',
  PASSPORT_NUMBER: 'PASSPORT_NUMBER',
  ISSUE_DATE: 'ISSUE_DATE',
  EXPIRATION_DATE: 'EXPIRATION_DATE',
  SELECT_DATE: 'SELECT_DATE',
  PERSONAL_INFO_ID_TIP: 'PERSONAL_INFO_ID_TIP',
  AWARD_POINTS_INFO: 'AWARD_POINTS_INFO',
  LOYALTY_APPLICABILITY_INFO: 'LOYALTY_APPLICABILITY_INFO',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  CONFIRMATION_EMAILS: 'CONFIRMATION_EMAILS',
  FLIGHT_NOTIFICATIONS: 'FLIGHT_NOTIFICATIONS',
  ARRANGER_SETTINGS_TABLE_TITLE: 'ARRANGER_SETTINGS_TABLE_TITLE',
  ARRANGER_SETTINGS_TABLE_MESSAGE: 'ARRANGER_SETTINGS_TABLE_MESSAGE',
  SELECT_ALL_LEGAL_ENTITIES: 'SELECT_ALL_LEGAL_ENTITIES',
  CREDIT_CARD_LEGAL_ENTITY_HELP: 'CREDIT_CARD_LEGAL_ENTITY_HELP',
  ADD_REDRESS_NUMBER: 'ADD_REDRESS_NUMBER',
  EDIT_REDRESS_NUMBER: 'EDIT_REDRESS_NUMBER',
  ADD_KNOWN_TRAVELER_NUMBER: 'ADD_KNOWN_TRAVELER_NUMBER',
  EDIT_KNOWN_TRAVELER_NUMBER: 'EDIT_KNOWN_TRAVELER_NUMBER',
  TSA_NUMBER: 'TSA_NUMBER',
};

export const travelerWorkTranslationKeys = {
  OFFICE: 'OFFICE',
  ORGANIZATION: 'ORGANIZATION',
  POSITION: 'POSITION',
  WORK_CONTACTS: 'WORK_CONTACTS',
  WORK_PHONE: 'WORK_PHONE',
  NO_WORK_NUMBERS: 'NO_WORK_NUMBERS',
  WORK_EMAIL: 'WORK_EMAIL',
  WORK_ADDRESS: 'WORK_ADDRESS',
};

export const travelerPaymentMethodsTranslationKeys = {
  BILLING_ADDRESS_TITLE: 'BILLING_ADDRESS_TITLE',
  PAYMENT_METHODS_TITLE: 'PAYMENT_METHODS_TITLE',
  CARD_INFO_TITLE: 'CARD_INFO_TITLE',
  CARD_TITLE: 'CARD_TITLE',
  CARD_NAME: 'CARD_NAME',
  CARD_LABEL: 'CARD_LABEL',
  CARD_COMPANY_NAME: 'CARD_COMPANY_NAME',
  EXPIRY_DATE: 'EXPIRY_DATE',
  CARD_CVV: 'CARD_CVV',
  APPLICABLE_TO: 'APPLICABLE_TO',
  AIR: 'AIR',
  FLIGHTS: 'FLIGHTS',
  HOTELS: 'HOTELS',
  CARS: 'CARS',
  RAIL: 'RAIL',
  RAILS: 'RAILS',
  NO_SAVED_CARDS: 'NO_SAVED_CARDS',
  DELETE_CONFIRMATION_HEADER: 'DELETE_CONFIRMATION_HEADER',
  DELETE_CONFIRMATION_LABEL: 'DELETE_CONFIRMATION_LABEL',
  UNUSED_CREDITS_TITLE: 'UNUSED_CREDITS_TITLE',
  AIRLINE: 'AIRLINE',
  EXPIRE: 'EXPIRE',
  NO_EXPIRY_DATE: 'NO_EXPIRY_DATE',
  CREDITS: 'CREDITS',
  PNR: 'PNR',
  NO_UNUSED_CREDITS: 'NO_UNUSED_CREDITS',
  BILLING_INFORMATION: 'BILLING_INFORMATION',
  UNMASK_CONFIRMATION_HEADER: 'UNMASK_CONFIRMATION_HEADER',
  UNMASK_CONFIRMATION_LABEL: 'UNMASK_CONFIRMATION_LABEL',
  REASON_FOR_REQUESTING: 'REASON_FOR_REQUESTING',
  UNMASK_INFO_LABEL: 'UNMASK_INFO_LABEL',
  UNMASK: 'UNMASK',
  UNMASKING: 'UNMASKING',
};

export const travelerLoyaltyTranslationKeys = {
  FREQUENT_FLYER: 'FREQUENT_FLYER',
  HOTEL_PROGRAMS: 'HOTEL_PROGRAMS',
  CAR_PROGRAMS: 'CAR_PROGRAMS',
  LOYALTY_ID: 'LOYALTY_ID',
  FREQUENT_FLYER_TITLE: 'FREQUENT_FLYER_TITLE',
  LOYALTY_CARD_TITLE: 'LOYALTY_CARD_TITLE',
  LOYALTY_PROGRAM: 'LOYALTY_PROGRAM',
  EMPTY_HOTEL_LOYALTY: 'EMPTY_LOYALTY',
  EMPTY_FREQUENT_FLYER: 'EMPTY_LOYALTY',
  EMPTY_CAR_LOYALTY: 'EMPTY_LOYALTY',
  ADD_CUSTOM_LOYALTY: 'ADD_CUSTOM_LOYALTY',
  DELETE_CONFIRMATION_HEADER: 'DELETE_CONFIRMATION_HEADER',
  DELETE_CONFIRMATION_LABEL: 'DELETE_CONFIRMATION_LABEL',
  ADD_LOYALTY_PROGRAM: 'ADD_LOYALTY_PROGRAM',
  CHOOSE_LOYALTY_PROGRAM: 'CHOOSE_LOYALTY_PROGRAM',
  TYPE_LOYALTY_PROGRAM: 'TYPE_LOYALTY_PROGRAM',
  TYPE_PROGRAM_NUMBER: 'TYPE_PROGRAM_NUMBER',
  PROGRAM_NUMBER: 'PROGRAM_NUMBER',
  APPLY: 'APPLY',
  SELECT_LOYALTY: 'SELECT_LOYALTY',
};

export const travelerPreferencesTranslationKeys = {
  FLIGHT_PREFERENCES: 'FLIGHT_PREFERENCES',
  DEFAULT_DEPARTURE: 'DEFAULT_DEPARTURE',
  PREFERED_NUMBER_STOPS: 'PREFERED_NUMBER_STOPS',
  PREFERED_ALLIANCE: 'PREFERED_ALLIANCE',
  PREFERED_AIRLINES: 'PREFERED_AIRLINES',
  SEARCH_AIRLINE: 'SEARCH_AIRLINE',
  NO_AIRLINES: 'NO_AIRLINES',
  CABIN_SEAT: 'CABIN_SEAT',
  UP_TO: 'UP_TO',
  OVER: 'OVER',
  FIRST_CLASS: 'FIRST_CLASS',
  BUSINESS: 'BUSINESS',
  PREMIUM_ECONOMY: 'PREMIUM_ECONOMY',
  ECONOMY: 'ECONOMY',
  AISLE: 'AISLE',
  WINDOW: 'WINDOW',
  PREF_BULKHEAD_SEATS: 'PREF_BULKHEAD_SEATS',
  SEAT_AMENITIES: 'SEAT_AMENITIES',
  MEALS: 'MEALS',
  PREFERRED_FOOD: 'PREFERRED_FOOD',
  PREFERRED_FOOD_LABEL: 'PREFERRED_FOOD_LABEL',
  SPECIAL_REQUEST: 'SPECIAL_REQUEST',
  SPECIAL_REQUEST_LABEL: 'SPECIAL_REQUEST_LABEL',
  FARE_ATTRIBUTES: 'FARE_ATTRIBUTES',
  SAVED: 'SAVED',
  BOOKING_ALERT_PREFERENCES: 'BOOKING_ALERT_PREFERENCES',
  ALERT_PREFERENCES_NOTE: 'ALERT_PREFERENCES_NOTE',
  ALERT_CONFIRMATION_EMAILS: 'ALERT_CONFIRMATION_EMAILS',
  CUSTOM_EMAILS: 'CUSTOM_EMAILS',
  ADD_EMAIL: 'ADD_EMAIL',
  ALERT_FLIGHT_NOTIFICATIONS: 'ALERT_FLIGHT_NOTIFICATIONS',
};

export const travelerBookingTranslationKeys = {
  NEW_BOOKING: 'NEW_BOOKING',
  ALL_BOOKINGS: 'ALL_BOOKINGS',
  NO_BOOKINGS: 'NO_BOOKINGS',
  PNR_NO: 'PNR_NO',
  STATUS: 'STATUS',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  CREATED_ON: 'CREATED_ON',
  EMPTY_PNR: 'EMPTY_PNR',
};

export const travelerModalTranslationKeys = {
  REQUIRED_FIELDS: 'REQUIRED_FIELDS',
  ADD: 'ADD',
  EDIT: 'EDIT',
  DELETE: 'DELETE',
  SAVE: 'SAVE',
  UPDATE: 'UPDATE',
  SET_DEFAULT: 'SET_DEFAULT',
  SAVING: 'SAVING',
  DELETING: 'DELETING',
  UPDATING: 'UPDATING',
  UNMASKCARD: 'UNMASKCARD',
  INFO: 'INFO',
  REGISTER_A_NEW_TRAVELER: 'REGISTER_A_NEW_TRAVELER',
};

export const unmaskReasonsTranslationKeys = {
  PURCHASE_A_FLIGHT: 'PURCHASE_A_FLIGHT',
  PURCHASE_BAGS_AIR_EXTRAS: 'PURCHASE_BAGS_AIR_EXTRAS',
  FLIGHT_EXCHANGE: 'FLIGHT_EXCHANGE',
  HOTEL_GUARANTEE: 'HOTEL_GUARANTEE',
  OTHER: 'OTHER',
} as const;

export const virtualCardUnmaskReasonsTranslationKeys = {
  SUPPLIER_REQUEST: 'SUPPLIER_REQUEST',
  MODIFICATION: 'MODIFICATION',
  ISSUE_WITH_CONFERMA: 'ISSUE_WITH_CONFERMA',
  ISSUE_WITH_OBT: 'ISSUE_WITH_OBT',
  OTHER: 'OTHER',
} as const;

export const FARE_JUSTIFICATION_OPTIONS_TRANSLATION_KEYS_MAPPING: { [key in PreDefinedAnswers]?: string } = {
  [PreDefinedAnswers.TIMING_OR_SCHEDULING]: 'TIMING_OR_SCHEDULING',
  [PreDefinedAnswers.AIRLINE_PREFERENCE]: 'AIRLINE_PREFERENCE',
  [PreDefinedAnswers.AIRPORT_PREFERENCE]: 'AIRPORT_PREFERENCE',
  [PreDefinedAnswers.MILEAGE_OR_PROGRAM_UPGRADE]: 'MILEAGE_OR_PROGRAM_UPGRADE',
  [PreDefinedAnswers.OTHER]: 'OTHER',
};

export enum CardRevealStatus {
  HIDDEN = 'HIDDEN',
  REVEALING = 'REVEALING',
  NETWORK_ERROR = 'NETWORK_ERROR',
  REVEAL_SUCCESS = 'REVEAL_SUCCESS',
  REVEAL_FAIL = 'REVEAL_FAIL',
}

export const MAX_LENGTH_PASSPORT_NUMBER = 25;
export const MAX_LENGTH_NATIONAL_ID_NUMBER = 29;

export const MAX_LENGTH_KNOWN_TRAVELER_NUMBER = 11;

export const MAX_LENGTH_REDRESS_NUMBER = 7;

export const MAX_LENGTH_NAME = {
  FIRST: 64,
  MIDDLE: 64,
  LAST: 64,
  SUFFIX: 9,
  PREFERRED: 64,
};

export const mapRelationToPassengerType: Record<Relation, PassengerTypeEnum> = {
  [Relation.CHILD]: PassengerTypeEnum.CHILD,
  [Relation.FRIEND]: PassengerTypeEnum.ADULT,
  [Relation.OTHER]: PassengerTypeEnum.ADULT,
  [Relation.PARENT]: PassengerTypeEnum.ADULT,
  [Relation.SPOUSE]: PassengerTypeEnum.ADULT,
  [Relation.UNKNOWN_RELATION]: PassengerTypeEnum.UNRECOGNIZED,
  [Relation.UNRECOGNIZED]: PassengerTypeEnum.UNRECOGNIZED,
};

export const aAdvantageProgramTermsAndConditions =
  'https://www.aa.com/i18n/aadvantage-program/aadvantage-terms-and-conditions.jsp';

export const aAdvantageProgramPolicy = 'https://www.aa.com/i18n/customer-service/support/privacy-policy.jsp';

export const TRAVELER_PREBOOK_QUESTIONS_KEY_ID = 'pre-book-questions';

export const nationalIDTypesV1toV2: Record<NationalDocType, NationalDocTypeEnum | null> = {
  [NationalDocType.TYPE_UNKNOWN]: null,
  [NationalDocType.DNI]: NationalDocTypeEnum.Dni,
  [NationalDocType.NIE]: NationalDocTypeEnum.Nie,
  [NationalDocType.UNRECOGNIZED]: null,
};

export const nationalIDTypesV2toV1: Record<NationalDocTypeEnum, NationalDocType> = {
  [NationalDocTypeEnum.Dni]: NationalDocType.DNI,
  [NationalDocTypeEnum.Nie]: NationalDocType.NIE,
};

interface ILabelValueCountryPair extends ILabelValuePair {
  countryCode: string;
}

export const nationalIDTypeOptions: ILabelValueCountryPair[] = [
  { value: NationalDocTypeEnum.Dni, label: defineMessage('DNI (Documento nacional de identidad)'), countryCode: 'ES' },
  {
    value: NationalDocTypeEnum.Nie,
    label: defineMessage('NIE (Número de identificación de extranjero)'),
    countryCode: 'ES',
  },
];

export const CountriesWithNationalID = ['ES'];

export const PassengerTypeMapV1toV2: Record<PassengerTypeV1, PassengerType> = {
  [PassengerTypeEnum.UNKNOWN_PASSENGER_TYPE]: PassengerType.UnknownPassengerType,
  [PassengerTypeEnum.ADULT]: PassengerType.Adult,
  [PassengerTypeEnum.CHILD]: PassengerType.Child,
  [PassengerTypeEnum.INFANT]: PassengerType.Infant,
  [PassengerTypeEnum.INFANT_ON_LAP]: PassengerType.InfantOnLap,
  [PassengerTypeEnum.YOUTH]: PassengerType.Youth,
  [PassengerTypeEnum.SENIOR]: PassengerType.Senior,
  [PassengerTypeEnum.UNRECOGNIZED]: PassengerType.UnknownPassengerType,
};
