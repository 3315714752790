import { Auth } from '@aws-amplify/auth';
import Config from '../Config';

export const e2eHeader = async () => {
  if (!Config.VITE_IS_E2E_TEST_RUN) {
    return {};
  }

  const userId = await Auth.currentUserInfo();
  const e2eHeaders = {
    userId: `${userId?.attributes?.sub}`,
  };
  return { 'spotnana-e2e-headers': JSON.stringify(e2eHeaders) };
};
