/* eslint-disable */
import type { DateTime } from '../../common/date_time';
import type { ImageGroup } from '../../common/image_group';
import type { Latlng } from '../../common/latlng';
import type { Money, PaymentMethod } from '../../common/money';
import type { PostalAddress } from '../../common/postal_address';
import type { Commission } from '../common/commission';
import type { TaxBreakdown } from '../common/tax_breakdown';
import type { UserOrgId } from '../common/user_org_id';
import type { AmadeusSessionParams } from '../supplier/amadeus';
import type { ThirdPartySource } from '../supplier/third_party_info';
import { RoomAmenityRoomAmenityTypeEnum } from './hotel_details_response';

export enum HotelAmenityType {
  TWENTY_FOUR_HOUR_FRONT_DESK = 0,
  TWENTY_FOUR_HOUR_ROOM_SERVICE = 1,
  TWENTY_FOUR_HOUR_SECURITY = 2,
  ADJOINING_ROOMS = 3,
  AIR_CONDITIONING = 4,
  AIRLINE_DESK = 5,
  ATM_CASH_MACHINE = 6,
  BABY_SITTING = 7,
  BBQ_PICNIC_AREA = 8,
  BILINGUAL_STAFF = 9,
  BOOKSTORE = 10,
  BOUTIQUES_STORES = 11,
  BRAILED_ELEVATORS = 12,
  BUSINESS_LIBRARY = 13,
  CAR_RENTAL_DESK = 14,
  CASINO = 15,
  CHECK_CASHING_POLICY = 16,
  CHECK_IN_KIOSK = 17,
  COCKTAIL_LOUNGE = 18,
  COFFEE_SHOP = 19,
  COIN_OPERATED_LAUNDRY = 20,
  CONCIERGE_DESK = 21,
  CONCIERGE_FLOOR = 22,
  CONFERENCE_FACILITIES = 23,
  COURTYARD = 24,
  CURRENCY_EXCHANGE = 25,
  DESK_WITH_ELECTRICAL_OUTLET = 26,
  DOCTOR_ON_CALL = 27,
  DOOR_MAN = 28,
  DRIVING_RANGE = 29,
  DRUGSTORE_PHARMACY = 30,
  DUTY_FREE_SHOP = 31,
  ELEVATORS = 32,
  EXECUTIVE_FLOOR = 33,
  EXERCISE_GYM = 34,
  EXPRESS_CHECK_IN = 35,
  EXPRESS_CHECK_OUT = 36,
  FAMILY_PLAN = 37,
  FLORIST = 38,
  FOLIOS = 39,
  FREE_AIRPORT_SHUTTLE = 40,
  FREE_PARKING = 41,
  FREE_TRANSPORTATION = 42,
  GAME_ROOM = 43,
  GIFT_NEWS_STAND = 44,
  HAIRDRESSER_BARBER = 45,
  ACCESSIBLE_FACILITIES = 46,
  HEALTH_CLUB = 47,
  HEATED_POOL = 48,
  HOUSEKEEPING_DAILY = 49,
  HOUSEKEEPING_WEEKLY = 50,
  ICE_MACHINE = 51,
  INDOOR_PARKING = 52,
  INDOOR_POOL = 53,
  JACUZZI = 54,
  JOGGING_TRACK = 55,
  KENNELS = 56,
  LAUNDRY_VALET_SERVICE = 57,
  LIQUOR_STORE = 58,
  LIVE_ENTERTAINMENT = 59,
  MASSAGE_SERVICES = 60,
  NIGHTCLUB = 61,
  OFF_SITE_PARKING = 62,
  ON_SITE_PARKING = 63,
  OUTDOOR_PARKING = 64,
  OUTDOOR_POOL = 65,
  PACKAGE_PARCEL_SERVICES = 66,
  PARKING = 67,
  PHOTOCOPY_CENTER = 68,
  PLAYGROUND = 69,
  POOL = 70,
  POOLSIDE_SNACK_BAR = 71,
  PUBLIC_ADDRESS_SYSTEM = 72,
  RAMP_ACCESS = 73,
  RECREATIONAL_VEHICLE_PARKING = 74,
  RESTAURANT = 75,
  ROOM_SERVICE = 76,
  SAFE_DEPOSIT_BOX = 77,
  SAUNA = 78,
  SECURITY = 79,
  SHOE_SHINE_STAND = 80,
  SHOPPING_MALL = 81,
  SOLARIUM = 82,
  SPA = 83,
  SPORTS_BAR = 84,
  STEAM_BATH = 85,
  STORAGE_SPACE = 86,
  SUNDRY_CONVENIENCE_STORE = 87,
  TECHNICAL_CONCIERGE = 88,
  THEATRE_DESK = 89,
  TOUR_SIGHTSEEING_DESK = 90,
  TRANSLATION_SERVICES = 91,
  TRAVEL_AGENCY = 92,
  TRUCK_PARKING = 93,
  VALET_CLEANING = 94,
  DRY_CLEANING = 95,
  VALET_PARKING = 96,
  VENDING_MACHINES = 97,
  VIDEO_TAPES = 98,
  WAKEUP_SERVICE = 99,
  WHEELCHAIR_ACCESS = 100,
  WHIRLPOOL = 101,
  MULTILINGUAL_STAFF = 102,
  WEDDING_SERVICES = 103,
  BANQUET_FACILITIES = 104,
  BELL_STAFF_PORTER = 105,
  BEAUTY_SHOP_SALON = 106,
  COMPLIMENTARY_SELF_SERVICE_LAUNDRY = 107,
  DIRECT_DIAL_TELEPHONE = 108,
  FEMALE_TRAVELER_ROOM_FLOOR = 109,
  PHARMACY = 110,
  STABLES = 111,
  ONE_TWENTY_AC = 112,
  ONE_TWENTY_DC = 113,
  TWO_TWENTY_AC = 114,
  ACCESSIBLE_PARKING = 115,
  TWO_TWENTY_DC = 116,
  BARBEQUE_GRILLS = 117,
  WOMENS_CLOTHING = 118,
  MENS_CLOTHING = 119,
  CHILDRENS_CLOTHING = 120,
  SHOPS_AND_COMMERCIAL_SERVICES = 121,
  VIDEO_GAMES = 122,
  SPORTS_BAR_OPEN_FOR_LUNCH = 123,
  SPORTS_BAR_OPEN_FOR_DINNER = 124,
  ROOM_SERVICE_FULL_MENU = 125,
  ROOM_SERVICE_LIMITED_MENU = 126,
  ROOM_SERVICE_LIMITED_HOURS = 127,
  VALET_SAME_DAY_DRY_CLEANING = 128,
  BODY_SCRUB = 129,
  BODY_WRAP = 130,
  PUBLIC_AREA_AIR_CONDITIONED = 131,
  EFOLIO_AVAILABLE_TO_COMPANY = 132,
  INDIVIDUAL_EFOLIO_AVAILABLE = 133,
  VIDEO_REVIEW_BILLING = 134,
  BUTLER_SERVICE = 135,
  COMPLIMENTARY_IN_ROOM_COFFEE_OR_TEA = 136,
  COMPLIMENTARY_BUFFET_BREAKFAST = 137,
  COMPLIMENTARY_COCKTAILS = 138,
  COMPLIMENTARY_COFFEE_IN_LOBBY = 139,
  COMPLIMENTARY_CONTINENTAL_BREAKFAST = 140,
  COMPLIMENTARY_FULL_AMERICAN_BREAKFAST = 141,
  DINNER_DELIVERY_SERVICE_FROM_LOCAL_RESTAURANT = 142,
  COMPLIMENTARY_NEWSPAPER_DELIVERED_TO_ROOM = 143,
  COMPLIMENTARY_NEWSPAPER_IN_LOBBY = 144,
  COMPLIMENTARY_SHOESHINE = 145,
  EVENING_RECEPTION = 146,
  FRONT_DESK = 147,
  GROCERY_SHOPPING_SERVICE_AVAILABLE = 148,
  HALAL_FOOD_AVAILABLE = 149,
  KOSHER_FOOD_AVAILABLE = 150,
  LIMOUSINE_SERVICE = 151,
  MANAGERS_RECEPTION = 152,
  MEDICAL_FACILITIES_SERVICE = 153,
  TELEPHONE_JACK_ADAPTOR_AVAILABLE = 154,
  ALL_INCLUSIVE_MEAL_PLAN = 155,
  BUFFET_BREAKFAST = 156,
  COMMUNAL_BAR_AREA = 157,
  CONTINENTAL_BREAKFAST = 158,
  FULL_MEAL_PLAN = 159,
  FULL_AMERICAN_BREAKFAST = 160,
  MEAL_PLAN_AVAILABLE = 161,
  MODIFIED_AMERICAN_MEAL_PLAN = 162,
  FOOD_AND_BEVERAGE_OUTLETS = 163,
  LOUNGES_BARS = 164,
  BARBER_SHOP = 165,
  VIDEO_CHECKOUT = 166,
  ONSITE_LAUNDRY = 167,
  TWENTY_FOUR_HOUR_FOOD_AND_BEVERAGE_KIOSK = 168,
  CONCIERGE_LOUNGE = 169,
  PARKING_FEE_MANAGED_BY_HOTEL = 170,
  TRANSPORTATION = 171,
  BREAKFAST_SERVED_IN_RESTAURANT = 172,
  LUNCH_SERVED_IN_RESTAURANT = 173,
  DINNER_SERVED_IN_RESTAURANT = 174,
  FULL_SERVICE_HOUSEKEEPING = 175,
  LIMITED_SERVICE_HOUSEKEEPING = 176,
  HIGH_SPEED_INTERNET_ACCESS_FOR_LAPTOP_IN_PUBLIC_AREAS = 177,
  WIRELESS_INTERNET_CONNECTION_IN_PUBLIC_AREAS = 178,
  ADDITIONAL_SERVICES_AMENITIES_FACILITIES_ON_PROPERTY = 179,
  TRANSPORTATION_SERVICES_LOCAL_AREA = 180,
  TRANSPORTATION_SERVICES_LOCAL_OFFICE = 181,
  DVD_VIDEO_RENTAL = 182,
  PARKING_LOT = 183,
  PARKING_DECK = 184,
  STREET_SIDE_PARKING = 185,
  COCKTAIL_LOUNGE_WITH_ENTERTAINMENT = 186,
  COCKTAIL_LOUNGE_WITH_LIGHT_FARE = 187,
  MOTORCYCLE_PARKING = 188,
  PHONE_SERVICES = 189,
  BALLROOM = 190,
  BUS_PARKING = 191,
  CHILDRENS_PLAY_AREA = 192,
  CHILDRENS_NURSERY = 193,
  DISCO = 194,
  EARLY_CHECK_IN = 195,
  LOCKER_ROOM = 196,
  NON_SMOKING_ROOMS_GENERIC = 197,
  TRAIN_ACCESS = 198,
  AEROBICS_INSTRUCTION = 199,
  BAGGAGE_HOLD = 200,
  BICYCLE_RENTALS = 201,
  DIETICIAN = 202,
  LATE_CHECK_OUT_AVAILABLE = 203,
  PET_SITTING_SERVICES = 204,
  PRAYER_MATS = 205,
  SPORTS_TRAINER = 206,
  TURNDOWN_SERVICE = 207,
  DVDS_VIDEOS_CHILDREN = 208,
  BANK = 209,
  LOBBY_COFFEE_SERVICE = 210,
  BANKING_SERVICES = 211,
  STAIRWELLS = 212,
  PET_AMENITIES_AVAILABLE = 213,
  EXHIBITION_CONVENTION_FLOOR = 214,
  LONG_TERM_PARKING = 215,
  CHILDREN_NOT_ALLOWED = 216,
  CHILDREN_WELCOME = 217,
  COURTESY_CAR = 218,
  HOTEL_DOES_NOT_PROVIDE_PORNOGRAPHIC_FILMS_TV = 219,
  HOTSPOTS = 220,
  FREE_HIGH_SPEED_INTERNET_CONNECTION = 221,
  INTERNET_SERVICES = 222,
  PETS_ALLOWED = 223,
  GOURMET_HIGHLIGHTS = 224,
  CATERING_SERVICES = 225,
  COMPLIMENTARY_BREAKFAST = 226,
  BUSINESS_CENTER = 227,
  BUSINESS_SERVICES = 228,
  SECURED_PARKING = 229,
  RACQUETBALL = 230,
  SNOW_SPORTS = 231,
  TENNIS_COURT = 232,
  WATER_SPORTS = 233,
  CHILD_PROGRAMS = 234,
  GOLF = 235,
  HORSEBACK_RIDING = 236,
  OCEANFRONT = 237,
  BEACHFRONT = 238,
  HAIR_DRYER = 239,
  IRONING_BOARD = 240,
  HEATED_GUEST_ROOMS = 241,
  TOILET = 242,
  PARLOR = 243,
  VIDEO_GAME_PLAYER = 244,
  THALASSOTHERAPY = 245,
  PRIVATE_DINING_FOR_GROUPS = 246,
  HEARING_IMPAIRED_SERVICES = 247,
  CARRYOUT_BREAKFAST = 248,
  DELUXE_CONTINENTAL_BREAKFAST = 249,
  HOT_CONTINENTAL_BREAKFAST = 250,
  HOT_BREAKFAST = 251,
  PRIVATE_POOL = 252,
  CONNECTING_ROOMS = 253,
  DATA_PORT = 254,
  EXTERIOR_CORRIDORS = 255,
  GULF_VIEW = 256,
  ACCESSIBLE_ROOMS = 257,
  HIGH_SPEED_INTERNET_ACCESS = 258,
  INTERIOR_CORRIDORS = 259,
  HIGH_SPEED_WIRELESS = 260,
  KITCHENETTE = 261,
  PRIVATE_BATH_OR_SHOWER = 262,
  FIRE_SAFETY_COMPLIANT = 263,
  WELCOME_DRINK = 264,
  BOARDING_PASS_PRINT_OUT_AVAILABLE = 265,
  PRINTING_SERVICES_AVAILABLE = 266,
  ALL_PUBLIC_AREAS_NON_SMOKING = 267,
  MEETING_ROOMS = 268,
  MOVIES_IN_ROOM = 269,
  SECRETARIAL_SERVICE = 270,
  SNOW_SKIING = 271,
  WATER_SKIING = 272,
  FAX_SERVICE = 273,
  GREAT_ROOM = 274,
  LOBBY = 275,
  MULTIPLE_PHONE_LINES_BILLED_SEPARATELY = 276,
  UMBRELLAS = 277,
  GAS_STATION = 278,
  GROCERY_STORE = 279,
  TWENTY_FOUR_HOUR_COFFEE_SHOP = 280,
  AIRPORT_SHUTTLE_SERVICE = 281,
  LUGGAGE_SERVICE = 282,
  PIANO_BAR = 283,
  VIP_SECURITY = 284,
  COMPLIMENTARY_WIRELESS_INTERNET = 285,
  CONCIERGE_BREAKFAST = 286,
  SAME_GENDER_FLOOR = 287,
  CHILDREN_PROGRAMS = 288,
  BUILDING_MEETS_LOCAL_STATE_AND_COUNTRY_BUILDING_CODES = 289,
  INTERNET_BROWSER_ON_TV = 290,
  NEWSPAPER = 291,
  PARKING_CONTROLLED_ACCESS_GATES_TO_ENTER_PARKING_AREA = 292,
  HOTEL_SAFE_DEPOSIT_BOX_NOT_ROOM_SAFE_BOX = 293,
  STORAGE_SPACE_AVAILABLE_FEE = 294,
  TYPE_OF_ENTRANCES_TO_GUEST_ROOMS = 295,
  BEVERAGE_COCKTAIL = 296,
  CELL_PHONE_RENTAL = 297,
  COFFEE_TEA = 298,
  EARLY_CHECK_IN_GUARANTEE = 299,
  FOOD_AND_BEVERAGE_DISCOUNT = 300,
  LATE_CHECK_OUT_GUARANTEE = 301,
  ROOM_UPGRADE_CONFIRMED = 302,
  ROOM_UPGRADE_ON_AVAILABILITY = 303,
  SHUTTLE_TO_LOCAL_BUSINESSES = 304,
  SHUTTLE_TO_LOCAL_ATTRACTIONS = 305,
  SOCIAL_HOUR = 306,
  VIDEO_BILLING = 307,
  WELCOME_GIFT = 308,
  HYPOALLERGENIC_ROOMS = 309,
  ROOM_AIR_FILTRATION = 310,
  SMOKE_FREE_PROPERTY = 311,
  WATER_PURIFICATION_SYSTEM_IN_USE = 312,
  POOLSIDE_SERVICE = 313,
  CLOTHING_STORE = 314,
  ELECTRIC_CAR_CHARGING_STATIONS = 315,
  OFFICE_RENTAL = 316,
  PIANO = 317,
  INCOMING_FAX = 318,
  OUTGOING_FAX = 319,
  SEMI_PRIVATE_SPACE = 320,
  LOADING_DOCK = 321,
  BABY_KIT = 322,
  CHILDRENS_BREAKFAST = 323,
  CLOAKROOM_SERVICE = 324,
  COFFEE_LOUNGE = 325,
  EVENTS_TICKET_SERVICE = 326,
  LATE_CHECK_IN = 327,
  LIMITED_PARKING = 328,
  OUTDOOR_SUMMER_BAR_CAFE = 329,
  NO_PARKING_AVAILABLE = 330,
  BEER_GARDEN = 331,
  GARDEN_LOUNGE_BAR = 332,
  SUMMER_TERRACE = 333,
  WINTER_TERRACE = 334,
  ROOF_TERRACE = 335,
  BEACH_BAR = 336,
  HELICOPTER_SERVICE = 337,
  FERRY = 338,
  TAPAS_BAR = 339,
  CAFE_BAR = 340,
  SNACK_BAR = 341,
  GUESTROOM_WIRED_INTERNET = 342,
  GUESTROOM_WIRELESS_INTERNET = 343,
  FITNESS_CENTER = 344,
  ALCOHOLIC_BEVERAGES = 345,
  NON_ALCOHOLIC_BEVERAGES = 346,
  HEALTH_AND_BEAUTY_SERVICES = 347,
  LOCAL_CALLS = 348,
  MINIBAR = 349,
  REFRIGERATOR = 350,
  IN_ROOM_SAFE = 351,
  SMOKING_ROOMS_AVAILBLE = 352,
  MOUNTAIN_VIEW = 353,
  POOL_VIEW = 354,
  BEACH_VIEW = 355,
  OCEAN_VIEW = 356,
  ROOMS_WITH_BALCONY = 357,
  FAMILY_ROOM = 358,
  CRIB_CHARGE = 359,
  ROLLAWAY_ADULT = 360,
  FREE_WIFI_IN_MEETING_ROOMS = 361,
  ECO_FRIENDLY = 362,
  EXTRA_PERSON = 363,
  STAY_SAFE = 364,
  ENHANCED_HYGIENE_CLEANLINESS_PROTOCOLS = 365,
  UNRECOGNIZED = -1,
}

export enum HotelPropertyType {
  APARTMENT = 3,
  HOTEL = 20,
  BED_AND_BREAKFAST = 4,
}

export enum HotelDeatilsInclusionsType {
  MEAL_INCLUDED_BREAKFAST = 189,
  WIRELESS_INTERNET_CONNECTION = 123,
  COFFEE_TEA_MAKER = 19,
  KITCHEN = 59,
  MINIBAR = 69,
  PRIVATE_POOL = 245,
  SAFE = 92,
  ACCESSIBLE_ROOMS = 161,
}

export enum HotelDeatilsBedType {
  DOUBLE = 1,
  QUEEN = 5,
  KING = 3,
  MORE_BEDS = -1,
}

// We will have multiple mapping for hotel room Amenities filters
// TODO: This is a place holder implementation as we are still deciding the codes, and it may change
export const HotelRoomAmenitiesMap = {
  MEAL_INCLUDED_BREAKFAST: [RoomAmenityRoomAmenityTypeEnum.MEAL_INCLUDED_BREAKFAST],
  WIRELESS_INTERNET_CONNECTION: [
    RoomAmenityRoomAmenityTypeEnum.WIRELESS_INTERNET_CONNECTION,
    RoomAmenityRoomAmenityTypeEnum.HIGH_SPEED_WIRELESS,
    RoomAmenityRoomAmenityTypeEnum.INTERNET_ACCESS,
    RoomAmenityRoomAmenityTypeEnum.COMPLIMENTARY_HIGH_SPEED_INTERNET_IN_ROOM,
    RoomAmenityRoomAmenityTypeEnum.HIGH_SPEED_INTERNET_CONNECTION,
    RoomAmenityRoomAmenityTypeEnum.HIGH_SPEED_INTERNET_ACCESS_FEE,
  ],
  COFFEE_TEA_MAKER: [RoomAmenityRoomAmenityTypeEnum.COFFEE_TEA_MAKER],
  KITCHEN: [
    RoomAmenityRoomAmenityTypeEnum.KITCHEN,
    RoomAmenityRoomAmenityTypeEnum.KITCHENETTE,
    RoomAmenityRoomAmenityTypeEnum.FULL_KITCHEN,
  ],
  MINIBAR: [RoomAmenityRoomAmenityTypeEnum.MINIBAR],
  PRIVATE_POOL: [RoomAmenityRoomAmenityTypeEnum.PRIVATE_POOL],
  SAFE: [RoomAmenityRoomAmenityTypeEnum.SAFE, RoomAmenityRoomAmenityTypeEnum.SAFE_LARGE_ENOUGH_TO_ACCOMMODATE_A_LAPTOP],
  ACCESSIBLE_ROOMS: [
    RoomAmenityRoomAmenityTypeEnum.ACCESSIBLE_ROOM,
    RoomAmenityRoomAmenityTypeEnum.ACCESSIBLE_ROOM_AMENITY,
  ],
};

export enum FeeType {
  UNKNOWN = 0,
  BED_TAX = 1,
  CITY_HOTEL_FEE = 2,
  CITY_TAX = 3,
  COUNTRY_TAX = 4,
  ENERGY_TAX = 5,
  FEDERAL_TAX = 6,
  FOOD_AND_BEVERAGE_TAX = 7,
  LODGING_TAX = 8,
  MAINTENANCE_FEE = 9,
  OCCUPANCY_TAX = 10,
  PACKAGE_FEE = 11,
  RESORT_FEE = 12,
  SALES_TAX = 13,
  SERVICE_CHARGE = 14,
  STATE_TAX = 15,
  SURCHARGE = 16,
  TOTAL_TAX = 17,
  TOURISM_TAX = 18,
  VAT_GST_TAX = 19,
  SURPLUS_LINES_TAX = 20,
  INSURANCE_PREMIUM_TAX = 21,
  APPLICATON_FEE = 22,
  EXPRESS_HANDLING_FEE = 23,
  EXEMPT = 24,
  STANDARD = 25,
  ZERO_RATED = 26,
  MISCELLANEOUS = 27,
  ROOM_TAX = 28,
  EARLY_CHECKOUT_FEE = 29,
  COUNTRY_TAXES = 30,
  EXTRA_PERSON_CHARGE = 31,
  BANQUET_SERVICE_FEE = 32,
  ROOM_SERVICE_FEE = 33,
  LOCAL_FEE = 34,
  GOODS_AND_SERVICES_TAX = 35,
  VALUE_ADDED_TAX = 36,
  CRIB_FEE = 37,
  ROLLAWAY_FEE = 38,
  ASSESSMENT_LICENSE_TAX = 39,
  PET_SANITATION_FEE = 40,
  NOT_KNOWN = 41,
  CHILD_ROLLAWAY_CHARGE = 42,
  CONVENTION_TAX = 43,
  EXTRA_CHILD_CHARGE = 44,
  STANDARD_FOOD_AND_BEVERAGE_GRATUITY = 45,
  NATIONAL_GOVERNMENT_TAX = 46,
  ADULT_ROLLAWAY_FEE = 47,
  BEVERAGE_WITH_ALCOHOL = 48,
  BEVERAGE_WITHOUT_ALCOHOL = 49,
  TOBACCO = 50,
  FOOD = 51,
  TOTAL_SURCHARGES = 52,
  STATE_COST_RECOVERY_FEE = 53,
  MISCELLANEOUS_FEE = 54,
  DESTINATION_AMENITY_FEE = 55,
  REFUNDABLE_PET_FEE = 56,
  CHARITY_SUPPORT_FEE = 57,
  LOCAL_AMENITY_USAGE_MAINTENANCE_FEE = 58,
  CONVENTION_TOURISM_FEE = 59,
  DESTINATION_MARKETING_FEE = 60,
  HOTEL_DEVELOPMENT_FEE = 61,
  EVENT_FEE = 62,
  SUSTAINABILITY_FEE = 63,
  TRANSPORTATION_TRANSFER_FEE = 64,
  INSURANCE_FEE = 65,
  LOCAL_GOVERNMENT_FEE = 66,
  LOCAL_ORDINANCE_SURCHARGE = 67,
  GUARANTEED_EARLY_CHECK_IN_FEE = 68,
  GUARANTEED_LATE_CHECK_OUT_FEE = 69,

  UNRECOGNIZED = -1,
}

export enum GuaranteeType {
  GUARANTEE = 0,
  DEPOSIT = 1,
  UNRECOGNIZED = -1,
}

/**
 * NB: See `HotelRateDeduplicator`, any non-eligibility-requiring rate types we add must be added
 * there
 */
export enum RateTypeV1 {
  PUBLISHED = 0,
  CORPORATE = 1,
  SPOTNANA = 2,
  MILITARY = 3,
  SENIOR_CITIZEN = 4,
  AAA = 5,
  AARP = 6,
  BEST_AVAILABLE_RATE = 7,
  GOVERNMENT = 8,
  MEMBERSHIP = 9,
  TMC = 10,
  UNRECOGNIZED = -1,
}

// this is bridge between v1 and v2 rate types for detail filters
export enum HotelDetailsRateType {
  UNKNOWN_RATE_TYPE = RateTypeV1.PUBLISHED,
  REGULAR = RateTypeV1.CORPORATE,
  AAA = RateTypeV1.AAA,
  SENIOR = RateTypeV1.SENIOR_CITIZEN,
  GOVERNMENT = RateTypeV1.GOVERNMENT,
  MILITARY = RateTypeV1.MILITARY,
  MEMBER = RateTypeV1.MEMBERSHIP,
  UNRECOGNIZED = RateTypeV1.UNRECOGNIZED,
}

export interface ImageSet {
  /** Image category. */
  category: ImageSetCategory;
  /** Images with captions. */
  imageGroup?: ImageGroup;
}

export enum ImageSetCategory {
  UNKNOWN_CATEGORY = 0,
  EXTERIOR_VIEW = 1,
  LOBBY_VIEW = 2,
  POOL_VIEW = 3,
  RESTAURANT = 4,
  HEALTH_CLUB = 5,
  GUEST_ROOM = 6,
  SUITE = 7,
  MEETING_ROOM = 8,
  BALLROOM = 9,
  GOLF_COURSE = 10,
  BEACH = 11,
  SPA = 12,
  BAR_OR_LOUNGE = 13,
  RECREATIONAL_FACILITY = 14,
  LOGO = 15,
  BASICS = 16,
  MAP = 17,
  PROMOTIONAL = 18,
  HOT_NEWS = 19,
  MISCELLANEOUS = 20,
  GUEST_ROOM_AMENITY = 21,
  PROPERTY_AMENITY = 22,
  BUSINESS_CENTER = 23,
  UNRECOGNIZED = -1,
}

export interface Description {
  type: DescriptionType;
  value: string;
}

export enum DescriptionType {
  UNKNOWN = 0,
  GENERAL = 1,
  ALERTS = 2,
  DINING = 3,
  FACILITIES = 4,
  RECREATION = 5,
  SERVICES = 6,
  ATTRACTIONS = 7,
  CANCELLATION_POLICY = 8,
  DEPOSIT_POLICY = 9,
  DIRECTIONS = 10,
  POLICIES = 11,
  SAFETY = 12,
  TRANSPORTATION = 13,
  UNRECOGNIZED = -1,
}

export interface HotelSpec {
  /** Name of the hotel. */
  name: string;
  /** Hotel chain name. */
  chainName: string;
  /** Hotel star rating. */
  starRating: number;
  /** Address of the hotel. */
  address?: PostalAddress;
  /** Coordinates of the hotel. */
  coordinates?: Latlng;
  /** Contact information of the hotel. */
  contactInfo?: HotelSpecContactInfo;
  /** List of amenities provided by the hotel. */
  amenities: HotelAmenity[];
  /** Additional plain text information about the hotel. */
  descriptions: Description[];
  /** List of images of the hotel. */
  imageSets: ImageSet[];
  /** Check-in/out time of the hotel. */
  checkInTime?: DateTime;
  checkOutTime?: DateTime;
  /** Hotel ID provided by the supplier. */
  sourceHotelId: string;
  /** Chain code for the hotel. */
  chainCode: string;
  /**
   * Brand name of the hotel. Note: This is the brand name provided by Spotnana
   * (may or may not same as Sabre).
   */
  brandName: string;
  /** Master chain code for the hotel. */
  masterChainCode: string;
  /**
   * Property Type tags to convey more information about hotel.
   * For example: HOTEL, ECOHOTEL, HISTORICHOTEL.
   */
  propertyTypes: PropertyTypeInfo[];
  starRatingType: HotelSpecStarRatingType;
  /** Hotel amenities that cannot be mapped to Spotnana enums. */
  additionalAmenities: string[];
  /** Third party hotel codes */
  thirdPartyHotelCodes: ThirdPartyHotelCode[];
}

export enum HotelSpecStarRatingType {
  OFFICIAL = 0,
  SELF_RATED = 1,
  UNRECOGNIZED = -1,
}

export interface HotelSpecContactInfo {
  phone: string[];
  fax: string[];
  email?: string[];
}

export interface PropertyTypeInfo {
  type: PropertyTypeInfoPropertyType;
  description: string;
}

export enum PropertyTypeInfoPropertyType {
  UNKNOWN_PROPERTY = 0,
  ALL_SUITE = 1,
  ALL_INCLUSIVE_RESORT = 2,
  APARTMENT = 3,
  BED_AND_BREAKFAST = 4,
  CABIN_OR_BUNGALOW = 5,
  CAMPGROUND = 6,
  CHALET = 7,
  CONDOMINIUM = 8,
  CONFERENCE_CENTER = 9,
  CORPORATE_BUSINESS_TRANSIENT = 11,
  CRUISE = 12,
  EXTENDED_STAY = 13,
  GUEST_FARM = 15,
  GUEST_HOUSE_LIMITED_SERVICE = 16,
  HEALTH_SPA = 17,
  HOLIDAY_RESORT = 18,
  HOSTEL = 19,
  HOTEL = 20,
  INN = 21,
  LODGE = 22,
  MONASTERY = 26,
  MOTEL = 27,
  RANCH = 28,
  RESIDENTIAL_APARTMENT = 29,
  RESORT = 30,
  TENT = 33,
  VACATION_HOME = 34,
  VILLA = 35,
  WILDLIFE_RESERVE = 36,
  CASTLE = 37,
  GOLF = 39,
  PENSION = 40,
  SKI = 41,
  SPA = 42,
  BOATEL = 44,
  HISTORICAL = 48,
  RECREATIONAL_VEHICLE_PARK = 50,
  CHARM_HOTEL = 51,
  OTHER = 62,
  UNRECOGNIZED = -1,
}

export interface HotelAmenity {
  /** The type of the amenity. */
  type: HotelAmenityType;
  /** Description of the amenity. */
  additionalInfo: string;
  /** Whether the amenity is complimentary. */
  complimentary: boolean;
}

export interface OccupancyDateParams {
  occupancy: OccupancyDateParamsOccupancy[];
  checkInDate?: DateTime;
  checkOutDate?: DateTime;
}

export interface OccupancyDateParamsOccupancy {
  /** Number of adults */
  numAdults: number;
  /** Number of children */
  numChildren: number;
  /** Ages of children, in case num_children is not 0. */
  childAges?: number[];
  /** Number of infants */
  numInfants?: number;
}

export interface HotelSearchKey {
  /** The third party pagination key. */
  sourcePaginationKey: string;
  /** Identifier for the hotel search request. */
  searchId: string;
}

/** Proto object to include pcc and its corresponding supplier name. */
export interface PCCDetail {
  /** Pcc code of the supplier. */
  pcc: string;
  /** Third party supplier for the booking. */
  supplier: ThirdPartySource;
}

export interface HotelDetailsKey {
  /** Code used by Spotnana to refer to a hotel. */
  hotelRefCode: string;
  /** Occupancy values and dates for the hotel search. */
  occupancyDates?: OccupancyDateParams;
  /** @deprecated */
  currency: string;
  /** @deprecated */
  pcc: string;
  /** User and organization details of the user who is making the request. */
  userOrgId?: UserOrgId;
  /** Identifier for the corresponding hotel search request. */
  searchId: string;
  /** Pcc details of each of the supplier in which hotel is present. */
  pccDetails: PCCDetail[];
}

export interface BookingKey {
  /** @deprecated */
  sabreBookingKey: string;
  /** Validity of the booking key generated by the supplier. */
  timeout?: DateTime;
  /** Identifier for the corresponding hotel details request. */
  hotelDetailsId: string;
  /** Serialized PriceValidateKey */
  priceValidateKey: string;
  /** Guarantee type for payment for the rate. */
  guaranteeType: GuaranteeType;
  pcc: string;
  /** Identifier for the corresponding hotel price check request. */
  priceCheckId: string;
  /** Identifier for the corresponding hotel search request. */
  searchId: string;
  /** Third party supplier for the booking. */
  supplier: ThirdPartySource;
  /** Booking key provided by supplier. */
  sourceBookingKey: string;
  /** Key encapsulating payment related information. */
  paymentKey: string;
}

export interface PriceValidateKey {
  /** Identifier for the corresponding hotel details request. */
  hotelDetailsId: string;
  /** Rate key of supplier used to revalidate the rate. */
  sourcePriceValidateKey: string;
  pcc: string;
  userOrgId?: UserOrgId;
  /** Identifier for the corresponding hotel search request. */
  searchId: string;
  /** Third party supplier for the booking. */
  supplier: ThirdPartySource;
  /** Occupancy values and dates for the booking. */
  occupancyDates?: OccupancyDateParams;
  /** Code used by Spotnana to refer a hotel. */
  hotelRefCode: string;
}

export interface AmadeusPriceValidateKey {
  /** Amadeus booking code. */
  bookingCode: string;
  /** Amadeus identifier for the room type. */
  roomTypeCode: string;
  /** Amadeus identifier for the rate plan. */
  ratePlanCode: string;
}

export interface AmadeusBookingKey {
  /** Amadeus booking code. */
  bookingCode: string;
  /** Booking session parameters */
  sessionParams?: AmadeusSessionParams;
}

export interface Rate {
  base?: Money;
  tax?: Money;
  fees: Fee[];
  /** Commission earned on the total booking value. */
  commission?: Commission;
  /** Breakdown of different types of taxes. */
  taxBreakdown?: TaxBreakdown;
  /** List of allowed forms of payment allowed for this rate option. */
  allowedFop: PaymentMethod[];
  /** Total amount */
  total?: Money;
}

export interface Fee {
  /** Fees amount */
  amount?: Money;
  /** Fee type, enum based on OTA codes for Fees and Taxes Type (FTT) */
  type: FeeType;
  /** Fee description (Used only in manual forms) */
  text: string;
  /** Flag to determine whether to explicitly show this fee type on UI */
  displayFee: boolean;
  /** Amenities included as part of the fee */
  feeInclusions: string[];
}

/**
 * Statistics data for hotel rates. Each rate statistics item can be calculated
 * for total and base amount.
 */
export interface RateStatistics {
  /** Median of nightly rate for the given hotel search. */
  median?: RateStatisticsStatisticsItem;
  /** Size of the sample set used to calculate the statistics. */
  sampleSize: number;
  policyType: RateStatisticsPolicyType;
}

export enum RateStatisticsPolicyType {
  /** DEFAULT - Use default parameters for querying and calculating statistics. */
  DEFAULT = 0,
  /** SET_BY_ADMIN - Use parameters set by the admin. */
  SET_BY_ADMIN = 1,
  UNRECOGNIZED = -1,
}

export interface RateStatisticsStatisticsItem {
  total?: Money;
  base?: Money;
}

/** Information about promotional offers that are applicable to the booking. */
export interface PromotionalOffer {
  /** Name of the promotional offer applied on this hotel rate. */
  name: string;
}

/** Third party hotel code mapping to be returned as part of hotel spec. */
export interface ThirdPartyHotelCode {
  hotelCodeType: ThirdPartyHotelCodeHotelCodeType;
  hotelCode: string;
}

export enum ThirdPartyHotelCodeHotelCodeType {
  HOTEL_CODE_TYPE_UNKNOWN = 0,
  SABRE_CSL = 1,
  SABRE_TN = 2,
  EXPEDIA_RAPID = 3,
  MARRIOTT = 4,
  GIATA = 5,
  UNRECOGNIZED = -1,
}

/** The co2 emission detail for the hotel. */
export interface HotelCo2EmissionDetail {
  /** Co2 emission value in kg per night. */
  co2EmissionValue: number;
}
