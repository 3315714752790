/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';
import { ComboboxStore, SelectStore } from '@ariakit/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useSelectConfig } from '../SelectRoot/SelectConfigContext';
import { TSelectOption, TSelectOptionList } from './SelectRenderer';
import { OptionWithStringifiedValue } from './storeUtils';
import { noop } from '../utils/fp';
import { SelectListLoader, TSelectSizes, selectRootLocalItems } from '../SelectRoot';
import { input_wrapper } from '../Input/Input.styles';
import { ItemStylingProps } from '../SelectRoot/defaultItemRenderers/types';
import { getFoundationVar } from '../GlobalStyles/globalStylesUtils';

const { SearchField } = selectRootLocalItems;

const MemodItem = React.memo(Item);

const RegularListWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className="ItemListWrapper">{children}</div>
);

function ItemList_<TItem extends TSelectOption>({
  list,
  renderInPlace,
  emptyContent,
  label = '',
  showLoader,
}: // children,
{
  label?: string;
  list: TSelectOptionList<OptionWithStringifiedValue<TItem>>;
  renderInPlace?: boolean;
  /**
   * Parent decides whether to send empty content or not
   */
  emptyContent?: React.ReactNode;
  children?: React.ReactNode;
  showLoader?: boolean;
}): JSX.Element | null {
  const { comboboxStore, selectStore, slots, size } = useSelectConfig();
  const { t: tt } = useTranslation('WEB');

  return (
    <SharedList
      //     /**
      //      * TODO: Raise a bug report with Ariakit. Currently, if items are changed after the popover is open (in search)
      //      * popover can not be closed by changing focus or clicking outside.
      //      *
      //      * To avoid this bug, we pass a key to destroy and recreate the wrapper.
      //      */
      key={renderInPlace ? undefined : list.length}
      ListWraperElement={RegularListWrapper}
      {...{
        itemsEl: (
          <>
            {list.map((item) => (
              <MemodItem item={item} key={item.label + item.value} />
            ))}
          </>
        ),
        slots,
        renderInPlace,
        label,
        showLoader,
        emptyContent,
        size,
        selectStore,
        comboboxStore,
        tt,
      }}
    />
  );
}

/**
 * Reasonable default item size is 48 in most cases, needs to be customised in `virtual` prop given to Select
 * Refer to tanstack virtual docs on what estimateSize does
 */
const estimateSize = () => 48;

function ItemListVirtual_<TItem extends TSelectOption>({
  list,
  renderInPlace,
  emptyContent,
  label = '',
  showLoader,
}: {
  label?: string;
  list: TSelectOptionList<OptionWithStringifiedValue<TItem>>;
  renderInPlace?: boolean;
  /**
   * Parent decides whether to send empty content or not
   */
  emptyContent?: React.ReactNode;
  children?: React.ReactNode;
  showLoader?: boolean;
}): JSX.Element | null {
  const { comboboxStore, selectStore, slots, size, virtual: virtualOrig } = useSelectConfig();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const virtual = virtualOrig!;
  const { t: tt } = useTranslation('WEB');
  const scrollerRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: list.length,
    getScrollElement: () => scrollerRef.current,
    estimateSize: virtual.estimateSize ?? estimateSize,
  });

  const Wrapper = useCallback(
    ({ children }: { children: React.ReactNode }) => (
      <div
        ref={scrollerRef}
        style={{
          height: virtual.listHeight || 500,
          overflowY: 'auto',
          contain: 'strict',
        }}
      >
        {children}
      </div>
    ),
    [virtual.listHeight],
  );

  return (
    <SharedList
      {...{
        ListWraperElement: Wrapper,
        itemsEl: (
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: 'translateY(0px)',
              }}
            >
              {rowVirtualizer.getVirtualItems().map((vItem) => (
                <MemodItem
                  item={list[vItem.index]}
                  key={vItem.key}
                  data-key={vItem.key}
                  data-index={vItem.index}
                  itemRef={rowVirtualizer.measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: `var(${getFoundationVar('padding-superTight')})`,
                    right: `var(${getFoundationVar('padding-superTight')})`,
                    width: 'calc(100% - 2 * var(--padding-superTight))',
                    minHeight: `${vItem.size}px`,
                    transform: `translateY(${vItem.start}px)`,
                  }}
                />
              ))}
            </div>
          </div>
        ),
        slots,
        renderInPlace,
        label,
        showLoader,
        emptyContent,
        size,
        selectStore,
        comboboxStore,
        tt,
      }}
    />
  );
}

function SharedList({
  slots,
  renderInPlace,
  label,
  showLoader,
  emptyContent,
  size,
  selectStore,
  comboboxStore,
  tt,
  itemsEl,
  ListWraperElement,
}: {
  itemsEl: React.ReactElement;
  ListWraperElement: React.FC<{ children: React.ReactNode }>;
  slots: ReturnType<typeof useSelectConfig>['slots'];
  renderInPlace?: boolean;
  label: string;
  showLoader?: boolean;
  emptyContent: React.ReactNode;
  size?: TSelectSizes | undefined;
  selectStore: SelectStore;
  comboboxStore?: ComboboxStore;
  tt: any;
}) {
  return (
    <slots.ListWrapper
      /**
       * TODO: Raise a bug report with Ariakit. Currently, if items are changed after the popover is open (in search)
       * popover can not be closed by changing focus or clicking outside.
       *
       * To avoid this bug, we pass a key to destroy and recreate the wrapper.
       */
      renderInPlace={renderInPlace}
      selectStore={selectStore}
      comboboxStore={comboboxStore}
    >
      <div className={clsx('BlocksSelect-OptionListWrapper', `option-list-size-${size}`)}>
        <ListHeaderAndSearch label={label} />

        {slots.listPrefixContent && <div className="BlocksSelect-ListPrefix">{slots.listPrefixContent}</div>}

        <ListWraperElement>
          {itemsEl}
          {showLoader &&
            (slots.listLoader?.loadingComponent ?? (
              <SelectListLoader
                loaderText={slots.listLoader?.loaderText ?? tt('Loading...')}
                numberOfRows={slots.listLoader?.numberOfRows}
              />
            ))}

          {!showLoader && emptyContent && <div className="BlocksSelect-EmptyContent">{emptyContent}</div>}
          {showLoader && (slots.listLoader?.loadingComponent ?? <div className="BottomLoader" />)}
        </ListWraperElement>
        <ListFooterArea />
      </div>
    </slots.ListWrapper>
  );
}

export const ItemList = React.memo(ItemList_);
export const ItemListVirtual = React.memo(ItemListVirtual_);

function Item<TItem extends TSelectOption>({
  item,
  ...rest
}: {
  item: TSelectOptionList<OptionWithStringifiedValue<TItem>>[number];
} & ItemStylingProps) {
  const {
    variant,
    slots: { SingleItem, MultiItem },
  } = useSelectConfig();
  const ItemSlot = useCallback<React.FC<{ option: OptionWithStringifiedValue<TItem> }>>(
    ({ option, ...stylingProps }) => {
      if (variant === 'single') {
        return <SingleItem key={option.value} option={option} {...stylingProps} />;
      }
      if (variant === 'multiple') {
        return <MultiItem key={option.value} option={option} {...stylingProps} />;
      }
      return null;
    },
    [MultiItem, SingleItem, variant],
  );

  if ('list' in item) {
    /**
     * TODO: follow up PR, nested support
     */
    return null;
  }
  return <ItemSlot option={item} {...rest} />;
}

function ListHeaderAndSearch({ label }: { label: string }) {
  const { slots, comboboxStore, selectStore } = useSelectConfig();
  if (!slots.ListHeader && !comboboxStore) return null;

  return (
    <div className="BlocksSelect-ListHeader" css={input_wrapper}>
      {slots.ListHeader && (
        <slots.ListHeader
          close={selectStore?.hide ?? noop}
          clear={noop}
          // evaluate if needed or can be removed
          // clear={() => {
          //   if (variant === 'single') {
          //     (selectStore as SelectStore).setValue('');
          //     selectStore?.hide();
          //   } else {
          //     (selectStore as SelectStore).setValue([]);
          //     selectStore?.hide();
          //   }
          //   selectStore.clearSelection?.();
          // }}
          heading={label}
        />
      )}
      {comboboxStore && <SearchField icon="Search" hasHeader={Boolean(slots.ListHeader)} />}
    </div>
  );
}

function ListFooterArea() {
  const {
    variant,
    selectStore,
    slots: { ListFooter },
  } = useSelectConfig();

  return ListFooter ? (
    <ListFooter
      variant={variant}
      clear={() => {
        if (variant === 'single') {
          (selectStore as SelectStore).setValue('');
          // TODO:
          // onClear('');
        } else {
          (selectStore as SelectStore).setValue([]);
          // onClear([]);
        }
        selectStore.clearSelection?.();
      }}
      close={selectStore?.hide ?? noop}
      selectAll={() => {
        if (variant === 'multiple') {
          selectStore.setState('value', () => {
            const value = selectStore
              .getState()
              .renderedItems.map((i) => i.value)
              .filter(Boolean);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return value as any;
          });
        }
      }}
      expanded={false}
      onSetExpand={noop}
      optionsLength={0}
    />
  ) : null;
}

export const RootItemList_testable = {
  ListFooterArea,
  ListHeaderAndSearch,
};
