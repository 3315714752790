import { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import {
  ContextOverride,
  IAnalytics,
  reportEvent,
  TelemetryContext,
  TelemetryErrors,
  TelemetrySource,
  Traits,
  useBaseTelemetryProvider,
} from 'obt-common';
import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import useEmbedStore from '../../components/EmbedContext/useEmbedStore';
import { EmbedEventTypes, AnalyticsEvent } from '../../components/EmbedContext/types';

interface IProps {
  providerKey?: string;
  maxQueueSize?: number;
  options?: any;
}

export const WebTelemetryProvider = ({
  children,
  providerKey,
  maxQueueSize = 25,
  options,
}: PropsWithChildren<IProps>): ReactElement => {
  const [error, setError] = useState<boolean>(false);
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);

  const { reportEvent: reportEmbedEvent } = useEmbedStore();

  // initialize telemetry provider
  useEffect(() => {
    if (!providerKey) return;
    const initialize = async (): Promise<void> => {
      try {
        const [response] = await AnalyticsBrowser.load({ writeKey: providerKey }, options);
        setAnalytics(response);
      } catch (ex) {
        reportEvent(TelemetryErrors.PROVIDER_ERROR, { message: ex });
        setError(true);
      }
    };
    initialize();
  }, [providerKey, options]);

  const getAnalytics = useMemo((): IAnalytics | undefined => {
    if (!analytics) return undefined;
    return {
      identify: async (id: string, traits?: Traits, context?: ContextOverride): Promise<object> => {
        await analytics.identify(id, traits);
        return analytics.group(context?.groupId);
      },
      track: (name: string, properties?: object, context?: ContextOverride): Promise<object> =>
        analytics.track(name, properties, context),
      source: TelemetrySource.WEB,
    };
  }, [analytics]);

  const {
    trackUser,
    trackError,
    trackEvent: trackEventBase,
    trackSurface,
  } = useBaseTelemetryProvider({
    analytics: getAnalytics,
    maxQueueSize,
    enabled: true,
    error,
  });

  const trackEvent: typeof trackEventBase = async (event) => {
    reportEmbedEvent({
      type: EmbedEventTypes.ANALYTICS,
      payload: {
        eventType: event.name,
        ...event.data,
      },
    } as AnalyticsEvent);

    await trackEventBase(event);
  };

  const values = {
    trackUser,
    trackSurface,
    trackEvent,
    trackError,
  };

  return <TelemetryContext.Provider value={values}>{children}</TelemetryContext.Provider>;
};
